import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import {
    ADD_FTL_ENTRY,
    BACK,
    FTL_FORM_TABLE_HEADERS,
    FTL_PAGES,
    FTL_STEPPER_HEADING_DESCRIPTIONS,
    FTL_STEPPER_HEADINGS,
    NEXT,
    CANCEL,
    REMOVE_FTL_ENTRY,
    STEP_FTL_BOL_INDEX,
    SAVE_DRAFT,
    FTL_STATUSES,
    FULL_TRUCKLOAD,
    SHIPPER,
    COUNTRY_OPTIONS,
    CONSIGNEE,
    APPROVE_FTL,
    UNAPPROVE_FTL,
    MARK_DRAFT_FTL,
    STEP_FTL_DOCUMENTS_INDEX,
    STEP_FTL_ITEMS_INDEX,
    STEP_FTL_ADDRESS_INDEX,
    SHIP,
    DElIVERY,
    TIME_IN,
    TIME_OUT,
    FTL_STEPPER_DOCUMENTS_HELPER_TEXT,
    REQUEST_FOR_APPROVAL,
    VIEW_DOCUMENTS,
    PARTIES_TO_TRANSACTION_OPTIONS,
    SHIPMENT_TERMS_OPTIONS,
    DUTIES_TAXES_PAYABLE_BY_OPTIONS,
    CURRENCY_OF_SALE_OPTIONS,
    MARK_FTL_SHIPPED,
    MARK_FTL_UNSHIPPED,
    MARK_FTL_INCOMPLETE,
    MARK_FTL_COMPLETE,
    VIEW_BOL,
    UPLOAD_BOL_AND_CI,
    ADD_DOCUMENT,
    REMOVE_DOCUMENT,
    FTL_STEPPER_UPLOAD_DOCUMENTS, FTL_CARRIER_MISSING_MESSAGE, FTL_BROKER_MISSING_MESSAGE,
} from "../constants";
import React from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import withShipment from "../../../withShipment";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Button, IconButton, Step, StepButton, Stepper, TextField, Tooltip, Typography} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {listAvailableLocations, listPackages, listProducts} from "../../../redux/actions/settings";
import {availableLocationsSelector, packagesSelector, productsSelector} from "../../../redux/selectors/settings";
import Checkbox from "@material-ui/core/Checkbox";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {getByteArray, SAVE} from "../../global/constants";
import {
    fetchFullTruckloadSettings,
    listFullTruckloadBrokers,
    listFullTruckloadCarriers,
    saveFullTruckload,
    updateFullTruckload
} from "../../../redux/actions/fullTruckload";
import {Box} from "@mui/material";
import {getStateAndProvinceMapping} from "../../../redux/actions/global";
import {stateAndProvinceMappingSelector} from "../../../redux/selectors/global";
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";
import DescriptionIcon from '@mui/icons-material/Description';
import FolderIcon from '@mui/icons-material/Folder';
import {
    fullTruckloadSettingsSelector, fullTruckloadUnavailableItemsSelector
} from "../../../redux/selectors/fullTruckload";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import DragAndDrop from "../../global/Dnd/DragAndDrop";
import {DRAG} from "../../automationrules/constants";
import DehazeIcon from "@material-ui/icons/Dehaze";
import {AssignmentTurnedIn} from "@material-ui/icons";
import ScheduleIcon from "@material-ui/icons/Schedule";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {Unpublished} from "@mui/icons-material";
import {Layout} from "../../global/Table/TablePageHelpers/LayoutObject";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SaveIcon from '@mui/icons-material/Save';

//styling
import './FullTruckloadForm.css'
import PublishIcon from "@material-ui/icons/Publish";
import FileUpload from "../../global/FileUpload";
import {fetchFullTruckloadCarriers} from "../../../redux/actions/fullTruckloadCarriers";
import {fetchFullTruckloadBrokers} from "../../../redux/actions/fullTruckloadBrokers";
import {fullTruckloadCarriersSelector} from "../../../redux/selectors/fullTruckloadCarrier";
import {fullTruckloadBrokersSelector} from "../../../redux/selectors/fullTruckloadBroker";
import {ERROR, PASSWORD_SUCCESS_MESSAGE, SUCCESS} from "../../settings/ManageUsers/constants";
import FeedbackBar from "../../feedbackBar/FeedbackBar";

const defaultProps = {
    bgcolor: 'background.paper',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    m: 1,

    border: 1,
    style: { width: '100%', height: '100%' },
};

class FullTruckloadForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [{
                shortcode: "",
                quantity: 0,
                numOfSkids: "0.00",
                isSample: false
            }],
            fromAddress: {
                selectedLocation: "",
                contactName: "",
                companyName: "",
                phoneNumber: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                stateOrProvince: "",
                postalCode: "",
                country: "",
                email: "",
                shipAddressId: ""
            },
            toAddress: {
                selectedLocation: "",
                contactName: "",
                companyName: "",
                phoneNumber: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                stateOrProvince: "",
                postalCode: "",
                country: "",
                email: "",
                shipAddressId: ""
            },
            shipmentNumber: "",
            sealNumber: "",
            poNumber: "",
            deliveryNumber: "",
            truckNumber: "",
            trailerNumber: "",
            shipDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0),
            expectedDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0),
            receivedDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0),
            pickupTimeIn: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0),
            pickupTimeOut: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0),
            receivedTimeIn: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0),
            receivedTimeOut: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0),
            carrierName: "",
            brokerName: "",
            packingCost: "",
            freightCost: "",
            devanCost: "",
            insuranceCost: "",
            otherCosts: "",
            partiesToTransaction: "",
            shipmentTerms: "",
            dutiesTaxesPayableBy: "",
            dutiesTaxesPayableByExporter: "",
            dutiesTaxesPayableByOther: "",
            currencyOfSale: "",
            emergencyPhoneNum: "",
            notes: "",
            attachments: [],
            billOfLading: null,
            finalDocument: null,
            status: FTL_STATUSES.DRAFT,

            activeStep: 0,
            settingsAttachments: [],
            displayContentCreator: this.getDisplayContentCreator(),
            newAttachments: [],
            deletedAttachments:[],
            filteredPackages: [],
            filteredProducts:[],
            errorMessage:"",
            feedbackBarOpen:false,
            itemsNotEnabled:[]
        }
        this.state.displayContents = this.getDisplayContents()
    }

    componentDidMount() {
        this.props.listProducts()
        this.props.listPackages()
        this.props.getStateAndProvinceMapping()
        this.props.listAvailableLocations()
        this.props.fetchFullTruckloadCarriers()
        this.props.fetchFullTruckloadBrokers()
        this.props.fetchFullTruckloadSettings()
        if (this.props.updating) {
            const editingFullTruckload = this.props.editingFullTruckload
            this.setState({
                items: editingFullTruckload.items,
                fromAddress: editingFullTruckload.fromAddress,
                toAddress: editingFullTruckload.toAddress,
                shipmentNumber: editingFullTruckload.shipmentNumber,
                sealNumber: editingFullTruckload.sealNumber,
                poNumber: editingFullTruckload.poNumber,
                deliveryNumber: editingFullTruckload.deliveryNumber,
                truckNumber: editingFullTruckload.truckNumber,
                trailerNumber: editingFullTruckload.trailerNumber,
                shipDate: new Date(editingFullTruckload.shipDate),
                expectedDate: new Date(editingFullTruckload.expectedDate),
                receivedDate: editingFullTruckload.receivedDate ? new Date(editingFullTruckload.receivedDate) : null,
                pickupTimeIn: new Date(editingFullTruckload.pickupTimeIn),
                pickupTimeOut: new Date(editingFullTruckload.pickupTimeOut),
                receivedTimeIn: editingFullTruckload.receivedTimeIn ? new Date(editingFullTruckload.receivedTimeIn) : null,
                receivedTimeOut: editingFullTruckload.receivedTimeOut ? new Date(editingFullTruckload.receivedTimeOut) : null,
                carrierName: editingFullTruckload.carrierName,
                brokerName: editingFullTruckload.brokerName,
                packingCost: editingFullTruckload.packingCost,
                freightCost: editingFullTruckload.freightCost,
                devanCost: editingFullTruckload.devanCost,
                insuranceCost: editingFullTruckload.insuranceCost,
                otherCosts: editingFullTruckload.otherCosts,
                partiesToTransaction: editingFullTruckload.partiesToTransaction,
                shipmentTerms: editingFullTruckload.shipmentTerms,
                dutiesTaxesPayableBy: editingFullTruckload.dutiesTaxesPayableBy,
                dutiesTaxesPayableByExporter: editingFullTruckload.dutiesTaxesPayableByExporter ? editingFullTruckload.dutiesTaxesPayableByExporter : "",
                dutiesTaxesPayableByOther: editingFullTruckload.dutiesTaxesPayableByOther ? editingFullTruckload.dutiesTaxesPayableByOther : "",
                currencyOfSale: editingFullTruckload.currencyOfSale,
                emergencyPhoneNum: editingFullTruckload.emergencyPhoneNum,
                notes: editingFullTruckload.notes,
                attachments: [
                    ...editingFullTruckload.attachments,
                    ...(editingFullTruckload.newAttachments || [])
                ],
                billOfLading: editingFullTruckload.billOfLading,
                finalDocument: editingFullTruckload.finalDocument,
                status: editingFullTruckload.status,
                settingsAttachments: editingFullTruckload.newAttachments?.map((attachment) => {return {file: attachment}})
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.dutiesTaxesPayableBy !== "" && prevState.dutiesTaxesPayableBy !== this.state.dutiesTaxesPayableBy) {
            this.setState({
                dutiesTaxesPayableByExporter: "",
                dutiesTaxesPayableByOther: ""
            })
        }
        if (prevState.shipDate !== this.state.shipDate || prevState.brokerName !== this.state.brokerName
            || prevState.dutiesTaxesPayableBy !== this.state.dutiesTaxesPayableBy || prevState.dutiesTaxesPayableByExporter !== this.state.dutiesTaxesPayableByExporter
            || prevState.dutiesTaxesPayableByOther !== this.state.dutiesTaxesPayableByOther || prevState.expectedDate !== this.state.expectedDate
            || prevState.carrierName !== this.state.carrierName || prevState.shipmentTerms !== this.state.shipmentTerms
            || prevState.pickupTimeIn !== this.state.pickupTimeIn || prevState.shipmentNumber !== this.state.shipmentNumber
            || prevState.currencyOfSale !== this.state.currencyOfSale || prevState.pickupTimeOut !== this.state.pickupTimeOut
            || prevState.sealNumber !== this.state.sealNumber || prevState.packingCost !== this.state.packingCost
            || prevState.receivedDate !== this.state.receivedDate || prevState.truckNumber !== this.state.truckNumber
            || prevState.freightCost !== this.state.freightCost || prevState.receivedTimeIn !== this.state.receivedTimeIn
            || prevState.trailerNumber !== this.state.trailerNumber || prevState.devanCost !== this.state.devanCost
            || prevState.receivedTimeOut !== this.state.receivedTimeOut || prevState.poNumber !== this.state.poNumber
            || prevState.insuranceCost !== this.state.insuranceCost || prevState.partiesToTransaction !== this.state.partiesToTransaction
            || prevState.deliveryNumber !== this.state.deliveryNumber || prevState.otherCosts !== this.state.otherCosts
            || prevState.emergencyPhoneNum !== this.state.emergencyPhoneNum
            || this.state.displayContentCreator !== prevState.displayContentCreator) {
            this.setState({
                displayContents: this.getDisplayContents()
            })
        }

        if (prevState.shipDate !== this.state.shipDate) {
            this.onShipDateChange(this.state.shipDate)
        }

        if (prevState.receivedDate !== this.state.receivedDate) {
            this.onReceivedDateChange(this.state.receivedDate)
        }

        if (prevProps.fullTruckloadBrokers !== this.props.fullTruckloadBrokers || prevProps.fullTruckloadCarriers !== this.props.fullTruckloadCarriers || prevState.dutiesTaxesPayableBy !== this.state.dutiesTaxesPayableBy) {
            this.setState({
                displayContentCreator: this.getDisplayContentCreator()
            })
        }
        if (prevProps.fullTruckloadSettings !== this.props.fullTruckloadSettings) {
            const attachments = this.props.fullTruckloadSettings.attachments
            const updatedAttachments = this.setAttachmentIds(attachments)
            this.setState({
                settingsAttachments : [...updatedAttachments, ...this.state.settingsAttachments]
            })
        }

        // filter out packages with no location enabled
        if (prevProps.packages !== this.props.packages && this.props.packages !== null) {
            const packages = this.props.packages.filter(curPackage =>curPackage.locations.length > 0)
            this.setState({
                filteredPackages: packages
            })
        }

        // filter out products with no location enabled
        if (prevProps.products !== this.props.products && this.props.products !== null) {
            const products = this.props.products.filter(curProduct => curProduct.locations.length > 0)
            this.setState({
                filteredProducts: products
            })
        }

        if (prevProps.unavailableItems !== this.props.unavailableItems) {
            this.setState({
                itemsNotEnabled: this.props.unavailableItems
            })
            if (this.props.unavailableItems.length > 0) {
                let errorMessage = "FTL update failed, items(s) with shortcode "
                let firstShortcode = true
                this.props.unavailableItems.map((shortcode)=>{
                    errorMessage += firstShortcode ? shortcode : ", " +shortcode
                })
                errorMessage += " is not enabled for current ship from location"
                this.setState({
                    errorMessage:errorMessage,
                    feedbackBarOpen: true
                })
            }
        }
    }

    getDisplayContents = () => {
        return this.getDisplayContentCreator()(this.handleFieldChange, this.getFieldValue)
    }

    getDisplayContentCreator = () => {
        return Layout.newLayout(4, [
            Layout.row([
                Layout.rowElement(1, 'shipDate', 'Ship Date', Layout.elementStyle.dateSelect, null, this.state?.status === FTL_STATUSES.COMPLETED, null, false, null, true, true),
                Layout.rowElement(1, 'brokerName', 'Broker Name', Layout.elementStyle.selectDropdown, this.props.fullTruckloadBrokers ? this.props.fullTruckloadBrokers.map(broker => broker.name) : [], this.state?.status === FTL_STATUSES.COMPLETED),
                Layout.rowElement(1, 'dutiesTaxesPayableBy', 'Duties & Taxes Payable By', Layout.elementStyle.selectDropdown, DUTIES_TAXES_PAYABLE_BY_OPTIONS, this.state?.status === FTL_STATUSES.COMPLETED, null, true, null, true, true),
                Layout.rowElement(1, 'receivedDate', 'Received Date', Layout.elementStyle.dateSelect, null, this.state?.status === FTL_STATUSES.COMPLETED, null),
            ]),
            Layout.row([
                Layout.rowElement(1, 'expectedDate', 'Expected Delivery Date', Layout.elementStyle.dateSelect, null, this.state?.status === FTL_STATUSES.COMPLETED, null, false, null, true, true),
                Layout.rowElement(1, 'carrierName', 'Carrier Name', Layout.elementStyle.selectDropdown, this.props.fullTruckloadCarriers ? this.props.fullTruckloadCarriers.map(carrier => carrier.name) : [] , this.state?.status === FTL_STATUSES.COMPLETED),
                this.state?.dutiesTaxesPayableBy === DUTIES_TAXES_PAYABLE_BY_OPTIONS[0] ?
                    Layout.rowElement(1, 'dutiesTaxesPayableByExporter', 'Exporter Account Number', Layout.elementStyle.textBox, null, this.state?.status === FTL_STATUSES.COMPLETED, true, this.state.dutiesTaxesPayableByExporter, null, true, true)
                    : this.state?.dutiesTaxesPayableBy === DUTIES_TAXES_PAYABLE_BY_OPTIONS[2] ?
                        Layout.rowElement(1, 'dutiesTaxesPayableByOther', 'Name of Payer', Layout.elementStyle.textBox, null, this.state?.status === FTL_STATUSES.COMPLETED, true, this.state.dutiesTaxesPayableByOther, null, true, true)
                        :
                        Layout.rowElement(1, '', '', Layout.elementStyle.blankSpacer, null, false, null, true, null, true, true),
                Layout.rowElement(1, 'receivedTimeIn', 'Received-Time In', Layout.elementStyle.timeSelect, null, this.state?.status === FTL_STATUSES.COMPLETED, null),
            ]),
            Layout.row([
                Layout.rowElement(1, 'pickupTimeIn', 'Pick Up-Time In', Layout.elementStyle.timeSelect, null, this.state?.status === FTL_STATUSES.COMPLETED, null, true, null, true, true),
                Layout.rowElement(1, 'shipmentNumber', 'Shipment Number', Layout.elementStyle.textBox, null, this.state?.status === FTL_STATUSES.COMPLETED),
                Layout.rowElement(1, 'shipmentTerms', 'Shipment Terms', Layout.elementStyle.selectDropdown, SHIPMENT_TERMS_OPTIONS, this.state?.status === FTL_STATUSES.COMPLETED, null, true, null, true, true),
                Layout.rowElement(1, 'receivedTimeOut', 'Received-Time Out', Layout.elementStyle.timeSelect, null, this.state?.status === FTL_STATUSES.COMPLETED, null),
            ]),
            Layout.row([
                Layout.rowElement(1, 'pickupTimeOut', 'Pick Up-Time Out', Layout.elementStyle.timeSelect, null, this.state?.status === FTL_STATUSES.COMPLETED, null, true, null, true, true),
                Layout.rowElement(1, 'sealNumber', 'Seal Number', Layout.elementStyle.textBox, null, this.state?.status === FTL_STATUSES.COMPLETED),
                Layout.rowElement(1, 'currencyOfSale', 'Currency of Sale', Layout.elementStyle.selectDropdown, CURRENCY_OF_SALE_OPTIONS, this.state?.status === FTL_STATUSES.COMPLETED,null, true, null, true, true),
                Layout.rowElement(1, 'packingCost', 'Packing Cost', Layout.elementStyle.textBox, null, this.state?.status === FTL_STATUSES.COMPLETED),
            ]),
            Layout.row([
                Layout.rowElement(1, "", "", Layout.elementStyle.blankSpacer, null, false, null, false, null, true, true),
                Layout.rowElement(1, 'truckNumber', 'Truck Number', Layout.elementStyle.textBox, null, this.state?.status === FTL_STATUSES.COMPLETED),
                Layout.rowElement(1, 'partiesToTransaction', 'Parties to Transaction', Layout.elementStyle.selectDropdown, PARTIES_TO_TRANSACTION_OPTIONS, this.state?.status === FTL_STATUSES.COMPLETED, null, true, null, true, true),
                Layout.rowElement(1, 'freightCost', 'Freight Cost', Layout.elementStyle.textBox, null, this.state?.status === FTL_STATUSES.COMPLETED),
            ]),
            Layout.row([
                Layout.rowElement(1, "", "", Layout.elementStyle.blankSpacer, null, false, null, false, null, true, true),
                Layout.rowElement(1, 'trailerNumber', 'Trailer Number', Layout.elementStyle.textBox, null, this.state?.status === FTL_STATUSES.COMPLETED),
                Layout.rowElement(1, 'emergencyPhoneNum', 'Emergency Phone Number', Layout.elementStyle.textBox, null, this.state?.status === FTL_STATUSES.COMPLETED, null, true, null, true, true),
                Layout.rowElement(1, 'devanCost', 'Devan Cost', Layout.elementStyle.textBox, null, this.state?.status === FTL_STATUSES.COMPLETED),
            ]),
            Layout.row([
                Layout.rowElement(1, "", "", Layout.elementStyle.blankSpacer, null, false, null, false, null, true, true),
                Layout.rowElement(1, 'poNumber', 'PO Number', Layout.elementStyle.textBox, null, this.state?.status === FTL_STATUSES.COMPLETED),
                Layout.rowElement(1, "", "", Layout.elementStyle.blankSpacer, null, false, false, false, null, true, true),
                Layout.rowElement(1, 'insuranceCost', 'Insurance Cost', Layout.elementStyle.textBox, null, this.state?.status === FTL_STATUSES.COMPLETED),
            ]),
            Layout.row([
                Layout.rowElement(1, "", "", Layout.elementStyle.blankSpacer, null, false, null, false, null, true, true),
                Layout.rowElement(1, 'deliveryNumber', 'Delivery Number', Layout.elementStyle.textBox, null, this.state?.status === FTL_STATUSES.COMPLETED),
                Layout.rowElement(1, "", "", Layout.elementStyle.blankSpacer, null, false, false, false, null, true, true),
                Layout.rowElement(1, 'otherCosts', 'Other Costs', Layout.elementStyle.textBox, null, this.state?.status === FTL_STATUSES.COMPLETED),
            ])
        ])
    }

    handleFieldChange = (nameValueObject) => {
        this.setState({
            [nameValueObject.name]: nameValueObject.value
        })
    }

    getFieldValue = (displayName, fieldName, elementStyle) => {
        if (elementStyle === Layout.elementStyle.dateSelect || elementStyle === Layout.elementStyle.timeSelect) {
            return this.state[fieldName] ?? ""
        }
        return this.state[fieldName] ?? Layout.getDefaultElementValueFromStyle(elementStyle)
    }

    setAttachmentIds = (attachmentsArray) => {
        let newSettingsAttachments = attachmentsArray
        for (let i = 0; i < newSettingsAttachments.length; i++) {
            newSettingsAttachments[i].id = i
        }
        let newNewSettingsAttachments = []
        let settingsAttachmentFiles = []
        for (let i = 0; i < newSettingsAttachments.length; i++) {
            settingsAttachmentFiles.push(newSettingsAttachments[i].file)
        }
        let attachments = this.props.updating ? this.props.editingFullTruckload.attachments : this.state.attachments
        for (let i = 0; i < attachments.length; i++) {
            if (newSettingsAttachments[this.getFileIndex(settingsAttachmentFiles, attachments[i])]) {
                newNewSettingsAttachments.push(newSettingsAttachments[this.getFileIndex(settingsAttachmentFiles, attachments[i])])
            }
        }
        for (let i = 0; i < newSettingsAttachments.length; i++) {
            if (this.getFileIndex(attachments, newSettingsAttachments[i].file) === -1) {
                newNewSettingsAttachments.push(newSettingsAttachments[i])
            }
        }

        return newNewSettingsAttachments
    }

    setStep = (index) => {
        this.setState({
            activeStep: index,
        });
    };

    getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case STEP_FTL_ITEMS_INDEX:
                return this.firstStepContent()
            case STEP_FTL_ADDRESS_INDEX:
                return this.secondStepContent()
            case STEP_FTL_BOL_INDEX:
                return this.thirdStepContent()
            case STEP_FTL_DOCUMENTS_INDEX:
                return this.fourthStepContent()
        }
    }

    handleBack = () => {
        // if they are on the first step
        if (this.state.activeStep === 0) {
            this.props.setCurrentPage(FTL_PAGES.TABLE)
        }
        else {
            this.setState({
                activeStep: this.state.activeStep - 1,
            });
        }

    };

    handleCancel = () => {
        this.props.setCurrentPage(FTL_PAGES.TABLE)
    }

    isAddressValid = (address, isToAddress) => {
        return address.contactName !== "" && (address.companyName !== "") && address.phoneNumber !== "" && address.addressLine1 !== "" &&
            address.city !== "" && address.stateOrProvince !== "" && address.postalCode !== "" && address.country !== "" && address.email !== ""
}

    isInputValid = (step) => {
        switch (step) {
            case STEP_FTL_ITEMS_INDEX:
                return true //TODO: This
            case STEP_FTL_ADDRESS_INDEX:
                return this.isAddressValid(this.state.fromAddress, false) && this.isAddressValid(this.state.toAddress, true)
            case STEP_FTL_BOL_INDEX:
                return !isNaN(Number(this.state.freightCost)) && this.state.freightCost !== "" && !isNaN(Number(this.state.devanCost)) &&
                    this.state.devanCost !== "" && this.state.carrierName !== "" && this.state.brokerName !== "" && this.state.shipmentNumber !== "" &&
                    this.state.sealNumber !== "" && this.state.truckNumber !== "" && this.state.trailerNumber  !== "" && this.state.poNumber !== "" &&
                    this.state.deliveryNumber !== "" && !this.InvalidDate(SHIP) && !this.InvalidDate(DElIVERY) && !this.InvalidDate(TIME_IN) &&
                    !this.InvalidDate(TIME_OUT) && this.state.notes !== ""
            case STEP_FTL_DOCUMENTS_INDEX:
                return true //TODO: This
        }
    }

    handlePreview = (isFinalDoc) => {
        this.props.previewFullTruckloadDocument({path: isFinalDoc ? this.state.finalDocument?.path : this.state.billOfLading?.path, id: this.props.editingFullTruckload.id})
        this.props.setPreviewingFinalDoc(isFinalDoc)
        this.props.setCurrentPage(FTL_PAGES.PREVIEW, null, FTL_PAGES.UPDATE)
    }

    changeStatus = (newStatus, operationName) => {
        let newSettings = {
            attachments: this.state.newAttachments.filter(attachment => attachment.file?.byteArray),
            deletedAttachments: this.state.deletedAttachments,
        }
        let editingFullTruckload = this.props.editingFullTruckload
        editingFullTruckload.status = newStatus
        editingFullTruckload.newAttachments = this.state.newAttachments.filter(attachment => attachment.file?.displayName).map(attachment => {return {file : {displayName: attachment.file?.displayName}}})
        this.props.updateFullTruckload(editingFullTruckload, newSettings, () => this.setState({
            status: newStatus
        }), operationName)
    }

    handleSave = () => {
        if (!this.state.carrierName){
            this.setState({
                errorMessage:FTL_CARRIER_MISSING_MESSAGE,
                feedbackBarOpen: true
            })
            return
        }
        if (!this.state.brokerName) {
            this.setState({
                errorMessage:FTL_BROKER_MISSING_MESSAGE,
                feedbackBarOpen: true
            })
            return
        }
        let newSettings = {
            attachments: this.state.settingsAttachments.filter(attachment => attachment.file?.byteArray),
            deletedAttachments: this.state.deletedAttachments,
        }
        if (this.props.creating) {
            this.props.saveFullTruckload(this.state, newSettings,() => this.props.setCurrentPage(FTL_PAGES.TABLE))
        }
        else if (this.props.updating) {
            let info = this.state
            info.id = this.props.editingFullTruckload.id
            this.props.updateFullTruckload(this.state, newSettings,() => this.props.setCurrentPage(FTL_PAGES.TABLE))
        }
    }

    handleNext = () => {
        if (this.state.activeStep === FTL_STEPPER_HEADINGS.length - 1) {
            if (this.state.status === FTL_STATUSES.DRAFT) {
                this.state.status = FTL_STATUSES.REQUEST_APPROVAL
            }
            this.handleSave()
        }

        else {
            this.setState({
                    activeStep: this.state.activeStep + 1,
                }
            );
        }
    }

    InvalidDate = (type) => {
        let today = new Date()
        let date
        switch (type) {
            case SHIP:
                date = this.state.shipDate
                today.setHours(0, 0, 0, 0)
                break
            case DElIVERY:
                date = this.state.expectedDate
                today.setHours(0, 0, 0, 0)
                break
            case TIME_IN:
                date = this.state.pickupTimeIn
                break
            case TIME_OUT:
                date = this.state.pickupTimeOut
                break
        }
        return date < today && this.props.creating
    }

    onShipDateChange = (date) => {
        let newPickupTimeIn = this.state.pickupTimeIn
        let newPickupTimeOut = this.state.pickupTimeOut

        let newShipDateYear = new Date(date).getFullYear()
        let newShipDateMonth = new Date(date).getMonth()
        let newShipDateDate = new Date(date).getDate()

        new Date(newPickupTimeIn).setFullYear(newShipDateYear)
        new Date(newPickupTimeIn).setMonth(newShipDateMonth)
        new Date(newPickupTimeIn).setDate(newShipDateDate)

        new Date(newPickupTimeOut).setFullYear(newShipDateYear)
        new Date(newPickupTimeOut).setMonth(newShipDateMonth)
        new Date(newPickupTimeOut).setDate(newShipDateDate)

        this.setState({
            pickupTimeIn: newPickupTimeIn,
            pickupTimeOut: newPickupTimeOut
        })
    }

    onReceivedDateChange = (date) => {
        if (date) {
            let newReceivedTimeIn = this.state.receivedTimeIn
            let newReceivedTimeOut = this.state.receivedTimeOut

            let newReceivedDateYear = new Date(date).getFullYear()
            let newReceivedDateMonth = new Date(date).getMonth()
            let newReceivedDateDate = new Date(date).getDate()

            newReceivedTimeIn.setFullYear(newReceivedDateYear)
            newReceivedTimeIn.setMonth(newReceivedDateMonth)
            newReceivedTimeIn.setDate(newReceivedDateDate)

            newReceivedTimeOut.setFullYear(newReceivedDateYear)
            newReceivedTimeOut.setMonth(newReceivedDateMonth)
            newReceivedTimeOut.setDate(newReceivedDateDate)

            this.setState({
                receivedTimeIn: newReceivedTimeIn,
                receivedTimeOut: newReceivedTimeOut
            })
        }
    }

    onLocationChange = (address, newLocationName, isToAddress) => {
        if (newLocationName === "" || !newLocationName) {
            let newAddress = address
            newAddress.selectedLocation = ""
            newAddress.contactName = ""
            newAddress.companyName = ""
            newAddress.phoneNumber = ""
            newAddress.addressLine1 = ""
            newAddress.addressLine2 = ""
            newAddress.city = ""
            newAddress.stateOrProvince = ""
            newAddress.postalCode = ""
            newAddress.country = ""
            newAddress.email = ""
            newAddress.shipAddressId = ""

            this.setState({
                fromAddress: isToAddress ? this.state.fromAddress : newAddress,
                toAddress: isToAddress ? newAddress : this.state.toAddress
            })
            return
        }
        let newAddress = address
        let selectedLocation = this.props.availableLocations.find(location => { return location.name === newLocationName })
        newAddress.selectedLocation = newLocationName
        newAddress.contactName = selectedLocation.contactName ? selectedLocation.contactName : ""
        newAddress.companyName = selectedLocation.companyName ? selectedLocation.companyName : ""
        newAddress.phoneNumber = selectedLocation.phone ? selectedLocation.phone : ""
        newAddress.addressLine1 = selectedLocation.addressLine1 ? selectedLocation.addressLine1 : ""
        newAddress.addressLine2 = selectedLocation.addressLine2 ? selectedLocation.addressLine2 : ""
        newAddress.city = selectedLocation.city ? selectedLocation.city : ""
        newAddress.stateOrProvince = selectedLocation.state ? selectedLocation.state : ""
        newAddress.postalCode = selectedLocation.postalCode ? selectedLocation.postalCode : ""
        newAddress.country = selectedLocation.country ? selectedLocation.country : ""
        newAddress.email = selectedLocation.email ? selectedLocation.email : ""
        newAddress.shipAddressId = selectedLocation.shipAddressId ? selectedLocation.shipAddressId : ""
        this.setState({
            fromAddress: isToAddress ? this.state.fromAddress : newAddress,
            toAddress: isToAddress ? newAddress : this.state.toAddress
        })
    }

    addEntry = () => {
        let newItems = this.state.items
        newItems.push({
            shortcode: "",
            quantity: 0,
            numOfSkids: "0.00",
            isSample: false,
            locations: []
        })

        this.setState({
            items: newItems
        })
    }

    removeItem = (index) => {
        let newItems = this.state.items
        newItems.splice(index, 1)

        let newAttachments = this.state.attachments
        let shortcodes = []
        for (let i = 0; i < newItems.length; i++) {
            if (!shortcodes.includes(newItems[i].shortcode)) {
                shortcodes.push(newItems[i].shortcode)
            }
        }
        let settingsAttachments = this.props.fullTruckloadSettings?.attachments ? this.props.fullTruckloadSettings?.attachments : []
        for (let i = 0; i < settingsAttachments.length; i++) {
            let removeAttachment = true
            for (let j = 0; j < settingsAttachments[i].shortcodes.length; j++) {
                if (shortcodes.includes(settingsAttachments[i].shortcodes[j])) {
                    removeAttachment = false
                }
            }
            if (removeAttachment) {
                let attachmentIndex = this.getFileIndex(newAttachments, settingsAttachments[i].file)
                if (attachmentIndex !== -1) {
                    newAttachments.splice(attachmentIndex, 1)
                }
            }
        }

        this.setState({
            items: newItems,
            attachments: newAttachments
        })
    }

    onShortcodeChange = (value, index) => {
        let newItems = this.state.items
        for (let i = 0; i < newItems.length; i++) {
            if (newItems[i].shortcode === value) {
                return
            }
        }
        newItems[index].shortcode = value
        newItems[index].quantity = 0
        newItems[index].numOfSkids = "0.00"

        let newAttachments = this.state.attachments
        let settingsAttachments = this.props.fullTruckloadSettings?.attachments ? this.props.fullTruckloadSettings?.attachments : []
        for (let i = 0; i < settingsAttachments.length; i++) {
            if (settingsAttachments[i].shortcodes.includes(value)) {
                if (this.getFileIndex(newAttachments, settingsAttachments[i].file) === -1) {
                    newAttachments.push(settingsAttachments[i].file)
                }
            }
        }

        let shortcodes = []
        for (let i = 0; i < newItems.length; i++) {
            if (!shortcodes.includes(newItems[i].shortcode)) {
                shortcodes.push(newItems[i].shortcode)
            }
        }
        for (let i = 0; i < settingsAttachments.length; i++) {
            let removeAttachment = true
            for (let j = 0; j < settingsAttachments[i].shortcodes.length; j++) {
                if (shortcodes.includes(settingsAttachments[i].shortcodes[j])) {
                    removeAttachment = false
                }
            }
            if (removeAttachment) {
                let attachmentIndex = this.getFileIndex(newAttachments, settingsAttachments[i].file)
                if (attachmentIndex !== -1) {
                    newAttachments.splice(attachmentIndex, 1)
                }
            }
        }

        this.setState({
            items: newItems,
            attachments: newAttachments
        })
    }

    onQuantityChange = (value, index) => {
        let newItems = this.state.items
        if (value >= 0 || value === '') {
            newItems[index].quantity = value === '' ? '' : Number(value)
            newItems[index].numOfSkids = value === '' ? '0.00' : this.calculateNumOfSkids(newItems[index].shortcode, Number(value))
            this.setState({
                items: newItems,
            })
        }
    }

    onNumOfSkidsChange = (value, index) => {
        let newItems = this.state.items
        newItems[index].numOfSkids = value
        newItems[index].quantity = this.calculateQuantity(newItems[index].shortcode, value)

        this.setState({
            items: newItems,
        })
    }

    toggleIsSample = (index) => {
        let newItems = this.state.items
        newItems[index].isSample = !newItems[index].isSample

        this.setState({
            items: newItems,
        })
    }

    calculateQuantity = (shortcode, numOfSkids) => {
        let product = this.state.filteredProducts.find((p) => {
            return p.shortcode === shortcode
        })
        if (!product) {
            product = this.state.filteredPackages.find((p) => {
                return p.shortcode === shortcode
            })
        }
        if (!product) {
            return 0
        }
        return product.paleteQuantity * Number(numOfSkids)
    }

    calculateNumOfSkids = (shortcode, quantity) => {
        let product = this.state.filteredProducts.find((p) => {
            return p.shortcode === shortcode
        })
        if (!product) {
            product = this.state.filteredPackages.find((p) => {
                return p.shortcode === shortcode
            })
        }
        if (!product) {
            return "0.00"
        }
        let paleteQuantity = product.paleteQuantity
        return Number((quantity / paleteQuantity)) || (quantity / paleteQuantity === 0) ?
            (quantity / paleteQuantity).toFixed(2).toString() :
            (quantity / paleteQuantity).toString()
    }

    onSecondStepExit = () => {
        if (this.props.creating) {
            let newAttachments = []
            let isDomesticShipment = this.state.fromAddress.country === this.state.toAddress.country
            let settingsAttachments = this.props.fullTruckloadSettings?.attachments ? this.props.fullTruckloadSettings?.attachments.map(attachment => attachment.file) : []
            for (let i = 0; i < this.state.attachments.length; i++) {
                let index = this.getFileIndex(settingsAttachments, this.state.attachments[i])
                if (index !== -1) {
                    if (this.props.fullTruckloadSettings?.attachments && this.props.fullTruckloadSettings?.attachments[index].requiredForDomestic && isDomesticShipment) {
                        newAttachments.push(this.state.attachments[i])
                    }
                    if (this.props.fullTruckloadSettings?.attachments && this.props.fullTruckloadSettings?.attachments[index].requiredForInternational && !isDomesticShipment) {
                        newAttachments.push(this.state.attachments[i])
                    }
                }
            }
            this.state.attachments = newAttachments
        }
    }

    getFileIndex = (files, file) => {
        for (let i = 0; i < files?.length; i++) {
            if (files[i]?.displayName === file?.displayName) {
                return i
            }
        }
        return -1
    }

    handleDocumentSelectToggle = (file) => {
        let newAttachments = this.state.attachments
        let index = this.getFileIndex(newAttachments, file)
        if (index !== -1) {
            newAttachments.splice(index, 1)
        }
        else {
            newAttachments.push(file)
        }
        this.setState({
            attachments: newAttachments
        })
    }

    // flag == True if function is being used for attachments from settings
    handleAttachmentsOrderChange = (newSettingsAttachments) => {
        let orderedAttachments = []
        for (let i = 0; i < newSettingsAttachments.length; i++) {
            if (this.getFileIndex(this.state.attachments, newSettingsAttachments[i].file) !== -1) {
                orderedAttachments.push(newSettingsAttachments[i].file)
            }
        }
        this.setState({
            settingsAttachments: newSettingsAttachments,
            attachments: [...orderedAttachments]
        })
    }

    handleItemsOrderChange = (items) => {
        let newOrderedItems = []
        for (let i = 0; i < items.length; i++) {
            if (this.state.items.indexOf(items[i]) !== -1) {
                newOrderedItems.push(items[i])
            }
        }
        this.setState({
            items: newOrderedItems
        })
    }

    firstStepContent = () => {
        const shortcodes = this.state.filteredProducts.map((product) => {
            return product.shortcode
        }).concat(this.state.filteredPackages.map((p) => {
            return p.shortcode
        })).sort()

        return (
            <Table>
                <TableRow className="table">
                    <TableCell className="add-delete-col" />
                    {FTL_FORM_TABLE_HEADERS.map((headCell) => (
                        <TableCell key={headCell.id}
                                   style={{textAlign: "center", fontWeight: "bold", width: "20%"}}>
                            {headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
                <TableRow className="table">
                    <TableCell style={{ textAlign: "center" }} colSpan={6}>
                        <div style={{
                            justifyContent: "center",
                            marginRight: "auto",
                            marginLeft: "auto",
                        }}>
                            <DragAndDrop
                                disabled={this.state.status === FTL_STATUSES.COMPLETED}
                                cards={this.state.items}
                                handleTableDisplayChange = {(items) => this.handleItemsOrderChange(items)}
                                items={this.state.items.map((row, index) => {
                                    return (
                                        {
                                            id: row.id,
                                            component:
                                                <div style={{
                                                    display: "flex",
                                                    border: this.state.itemsNotEnabled.includes(row.shortcode) ? "1px solid red" : "1px solid grey",
                                                    margin: "5px",
                                                    marginBottom: "20px",
                                                    borderRadius: "10px",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    height: "75px"
                                                }}>
                                                    {this.state.status !== FTL_STATUSES.COMPLETED && this.state.status !== FTL_STATUSES.SHIPPED && this.state.status !== FTL_STATUSES.RECEIVED &&
                                                        <>
                                                            <Tooltip title={REMOVE_FTL_ENTRY}>
                                                                <IconButton onClick={() => this.removeItem(index)}>
                                                                    <RemoveCircleIcon
                                                                        fontSize='small'
                                                                        color='error'
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </>
                                                    }
                                                    <div style={{borderRadius: "1px", width:"21%", paddingLeft: "1.8%"}}>
                                                        <Autocomplete
                                                            className="ftl-item-shortcode-field"
                                                            options={shortcodes}
                                                            getOptionLabel={(entry) => entry}
                                                            autoHighlight={true}
                                                            blurOnSelect={false}
                                                            value={row.shortcode}
                                                            onChange={(event, value) => this.onShortcodeChange(value, index)}
                                                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    name="shortcode"
                                                                    InputLabelProps={{ shrink: true }}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                    <div style={{width:"22%", paddingLeft: "2.4%"}}>
                                                        {
                                                            this.state.filteredProducts.find((p) => {
                                                                return p.shortcode === row.shortcode
                                                            })?.manufacturerPartNum ??
                                                            this.state.filteredPackages.find((p) => {
                                                                return p.shortcode === row.shortcode
                                                            })?.manufacturerPartNum
                                                        }
                                                    </div>
                                                    <div style={{borderRadius: "1px", width:"20%",  paddingLeft: "2.8%"}}>
                                                        <TextField
                                                            //error={row.quantity < 0}
                                                            type="number"
                                                            variant="outlined"
                                                            name="quantity"
                                                            value={row.quantity}
                                                            onChange={(e) => this.onQuantityChange(e.target.value, index)}
                                                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                                                            size="small"
                                                        />
                                                    </div>
                                                    <div style={{borderRadius: "1px", width:"20%", paddingLeft: "4.6%"}}>
                                                        <TextField
                                                            //error={(isNaN(Number(row.numOfSkids)) || row.numOfSkids === "")}
                                                            variant="outlined"
                                                            name="numOfSkids"
                                                            value={row.numOfSkids}
                                                            onChange={(e) => this.onNumOfSkidsChange(e.target.value, index)}
                                                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                                                            size="small"
                                                        />
                                                    </div>
                                                    <div style={{borderRadius: "1px", width:"15%",  paddingLeft: "6.7%"}}>
                                                        <Checkbox
                                                            checked={row.isSample}
                                                            onChange={() => this.toggleIsSample(index)}
                                                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                                                            name="sample"
                                                            style={{ color: '#acd685' }}
                                                        />
                                                    </div>
                                                    <Tooltip title={DRAG} style={{float: "right"}}>
                                                        <IconButton
                                                            aria-label='drag'
                                                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                                                        >
                                                            <DehazeIcon variant='outlined' fontSize='small' />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                        }
                                    )
                                })}
                            />
                        </div>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell/>
                    <TableCell/>
                    <TableCell/>
                    <TableCell/>
                    <TableCell style={{textAlign: "center", fontWeight: "bold", width: "20%"}}>
                        {"Total Skids: " +  this.state.items.reduce((acc, curr) => acc + parseFloat(curr.numOfSkids), 0).toFixed(2).toString()}
                    </TableCell>
                    <TableCell/>
                </TableRow>
                <TableRow>
                    {this.state.status !== FTL_STATUSES.COMPLETED && this.state.status !== FTL_STATUSES.SHIPPED && this.state.status !== FTL_STATUSES.RECEIVED?
                        <TableCell className='add-ftl-item'>
                            <>
                                <Tooltip title={ADD_FTL_ENTRY}>
                                    <IconButton onClick={this.addEntry}>
                                        <AddCircleIcon
                                            fontSize="small"
                                            color='secondary'
                                        />

                                    </IconButton>
                                </Tooltip>
                            </>
                        </TableCell>
                        :
                        <TableCell className='add-ftl-item'></TableCell>}
                </TableRow>
            </Table>
        )
    }

    secondStepContent = () => {
        return (
            <div className="ftl-address-section">
                <Box borderRadius="borderRadius" {...defaultProps} style={{borderColor: "lightgray", borderRadius: "5px"}}>
                    <Typography variant="h6" gutterBottom component="div" className="ftl-form-header">
                        {SHIPPER}
                        <hr/>
                    </Typography>

                    <div className="ftl-row-spec">
                        <Autocomplete
                            style={{margin: '10px', width: '200px'}}
                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                            value={this.state.fromAddress.selectedLocation}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            options={this.props.availableLocations ? this.props.availableLocations.map(location => {return location.name}) : []}
                            getOptionLabel={(entry) => entry}
                            autoHighlight={true}
                            name="fromAddressSelectedLocation"
                            onChange={(event, value) => {this.onLocationChange(this.state.fromAddress, value, false)}}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Location"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    name="fromAddressSelectedLocation"
                                    error={!this.state.fromAddress.selectedLocation}
                                    required
                                />
                            }
                        />
                    </div>

                    <div className="ftl-row-spec">
                        <TextField
                            style={{margin: '10px', width: '200px'}}
                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                            //error={this.state.fromAddress.contactName === ""}
                            variant="outlined"
                            name="fromAddressContactName"
                            label="Contact Name"
                            value={this.state.fromAddress.contactName}
                            onChange={(e) => {
                                let newFromAddress = this.state.fromAddress
                                newFromAddress.contactName = e.target.value
                                this.setState({
                                    fromAddress: newFromAddress
                                })
                            }}
                            size="medium"
                            InputLabelProps={{shrink: true}}
                        />

                        <TextField
                            style={{margin: '10px', width: '200px'}}
                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                            //error={this.state.fromAddress.companyName === ""}
                            variant="outlined"
                            name="fromAddressCompanyName"
                            label="Company Name"
                            value={this.state.fromAddress.companyName}
                            onChange={(e) => {
                                let newFromAddress = this.state.fromAddress
                                newFromAddress.companyName = e.target.value
                                this.setState({
                                    fromAddress: newFromAddress
                                })
                            }}
                            size="medium"
                            InputLabelProps={{shrink: true}}
                        />

                        <TextField
                            style={{margin: '10px', width: '200px'}}
                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                            //error={this.state.fromAddress.phoneNumber === ""}
                            variant="outlined"
                            name="fromAddressPhoneNumber"
                            label="Phone Number"
                            value={this.state.fromAddress.phoneNumber}
                            onChange={(e) => {
                                let newFromAddress = this.state.fromAddress
                                newFromAddress.phoneNumber = e.target.value
                                this.setState({
                                    fromAddress: newFromAddress
                                })
                            }}
                            size="medium"
                            InputLabelProps={{shrink: true}}
                        />
                    </div>

                    <div className="ftl-row-spec">
                        <TextField
                            style={{margin: '10px', width: '100%'}}
                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                            //error={this.state.fromAddress.addressLine1 === ""}
                            variant="outlined"
                            name="fromAddressAddressLine1"
                            label="Address Line 1"
                            value={this.state.fromAddress.addressLine1}
                            onChange={(e) => {
                                let newFromAddress = this.state.fromAddress
                                newFromAddress.addressLine1 = e.target.value
                                this.setState({
                                    fromAddress: newFromAddress
                                })
                            }}
                            size="medium"
                            InputLabelProps={{shrink: true}}
                        />
                    </div>

                    <div className="ftl-row-spec">
                        <TextField
                            style={{margin: '10px', width: '100%'}}
                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                            variant="outlined"
                            name="fromAddressAddressLine2"
                            label="Address Line 2"
                            value={this.state.fromAddress.addressLine2}
                            onChange={(e) => {
                                let newFromAddress = this.state.fromAddress
                                newFromAddress.addressLine2 = e.target.value
                                this.setState({
                                    fromAddress: newFromAddress
                                })
                            }}
                            size="medium"
                            InputLabelProps={{shrink: true}}
                        />
                    </div>

                    <div className="ftl-row-spec">
                        <TextField
                            style={{margin: '10px', width: '200px'}}
                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                            //error={this.state.fromAddress.city === ""}
                            variant="outlined"
                            name="fromAddressCity"
                            label="City"
                            value={this.state.fromAddress.city}
                            onChange={(e) => {
                                let newFromAddress = this.state.fromAddress
                                newFromAddress.city = e.target.value
                                this.setState({
                                    fromAddress: newFromAddress
                                })
                            }}
                            size="medium"
                            InputLabelProps={{shrink: true}}
                        />

                        <Autocomplete
                            style={{margin: '10px', width: '200px'}}
                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                            value={this.state.fromAddress.stateOrProvince}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            options={this.props.stateAndProvinceMapping ? this.props.stateAndProvinceMapping.map(state => state.state) : []}
                            getOptionLabel={(entry) => entry}
                            autoHighlight={true}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    {option + " (" + this.props.stateAndProvinceMapping.find(state => state.state === option)?.displayName + ")"}
                                </React.Fragment>
                            )}
                            name="fromAddressStateOrRegion"
                            onChange={(event, value) => {
                                let newFromAddress = this.state.fromAddress
                                newFromAddress.stateOrProvince = value
                                this.setState({
                                    fromAddress: newFromAddress
                                })
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="State or Province"
                                    //error={this.state.fromAddress.stateOrProvince === "" || this.state.fromAddress.stateOrProvince === null || this.state.fromAddress.stateOrProvince === undefined}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    name="fromAddressStateOrRegion"
                                />
                            }
                        />

                        <TextField
                            style={{margin: '10px', width: '200px'}}
                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                            //error={this.state.fromAddress.postalCode === ""}
                            variant="outlined"
                            name="fromAddressPostalCode"
                            label="Postal Code"
                            value={this.state.fromAddress.postalCode}
                            onChange={(e) => {
                                let newFromAddress = this.state.fromAddress
                                newFromAddress.postalCode = e.target.value
                                this.setState({
                                    fromAddress: newFromAddress
                                })
                            }}
                            size="medium"
                            InputLabelProps={{shrink: true}}
                        />
                    </div>

                    <div className="ftl-row-spec">
                        <Autocomplete
                            style={{margin: '10px', width: '200px'}}
                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                            value={this.state.fromAddress.country}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            options={COUNTRY_OPTIONS}
                            getOptionLabel={(entry) => entry}
                            autoHighlight={true}
                            name="fromAddressCountry"
                            onChange={(event, value) => {
                                let newFromAddress = this.state.fromAddress
                                newFromAddress.country = value
                                this.setState({
                                    fromAddress: newFromAddress
                                })
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Country"
                                    //error={this.state.fromAddress.country === "" || this.state.fromAddress.country === null || this.state.fromAddress.country === undefined}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    name="fromAddressCountry"
                                />
                            }
                        />

                        <TextField
                            style={{margin: '10px', width: '420px'}}
                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                            //error={this.state.fromAddress.email === ""}
                            variant="outlined"
                            name="fromAddressEmail"
                            label="Email"
                            value={this.state.fromAddress.email}
                            onChange={(e) => {
                                let newFromAddress = this.state.fromAddress
                                newFromAddress.email = e.target.value
                                this.setState({
                                    fromAddress: newFromAddress
                                })
                            }}
                            size="medium"
                            InputLabelProps={{shrink: true}}
                        />
                    </div>
                </Box>

                <Box borderRadius="borderRadius" {...defaultProps} style={{borderColor: "lightgray", borderRadius: "5px"}}>
                    <Typography variant="h6" gutterBottom component="div" className="ftl-form-header">
                        {CONSIGNEE}
                        <hr/>
                    </Typography>

                    <div className="ftl-row-spec">
                        <Autocomplete
                            style={{margin: '10px', width: '200px'}}
                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                            value={this.state.toAddress.selectedLocation}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            options={this.props.availableLocations ? this.props.availableLocations.map(location => {return location.name}) : []}
                            getOptionLabel={(entry) => entry}
                            autoHighlight={true}
                            name="toAddressSelectedLocation"
                            onChange={(event, value) => {this.onLocationChange(this.state.toAddress, value, true)}}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Location"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    name="toAddressSelectedLocation"
                                    error={!this.state.toAddress.selectedLocation}
                                    required
                                />
                            }
                        />
                    </div>

                    <div className="ftl-row-spec">
                        <TextField
                            style={{margin: '10px', width: '200px'}}
                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                            //error={this.state.toAddress.contactName === ""}
                            variant="outlined"
                            name="toAddressContactName"
                            label="Contact Name"
                            value={this.state.toAddress.contactName}
                            onChange={(e) => {
                                let newToAddress = this.state.toAddress
                                newToAddress.contactName = e.target.value
                                this.setState({
                                    toAddress: newToAddress
                                })
                            }}
                            size="medium"
                            InputLabelProps={{shrink: true}}
                        />

                        <TextField
                            style={{margin: '10px', width: '200px'}}
                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                            //error={this.state.toAddress.companyName === ""}
                            variant="outlined"
                            name="toAddressCompanyName"
                            label="Company Name"
                            value={this.state.toAddress.companyName}
                            onChange={(e) => {
                                let newToAddress = this.state.toAddress
                                newToAddress.companyName = e.target.value
                                this.setState({
                                    toAddress: newToAddress
                                })
                            }}
                            size="medium"
                            InputLabelProps={{shrink: true}}
                        />

                        <TextField
                            style={{margin: '10px', width: '200px'}}
                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                            //error={this.state.toAddress.phoneNumber === ""}
                            variant="outlined"
                            name="toAddressPhoneNumber"
                            label="Phone Number"
                            value={this.state.toAddress.phoneNumber}
                            onChange={(e) => {
                                let newToAddress = this.state.toAddress
                                newToAddress.phoneNumber = e.target.value
                                this.setState({
                                    toAddress: newToAddress
                                })
                            }}
                            size="medium"
                            InputLabelProps={{shrink: true}}
                        />
                    </div>

                    <div className="ftl-row-spec">
                        <TextField
                            style={{margin: '10px', width: '100%'}}
                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                            //error={this.state.toAddress.addressLine1 === ""}
                            variant="outlined"
                            name="toAddressAddressLine1"
                            label="Address Line 1"
                            value={this.state.toAddress.addressLine1}
                            onChange={(e) => {
                                let newToAddress = this.state.toAddress
                                newToAddress.addressLine1 = e.target.value
                                this.setState({
                                    toAddress: newToAddress
                                })
                            }}
                            size="medium"
                            InputLabelProps={{shrink: true}}
                        />
                    </div>

                    <div className="ftl-row-spec">
                        <TextField
                            style={{margin: '10px', width: '100%'}}
                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                            variant="outlined"
                            name="toAddressAddressLine2"
                            label="Address Line 2"
                            value={this.state.toAddress.addressLine2}
                            onChange={(e) => {
                                let newToAddress = this.state.toAddress
                                newToAddress.addressLine2 = e.target.value
                                this.setState({
                                    toAddress: newToAddress
                                })
                            }}
                            size="medium"
                            InputLabelProps={{shrink: true}}
                        />
                    </div>

                    <div className="ftl-row-spec">
                        <TextField
                            style={{margin: '10px', width: '200px'}}
                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                            //error={this.state.toAddress.city === ""}
                            variant="outlined"
                            name="toAddressCity"
                            label="City"
                            value={this.state.toAddress.city}
                            onChange={(e) => {
                                let newToAddress = this.state.toAddress
                                newToAddress.city = e.target.value
                                this.setState({
                                    toAddress: newToAddress
                                })
                            }}
                            size="medium"
                            InputLabelProps={{shrink: true}}
                        />

                        <Autocomplete
                            style={{margin: '10px', width: '200px'}}
                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                            value={this.state.toAddress.stateOrProvince}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            options={this.props.stateAndProvinceMapping ? this.props.stateAndProvinceMapping.map(state => state.state) : []}
                            getOptionLabel={(entry) => entry}
                            autoHighlight={true}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    {option + " (" + this.props.stateAndProvinceMapping.find(state => state.state === option)?.displayName + ")"}
                                </React.Fragment>
                            )}
                            name="toAddressStateOrRegion"
                            onChange={(event, value) => {
                                let newToAddress = this.state.toAddress
                                newToAddress.stateOrProvince = value
                                this.setState({
                                    toAddress: newToAddress
                                })
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="State or Province"
                                    //error={this.state.toAddress.stateOrProvince === "" || this.state.toAddress.stateOrProvince === null || this.state.toAddress.stateOrProvince === undefined}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    name="toAddressStateOrRegion"
                                />
                            }
                        />

                        <TextField
                            style={{margin: '10px', width: '200px'}}
                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                            //error={this.state.toAddress.postalCode === ""}
                            variant="outlined"
                            name="toAddressPostalCode"
                            label="Postal Code"
                            value={this.state.toAddress.postalCode}
                            onChange={(e) => {
                                let newToAddress = this.state.toAddress
                                newToAddress.postalCode = e.target.value
                                this.setState({
                                    toAddress: newToAddress
                                })
                            }}
                            size="medium"
                            InputLabelProps={{shrink: true}}
                        />
                    </div>

                    <div className="ftl-row-spec">
                        <Autocomplete
                            style={{margin: '10px', width: '200px'}}
                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                            value={this.state.toAddress.country}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            options={COUNTRY_OPTIONS}
                            getOptionLabel={(entry) => entry}
                            autoHighlight={true}
                            name="toAddressCountry"
                            onChange={(event, value) => {
                                let newToAddress = this.state.toAddress
                                newToAddress.country = value
                                this.setState({
                                    toAddress: newToAddress
                                })
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Country"
                                    //error={this.state.toAddress.country === "" || this.state.toAddress.country === null || this.state.toAddress.country === undefined}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    name="toAddressCountry"
                                />
                            }
                        />

                        <TextField
                            style={{margin: '10px', width: '420px'}}
                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                            //error={this.state.toAddress.email === ""}
                            variant="outlined"
                            name="toAddressEmail"
                            label="Email"
                            value={this.state.toAddress.email}
                            onChange={(e) => {
                                let newToAddress = this.state.toAddress
                                newToAddress.email = e.target.value
                                this.setState({
                                    toAddress: newToAddress
                                })
                            }}
                            size="medium"
                            InputLabelProps={{shrink: true}}
                        />
                    </div>
                </Box>
            </div>
        )
    }

    thirdStepContent = () => {
        this.onSecondStepExit()
        return (
            <div className='ftl-bol-page'>
                {this.state.displayContents.errorMessage === "" ?
                    this.state.displayContents.content :
                    this.state.displayContents.errorMessage
                }
            </div>
        )
    }

    addAttachment = () => {
        let settingsAttachments = this.state.settingsAttachments
        settingsAttachments.push({file:{newAttachment: true}})
        this.setState({
            settingsAttachments: settingsAttachments
        })
    }

    deleteAttachment = (index) => {
        let deletedAttachments = this.state.deletedAttachments
        let settingsAttachments = this.state.settingsAttachments
        let attachments = this.state.attachments.filter((attachment) => {return attachment.displayName !== settingsAttachments[index].file.displayName})
        if (!settingsAttachments[index].file?.byteArray) deletedAttachments.push(settingsAttachments[index])
        settingsAttachments.splice(index, 1)
        this.setState({
            deletedAttachments: deletedAttachments,
            settingsAttachments: settingsAttachments,
            attachments: attachments
        })
    }

    handleFileAdd = (file, index) => {
        let deletedAttachments = this.state.deletedAttachments;
        let settingsAttachments = this.state.settingsAttachments;
        let attachments = this.state.attachments;
        let newAttachment = this.getFormattedNewAttachmentObject(file);
        if (settingsAttachments[index].file?.displayName) {
            if (!settingsAttachments[index].file?.byteArray) {
                deletedAttachments.push(settingsAttachments[index]);
            }
            // Find the index in the attachments array
            let attachmentIndex = attachments.findIndex(
                (attachment) => attachment.displayName === settingsAttachments[index].file.displayName
            );
            // If the index is found, replace the attachment with the newAttachment
            if (attachmentIndex !== -1) {
                attachments[attachmentIndex] = newAttachment;
            } else {
                // If the index is not found, add the new attachment to the end of the attachments array
                attachments.push(newAttachment);
            }
        } else {
            // If there was no file at the index, add the new attachment to the end of the attachments array
            attachments.push(newAttachment);
        }
        settingsAttachments[index].file = newAttachment;
        this.setState({
            settingsAttachments: settingsAttachments,
            attachments: attachments,
        });
    };

    handleFileDelete = (file, fileIndex, index) => {
        let settingsAttachments = this.state.settingsAttachments
        let attachments = this.state.attachments.filter((attachment) => {return attachment.displayName !== settingsAttachments[index].file.displayName})
        settingsAttachments[index].file = {newAttachment: true}
        this.setState({
            settingsAttachments: settingsAttachments,
            attachments: attachments
        })
    }

    getFormattedNewAttachmentObject = (file) => {
        return {
            localURL: URL.createObjectURL(file),
            displayName: file.name,
            byteArray: getByteArray(file),
            type: file.type,
            newAttachment: true
        }
    }

    fourthStepContent = () => {
        return (
            <div style={{marginLeft: "25px"}}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    <div style={{marginBottom: "15px", marginLeft: "5%"}}>
                        {FTL_STEPPER_DOCUMENTS_HELPER_TEXT}
                    </div>
                    <div style={{justifyContent: "center", border: "1px solid grey", width: "60%", borderRadius: "10px", marginRight: "auto", marginLeft: "auto"}}>
                        <DragAndDrop
                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                            cards={this.state.settingsAttachments}
                            handleTableDisplayChange = {(newSettingsAttachments) => this.handleAttachmentsOrderChange(newSettingsAttachments)}
                            items={this.state.settingsAttachments?.map((attachment, index) => {
                                return (
                                    {
                                        id: attachment.id,
                                        component:
                                            <div style={{
                                                display: "flex",
                                                border: "1px solid grey",
                                                margin: "10px",
                                                width: '98%',
                                                borderRadius: "10px",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                height: "50px"
                                            }}>
                                                {attachment?.file.newAttachment ?
                                                    <>
                                                        <Tooltip title={REMOVE_DOCUMENT}>
                                                            <IconButton onClick={() => this.deleteAttachment(index)}>
                                                                <RemoveCircleIcon
                                                                    fontSize='small'
                                                                    color='error'
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <div style={{borderRadius: "1px", width:"90%", height: "65px"}}>
                                                            <FileUpload
                                                                handleFileAdd={(file) => this.handleFileAdd(file, index)}
                                                                handleFileDelete={(file, fileIndex) => this.handleFileDelete(file, fileIndex, index)}
                                                                files={attachment.file?.displayName ? [attachment.file] : []}
                                                                singleFileUpload={true}
                                                                isFTLUpload={true}
                                                            />
                                                        </div>
                                                    </> :
                                                    <>
                                                        <Checkbox
                                                            checked={this.getFileIndex(this.state.attachments, attachment.file) !== -1}
                                                            disabled={this.state.status === FTL_STATUSES.COMPLETED}
                                                            onChange={() => this.handleDocumentSelectToggle(attachment.file)}
                                                            name="document"
                                                            style={{color: '#acd685'}}
                                                        />
                                                        <div style={{width:"90%", height: "28px"}}>
                                                            {attachment.file.displayName}
                                                        </div>
                                                    </>
                                                }
                                                <Tooltip title={DRAG} style={{float: "right"}}>
                                                    <IconButton
                                                        aria-label='drag'
                                                        disabled={this.state.status === FTL_STATUSES.COMPLETED}
                                                    >
                                                        <DehazeIcon variant='outlined' fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                    }
                                )
                            })}
                        />
                        <div style={{display: "flex"}}>
                            <Tooltip title={ADD_DOCUMENT}>
                                <IconButton onClick={this.addAttachment} style={{marginLeft: "10px"}}>
                                    <AddCircleIcon
                                        fontSize="small"
                                        color='secondary'
                                    />
                                </IconButton>
                            </Tooltip>
                            <div style={{marginTop: "1%"}}>
                                {FTL_STEPPER_UPLOAD_DOCUMENTS}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    closeTag = () => {
        this.setState({
            feedbackBarOpen: !this.state.feedbackBarOpen
        })
    }

    render() {
        return (
            <React.Fragment>
                <FeedbackBar
                    open={this.state.feedbackBarOpen}
                    severity={ERROR}
                    message={this.state.errorMessage}
                    handleClose={this.closeTag}
                />
                <TopToolbar
                    pageName={FULL_TRUCKLOAD}
                    menuItems={[
                        {title: BACK, icon: <ArrowBackIcon/>, onClick: () => {this.state.activeStep === 0 ? this.props.setCurrentPage(FTL_PAGES.TABLE) : this.handleBack()}},
                        {title: this.state.status === FTL_STATUSES.DRAFT? SAVE_DRAFT : SAVE, icon: <SaveIcon/>, onClick: () => {this.handleSave()}, disabled: !this.state.fromAddress.selectedLocation || !this.state.toAddress.selectedLocation ||
                        (this.state?.dutiesTaxesPayableBy === DUTIES_TAXES_PAYABLE_BY_OPTIONS[0] && !this.state.dutiesTaxesPayableByExporter) ||
                        (this.state?.dutiesTaxesPayableBy === DUTIES_TAXES_PAYABLE_BY_OPTIONS[2] && !this.state.dutiesTaxesPayableByOther)},
                        {title: NEXT, icon: <ArrowForwardIcon/>, onClick: () => this.handleNext(), disabled: this.state.activeStep === FTL_STEPPER_HEADINGS.length - 1},
                        {title: '|', isNotIcon: true},
                        {title: MARK_DRAFT_FTL, icon: <DesignServicesIcon/>, onClick: () => {this.changeStatus(FTL_STATUSES.DRAFT, MARK_DRAFT_FTL)}, disabled: this.state.status === FTL_STATUSES.DRAFT || this.state.status === FTL_STATUSES.SHIPPED || this.state.status === FTL_STATUSES.RECEIVED || this.state.status === FTL_STATUSES.COMPLETED || (this.state.status === FTL_STATUSES.APPROVED && !this.props.user.routes.includes('ftl-approval'))},
                        {title: UNAPPROVE_FTL, icon: <CancelIcon/>, onClick: () => {this.changeStatus(FTL_STATUSES.REQUEST_APPROVAL, UNAPPROVE_FTL)}, disabled: this.state.status !== FTL_STATUSES.APPROVED || !this.props.user.routes.includes('ftl-approval')},
                        {title: APPROVE_FTL, icon: <CheckIcon/>, onClick: () => {this.changeStatus(FTL_STATUSES.APPROVED, APPROVE_FTL)}, disabled: this.state.status !== FTL_STATUSES.REQUEST_APPROVAL || !this.props.user.routes.includes('ftl-approval')},
                        {title: MARK_FTL_UNSHIPPED, icon: <ScheduleIcon/>, onClick: () => {this.changeStatus(FTL_STATUSES.APPROVED, MARK_FTL_UNSHIPPED)}, disabled: this.state.status !== FTL_STATUSES.SHIPPED || !this.props.user.routes.includes('ftl-approval')},
                        {title: MARK_FTL_SHIPPED, icon: <AssignmentTurnedIn/>, onClick: () => {this.changeStatus(FTL_STATUSES.SHIPPED, MARK_FTL_SHIPPED)}, disabled: this.state.status !== FTL_STATUSES.APPROVED || !this.props.user.routes.includes('ftl-approval')},
                        {title: MARK_FTL_INCOMPLETE, icon: <Unpublished/>, onClick: () => {this.changeStatus(FTL_STATUSES.RECEIVED, MARK_FTL_INCOMPLETE)}, disabled: this.state.status !== FTL_STATUSES.COMPLETED || !this.props.user.routes.includes('ftl-approval')},
                        {title: MARK_FTL_COMPLETE, icon: <CheckCircleIcon/>, onClick: () => {this.changeStatus(FTL_STATUSES.COMPLETED, MARK_FTL_COMPLETE)}, disabled: this.state.status !== FTL_STATUSES.RECEIVED || !this.props.user.routes.includes('ftl-approval')},
                        {title: '|', isNotIcon: true},
                        {title: UPLOAD_BOL_AND_CI, icon: <PublishIcon/>, onClick: () => {this.props.setCurrentPage(FTL_PAGES.UPLOAD)}, disabled: this.state.status === FTL_STATUSES.COMPLETED},
                        {title: VIEW_BOL, icon: <DescriptionIcon/>, onClick: () => this.handlePreview(false), disabled: this.state.status !== FTL_STATUSES.APPROVED && this.state.status !== FTL_STATUSES.SHIPPED && this.state.status !== FTL_STATUSES.RECEIVED && this.state.status !== FTL_STATUSES.COMPLETED || !this.props.user.routes.includes('ftl-approval')},
                        {title: VIEW_DOCUMENTS, icon: <FileCopyIcon/>, onClick: () => this.handlePreview(true), disabled: this.state.status !== FTL_STATUSES.APPROVED && this.state.status !== FTL_STATUSES.SHIPPED && this.state.status !== FTL_STATUSES.RECEIVED && this.state.status !== FTL_STATUSES.COMPLETED  || !this.props.user.routes.includes('ftl-approval')},
                    ]}
                />

                <Stepper nonLinear={!this.props.creating} activeStep={this.state.activeStep} alternativeLabel>
                    {FTL_STEPPER_HEADINGS.map((label, index) => (
                        <Step key={label}>
                            <StepButton
                                onClick={() => {
                                    this.setStep(index);
                                }}
                                optional={
                                    <Typography variant='caption'>
                                        {FTL_STEPPER_HEADING_DESCRIPTIONS[index]}
                                    </Typography>
                                }
                            >
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
                <DndProvider backend={HTML5Backend}>
                    {this.getStepContent(this.state.activeStep)}
                </DndProvider>
                <div className="ftl-stepper-buttons">
                    {this.state.activeStep !== 0 &&
                        <Button
                            style={{marginRight: "10px"}}
                            variant='contained'
                            onClick={this.handleCancel}
                        >
                            {CANCEL}
                        </Button>
                    }

                    <Button
                        style={{marginRight: "10px"}}
                        variant='contained'
                        onClick={this.handleBack}
                    >
                        {this.state.activeStep === 0 ? CANCEL : BACK}
                    </Button>

                    <Button
                        variant='contained'
                        color={this.state.status === FTL_STATUSES.DRAFT? "" : 'primary'}
                        onClick={this.handleSave}
                        disabled={!this.state.fromAddress.selectedLocation || !this.state.toAddress.selectedLocation ||
                        (this.state?.dutiesTaxesPayableBy === DUTIES_TAXES_PAYABLE_BY_OPTIONS[0] && !this.state.dutiesTaxesPayableByExporter) ||
                        (this.state?.dutiesTaxesPayableBy === DUTIES_TAXES_PAYABLE_BY_OPTIONS[2] && !this.state.dutiesTaxesPayableByOther)}
                    >
                        {this.state.status === FTL_STATUSES.DRAFT? SAVE_DRAFT : SAVE}
                    </Button>

                    {this.state.status === FTL_STATUSES.DRAFT && this.state.activeStep === FTL_STEPPER_HEADINGS.length - 1 ?
                        <div>
                            <br/>
                        </div>
                        :
                        null
                    }

                    {this.state.activeStep === FTL_STEPPER_HEADINGS.length - 1  && this.state.status !== FTL_STATUSES.DRAFT ?
                     <></> :
                        <Button
                            style={{marginLeft: "10px"}}
                            disabled={this.state.status === FTL_STATUSES.COMPLETED && this.state.activeStep === FTL_STEPPER_HEADINGS.length - 1}
                            variant='contained'
                            color='primary'
                            onClick={() => this.handleNext()}
                        >
                            {this.state.activeStep === FTL_STEPPER_HEADINGS.length - 1 ? REQUEST_FOR_APPROVAL : NEXT}
                        </Button>
                    }
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    products: productsSelector(state),
    packages: packagesSelector(state),
    stateAndProvinceMapping: stateAndProvinceMappingSelector(state),
    availableLocations: availableLocationsSelector(state),
    fullTruckloadCarriers: fullTruckloadCarriersSelector(state),
    fullTruckloadBrokers: fullTruckloadBrokersSelector(state),
    fullTruckloadSettings: fullTruckloadSettingsSelector(state),
    unavailableItems: fullTruckloadUnavailableItemsSelector(state)
});

const actionCreators = {
    fetchFullTruckloadSettings,
    listProducts,
    listPackages,
    saveFullTruckload,
    updateFullTruckload,
    getStateAndProvinceMapping,
    listAvailableLocations,
    listFullTruckloadCarriers,
    fetchFullTruckloadCarriers,
    listFullTruckloadBrokers,
    fetchFullTruckloadBrokers
}

export default withShipment({
    mapStateToProps,
    actionCreators,
}, FullTruckloadForm);