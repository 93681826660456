import { createSelector } from 'reselect';

export const shippingSettingSelector = createSelector(state => state.accounting.rates, shippingSettingSelector => shippingSettingSelector)

export const shippingSummarySelector = createSelector(state => state.accounting.shippingSummary, shippingSummarySelector => shippingSummarySelector)
export const chequeSelector = createSelector(state => state.accounting.cheques, chequeSelector => chequeSelector)
export const transactionSelector = createSelector(state => state.accounting.transactions, transactionSelector => transactionSelector)
export const processingSelector = createSelector(state => state.accounting.processing, processingSelector => processingSelector)
export const attachmentSelector = createSelector(state => state.accounting.attachmentFile, attachmentSelector => attachmentSelector)
export const fetchedReferencesSelector = createSelector(state => state.accounting.fetchedReferences, fetchedReferencesSelector => fetchedReferencesSelector)
export const transactionCategoriesSelector = createSelector(state => state.accounting.transactionCategories, transactionCategoriesSelector => transactionCategoriesSelector);
export const foundInvoiceSelector = createSelector(state => state.accounting.invoice, foundInvoiceSelector => foundInvoiceSelector);
export const taxInfosSelector = createSelector(state => state.accounting.taxInfos, taxInfosSelector => taxInfosSelector);
export const taxTypewithStateProvinceSelector = createSelector(state => state.accounting.taxTypewithStateProvince, taxTypewithStateProvinceSelector => taxTypewithStateProvinceSelector);
export const editingTransactionSelector = createSelector(state => state.accounting.editingTransaction, editingTransactionSelector => editingTransactionSelector)
export const statementTaxesTemplateSelector = createSelector(state => state.accounting.templates, statementTaxesTemplateSelector => statementTaxesTemplateSelector)
export const statementTaxesSummarySelector = createSelector(state => state.accounting.taxSummary, statementTaxesSummarySelector => statementTaxesSummarySelector)
