import React from "react";

// material components
import Autocomplete from "@material-ui/lab/Autocomplete";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";

// custom components
import FilterIcon from "../shipments/FilterIcon";
import InventoryFiltersDialog from "./InventoryFiltersDialog";

// styling
import './InventorySearchFilterField.css';

// constants
import {
    INVENTORY,
    FILTER,
    SHOW_OUT_OF_STOCK,
    SHOW_INSUFFICIENT_STOCK_ONLY,
    SHOW_LOW_INVENTORY_ONLY, SHOW_THRESHOLDS
} from "./constants";
import FilterTagsBar from "../shipments/FilterTagsBar";
import PropTypes from "prop-types";
import CustomTableHeader from "../global/Table/CustomTableHeader";
import { Slide } from "@material-ui/core";
import LocationSelectBox from "../global/subcomponents/locationSelectBox/LocationSelectBox";
import {FormControlLabel} from "@material-ui/core";
import {Checkbox} from "@material-ui/core";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleIcon from "@mui/icons-material/Circle";

class InventorySearchFilterField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filtersOpen: false,
            default: {},
            filtersMap: {},
            visibleFiltersMap: {},
            deletedTagsWithCookies: [],
            shortcodeKey: 0,
            searchValue: '',
            filtersMapBeforeOpenDialog: {},

            fromDate: new Date(),
            toDate: new Date(),

            allBrands: this.getManufacturers(),
            allShortcodes: this.getShortcodes(),
            allActionTypes: this.getActionTypes(),

            brands: [],
            shortcodes: [],
            actionTypes: [],
        };
    }

    componentDidMount() {
        let curLoc = this.props.availableLocations.find(element => element.shipAddressId === this.props.user.selectedLocationId)
        if (curLoc) {
            this.setState({
                default: curLoc
            });
        }
        this.setState({
            // filtersMap: this.addFilterDates(this.props.filtersMap),
            filtersMap: this.addFilterDates(this.props.tableSetting?.inventoryFilterSetting),
            searchValue: this.props.searchText,
            fromDate: this.getLastWeekDate()
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.manufacturers !== this.props.manufacturers) {
            this.setState({
                allBrands: this.getManufacturers()
            })
        }
        if (prevProps.availableShortcodes !== this.props.availableShortcodes) {
            this.setState({
                allShortcodes: this.getShortcodes()
            })
        }
        if (prevProps.inventoryAdjustmentTypes !== this.props.inventoryAdjustmentTypes) {
            this.setState({
                allActionTypes: this.getActionTypes()
            })
        }

        if (prevState.filtersMap !== this.state.filtersMap) {
            if (this.state.filtersMap?.brands !== this.state.brands) {
                this.setState({
                    brands: this.state.filtersMap?.brands
                })
            }
            if (this.state.filtersMap?.shortcodes !== this.state.shortcodes) {
                this.setState({
                    shortcodes: this.state.filtersMap?.shortcodes
                })
            }
            if (this.state.filtersMap?.actionTypes !== this.state.actionTypes) {
                this.setState({
                    actionTypes: this.state.filtersMap?.actionTypes
                })
            }
            if (this.state.filtersMap?.fromDate !== this.state.fromDate) {
                this.setState({
                    fromDate: this.state.filtersMap?.fromDate ? this.state.filtersMap?.fromDate : this.getLastWeekDate()
                })
            }
            if (this.state.filtersMap?.toDate !== this.state.toDate) {
                this.setState({
                    toDate: this.state.filtersMap?.toDate ? this.state.filtersMap?.toDate : new Date()
                })
            }
            if (this.state.filtersMap?.inventoryType !== prevProps.filtersMap?.inventoryType) {
                this.props.saveTableSetting({
                    userId: this.props.user.userId,
                    changeInventoryFilters: true,
                    inventoryFilterSetting: {...this.props.tableSetting.inventoryFilterSetting, inventoryType: this.state.filtersMap?.inventoryType}
                })
            }
        }

        if (prevState.filtersMap !== this.state.filtersMap) {
            this.setState({
                visibleFiltersMap: this.applyChangeToFiltersMap(this.state.filtersMap, {name: 'inventoryType', value: ""})
            })
        }

        if (this.props.availableLocations !== prevProps.availableLocations) {
            let curLoc = this.props.availableLocations.find(element => element.shipAddressId === this.props.user.selectedLocationId)
            this.setState({
                default: curLoc
            });
            this.props.onLocationChange(curLoc);
        }
        if (prevProps.tableSetting?.inventoryFilterSetting !== this.props.tableSetting?.inventoryFilterSetting) {
            this.setState({
                filtersMap: this.addFilterDates(this.props.tableSetting?.inventoryFilterSetting)
            })
        }
        if (prevProps.searchText !== this.props.searchText || this.state.searchValue !== this.props.searchText) {
            this.setState({
                searchValue: this.props.searchText
            })
        }
    }

    getManufacturers = () => {
        return this.props.manufacturers?.sort((a, b) => {
            return a.toLowerCase().localeCompare(b.toLowerCase())
        }) ?? []
    }
    getShortcodes = () => {
        return this.props.availableShortcodes?.sort((a, b) => {
            return a.toLowerCase().localeCompare(b.toLowerCase())
        }) ?? []
    }
    getActionTypes = () => {
        return this.props.inventoryAdjustmentTypes?.map(adj => adj.type)?.sort((a, b) => {
            return a.toLowerCase().localeCompare(b.toLowerCase())
        }) ?? []
    }

    toggleFiltersDialog = () => {
        this.setState({
            filtersOpen: !this.state.filtersOpen
        });
    }

    addFilterDates = (filters) => {
        if (filters) {
            if (!this.isLastWeekDate(this.props.fromDate)) {
                filters['fromDate'] = this.props.fromDate
            }
            if (!this.isToday(this.props.toDate)) {
                filters['toDate'] = this.props.toDate
            }
        }
        return filters
    }

    showFiltersDialog = () => {
        const filtersMapClone = { ...this.state.filtersMap };
        this.setState({
            filtersOpen: !this.state.filtersOpen,
            filtersMapBeforeOpenDialog: filtersMapClone
        });
    }

    getLastWeekDate = () => {
        const today = new Date();
        const lastWeekDate = new Date(today);
        lastWeekDate.setHours(0);
        lastWeekDate.setMinutes(0);
        lastWeekDate.setSeconds(0);
        lastWeekDate.setDate(lastWeekDate.getDate() - 7)
        return lastWeekDate
    }

    isLastWeekDate = (someDate) => {
        const lastWeekDate = this.getLastWeekDate()
        return someDate.getDate() == lastWeekDate.getDate() &&
            someDate.getMonth() == lastWeekDate.getMonth() &&
            someDate.getFullYear() == lastWeekDate.getFullYear()
    }

    isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
    }

    closeFilters = (apply) => {
        this.setState({
            filtersOpen: false,
        });

        let filters = {}
        if (!apply) {
            filters = { ...this.state.filtersMapBeforeOpenDialog }
            this.setState({
                filtersMap: filters,
            })
            this.handleDateChange(filters?.fromDate ?? this.getLastWeekDate(), true)
            this.handleDateChange(filters?.toDate ?? new Date(), false)
        } else {
            if (this.state.deletedTagsWithCookies.length > 0) {
                let storage = JSON.parse(localStorage.getItem(INVENTORY))
                this.state.deletedTagsWithCookies.forEach((tagName) => {
                    delete storage[tagName]
                })
                localStorage.setItem(INVENTORY, JSON.stringify(storage))
                if(JSON.stringify(storage) == JSON.stringify({})) localStorage.removeItem(INVENTORY)
                this.setState({deletedTagsWithCookies: []})
            }
            filters = { ...this.state.filtersMap }
            const expiry = new Date().setHours(23, 59, 59, 0);
            if (filters.fromDate !== null && filters.fromDate !== undefined) {
                let existing = localStorage.getItem(INVENTORY)
                existing = existing ? JSON.parse(existing) : {};
                existing.fromDate = filters.fromDate;
                localStorage.setItem(INVENTORY, JSON.stringify(existing));
                localStorage.setItem("expiry", JSON.stringify(expiry))
            }
            if (filters.toDate !== null && filters.toDate !== undefined) {
                let existing = localStorage.getItem(INVENTORY)
                existing = existing ? JSON.parse(existing) : {};
                existing.toDate = filters.toDate;
                localStorage.setItem(INVENTORY, JSON.stringify(existing));
                localStorage.setItem("expiry", JSON.stringify(expiry))
            }
        }

        this.saveUpdatedFilters(filters)

        this.passFiltersUp(filters);
    }

    onTagClickedWhenDialogueOpen = (event, tag) => {
        //prevent the user from removing maxAllowedDateRange Tag
        if (tag === "maxAllowedDateRange") return
        const filtersMap = { ...this.state.filtersMap };
        let tagValue = filtersMap[tag]
        let deletedTagsWithCookies = this.state.deletedTagsWithCookies
        delete filtersMap[tag];
        if (tag === 'fromDate') {
            let storage = JSON.parse(localStorage.getItem(INVENTORY))
            if (storage) {
                if (storage.fromDate !== null && storage.fromDate !== undefined) {
                    if (new Date(storage.fromDate).getTime() === new Date(tagValue).getTime()) {
                        this.setState({deletedTagsWithCookies: [...deletedTagsWithCookies, "fromDate"]})
                    }
                }
            }
            this.handleDateChange(this.getLastWeekDate(), true)
        }
        if (tag === 'toDate') {
            let storage = JSON.parse(localStorage.getItem(INVENTORY))
            if (storage) {
                if (storage.toDate !== null && storage.toDate !== undefined) {
                    if (new Date(storage.toDate).getTime() === new Date(tagValue).getTime()) {
                        this.setState({deletedTagsWithCookies: [...deletedTagsWithCookies, "toDate"]})
                    }
                }
            }
            this.handleDateChange(new Date(), false)
        }
        this.setState({
            filtersMap: filtersMap,
            isFilterApplied: Object.keys(filtersMap).length !== 0,
        }, () => {
            this.passFiltersUp(filtersMap);
        });
    }

    onTagClicked = (event, tag) => {
        //prevent the user from removing maxAllowedDateRange Tag
        if (tag === "maxAllowedDateRange") return
        // remove the key from the filters map
        // clone the map, delete the key, set the state
        const filtersMap = { ...this.state.filtersMap };
        delete filtersMap[tag];
        if (tag === 'inventoryType') {
            filtersMap.inventoryType = 'Products';
        }
        if (tag === 'fromDate') {
            let storage = JSON.parse(localStorage.getItem(INVENTORY))
            delete storage.fromDate
            this.handleDateChange(this.getLastWeekDate(), true)
            localStorage.setItem(INVENTORY, JSON.stringify(storage))
            if(JSON.stringify(storage) == JSON.stringify({})) localStorage.removeItem(INVENTORY)
        }
        if (tag === 'toDate') {
            let storage = JSON.parse(localStorage.getItem(INVENTORY))
            delete storage.toDate
            this.handleDateChange(new Date(), false)
            localStorage.setItem(INVENTORY, JSON.stringify(storage))
            if(JSON.stringify(storage) == JSON.stringify({})) localStorage.removeItem(INVENTORY)
        }

        if (tag !== 'toDate' && tag !== 'fromDate') {
            let filtersMapClone = filtersMap
            this.saveUpdatedFilters(filtersMapClone)
        }
        this.setState({
            filtersMap: filtersMap,
            isFilterApplied: Object.keys(filtersMap).length !== 0,
        }, () => {
            this.passFiltersUp(filtersMap);
        });

    }

    onFiltersInputChange = (name, val, sendRequest) => {
        const filtersMap = { ...this.state.filtersMap };

        // if filter already exists, then update
        if (val !== "" && val.length !== 0) {
            filtersMap[name] = val;
        }
        else {
            delete filtersMap[name];
        }

        if (sendRequest) {
            let filtersMapClone = filtersMap
            this.saveUpdatedFilters(filtersMapClone)
        }

        this.setState({
            filtersMap: filtersMap,
        });

        this.passFiltersUp(filtersMap)

    }

    /**
     * Resets the filter map
     */
    clearFiltersMap = () => {
        this.setState({
            filtersMap: {},
            fromDate: this.getLastWeekDate(),
            toDate: new Date()
        })
        this.saveUpdatedFilters({})
    }

    saveUpdatedFilters = (filters) => {
        delete filters['fromDate']
        delete filters['toDate']
        this.props.saveTableSetting({
            userId: this.props.user.userId,
            tableSetting: null,
            changeFilters: false,
            changeInventoryFilters: true,
            inventoryFilterSetting: filters
        })
    }

    handleDateChange = (date, isFromDate) => {
        const filtersMap = { ...this.state.filtersMap };
        if (isFromDate) {
            if (!this.isLastWeekDate(date)) {
                filtersMap["fromDate"] = date;
                this.setState({
                    filtersMap: filtersMap,
                    deletedTagsWithCookies: [],
                    fromDate: date
                })
            }
        } else {
            filtersMap["toDate"] = date;
            this.setState({
                filtersMap: filtersMap,
                deletedTagsWithCookies: [],
                toDate: date
            })
        }
        this.props.handleDateChange(date, isFromDate ? 'fromDate' : 'toDate')
    }

    passFiltersUp = (filters) => {
        this.props.passFiltersUp(filters);
    }

    onSearchValueChange = (event) => {
        this.setState({
            searchValue: event.target.value,
        });
        this.props.searchShortcodeFilter(event)
        this.props.handleSearchChange(event)
    }

    applyChangeToFiltersMap = (oldFiltersMap, nameValueObj) => {
        // set filtersMap, including removing the key if the value is empty
        let filtersMap = {...oldFiltersMap}
        let newValue = nameValueObj.value
        if (!newValue || (newValue.length !== null && newValue.length === 0)) {
            let oldKeys = Object.keys(filtersMap);
            let keys = []
            if (oldKeys) {
                for (let i = 0; i < oldKeys.length; i++) {
                    if (oldKeys[i] !== nameValueObj.name) {
                        keys.push(oldKeys[i])
                    }
                }
            }
            let newFiltersMap = {}
            if (keys) {
                for (let j = 0; j < keys.length; j++) {
                    newFiltersMap[keys[j]] = filtersMap[keys[j]]
                }
            }
            filtersMap = newFiltersMap
        } else {
            filtersMap[nameValueObj.name] = newValue
        }
        return filtersMap
    }

    setFilterValue = (nameValueObj) => {
        switch (nameValueObj.name) {
            case 'fromDate':
                this.handleDateChange(new Date(nameValueObj.value), true)
                break
            case 'toDate':
                this.handleDateChange(new Date(nameValueObj.value), false)
                break
            default:
                let filtersMap = this.applyChangeToFiltersMap(this.state.filtersMap, nameValueObj)
                this.setState({
                    filtersMap: filtersMap,
                    [nameValueObj.name]: nameValueObj.value
                })
        }
    }

    clearFilters = () => {
        let filtersMap = {...this.state.filtersMap}
        filtersMap = this.applyChangeToFiltersMap(filtersMap,{name: "brands", value: []})
        filtersMap = this.applyChangeToFiltersMap(filtersMap,{name: "shortcodes", value: []})
        filtersMap = this.applyChangeToFiltersMap(filtersMap,{name: "actionTypes", value: []})
        filtersMap = this.applyChangeToFiltersMap(filtersMap,{name: "fromDate", value: ""})
        filtersMap = this.applyChangeToFiltersMap(filtersMap,{name: "toDate", value: ""})

        this.handleDateChange(new Date(this.getLastWeekDate()), true)
        this.handleDateChange(new Date(new Date()), false)

        this.setState({
            filtersMap: filtersMap,
            brands: [],
            shortcodes: [],
            actionTypes: [],
            fromDate: this.getLastWeekDate(),
            toDate: new Date(),
        })
    }

    render() {
        return (
            <>
                <div className='filter-tag-wrapper'>
                    <FilterTagsBar
                        filtersMap={this.state.visibleFiltersMap}
                        onClick={this.state.filtersOpen ? this.onTagClickedWhenDialogueOpen : this.onTagClicked}
                    />
                </div>

                <CustomTableHeader
                    searchText={this.props.searchText}
                    onSearchValueChange={this.props.onSearchValueChange}
                    filterIcon={
                        <Tooltip title={FILTER} style={{ paddingTop: 10 }}>
                            <IconButton className="filterButton" size='small' aria-label={FILTER} onClick={this.showFiltersDialog} >
                                <FilterIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    showFilters={this.state.filtersOpen}
                    customFilterField={
                        <React.Fragment>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.props.showThresholds}
                                        onChange={this.props.handleShowThresholdsChange}
                                    />
                                }
                                label={SHOW_THRESHOLDS}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.props.showOutOfStock}
                                        onChange={this.props.handleShowOutOfStockChange}
                                    />
                                }
                                label={SHOW_OUT_OF_STOCK}
                            />
                            <div style={{fontSize: "25px", paddingRight: "18px"}}>
                                |
                            </div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.props.onlyInsufficientStock}
                                        onChange={this.props.handleOnlyInsufficientStockChange}
                                        icon={<CircleOutlinedIcon/>}
                                        checkedIcon={<CircleIcon/>}
                                    />
                                }
                                label={SHOW_INSUFFICIENT_STOCK_ONLY}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.props.onlyLowInventory}
                                        onChange={this.props.handleOnlyLowInventoryChange}
                                        icon={<CircleOutlinedIcon/>}
                                        checkedIcon={<CircleIcon/>}
                                    />
                                }
                                label={SHOW_LOW_INVENTORY_ONLY}
                            />

                        </React.Fragment>
                    }
                    filtersComponent={
                        <Slide in={this.state.filtersOpen} mountOnEnter unmountOnExit timeout={300}>
                            <InventoryFiltersDialog

                                allBrands={this.state.allBrands}
                                allShortcodes={this.state.allShortcodes}
                                allActionTypes={this.state.allActionTypes}
                                setFilterValue={this.setFilterValue}
                                maxAllowedSearchRange={this.props.maxAllowedSearchRange}
                                setMaxAllowedSearchRangeError={this.props.setMaxAllowedSearchRangeError}
                                setToDateBeforeFromDateError={this.props.setToDateBeforeFromDateError}
                                brands={this.state.brands}
                                shortcodes={this.state.shortcodes}
                                actionTypes={this.state.actionTypes}
                                fromDate={this.state.fromDate}
                                toDate={this.state.toDate}

                                applyFilters={() => {this.closeFilters(true)}}
                                cancelFilters={() => {this.closeFilters(false)}}
                                clearFilters={() => {this.clearFilters()}}

                            />
                        </Slide>}
                    location={
                        <>
                            <Autocomplete
                                options={['Packaging', 'Products']}
                                value={this.state.filtersMap?.inventoryType ?? 'Products'}
                                getOptionLabel={(entry) => entry}
                                autoHighlight={true}
                                name="inventoryType"
                                disableClearable
                                onChange={(event, value) => {
                                    this.onFiltersInputChange('inventoryType', value)
                                }
                                }
                                blurOnSelect={true}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Inventory Type"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        name="inventoryType"
                                        style={{ width: "20vw", marginTop: "13px" }}
                                    />
                                }
                            />

                            <div style={{width: "20vu", marginRight: "30px", marginTop: "13px"}}>
                                <LocationSelectBox
                                    updateCallback={(location) => {
                                        this.props.updateUserInfo({ ...this.props.user, selectedLocationId: location.shipAddressId })
                                        this.setState({
                                            default: location
                                        })
                                        this.props.onLocationChange(location)}
                                    }
                                />
                            </div>

                        </>
                    }
                />
            </>
        )
    }


}

InventorySearchFilterField.propTypes = {
    availableLocations: PropTypes.array,
    isFilterApplied: PropTypes.bool,
    onLocationChange: PropTypes.func,
    onShortcodeSelectionChange: PropTypes.func,
    manufacturers: PropTypes.array,
    inventoryAdjustmentTypes: PropTypes.array,
    availableShortcodes: PropTypes.array,
    passFiltersUp: PropTypes.func,
    onSearchValueChange: PropTypes.func,
}

InventorySearchFilterField.defaultProps = {
    availableLocations: [],
    isFilterApplied: false,
    onLocationChange: () => { },
    onShortcodeSelectionChange: () => { },
    manufacturers: [],
    inventoryAdjustmentTypes: [],
    availableShortcodes: [],
    passFiltersUp: () => { },
    onSearchValueChange: () => { }
}

export default InventorySearchFilterField;