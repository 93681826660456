import React from 'react';

// styling
import styles from "./CreateCarrierDialog.module.css";

// material components
import {TextField} from "@material-ui/core";
import PropTypes from "prop-types";
import {BUY_SHIPPING_SETUP} from "./constants";

function CarrierForm(props) {

    return(
        <div className={styles.rowCarrier}>
            <div>
                <div className={styles.rowSpec}>
                    { props.parentState.carrier === "UPS" ?
                        <TextField
                        required={props.parentState.carrierSetup !== BUY_SHIPPING_SETUP}
                        error={props.errors.username}
                        className={styles.formTwoInput}
                        variant="outlined"
                        value={props.parentState.username}
                        name="username"
                        onChange={props.handleChange}
                        label="Client Id"
                        InputLabelProps={{ shrink: true }}
                        error={props.parentState.error && (props.parentState.username === '' || props.parentState.username === undefined)}
                    />:
                        <TextField
                            required={props.parentState.carrierSetup !== BUY_SHIPPING_SETUP}
                            error={props.errors.username}
                            className={styles.formTwoInput}
                            variant="outlined"
                            value={props.parentState.username}
                            name="username"
                            onChange={props.handleChange}
                            label="Key"
                            InputLabelProps={{ shrink: true }}
                            error={props.parentState.error && (props.parentState.username === '' || props.parentState.username === undefined)}
                        />}
                    { props.parentState.carrier === "UPS" ?
                        <TextField
                        required={props.parentState.carrierSetup !== BUY_SHIPPING_SETUP}
                    type="password"
                    error={props.errors.password}
                    className={styles.formTwoInput}
                    variant="outlined"
                    value={props.parentState.password}
                    name="password"
                    onChange={props.handleChange}
                    label="Client Secret"
                    InputLabelProps={{ shrink: true }}
                    error={props.parentState.error && (props.parentState.password === '' || props.parentState.password === undefined)}
                />
                :
                    <TextField
                        required={props.parentState.carrierSetup !== BUY_SHIPPING_SETUP}
                        type="password"
                        error={props.errors.password}
                        className={styles.formTwoInput}
                        variant="outlined"
                        value={props.parentState.password}
                        name="password"
                        onChange={props.handleChange}
                        label="Password"
                        InputLabelProps={{ shrink: true }}
                        error={props.parentState.error && (props.parentState.password === '' || props.parentState.password === undefined)}
                    />}
                </div>
                <div className={styles.rowSpec}>
                {props.parentState.carrier === "Canada Post" ?
                    <TextField
                        required={props.parentState.carrierSetup !== BUY_SHIPPING_SETUP}
                        error={props.errors.accountNumber}
                        className={styles.formTwoInput}
                        variant="outlined"
                        value={props.parentState.accountNumber}
                        name="accountNumber"
                        onChange={props.handleChange}
                        label="Customer Number"
                        InputLabelProps={{ shrink: true }}
                        error={props.parentState.error && (props.parentState.accountNumber === '' || props.parentState.accountNumber === undefined)}
                    />
                    :
                    <TextField
                        required={props.parentState.carrierSetup !== BUY_SHIPPING_SETUP}
                        error={props.errors.accountNumber}
                        className={styles.formTwoInput}
                        variant="outlined"
                        value={props.parentState.accountNumber}
                        name="accountNumber"
                        onChange={props.handleChange}
                        label="Account Number"
                        InputLabelProps={{ shrink: true }}
                        error={props.parentState.error && (props.parentState.accountNumber === '' || props.parentState.accountNumber === undefined)}
                    />}
                    {props.parentState.carrier === "FedEx" ?
                        <TextField
                            required={props.parentState.carrierSetup !== BUY_SHIPPING_SETUP}
                            className={styles.formTwoInput}
                            variant="outlined"
                            value={props.parentState.meterNumber}
                            name="meterNumber"
                            onChange={props.handleChange}
                            label="Meter Number"
                            InputLabelProps={{ shrink: true }}
                            error={props.parentState.error && (props.parentState.meterNumber === '' || props.parentState.meterNumber === undefined)&&(props.parentState.contractId === '' || props.parentState.contractId === undefined)}
                        />
                        :props.parentState.carrier === "Canada Post" ? (
                                <TextField
                                    className={styles.formTwoInput}
                                    variant="outlined"
                                    value={props.parentState.contractId}
                                    name="contractId"
                                    onChange={props.handleChange}
                                    label="Contract ID"
                                    InputLabelProps={{ shrink: true }}
                                    error={props.parentState.error && (props.parentState.contractId === '' || props.parentState.contractId === undefined)}
                                />
                            ) :
                        <>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

CarrierForm.propTypes = {
    parentState: PropTypes.object,
    handleChange: PropTypes.func,
    errors: PropTypes.array,
    isEdit: PropTypes.bool
}

CarrierForm.defaultProps = {
    parentState: {},
    handleChange: ()=>{},
    errors: [],
    isEdit: ()=>{}
}

export default CarrierForm;