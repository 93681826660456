import {
    createSelector
} from "reselect";

export const carriersSelector = createSelector(state => state && state.settings && state.settings.carriers, carriersSelector => carriersSelector);
export const shippingTemplatesSelector = createSelector(state => state && state.settings && state.settings.shippingTemplates, shippingTemplatesSelector => shippingTemplatesSelector);
export const carrierNamesSelector = createSelector(state => state && state.settings && state.settings.carrierNames, carrierNamesSelector => carrierNamesSelector);
export const addressesSelector = createSelector(state => state && state.settings && state.settings.addresses, addressesSelector => addressesSelector);
export const linkedAddressesSelector = createSelector(state => state && state.settings && state.settings.linkedAddresses, linkedAddressesSelector => linkedAddressesSelector);
export const carrierOperationResponseSelector = createSelector(state => state.settings && state.settings.carrierOperationResponse, carrierOperationResponseSelector => carrierOperationResponseSelector);

export const productsSelector = createSelector(state => state && state.settings && state.settings.products, productsSelector => productsSelector);
export const suppliersSelector = createSelector(state => state && state.settings && state.settings.suppliers, suppliersSelector => suppliersSelector);
export const locationsSelector = createSelector(state => state && state.settings && state.settings.locations, locationsSelector => locationsSelector);
export const availableLocationsSelector = createSelector(state => state && state.settings && state.settings.availableLocations, availableLocationsSelector => availableLocationsSelector);
export const printersSelector = createSelector(state => state && state.settings && state.settings.printers, printersSelector => printersSelector);
export const tagsSelector = createSelector(state => state?.settings?.tags, tagsSelector => tagsSelector);
export const logoSelector = createSelector(state => state && state.settings && state.settings.logo, logoSelector => logoSelector);
export const packagesSelector = createSelector(state => state && state.settings && state.settings.packages, packagesSelector => packagesSelector);

export const historicalDataSelector = createSelector(state => state && state.settings && state.settings.historicalData, historicalDataSelector => historicalDataSelector);
export const shiprushFileNamesSelectors =  createSelector(state => state && state.settings && state.settings.shiprushFileNames, shiprushFileNamesSelectors => shiprushFileNamesSelectors);
export const emailTemplatesSelector = createSelector(state => state && state.settings && state.settings.emailTemplates, emailTemplatesSelector => emailTemplatesSelector)
export const emailTemplateTypeSelector = createSelector(state => state && state.settings && state.settings.emailTemplateTypes, emailTemplateTypeSelector => emailTemplateTypeSelector)

export const listingsSelector = createSelector(state => state && state.settings && state.settings.listings, listingsSelector => listingsSelector);
export const pricingListingsSelector = createSelector(state => state && state.settings && state.settings.pricingListings, pricingListingsSelector => pricingListingsSelector);
export const mainSalesRankSelector = createSelector(state => state && state.settings && state.settings.mainSalesRank, mainSalesRankSelector => mainSalesRankSelector);
export const subSalesRankSelector = createSelector(state => state && state.settings && state.settings.subSalesRank, subSalesRankSelector => subSalesRankSelector);
export const relatedProductsMainSalesRankSelector = createSelector(state => state && state.settings && state.settings.relatedProductsMainSalesRank, relatedProductsMainSalesRankSelector => relatedProductsMainSalesRankSelector);
export const relatedProductsSelector = createSelector(state => state && state.settings && state.settings.relatedProducts, relatedProductsSelector => relatedProductsSelector);
export const relatedProductBuyBoxPricesSelector = createSelector(state => state && state.settings && state.settings.relatedProductBuyBoxPrices, relatedProductBuyBoxPricesSelector => relatedProductBuyBoxPricesSelector);
export const buyBoxPricesSelector = createSelector(state => state && state.settings && state.settings.buyBoxPrices, buyBoxPricesSelector => buyBoxPricesSelector);
export const barcodesSelector = createSelector(state => state && state.settings && state.settings.barcodes, barcodesSelector => barcodesSelector);
export const marketplacesSelector = createSelector(state => state && state.settings && state.settings.marketplaces, marketplacesSelector => marketplacesSelector)
export const allSelector = createSelector(state => state, allSelector => allSelector);
export const categorySelector = createSelector(state => state && state.settings && state.settings.category, categorySelector => categorySelector);
export const countriesSelector = createSelector(state => state && state.settings && state.settings.countries, countriesSelector => countriesSelector)
export const feedsSelector = createSelector(state => state && state.settings && state.settings.feeds, feedsSelector => feedsSelector);
export const responseMessageSelector = createSelector(state => state && state.settings && state.settings.marketplaces && state.settings.responseMessage, responseMessageSelector => responseMessageSelector)

export const hasPurchaseOrderEmailTemplateSelector = createSelector(state => state && state.settings && state.settings.hasPurchaseOrderEmailTemplate, hasPurchaseOrderEmailTemplateSelector => hasPurchaseOrderEmailTemplateSelector)

export const archivedLabelTrackingNumbersSelector = createSelector(state => state && state.settings && state.settings.archivedLabelTrackingNumbers, archivedLabelTrackingNumbersSelector => archivedLabelTrackingNumbersSelector)
export const archivedLabelsReturnedSelector = createSelector(state => state && state.settings && state.settings.archivedLabelsReturned, archivedLabelsReturnedSelector => archivedLabelsReturnedSelector)

export const printConfigSelector = createSelector(state => state && state.settings && state.settings.printConfig, printConfigSelector => printConfigSelector);

export const bankAccountsSelector = createSelector(state => state && state.settings && state.settings.bankAccounts, bankAccountsSelector => bankAccountsSelector)
