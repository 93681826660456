export const CREATE_CARRIER = "CREATE_CARRIER";
export const LIST_CARRIERS = "LIST_CARRIERS";
export const LIST_SHIPPING_TEMPLATES = "LIST_SHIPPING_TEMPLATES"
export const LIST_UNIQUE_CARRIERS = "LIST_UNIQUE_CARRIERS"
export const LIST_ADDRESSES = "LIST_ADDRESSES";
export const LIST_LINKED_ADDRESSES = "LIST_LINKED_ADDRESSES";
export const IMPORT_HISTORICAL_DATA = "IMPORT_HISTORICAL_DATA";
export const LIST_SHEETS = "listSheets";
export const CLEAR_SHIPRUSH_SPREADSHEETS_INFO = "clearShiprushSpreadsheetsInfo";
export const CLEAR_HISTORICAL_DATA = "CLEAR_HISTORICAL_DATA";
export const STORE_HISTORICAL_DATA = "STORE_HISTORICAL_DATA";
export const STORE_SHIPRUSH_FILE_NAMES = "STORE_SHIPRUSH_FILE_NAMES";
export const STORE_CARRIERS = "STORE_CARRIERS";
export const STORE_SHIPPING_TEMPLATES = "STORE_SHIPPING_TEMPLATES";
export const STORE_INVENTORY_AUTO_ALLOCATION = "STORE_INVENTORY_AUTO_ALLOCATION"
export const STORE_CARRIER_NAMES = "STORE_CARRIER_NAMES";
export const STORE_ADDRESSES = "STORE_ADDRESSES";
export const UPDATE_CARRIERS = "UPDATE_CARRIERS";
export const CLEAR_CARRIER_OPERATION_RESPONSE = "CLEAR_CARRIER_OPERATION_RESPONSE";
export const STORE_CARRIER_OPERATION_RESPONSE = "STORE_CARRIER_OPERATION_RESPONSE";
export const UPDATE_ADDRESSES = "UPDATE_ADDRESSES";
export const STORE_LINKED_ADDRESSES = "STORE_LINKED_ADDRESSES";
export const EDIT_CARRIER = "EDIT_CARRIER";
export const UPDATE_CARRIERS_EDIT = "UPDATE_CARRIERS_EDIT";
export const DELETE_CARRIER = "DELETE_CARRIER";
export const UPDATE_CARRIERS_DELETE = "UPDATE_CARRIERS_DELETE"
export const SET_DEFAULT_CARRIER = "SET_DEFAULT_CARRIER"
export const ADDRESS_VALIDATION_CHANGE = 'ADDRESS_VALIDATION_CHANGE'
export const LIST_CARRIER_NAMES = "LIST_CARRIER_NAMES"
export const LIST_ALL_BANK_ACCOUNTS = 'LIST_ALL_BANK_ACCOUNTS';
export const STORE_ALL_BANK_ACCOUNTS = 'STORE_ALL_BANK_ACCOUNTS';
export const SAVE_BANK_ACCOUNT = 'SAVE_BANK_ACCOUNT';
export const STORE_UPDATED_BANK = "STORE_UPDATED_BANK"
export const STORE_UPDATED_BANK_WITH_PREVIEW = "STORE_UPDATED_BANK_WITH_PREVIEW"
export const PREVIEW_BANK_FILE = 'PREVIEW_BANK_FILE';
export const DELETE_BANK_ACCOUNT = 'DELETE_BANK_ACCOUNT';

export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const STORE_PRODUCTS = "STORE_PRODUCTS";
export const LIST_PRODUCTS = "LIST_PRODUCTS";
export const STORE_UPDATED_PRODUCT = "STORE_UPDATED_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";

export const CREATE_SUPPLIER = "CREATE_SUPPLIER";
export const UPDATE_SUPPLIER = "UPDATE_SUPPLIER";
export const STORE_SUPPLIERS = "STORE_SUPPLIERS";
export const PROCESS_SUPPLIER_INVOICES = "PROCESS_SUPPLIER_INVOICES";
export const LIST_SUPPLIERS = "LIST_SUPPLIERS";

export const CREATE_LOCATION = "CREATE_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const STORE_LOCATIONS = "STORE_LOCATIONS";
export const AUTOMATION_SETTINGS = "AUTOMATION_SETTINGS";
export const LIST_LOCATIONS = "LIST_LOCATIONS";
export const LIST_AVAILABLE_LOCATIONS = 'LIST_AVAILABLE_LOCATIONS';
export const STORE_AVAILABLE_LOCATIONS = 'STORE_AVAILABLE_LOCATIONS';

export const LIST_COMPANIES = "LIST_COMPANIES";
export const STORE_COMPANIES = "STORE_COMPANIES";

export const LIST_PRINTERS = "LIST_PRINTERS";
export const REFRESH_PRINTERS = "REFRESH_PRINTERS";
export const UPDATE_PRINTERS = "UPDATE_PRINTERS";
export const STORE_PRINTERS = "STORE_PRINTERS";
export const UPLOAD_LOGO = "UPLOAD_LOGO";
export const FETCH_LOGO = "FETCH_LOGO";
export const STORE_LOGO = "STORE_LOGO";
export const DELETE_LOGO = "DELETE_LOGO"
export const EDIT_TAGS = "EDIT_TAGS";
export const LIST_TAGS = "LIST_TAGS";
export const STORE_TAGS = "STORE_TAGS";

export const GET_UUID = "GET_UUID";
export const GET_UUIDS = "GET_UUIDS";

export const CREATE_PACKAGE = "CREATE_PACKAGE";
export const UPDATE_PACKAGE = "UPDATE_PACKAGE";
export const STORE_PACKAGES = "STORE_PACKAGES";
export const LIST_PACKAGES = "LIST_PACKAGES";
export const STORE_UPDATED_PACKAGE = "STORE_UPDATED_PACKAGE";

export const LIST_EMAIL_TEMPLATES = "LIST_EMAIL_TEMPLATES";
export const STORE_EMAIL_TEMPLATES = "STORE_EMAIL_TEMPLATES"
export const STORE_EMAIL_TEMPLATE_TYPES = "STORE_EMAIL_TEMPLATE_TYPES"
export const EMAIL_TEMPLATE_UPDATE = "EMAIL_TEMPLATE_UPDATE"
export const CREATE_NEW_EMAIL_TEMPLATE = "CREATE_NEW_EMAIL_TEMPLATE"
export const DELETE_NEW_EMAIL_TEMPLATE = "DELETE_NEW_EMAIL_TEMPLATE"
export const GET_EMAIL_TEMPLATE_TYPES = "GET_EMAIL_TEMPLATE_TYPES"
export const EMAIL_TEMPLATE_TYPE_UPDATE = "EMAIL_TEMPLATE_TYPE_UPDATE"
export const CREATE_NEW_EMAIL_TEMPLATE_TYPE = "CREATE_NEW_EMAIL_TEMPLATE_TYPE"
export const DELETE_NEW_EMAIL_TEMPLATE_TYPE = "DELETE_NEW_EMAIL_TEMPLATE_TYPE"
export const FETCH_SENDGRID_TEMPLATES = "FETCH_SENDGRID_TEMPLATES"
export const FETCH_PURCHASE_ORDER_EMAIL_TEMPLATE = "FETCH_PURCHASE_ORDER_EMAIL_TEMPLATE"
export const SEND_EMAIL_PURCHASE_ORDER = "SEND_EMAIL_PURCHASE_ORDER"
export const STORE_PURCHASE_ORDER_EMAIL_TEMPLATE = "STORE_PURCHASE_ORDER_EMAIL_TEMPLATE"
export const PO_EMAIL_SEND = 'PO_EMAIL_SEND'

export const FETCH_SUPPLIER_TEMPLATES = "FETCH_SUPPLIER_TEMPLATES";
export const STORE_SUPPLIER_TEMPLATES = "STORE_SUPPLIER_TEMPLATES";
export const FETCH_SUPPLIERS_WITHOUT_TEMPLATE = "FETCH_SUPPLIERS_WITHOUT_TEMPLATE";
export const STORE_SUPPLIERS_WITHOUT_TEMPLATE = "STORE_SUPPLIERS_WITHOUT_TEMPLATE";
export const CREATE_SAVE_INVOICE_TEMPLATE = "CREATE_SAVE_INVOICE_TEMPLATE";
export const GET_PDF_TEMPLATE = "GET_PDF_TEMPLATE";
export const STORE_PDF_TEMPLATE = "STORE_PDF_TEMPLATE";
export const DELETE_INVOICE_TEMPLATE = "DELETE_INVOICE_TEMPLATE";

export const FETCH_LISTINGS = "FETCH_LISTINGS";
export const FETCH_PRICING_LISTINGS = "FETCH_PRICING_LISTINGS";
export const Add_RELATED_PRODUCT = "Add_RELATED_PRODUCT";
export const EDIT_RELATED_PRODUCT = "EDIT_RELATED_PRODUCT";
export const UPDATE_RELATED_PRODUCTS = "UPDATE_RELATED_PRODUCTS";
export const GET_SALES_RANK = "GET_SALES_RANK";
export const GET_BUY_BOX_PRICES = "GET_BUY_BOX_PRICES";
export const GET_RELATED_PRODUCTS = "GET_RELATED_PRODUCTS";
export const FETCH_AMAZON_LISTINGS_PRICE = "FETCH_AMAZON_LISTINGS_PRICE";
export const SET_SKU_LOCATION = "SET_SKU_LOCATION";
export const FETCH_BARCODES = "FETCH_BARCODES";
export const CHANGE_PRIMARY = "CHANGE_PRIMARY";
export const ADD_NEW_CHILD = "ADD_NEW_CHILD";
export const DELETE_CHILD = "DELETE_CHILD"
export const STORE_LISTINGS = "STORE_LISTINGS";
export const STORE_PRICING_LISTINGS = "STORE_PRICING_LISTINGS";
export const STORE_SALES_RANK = "STORE_SALES_RANK";
export const STORE_BUY_BOX_PRICES = "STORE_BUY_BOX_PRICES";
export const STORE_RELATED_PRODUCTS = "STORE_RELATED_PRODUCTS";
export const STORE_BARCODES = "STORE_BARCODES";
export const UPDATE_BARCODES = "UPDATE_BARCODES";
export const UPDATE_LISTING = "UPDATE_LISTING";
export const UPDATE_PRICING_LISTING = "UPDATE_PRICING_LISTING";
export const UPDATE_REPRICING = "UPDATE_REPRICING";
export const DELETE_LISTING = "DELETE_LISTING";
export const STORE_DELETE_LISTING = 'STORE_DELETE_LISTING';
export const STORE_UPDATED_LISTING = "STORE_UPDATED_LISTING";
export const STORE_UPDATED_PRICING_LISTING = "STORE_UPDATED_PRICING_LISTING";
export const STORE_UPDATED_BARCODE = "STORE_UPDATED_BARCODE";
export const STORE_REMOVED_BARCODE = "STORE_REMOVED_BARCODE";
export const GET_UNMAPPED_PRODUCTS = "GET_UNMAPPED_PRODUCTS";
export const STORE_UNMAPPED_PRODUCTS = "STORE_UNMAPPED_PRODUCTS";
export const GET_ACTIVE_PRODUCTS = "GET_ACTIVE_PRODUCTS";
export const STORE_ACTIVE_PRODUCTS = "STORE_ACTIVE_PRODUCTS";
export const GET_INACTIVE_PRODUCTS = "GET_INACTIVE_PRODUCTS";
export const STORE_INACTIVE_PRODUCTS = "STORE_INACTIVE_PRODUCTS";
export const GET_DELETED_PRODUCTS = "GET_DELETED_PRODUCTS";
export const STORE_DELETED_PRODUCTS = "STORE_DELETED_PRODUCTS";
export const FETCH_AMAZON_SKUS = "FETCH_AMAZON_SKUS";

export const SCRUB_DATA = "SCRUB_DATA";

export const SAVE_PRODUCT_CATEGORY_CHANGES = "SAVE_PRODUCT_CATEGORY_CHANGES";
export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const REMOVE_PRODUCT_CATEGORY = "REMOVE_PRODUCT_CATEGORY";
export const STORE_ALL_CATEGORY = 'STORE_ALL_CATEGORY';
export const STORE_ALL_HS_CODE = 'STORE_ALL_HS_CODE';

export const CREATE_MARKETPLACE = "CREATE_MARKETPLACE";
export const LIST_MARKETPLACES = "LIST_MARKETPLACES";
export const UPDATE_INVENTORY_ALLOCATION = "UPDATE_INVENTORY_ALLOCATION";
export const STORE_INVENTORY_ALLOCATION = "STORE_INVENTORY_ALLOCATION"
export const MANUAL_EXECUTE_AUTOMATION_SETTINGS = "MANUAL_EXECUTE_AUTOMATION_SETTINGS";
export const EDIT_MARKETPLACE = "EDIT_MARKETPLACE";
export const DELETE_MARKETPLACE = "DELETE_MARKETPLACE";
export const STORE_MARKETPLACES = "STORE_MARKETPLACES";
export const UPDATE_MARKETPLACES = "UPDATE_MARKETPLACES";
export const UPDATE_MARKETPLACES_EDIT = "UPDATE_MARKETPLACES_EDIT";
export const UPDATE_MARKETPLACES_DELETE = "UPDATE_MARKETPLACES_DELETE";
export const STORE_RESPONSE_MESSAGE = "STORE_RESPONSE_MESSAGE";
export const VALIDATE_MARKETPLACE = "VALIDATE_MARKETPLACE";

export const GET_COUNTRY_CODES = "GET_COUNTRY_CODES"
export const STORE_COUNTRY_CODES = "STORE_COUNTRY_CODES"
export const GET_AMAZON_FEEDS = 'GET_AMAZON_FEEDS'
export const STORE_AMZ_FEEDS = 'STORE_AMZ_FEEDS'
export const RESTART_FEED_FETCH = 'RESTART_FEED_FETCH'

export const ARCHIVE_LABELS = 'ARCHIVE_LABELS'
export const STORE_ARCHIVED_LABELS = 'STORE_ARCHIVED_LABELS'
export const STORE_ARCHIVED_LABELS_RETURNED = 'STORE_ARCHIVED_LABELS_RETURNED'

export const GET_PRINT_CONFIG = 'GET_PRINT_CONFIG'
export const STORE_PRINT_CONFIG = 'STORE_PRINT_CONFIG'

export const INVOICE_EMAIL_SEND = 'INVOICE_EMAIL_SEND'