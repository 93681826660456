import React from "react";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import { PURCHASE_SUMMARY_PAGES} from "./constants";
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import withShipment from "../../../withShipment";
import {
    listShippingSummary
} from "../../../redux/actions/accounting";
import { shippingSummarySelector} from "../../../redux/selectors/accounting";
import {statusSelector} from "../../../redux/selectors/global";
import {clearStatus} from "../../../redux/actions/global";
import {userInfoSelector} from "../../../redux/selectors/auth";
import PurchaseSummaryTable from "./PurchaseSummaryTable";

class PurchaseSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shippingSummary: this.props.shippingSummary,
            currentPage: PURCHASE_SUMMARY_PAGES.TABLE,
            feedbackBarOpen: false,
            error: false,
            selectedYear: new Date().getFullYear(),
            selectedQuarters: null,
            supplierTotal: 0,
            selectedCurrency: 'CAD',
            selectedMonth: ' ',
    }
    }


    componentDidMount() {
        this.listShippingApi()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.status.statusText && prevProps.status.statusText !== this.props.status.statusText){
            this.openFeedbackBar()
        }


        if (prevProps.shippingSummary !== this.props.shippingSummary) {
             this.calculateTotal()
        }
    }


    listShippingApi = () =>{
        let data
        if(this.state.selectedQuarters === null){
            data = {
                "type": "supplier",
                "year": this.state.selectedYear,
                "quarter": [1,2,3,4],
                "month": this.state.selectedMonth.trim(),
                "currency": this.state.selectedCurrency,
            }
        }else{
            let newQuarter = [this.state.selectedQuarters]
            data = {
                "type": "supplier",
                "year": this.state.selectedYear,
                "quarter": newQuarter,
                "month": this.state.selectedMonth.trim(),
                "currency": this.state.selectedCurrency,
            }
        }
        this.props.listShippingSummary(data)
    }

    handleYearChange = (event) => {
        this.setState({ selectedYear: event.target.value },() => {
            this.listShippingApi()
        })

    }

    handleMonthChange = (event) => {
        this.setState({ selectedMonth: event.target.value },() => {
            this.listShippingApi()
        })

    }

    handleCurrencyChange = (event) => {
        this.setState({ selectedCurrency: event.target.value },() => {
            this.listShippingApi()
        })
    }


    handleQuarterChange = (quarter) => {
        const quarterMapping = {
            "QTR 1": 1,
            "QTR 2": 2,
            "QTR 3": 3,
            "QTR 4": 4
        }
        const quarterNumber = quarterMapping[quarter]
        const selectedQuarters = this.state.selectedQuarters === quarterNumber ? null : quarterNumber
        this.setState({ selectedQuarters }, () => {
            this.listShippingApi()
        })
    }

    calculateTotal = () => {
        const supplierTotal = this.props.shippingSummary.reduce((total, summary) => {
            return total + parseFloat(summary.CY_TOTAL_PAYABLE) || 0
        }, 0)

        this.setState({ supplierTotal })
    }

    setCurrentPage = (newPage) => {
        this.setState({
            currentPage: newPage
        })
    }

    displayTable = () => {
            return (
                <PurchaseSummaryTable
                    selectedQuarters={this.state.selectedQuarters}
                    selectedYear={this.state.selectedYear}
                    handleQuarterChange={this.handleQuarterChange}
                    handleYearChange={this.handleYearChange}
                    selectedCurrency={this.state.selectedCurrency}
                    handleCurrencyChange={this.handleCurrencyChange}
                    selectedMonth={this.state.selectedMonth}
                    handleMonthChange={this.handleMonthChange}
                    shippingSummary={this.props.shippingSummary}
                    supplierTotal={this.state.supplierTotal}
                    setCurrentPage={this.setCurrentPage}
                    openFeedbackBar={this.openFeedbackBar}
                />
            )
    }


    displayCurrentPage = () => {
        switch (this.state.currentPage) {
            case PURCHASE_SUMMARY_PAGES.TABLE:
                return this.displayTable()
            default:
                return <div>We encountered an error. Please refresh the page.</div>
        }
    }

    openFeedbackBar = () => {
        this.setState({feedbackBarOpen: true})
    }

    closeFeedbackBar = () => {
        this.setState({ feedbackBarOpen: false }, () => {
            this.props.clearStatus()
        })
    }

    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar/>
                <FeedbackBar
                    open={this.state.feedbackBarOpen}
                    handleClose={this.closeFeedbackBar}
                    severity={this.props.status?.success ? 'success' : 'error'}
                    message={this.props.status?.statusText}
                />
                {this.displayCurrentPage()}
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    status: statusSelector(state),
    shippingSummary: shippingSummarySelector(state),
    user: userInfoSelector(state),
})


const actionCreators = {
    clearStatus,
    listShippingSummary,
}

export default withShipment({
    actionCreators,
    mapStateToProps
}, PurchaseSummary);