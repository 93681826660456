import React, { useState } from "react";

// material components
import Divider from "@material-ui/core/Divider";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import {Button, Checkbox, IconButton, MenuItem, Select, TextField, Tooltip, Typography} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableCell from "@material-ui/core/TableCell";
import green from "@material-ui/core/colors/green";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TableHead from "@material-ui/core/TableHead";
import { KeyboardDatePicker } from "@material-ui/pickers";


// constants
import {
    ADD_PO_ENTRY, CANCEL, CREATE_PO_CELLS_PRICING_ROLE,
    CREATE_PURCHASE_ORDERS_CELLS,
    NOTES, PREVIEW
} from "./constants";

// styling
import './CreateCustomerPurchaseOrderTable.css';
import PropTypes from "prop-types";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import InputAdornment from "@material-ui/core/InputAdornment";
import {FormControlLabel} from "@mui/material";
import styles from "../../settings/ManageCarriers/CreateCarrierDialog.module.css";
import {COUNTRY_OPTIONS} from "../../automationrules/ConditionActions/constants";


class CreateCustomerPurchaseOrderTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageInit: true,
            showPreview: false,
            pageNumber: 1,
            numPages: null,
        };
    }

    componentDidMount() {
        if(!this.props.editingPurchaseOrder){
            this.props.handleCreateDateChange(new Date())
            this.props.handleDateChange(this.initDate())
            this.props.handlePOChange('')
            this.props.handleShipToAddressChange(null)
            this.props.handleHistoricalChange(false)
            this.props.handleShipByChange(true)
        }
    }

    initDate = () => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow
    }


    onDocumentLoadSuccess = ({ numPages }) => {
        if (!this.state.numPages) {
            this.setState({
                numPages: numPages
            });
        }
    }

    InvalidDate() {
        // if (this.props.editingPurchaseOrder) {
        //     return false;
        // }
        const specificDate = new Date(this.props.createDate);
        specificDate.setDate(specificDate.getDate() + 1);
        specificDate.setHours(0, 0, 0, 0);
        let date = this.props.expectedDate
        date.setHours(0, 0, 0, 0)
        if(this.props.isHistorical){
            return false
        }else{
            return date < specificDate
        }

    }

    handlePreview = () => {
        this.props.changeHeader("previewCreating")
        this.props.previewPDF()
    }

    displayAccountNumber = (selectedLocation, selectedCustomerInfo) => {
        let matchingAccount = selectedCustomerInfo?.accounts?.find((account)=>
            account?.location?.shipAddressId === selectedLocation.shipAddressId
        )
        return matchingAccount?.accountNumber || ""
    }

    formatDate = (date) => {
        return date.substring(5, 7) + "/" + date.substring(8, 10) + "/" + date.substring(0, 4)
    }

    render() {
        return (
            <>
                <Paper className="paper">
                    <TableContainer>
                        {/*Dropdown to select the customer*/}
                        <br/>
                        <div className="wrap">
                            <div style={{width: "15%", marginLeft: "16px"}}>
                                <Autocomplete
                                    options={this.props.editingPurchaseOrder && this.props.purchaseOrder.status == 'partial' ? [] : this.props.availableCustomerNames}
                                    getOptionLabel={(entry) => entry}
                                    autoHighlight={!this.props.editingPurchaseOrder}
                                    value={this.props.selectedCustomer}
                                    onChange={(event, value) => {
                                        if ((this.props.editingPurchaseOrder && this.props.purchaseOrder?.status !== 'partial') || !this.props.editingPurchaseOrder) {
                                            this.props.onCustomerChange(value ? value : "")
                                            this.setState({
                                                pageInit: false
                                            });
                                        }
                                    }
                                    }
                                    blurOnSelect={true}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            required
                                            label="Customer Name"
                                            InputLabelProps={{
                                                shrink: true,
                                                readOnly: this.props.editingPurchaseOrder && this.props.purchaseOrder.status == 'partial'
                                            }}
                                            variant="outlined"
                                            autoFocus={this.state.pageInit}
                                            name="customer"
                                            onChange={() => {
                                            }}

                                        />
                                    }
                                />
                            </div>
                            <Autocomplete
                                className="location-field"
                                options={this.props.editingPurchaseOrder && this.props.purchaseOrder.status == 'partial' ? [] : this.props.locations.filter(location => location.enabled)}
                                getOptionLabel={(entry) => entry.name}
                                autoHighlight={true}
                                // style={{width: 278, marginLeft: 28, marginBottom: 10}}
                                disableClearable
                                onChange={(event, value) => {
                                    return this.props.editingPurchaseOrder && this.props.purchaseOrder.status == 'partial' ? null : this.props.handleLocationChange(value, true)
                                }}
                                blurOnSelect={true}
                                value={this.props.selectedLocation}

                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Ship To"
                                        InputLabelProps={{
                                            shrink: true,
                                            readOnly: this.props.editingPurchaseOrder && this.props.purchaseOrder.status == 'partial'
                                        }}
                                        variant="outlined"
                                        name="location"
                                    />
                                }
                            />
                            <TextField
                                style={{marginRight: '10px', width: '150px'}}
                                error={(isNaN(Number(this.props.taxPercentage)) || this.props.taxPercentage === "")}
                                variant="outlined"
                                name="taxPercentage"
                                label="Tax Percentage"
                                value={this.props.taxPercentage}
                                onChange={(e) => this.props.handleTaxChange(e.target.value)}
                                size="medium"
                                InputLabelProps={{shrink: true}}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            %
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                style={{width: '150px'}}
                                error={(isNaN(Number(this.props.discountPercentage)) || this.props.discountPercentage === "") && this.props.discountPercentage !== ""}
                                variant="outlined"
                                name="discountPercentage"
                                label="Discount"
                                value={this.props.discountPercentage}
                                onChange={(e) => this.props.handleDiscountChange(e.target.value)}
                                size="medium"
                                InputLabelProps={{shrink: true}}
                                error={!this.props.discountPercentage || this.props.discountPercentage == ""}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            %
                                        </InputAdornment>
                                    )
                                }}
                            />


                            <TextField
                                style={{marginLeft: "8px"}}
                                variant="outlined"
                                name="poNumber"
                                error={!this.props.poNumber || this.props.poNumber === ""}
                                value={this.props.poNumber}
                                onChange={e => this.props.handlePOChange(e.target.value)}
                                label="PO Number"
                                InputLabelProps={{shrink: true}}
                                required
                            />
                            <div className="pick-date">
                                <KeyboardDatePicker
                                    required
                                    autoOk
                                    style={{width: "200px"}}
                                    variant="inline"
                                    inputVariant="outlined"
                                    label="PO Date"
                                    format="MM/dd/yyyy"
                                    value={this.props.createDate}
                                    InputAdornmentProps={{position: "start"}}
                                    onChange={date => this.props.handleCreateDateChange(date)}
                                />
                            </div>

                            <div className="pick-date">
                                <KeyboardDatePicker
                                    autoOk
                                    error={this.InvalidDate()}
                                    helperText={this.InvalidDate() ? "Invalid Expected Ship Date" : ""}
                                    variant="inline"
                                    inputVariant="outlined"
                                    label="Expected Ship Date"
                                    format="MM/dd/yyyy"
                                    value={this.props.expectedDate}
                                    required
                                    InputAdornmentProps={{position: "start"}}
                                    onChange={date => this.props.handleDateChange(date)}
                                />
                            </div>
                            <div className="notes">
                                <Tooltip title={NOTES}>
                                    <IconButton
                                        aria-label='delete'
                                        onClick={this.props.showNotes}
                                    >
                                        <SpeakerNotesIcon/>
                                    </IconButton>
                                </Tooltip>
                            </div>


                        </div>

                        <div style={{
                            marginLeft: "16px",
                            marginTop: "16px",
                            marginBottom: "16px",
                            display: "flex",
                            alignItems: "center"
                        }}>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.props.isHistorical}
                                        onChange={(event, value) => {
                                            this.props.handleHistoricalChange(event.target.checked)
                                        }
                                    }
                                    />
                                }
                                label="Historical"
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.props.isSameAsBillingAddress}
                                        onChange={(event, value) => {
                                             this.props.handleShipByChange(event.target.checked)
                                        }

                                        }
                                    />
                                }
                                label="Same as billing address"
                            />
                        </div>

                        {!this.props.isSameAsBillingAddress && <div style={{
                            marginBottom: "16px",
                        }}>
                            <div style={{fontSize: "16px", fontWeight: "bold",marginLeft:"12px", marginBottom:"16px"}}>
                                Ship To
                                <br/>
                            </div>
                            <div className={styles.rowSpec}>
                                <TextField
                                    className={styles.formTwoInput}
                                    variant="outlined"
                                    name="name"
                                    onChange={e => this.props.handleShipToAddressChange(e.target.name, e.target.value)}
                                    value={this.props.shipToAddress?.name || ''}
                                    label="Name"
                                    InputLabelProps={{shrink: true}}
                                />
                                <TextField
                                    className={styles.formTwoInput}
                                    variant="outlined"
                                    name="phone"
                                    onChange={e => this.props.handleShipToAddressChange(e.target.name, e.target.value)}
                                    value={this.props.shipToAddress?.phone || ''}
                                    label="Phone"
                                    InputLabelProps={{shrink: true}}
                                />
                                <Autocomplete
                                    options={COUNTRY_OPTIONS.map(n => {
                                        return n.name
                                    })}
                                    getOptionLabel={(option) => option}
                                    className={styles.formThreeInput}
                                    name="country"
                                    value={this.props.shipToAddress?.country || ''}
                                    onChange={(event, value) => {
                                        this.props.handleShipToAddressChange("country", value)
                                    }}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label="Country"
                                            InputLabelProps={{shrink: true}}
                                            variant="outlined"
                                            name="country"
                                        />
                                    }
                                />
                            </div>

                            <div className={styles.rowSpec}>
                                <TextField
                                    className={styles.formInputsingle}
                                    variant="outlined"
                                    name="addressLine1"
                                    onChange={e => this.props.handleShipToAddressChange(e.target.name, e.target.value)}
                                    value={this.props.shipToAddress?.addressLine1 || ''}
                                    label="Address Line 1"
                                    InputLabelProps={{shrink: true}}
                                />
                            </div>
                            <div className={styles.rowSpec}>
                                <TextField
                                    className={styles.formInputsingle}
                                    variant="outlined"
                                    name="addressLine2"
                                    onChange={e => this.props.handleShipToAddressChange(e.target.name, e.target.value)}
                                    value={this.props.shipToAddress?.addressLine2 || ''}
                                    label="Address Line 2"
                                    InputLabelProps={{shrink: true}}
                                />
                            </div>
                            <div className={styles.rowSpec}>
                                <TextField
                                    className={styles.formThreeInput}
                                    variant="outlined"
                                    name="city"
                                    onChange={e => this.props.handleShipToAddressChange(e.target.name, e.target.value)}
                                    value={this.props.shipToAddress?.city || ''}
                                    label="City"
                                    InputLabelProps={{shrink: true}}
                                />
                                <TextField
                                    className={styles.formThreeInput}
                                    variant="outlined"
                                    name="state"
                                    onChange={e => this.props.handleShipToAddressChange(e.target.name, e.target.value)}
                                    value={this.props.shipToAddress?.state || ''}
                                    label="State or Province"
                                    InputLabelProps={{shrink: true}}
                                />
                                <TextField
                                    className={styles.formThreeInput}
                                    variant="outlined"
                                    name="postalCode"
                                    onChange={e => this.props.handleShipToAddressChange(e.target.name, e.target.value)}
                                    value={this.props.shipToAddress?.postalCode || ''}
                                    label="Zip or Postal Code"
                                    InputLabelProps={{shrink: true}}
                                />
                            </div>
                            {/*<div className={styles.rowSpec}>*/}

                            {/*</div>*/}

                        </div>}


                        <Table
                            aria-labelledby="tableTitle"
                            size='small'
                            aria-label={this.props.title}
                        >
                            <TableHead>
                            <TableRow className="table">
                                    <TableCell className="add-delete-col"/>
                                {this.props.hasPricingRole ? CREATE_PO_CELLS_PRICING_ROLE.map((headCell) => (
                                    <TableCell key={headCell.id} className={headCell.id}
                                               style={{textAlign: "center"}}>
                                        {headCell.label}
                                    </TableCell>
                                )) : CREATE_PURCHASE_ORDERS_CELLS.map((headCell) => (
                                    <TableCell key={headCell.id} className={headCell.id}
                                               style={{textAlign: "center"}}>
                                        {headCell.label}
                                    </TableCell>
                                ))
                                }
                                </TableRow>
                            </TableHead>
                            <TableBody key={this.props.key}>
                                {/*Initial table row that will show up by default*/}
                                {this.props.entries?.map((row, index) => {
                                    return (
                                        <TableRow>
                                            {row.deleteEntry ? <TableCell>
                                                <>
                                                    <IconButton onClick={() => this.props.removeEntry(index)}>
                                                        <RemoveCircleIcon
                                                            fontSize='small'
                                                            color='error'
                                                        />
                                                    </IconButton>
                                                </>
                                            </TableCell> : <TableCell></TableCell>}

                                            <TableCell style={{textAlign: "center"}}>
                                                {/*Dropdown to select the shortcode which auto populates everything*/}
                                                <Autocomplete
                                                    options={this.props.availableProducts ? this.props.availableProducts.sort((a, b) => {
                                                        return a.toLowerCase().localeCompare(b.toLowerCase())
                                                    }) : []}
                                                    getOptionLabel={(entry) => entry}
                                                    // style={{ width: 215 }}
                                                    className="shortcode-field"
                                                    autoHighlight={true}
                                                    blurOnSelect={false}
                                                    value={row.shortcode}
                                                    onChange={(event, value) => this.props.onShortcodeChange(value, index)}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            autoFocus={!this.state.pageInit}
                                                            name="shortcode"
                                                            InputLabelProps={{shrink: true}}
                                                        />
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell style={{textAlign: "center"}} className="center partnum-cell">
                                                {row.partNum}
                                            </TableCell>
                                            <TableCell style={{textAlign: "left"}}>
                                                {row.description}
                                            </TableCell>
                                            {this.props.hasPricingRole ?
                                                <TableCell style={{textAlign: "center"}} className="pricing-cell">
                                                    <TextField
                                                        error={(isNaN(Number(row.price)) || row.price === "") && row.shortcode !== ""}
                                                        variant="outlined"
                                                        name="price"
                                                        value={row.price}
                                                        onChange={(e) => this.props.onChange(e, index)}
                                                        size="small"
                                                        inputProps={{style: {textAlign: "right"}}}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    $
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        onBlur={(e) => this.props.formatPrice(e.target.value, index)}
                                                    />
                                                </TableCell> : null}
                                            <TableCell style={{textAlign: "center"}} className="discount-cell">
                                                <TextField
                                                    error={(isNaN(Number(row.discount)) || row.discount === "") && row.discount !== ""}
                                                    variant="outlined"
                                                    name="discount"
                                                    value={row.discount}
                                                    onChange={(e) => this.props.onChange(e, index)}
                                                    size="small"
                                                    error={!row.discount || row.discount == ""}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                %
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell style={{textAlign: "center"}} className="quantity-cell">
                                                <TextField
                                                    type="number"
                                                    variant="outlined"
                                                    name="skid"
                                                    value={row.skid}
                                                    onChange={(e) => this.props.onChange(e, index)}
                                                    size="small"
                                                />
                                            </TableCell>
                                            <TableCell style={{textAlign: "center"}} className="quantity-cell">
                                                <TextField
                                                    error={row.quantity === ""}
                                                    type="number"
                                                    variant="outlined"
                                                    name="quantity"
                                                    value={row.quantity}
                                                    onChange={(e) => this.props.onChange(e, index)}
                                                    size="small"
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                                <TableRow>
                                    <TableCell className='check'>
                                        <Tooltip placement="left" title={ADD_PO_ENTRY}>
                                            <IconButton onClick={this.props.addEntry}>
                                                <AddCircleIcon
                                                    fontSize="small"
                                                    color='secondary'
                                                />

                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/*
                    <br/>

                    <div className='cancel-preview-buttons'>
                        <Button
                            className="cancel-button"
                            onClick={this.props.handleCancel}
                            variant="contained"
                        >
                            {CANCEL}
                        </Button>
                        <Button
                            onClick={this.handlePreview}
                            variant="contained"
                            color='primary'
                            className="preview-button"
                        >
                            {PREVIEW}
                        </Button>
                    </div>

                    <br/>*/}
                </Paper>
            </>
        );
    }
};

CreateCustomerPurchaseOrderTable.propTypes = {
    customerInfo: PropTypes.array,
    selectedCustomer: PropTypes.string,
    availableProducts: PropTypes.array,
    availableCustomerNames: PropTypes.array,
    pdfData: PropTypes.array,
    openPreview: PropTypes.bool,
    entries: PropTypes.array,
    numRows: PropTypes.number,
    addEntry: PropTypes.func,
    removeEntry: PropTypes.func,
    onShortcodeChange: PropTypes.func,
    onCustomerChange: PropTypes.func,
    onNotesChange: PropTypes.func,
    onChange: PropTypes.func,
    tableKey: PropTypes.number,
    handleDateChange: PropTypes.func,
    handleShipByChange: PropTypes.func,
    handleHistoricalChange: PropTypes.func,
    handleCreateDateChange: PropTypes.func,
    handlePOChange: PropTypes.func,
    handleShipToAddressChange: PropTypes.func,
    createDate: PropTypes.number,
    expectedDate: PropTypes.number,
    showNotes: PropTypes.func,
    previewPDF: PropTypes.func,
    previewClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleSendSubmit: PropTypes.func,
    handleCancel: PropTypes.func
}

CreateCustomerPurchaseOrderTable.defaultProps = {
    customerInfo: [],
    selectedCustomer: '',
    availableProducts: [],
    availableCustomerNames: [],
    entries: [],
    numRows: 0,
    addEntry: () => {
    },
    removeEntry: () => {
    },
    onShortcodeChange: () => {
    },
    onCustomerChange: () => {
    },
    onHistoricalChange: () =>{

    },
    onNotesChange: () => {
    },
    onChange: () => {
    },
    tableKey: 0,
    handleDateChange: () => {
    },
    createDate: 0,
    expectedDate: 0,
    showNotes: () => {
    },
    previewPDF: () => {
    },
    handleCancel: () => {
    }
}

export default CreateCustomerPurchaseOrderTable;