export const PRICING_DASHBOARD_PAGES = {
    TABLE: "table",
    LOADING: "loading",
    DETAIL: "detail"
}
export const PRICING_DASHBOARD_PAGE_NAME = "Pricing Dashboard"
export const REFRESH_PRICING = "Refresh Pricing"
export const PRICING_DASHBOARD_TABLE_CELLS = [
    { id: 'shortcode',label: 'Shortcode'},
    { id: 'sku',label: 'SKU'},
    { id: 'quantity',label: 'Quantity'},
    { id: 'asin',label: 'ASIN'},
    { id: 'marketplaces',label: 'Marketplace'},
    { id: 'price',label: 'Price'},
    { id: 'minimumPrice',label: 'Minimum'},
    { id: 'maximumPrice',label: 'Maximum'},
    { id: 'repricing',label: 'Repricing'},
    { id: 'details',label: ''},
];
export const SKU_FILTER_NAMES = {
    BUY_BOX_WINNER: "notBoyBoxWinner"
}
export const DRAG = "Drag";
export const DELETE_RELATED_PRODUCT = "Delete Related Product"
export const PRODUCT_ON_AMAZON = "Product on Amazon"
export const PRICING_DASHBOARD_TABLE_PAGINATION_LABEL = "Sku per page"
export const EDIT_ENTRY = "Edit";
export const CANCEL_EDIT_ENTRY = "Cancel";
export const APPLY_EDIT_ENTRY = "Apply";
export const LOADING_MESSAGE = "This may take a few minutes";
export const BACK_BUTTON = "Back"
export const Add_BUTTON = "Add related product"
export const Edit_BUTTON = "Edit related product"
export const DETAIL = "Detail"