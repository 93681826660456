import React from 'react'


// Material UI
import {
    Button, IconButton,
    Radio,
    Slide, TextField, Tooltip,
    withStyles,
} from "@material-ui/core";
import './PricingDashboardForm.css';
import {HTML5Backend} from "react-dnd-html5-backend";
import DragAndDrop from "../../global/Dnd/DragAndDrop";
import {DndProvider} from "react-dnd";
import EditRelatedProductCard from "./EditRelatedProductCard";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {PRODUCT_ON_AMAZON} from "../constants";

class EditRelatedProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            relatedProducts: this.props.relatedProducts? this.props.relatedProducts : [],
            notes:this.props.row.notes? this.props.row.notes : ''
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.relatedProducts !== this.props.relatedProducts || prevProps.row.notes !== this.props.row.notes) {
            this.setState({
                relatedProducts: this.props.relatedProducts,
                notes: this.props.row.notes
            });
        }
    }

    updateRelatedProducts = (newRelatedProducts) => {
        this.setState(
            {
                relatedProducts: newRelatedProducts,
            }
        );
    }

    updateRelatedProduct = (newRelatedProduct) => {
        const index = this.state.relatedProducts.findIndex(
            (relatedProduct) => relatedProduct.relatedProductId === newRelatedProduct.relatedProductId
        );
        let newrelatedProducts = [...this.state.relatedProducts]
        if(index !== -1){
            newrelatedProducts[index] = newRelatedProduct
        }
        this.setState(
            {
                relatedProducts: newrelatedProducts,
            }
        );
    }

    deleteRelatedProduct = (relatedProductId) => {
        let index = this.state.relatedProducts.findIndex(relatedProduct => relatedProduct.relatedProductId ===relatedProductId)
        if (index !== -1) {
            let newRelatedProducts = [...this.state.relatedProducts];
            newRelatedProducts.splice(index, 1);
            this.setState({ relatedProducts: newRelatedProducts });
        }
    }

    handleCancel = () => {
        this.setState({
            relatedProducts: this.props.relatedProducts,
            notes: this.props.row.notes
        });
    }

    saveChanges = () => {
        this.props.updateRelatedProducts(this.state.relatedProducts, this.state.notes, this.props.row.webstoreProductId)
    }

    isRelatedProductsSame = () => {
        const currentProducts = JSON.stringify(this.state.relatedProducts.map(obj => ({...obj})).sort((a, b) => a.id - b.id));
        const initialProducts = JSON.stringify(this.props.relatedProducts.map(obj => ({...obj})).sort((a, b) => a.id - b.id));
        return currentProducts === initialProducts;
    }

    isNotesSame = () => {
        if (!this.props.row.notes && this.state.notes == ''){
             return true
        }
        return this.state.notes === this.props.row.notes
    }

    handleOpenProductPage = () => {
        const baseURL = `https://www.${this.props.row.marketplaces[0]}/dp/${this.props.row.asin}`;
        window.open(baseURL, '_blank'); // Opens the link in a new tab
    }

    handleNotesChange = (event) => {
        this.setState({
            notes: event.target.value
        });
    };

    render() {
        return ( <>
            <div style={{overflow: "hidden"}}>
                <div className="pricingDashboardSidebarHolder" style={{width: this.props.showEditRelatedProducts ? "22%" : "0"}}>
                    <Slide in={this.props.showEditRelatedProducts} direction="left">
                        <div>
                            <div className="editRelatedProductTitle">{this.props.row.asin}
                                <Tooltip title={PRODUCT_ON_AMAZON}>
                                    <IconButton
                                        aria-label={PRODUCT_ON_AMAZON}
                                        onClick={this.handleOpenProductPage}
                                        style={{marginBottom: '6px'}}
                                    >
                                        <OpenInNewIcon variant='outlined' fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </div >
                            <div className="editRelatedProductSubTitle">{"Related products:"}</div >
                            <DndProvider backend={HTML5Backend}>
                                <DragAndDrop
                                    cards={this.state.relatedProducts}
                                    handleTableDisplayChange={this.updateRelatedProducts}
                                    items = {
                                        this.state.relatedProducts.map((relatedProduct, i) => {
                                                    return ({
                                                        id: relatedProduct.relatedProductId,
                                                        component: <div>
                                                            <EditRelatedProductCard
                                                                relatedProduct={relatedProduct}
                                                                deleteRelatedProduct={this.deleteRelatedProduct}
                                                                updateRelatedProductWithParent={this.updateRelatedProduct}
                                                                key={relatedProduct.relatedProductId}
                                                                row={this.props.row}
                                                            />
                                                        </div>
                                                    });
                                        })
                                    }
                                />
                            </DndProvider>
                            <div style={{display: "flex", justifyContent: "left", marginLeft:'10px', marginRight:'10px' }}>
                                <div className="notesContainer" >
                                        <TextField
                                            variant="outlined"
                                            name="notes"
                                            label="Notes"
                                            InputLabelProps={{ shrink: true }}
                                            value={this.state.notes || ""}
                                            onChange={(e) => this.handleNotesChange(e)}
                                            style={{ width: "inherit" }}
                                        />
                                </div>
                            </div>
                            <div className="bottom-wrapper" style={{alignItems: 'center'}}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className="submit-button"
                                    onClick={this.saveChanges}
                                    disabled={this.isRelatedProductsSame() && this.isNotesSame()}

                                >
                                    APPLY
                                </Button>
                                <div style={{width: '30px'}}></div>
                                <Button
                                    variant="contained"
                                    className="submit-button"
                                    onClick={this.handleCancel}
                                    disabled={this.isRelatedProductsSame() && this.isNotesSame()}
                                >
                                    CANCEL
                                </Button>
                            </div>
                        </div>
                    </Slide>
                </div>
            </div>
        </>)
    }
}

export default EditRelatedProducts