export const CREATING_TEMPLATE  = "creating"
export const EDITING_TEMPLATE   = "editing"
export const VIEWING_TABLE      = "viewing"

export const CARRIER_INVOICE_TEMPLATE_FIELDS = {
    marker:         { label: "Marker",          required: true, visible: true, position: { x: 0, y: 0, w: 0, h: 0 } },
    invoiceDate:    { label: "Invoice Date",    required: true, visible: true, position: { x: 0, y: 0, w: 0, h: 0 } },
    invoiceNumber:  { label: "Invoice Number",  required: true, visible: true, position: { x: 0, y: 0, w: 0, h: 0 } },
    invoiceAmount:  { label: "Invoice Total",  required: true, visible: true, position: { x: 0, y: 0, w: 0, h: 0 } },
    invoiceTax:     { label: "Invoice Tax",     required: false, visible: true, position: { x: 0, y: 0, w: 0, h: 0 } },
    shipmentDate:   { label: "Shipment Date",   required: true, visible: true, position: { x: 0, y: 0, w: 0, h: 0 } },
    receiveDate:    { label: "Receive Date",    required: true, visible: true, position: { x: 0, y: 0, w: 0, h: 0 } },
}

export const FTL_CARRIER_INVOICE_TABLE_HEAD_CELLS = [
    { id: 'carrierName',    center: false, disablePadding: false, addDollar: false, rightAlign: false, label: 'Carrier Name'     },
    { id: 'invoiceDate',    center: false, disablePadding: false, addDollar: false, rightAlign: false, label: 'Invoice Date'     },
    { id: 'invoiceNumber',  center: false, disablePadding: false, addDollar: false, rightAlign: false, label: 'Invoice Number'   },
    { id: 'accountNumber',  center: false, disablePadding: false, addDollar: false, rightAlign: false, label: 'Account Number'   },
    { id: 'status',         center: true,  disablePadding: false, addDollar: false, rightAlign: false, label: 'Status'           },
    { id: 'invoiceTax',     center: true,  disablePadding: false, addDollar: false, rightAlign: true,  label: 'Tax'              },
    { id: 'invoiceAmount',  center: true,  disablePadding: false, addDollar: false, rightAlign: true,  label: 'Total'           },
]