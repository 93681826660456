import React from "react";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import {
    PRICING_DASHBOARD_PAGES,
    BACK_BUTTON, Add_BUTTON, Edit_BUTTON,
} from "../constants";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import './PricingDashboardForm.css';
import withShipment from "../../../withShipment";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import {
    addRelatedProduct,
    fetchPricingListings,
    getBuyBoxPrices,
    getSalesRank,
    editRelatedProduct, getRelatedProducts, updateRelatedProducts,
} from "../../../redux/actions/settings";
import {
    buyBoxPricesSelector,
    mainSalesRankSelector,
    subSalesRankSelector,
    relatedProductsMainSalesRankSelector, relatedProductsSelector, relatedProductBuyBoxPricesSelector,
} from "../../../redux/selectors/settings";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import AddRelatedProductPopper from "./AddRelatedProductPopper";
import EditRelatedProductPopper from "./EditRelatedProductPopper";
import { IconButton, Tooltip, Slide} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import EditRelatedProducts from "./EditRelatedProducts";


class PricingDashboardForm extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            showSubCategoryChart: false,
            responseMessage: "",
            feedbackBarOpen: false,
            showEditRelatedProducts: false,
            mainSalesRank:[],
            subSalesRank: [],
            relatedProductsMainSalesRank:[],
            buyBoxPrices: [],
            relatedProductBuyBoxPrices: [],
        };
    }

    componentDidMount() {
        const currentDate = new Date();
        const thirtyDaysBefore = new Date(currentDate);
        thirtyDaysBefore.setDate(currentDate.getDate() - 30);
        // Convert both dates to ISO strings in UTC ("YYYY-MM-DDTHH:MM:SSZ")
        const startDate = thirtyDaysBefore.toISOString().replace(/\.\d+Z$/, 'Z'); // Replaces '.sssZ' with 'Z'
        const endDate = currentDate.toISOString().replace(/\.\d+Z$/, 'Z'); // Replaces '.sssZ' with 'Z'
        this.props.getSalesRank(this.props.row.webstoreProductId, startDate, endDate);
        this.props.getBuyBoxPrices(this.props.row.webstoreProductId, startDate, endDate);
        this.props.getRelatedProducts(this.props.row.webstoreProductId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mainSalesRank !== this.props.mainSalesRank ||
            prevProps.subSalesRank !== this.props.subSalesRank ||
            prevProps.relatedProductsMainSalesRank !== this.props.relatedProductsMainSalesRank ||
            prevProps.buyBoxPrices !== this.props.buyBoxPrices ||
            prevProps.relatedProductBuyBoxPrices !== this.props.relatedProductBuyBoxPrices) {

            this.setState({
                mainSalesRank: this.props.mainSalesRank,
                subSalesRank: this.props.subSalesRank,
                relatedProductsMainSalesRank: this.props.relatedProductsMainSalesRank,
                buyBoxPrices: this.props.buyBoxPrices,
                relatedProductBuyBoxPrices: this.props.relatedProductBuyBoxPrices
            });
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

    }

    toggleSubCategoryChart = () => {
        this.setState(prevState => ({
            showSubCategoryChart: !prevState.showSubCategoryChart
        }));
    }

    handleAddRelatedProduct= (relatedProduct) => {
        this.props.addRelatedProduct(relatedProduct, this.props.row.webstoreProductId)
    }

    handleEditRelatedProduct= (relatedProduct) => {
        this.props.editRelatedProduct(relatedProduct, this.props.row.webstoreProductId)
    }

    toggleEditRelatedProductsShow = () => {
        this.setState({ showEditRelatedProducts: !this.state.showEditRelatedProducts })
    }

    getPageName = () => {
        let rightHeaderButtons = [{ icon: <MenuIcon />, handler: this.toggleEditRelatedProductsShow, disabled: false, title: "Edit Related Products" }]
        return (
            <div>
                {this.props.row.shortcode? `(${this.props.row.shortcode})` + (this.props.row.quantity ? `-${this.props.row.quantity}` : '') +(this.props.row.marketplaces ? ` ${this.props.row.marketplaces[0]}` : '') : ""}
                {rightHeaderButtons && rightHeaderButtons.map((button, index) => (
                    <Tooltip title={button.title} key={button.title + index}>
                        <IconButton aria-label={button.title} onClick={button.handler} disabled={button.disabled}>
                            {button.icon}
                        </IconButton>
                    </Tooltip>
                ))}
            </div>
        );
    }
    getMainChartOptions = () => {

        let buyBoxPricesData = {
            name: "Buy Box Price",
            data: this.state.buyBoxPrices.map(item => [
                item.timeStamp, parseFloat(item.buyBoxPriceAmount)
            ]),
            yAxis: 0,
            marker: {
                enabled: null,
                radius: 3,
                lineWidth: 1,
            },
            events: {
                legendItemClick: () => {
                }
            },
            tooltip: {
                valueDecimals: 2,
                valuePrefix: '$'
            }
        }

        let mainSalesRankData = this.state.mainSalesRank.map(categoryObj => {
            // Each categoryObj is an object where key is the category name and value is the rank array
            const categoryName = Object.keys(categoryObj)[0]; // Get the category name
            const ranks = categoryObj[categoryName]; // Get the array of rank objects
            const lastRank = ranks.length > 0 ? ranks[ranks.length - 1].rank : '';

            return {
                name: `Sales Rank - ${categoryName}</br>Last Rank: ${lastRank}`,
                data: ranks.map(({ timeStamp, rank }) => [timeStamp, parseInt(rank, 10)]),
                yAxis: 1,
                marker: {
                    enabled: null,
                    radius: 3,
                    lineWidth: 1,
                },
                events: {
                    legendItemClick: () => {
                    }
                },
                tooltip: {
                    valueDecimals: 0
                }
            };
        });

        let subcategoryData = {
            name:`Subcategory<br>&nbsp;<br>`, //  dummy series for showing the sub category chart
            data: [],
            marker: {
                enabled: null,
                radius: 3,
                lineWidth: 1,
            },
            events: {
                legendItemClick: () => {
                    this.toggleSubCategoryChart(); // Toggle visibility of the second chart
                return false
                }
            }
        }

        let relatedProductsData = [];

        //look up buy box prices using sales rank
        let section1 = this.state.relatedProductsMainSalesRank?.forEach((categoryObj, index, array) => {
            const relatedProductAsin = categoryObj.relatedProductAsin;
            const relatedProductPName = categoryObj.relatedProductName;
            const relatedProductMainRank = categoryObj.salesRank[0];
            const categoryName = Object.keys(relatedProductMainRank)[0]; // Get the category name
            const ranks = relatedProductMainRank[categoryName];
            const lastRank = ranks && ranks.length > 0 ? `</br>Last Rank: ${ranks[ranks.length - 1].rank}` : '';

            // Find corresponding BuyBox data
            const buyBoxObj = this.state.relatedProductBuyBoxPrices?.find(obj => obj.relatedProductAsin === relatedProductAsin);

            const salesRankName = relatedProductPName !== ''
                ? `Sales Rank - ${categoryName}</br>${relatedProductPName} (${relatedProductAsin})${lastRank}${!buyBoxObj ? '<br>&nbsp;<br>' : ''}`
                : `Sales Rank - ${categoryName}</br>(${relatedProductAsin})${lastRank}${!buyBoxObj ? '<br>&nbsp;<br>' : ''}`;

            // Sales Rank data
            relatedProductsData.push({
                name: salesRankName,
                data: ranks.map(({ timeStamp, rank }) => [timeStamp, parseInt(rank, 10)]),
                yAxis: 1,
                marker: {
                    enabled: null,
                    radius: 3,
                    lineWidth: 1,
                },
                events: {
                    legendItemClick: () => {
                    }
                },
                tooltip: {
                    valueDecimals: 0,
                    valuePrefix: ''
                }
            });

            if (buyBoxObj) {
                const buyBoxName = relatedProductPName !== ''
                    ? `Buy Box Price</br>${relatedProductPName} (${relatedProductAsin})<br>&nbsp;<br>`
                    : `Buy Box Price</br>(${relatedProductAsin})<br>&nbsp;<br>`;

                relatedProductsData.push({
                    name: buyBoxName,
                    data: buyBoxObj.buyBox.map(item => [
                        item.timeStamp, parseFloat(item.buyBoxPriceAmount)
                    ]),
                    yAxis: 0,
                    marker: {
                        enabled: null,
                        radius: 3,
                        lineWidth: 1,
                    },
                    events: {
                        legendItemClick: () => {}
                    },
                    tooltip: {
                        valueDecimals: 2,
                        valuePrefix: '$'
                    },
                });
            }
        });

        // Add any remaining BuyBox data that wasn't matched to SalesRank
        let section2= this.state.relatedProductBuyBoxPrices?.forEach(buyBoxObj => {
            const relatedProductAsin = buyBoxObj.relatedProductAsin;
            const relatedProductPName = buyBoxObj.relatedProductName;

            // Check if this ASIN has already been processed (i.e., has SalesRank data)
            const alreadyProcessed = this.state.relatedProductsMainSalesRank?.some(
                obj => obj.relatedProductAsin === relatedProductAsin
            );

            if (!alreadyProcessed) {
                const buyBoxName = relatedProductPName !== ''
                    ? `Buy Box Price</br>${relatedProductPName} (${relatedProductAsin})<br>&nbsp;<br>`
                    : `Buy Box Price</br>(${relatedProductAsin})<br>&nbsp;<br>`;

                relatedProductsData.push({
                    name: buyBoxName,
                    data: buyBoxObj.buyBox.map(item => [
                        item.timeStamp, parseFloat(item.buyBoxPriceAmount)
                    ]),
                    yAxis: 0,
                    marker: {
                        enabled: null,
                        radius: 3,
                        lineWidth: 1,
                    },
                    events: {
                        legendItemClick: () => {}
                    },
                    tooltip: {
                        valueDecimals: 2,
                        valuePrefix: '$'
                    },
                });
            }
        });

        let seriesData = [...mainSalesRankData, buyBoxPricesData, subcategoryData, ...relatedProductsData]

        let mainChartOptions = {
            chart: {
                type: 'line',
                width: 1200,
                height: 680
            },

            title: {
                text: '',
            },

            xAxis: {
                type: "datetime"
            },
            yAxis:[
                {
                    title: {
                        text: 'Buy Box Prices'
                    },
                    labels: {
                        format: '${value}',
                    }
                },
                {
                    title: {
                        text: 'Sales Rank'
                    },
                    opposite: true,
                    reversed: true,
                }

            ],

            series:
            seriesData,
            legend: {
                title: {
                    text: this.props.row.shortcode? this.props.row.shortcode + (this.props.row.quantity ? `-${this.props.row.quantity}` : '' ) + ` (${this.props.row.asin})`+ '<br/>' : "",
                    style: {
                        fontSize: '1.2em'
                    }
                },
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                itemMarginBottom: 5,
                itemStyle: {fontSize : "1.05.em"},
            },
            time: {
                useUTC: false
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    pointStart: 2010
                }
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }
        };

        return mainChartOptions;
    }

    getSubCategoryChartOptions = () => {
        let subSalesRankData = this.state.subSalesRank.map(categoryObj => {
            const categoryName = Object.keys(categoryObj)[0];
            const ranks = categoryObj[categoryName];

            return {
                name: categoryName,
                data: ranks.map(({ timeStamp, rank }) => [timeStamp, parseInt(rank, 10)]),
                marker: {
                    enabled: null,
                    radius: 3,
                    lineWidth: 1,
                },
                tooltip: {
                    valueDecimals: 0
                }
            };
        });

        let subChartOptions = {
            chart: {
                type: 'line',
                width: 1260,
                height: 680
            },
            title: {
                text: ''
            },
            xAxis: {
                type: 'datetime'
            },
            yAxis: {
                title: {
                    text: 'Sales Rank'
                },
                reversed: true,
            },
            series: subSalesRankData,
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                itemMarginBottom: 5,
                itemStyle: {fontSize: "1.05.em"},
                title: {
                    text: this.props.row.shortcode ? `${this.props.row.shortcode}${this.props.row.quantity ? `-${this.props.row.quantity}` : ''}<br/>` : '',
                    style: {
                        fontSize: '1.2em'
                    }
                },
            },
            time: {
                useUTC: false
            }
        };

        return subChartOptions;
    }

    render() {
        const { showSubCategoryChart } = this.state;
        return (
            <React.Fragment>
                <TopToolbar
                    pageName={this.getPageName()}
                    menuItems={[
                        {title: BACK_BUTTON, icon: <KeyboardBackspaceIcon/>, onClick: () => {this.props.setCurrentPage(PRICING_DASHBOARD_PAGES.TABLE)}},
                        {title: Add_BUTTON, icon: <AddCircleIcon/>,
                            popper: <AddRelatedProductPopper
                                handleAddRelatedProduct={(relatedProduct) => this.handleAddRelatedProduct(relatedProduct)}
                            />},
                        /*{title: Edit_BUTTON, icon: <EditIcon/>,
                            popper: <EditRelatedProductPopper
                                row= {this.props.row}
                                handleEditRelatedProduct={(relatedProduct) => this.handleEditRelatedProduct(relatedProduct)}
                            />},*/

                    ]}
                />
                <div className="mainChart">
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={this.getMainChartOptions()}
                    />
                </div>
                <div className="subChart">
                    {showSubCategoryChart?
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={this.getSubCategoryChartOptions()}
                        />
                        :null}
                </div>
                    {this.state.showEditRelatedProducts && (
                        <EditRelatedProducts
                            showEditRelatedProducts={this.state.showEditRelatedProducts}
                            relatedProducts={this.props.relatedProducts}
                            updateRelatedProducts={this.props.updateRelatedProducts}
                            row={this.props.row}
                        />
                    )}
            </React.Fragment>
        )
    }
}

PricingDashboardForm.defaultProps = {
    mainSalesRank:[],
    subSalesRank:[],
    relatedProductsMainSalesRank:[],
    buyBoxPrices:[],
    relatedProducts:[],
    relatedProductBuyBoxPrices:[]

}
const mapStateToProps = (state) => ({
    mainSalesRank: mainSalesRankSelector(state),
    subSalesRank: subSalesRankSelector(state),
    relatedProductsMainSalesRank: relatedProductsMainSalesRankSelector(state),
    buyBoxPrices: buyBoxPricesSelector(state),
    relatedProducts: relatedProductsSelector(state),
    relatedProductBuyBoxPrices: relatedProductBuyBoxPricesSelector(state),
})

const actionCreators = {
    fetchPricingListings,
    getSalesRank,
    getBuyBoxPrices,
    getRelatedProducts,
    addRelatedProduct,
    updateRelatedProducts,
    editRelatedProduct
}

export default withShipment({
    actionCreators,
    mapStateToProps
}, PricingDashboardForm);