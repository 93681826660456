import {
    STORE_ADDRESSES,
    STORE_CARRIERS,
    STORE_SHIPPING_TEMPLATES,
    STORE_INVENTORY_AUTO_ALLOCATION,
    LIST_ADDRESSES,
    LIST_CARRIERS,
    LIST_SHIPPING_TEMPLATES,
    LIST_LINKED_ADDRESSES,
    CREATE_CARRIER,
    UPDATE_CARRIERS,
    SET_DEFAULT_CARRIER,
    ADDRESS_VALIDATION_CHANGE,
    UPDATE_ADDRESSES,
    STORE_LINKED_ADDRESSES,
    EDIT_CARRIER,
    UPDATE_CARRIERS_EDIT,
    DELETE_CARRIER,
    UPDATE_CARRIERS_DELETE,
    CREATE_PRODUCT,
    UPDATE_PRODUCT,
    STORE_PRODUCTS,
    LIST_PRODUCTS,
    STORE_UPDATED_PRODUCT,
    CREATE_SUPPLIER,
    LIST_SUPPLIERS,
    UPDATE_SUPPLIER,
    LIST_PRINTERS,
    UPDATE_PRINTERS,
    REFRESH_PRINTERS,
    STORE_PRINTERS,
    UPLOAD_LOGO,
    EDIT_TAGS,
    LIST_TAGS,
    STORE_TAGS,
    GET_UUID,
    GET_UUIDS,
    STORE_SUPPLIERS,
    LIST_LOCATIONS,
    LIST_AVAILABLE_LOCATIONS,
    STORE_AVAILABLE_LOCATIONS,
    CREATE_LOCATION,
    UPDATE_LOCATION,
    STORE_LOCATIONS,
    LIST_COMPANIES,
    STORE_COMPANIES,
    FETCH_LOGO,
    STORE_LOGO,
    DELETE_LOGO,
    CREATE_PACKAGE,
    UPDATE_PACKAGE,
    STORE_PACKAGES,
    LIST_PACKAGES,
    STORE_UPDATED_PACKAGE,
    DELETE_PRODUCT,
    FETCH_LISTINGS,
    SET_SKU_LOCATION,
    FETCH_PRICING_LISTINGS,
    Add_RELATED_PRODUCT,
    EDIT_RELATED_PRODUCT,
    UPDATE_RELATED_PRODUCTS,
    GET_SALES_RANK,
    GET_BUY_BOX_PRICES,
    GET_RELATED_PRODUCTS,
    FETCH_AMAZON_LISTINGS_PRICE,
    STORE_LISTINGS,
    STORE_PRICING_LISTINGS,
    STORE_SALES_RANK,
    STORE_BUY_BOX_PRICES,
    STORE_RELATED_PRODUCTS,
    UPDATE_BARCODES,
    STORE_UPDATED_LISTING,
    STORE_UPDATED_PRICING_LISTING,
    UPDATE_LISTING,
    UPDATE_PRICING_LISTING,
    UPDATE_REPRICING,
    GET_UNMAPPED_PRODUCTS,
    STORE_UNMAPPED_PRODUCTS,
    FETCH_AMAZON_SKUS,
    DELETE_BARCODE,
    SAVE_PRODUCT_CATEGORY_CHANGES,
    GET_ALL_CATEGORY,
    REMOVE_PRODUCT_CATEGORY,
    STORE_ALL_CATEGORY,
    STORE_ALL_HS_CODE,
    AUTOMATION_SETTINGS,
    CREATE_MARKETPLACE,
    LIST_MARKETPLACES,
    UPDATE_INVENTORY_ALLOCATION,
    EDIT_MARKETPLACE,
    DELETE_MARKETPLACE,
    UPDATE_MARKETPLACES,
    STORE_MARKETPLACES,
    UPDATE_MARKETPLACES_EDIT,
    UPDATE_MARKETPLACES_DELETE,
    VALIDATE_MARKETPLACE,
    SCRUB_DATA,
    LIST_UNIQUE_CARRIERS,
    LIST_EMAIL_TEMPLATES,
    STORE_EMAIL_TEMPLATES,
    EMAIL_TEMPLATE_UPDATE,
    CREATE_NEW_EMAIL_TEMPLATE,
    DELETE_NEW_EMAIL_TEMPLATE,
    GET_EMAIL_TEMPLATE_TYPES,
    STORE_EMAIL_TEMPLATE_TYPES,
    PO_EMAIL_SEND,
    GET_COUNTRY_CODES,
    STORE_COUNTRY_CODES,
    GET_AMAZON_FEEDS,
    STORE_AMZ_FEEDS,
    RESTART_FEED_FETCH,
    EMAIL_TEMPLATE_TYPE_UPDATE,
    CREATE_NEW_EMAIL_TEMPLATE_TYPE,
    DELETE_NEW_EMAIL_TEMPLATE_TYPE,
    FETCH_SENDGRID_TEMPLATES,
    FETCH_PURCHASE_ORDER_EMAIL_TEMPLATE,
    SEND_EMAIL_PURCHASE_ORDER,
    STORE_PURCHASE_ORDER_EMAIL_TEMPLATE,
    PROCESS_SUPPLIER_INVOICES,
    ARCHIVE_LABELS,
    STORE_ARCHIVED_LABELS,
    STORE_ARCHIVED_LABELS_RETURNED,
    CHANGE_PRIMARY,
    ADD_NEW_CHILD,
    DELETE_CHILD,
    GET_PRINT_CONFIG,
    STORE_PRINT_CONFIG,
    GET_INACTIVE_PRODUCTS,
    STORE_INACTIVE_PRODUCTS,
    GET_DELETED_PRODUCTS,
    STORE_DELETED_PRODUCTS,
    DELETE_LISTING,
    GET_ACTIVE_PRODUCTS,
    STORE_ACTIVE_PRODUCTS,
    FETCH_BARCODES,
    FETCH_PRODUCTS,
    STORE_BARCODES,
    STORE_UPDATED_BARCODE,
    STORE_REMOVED_BARCODE,
    STORE_DELETE_LISTING,
    STORE_CARRIER_NAMES,
    LIST_CARRIER_NAMES,
    LIST_ALL_BANK_ACCOUNTS,
    STORE_ALL_BANK_ACCOUNTS,
    SAVE_BANK_ACCOUNT,
    DELETE_BANK_ACCOUNT,
    IMPORT_HISTORICAL_DATA,
    STORE_HISTORICAL_DATA,
    CLEAR_HISTORICAL_DATA,
    STORE_INVENTORY_ALLOCATION,
    LIST_SHEETS,
    STORE_SHIPRUSH_FILE_NAMES,
    CLEAR_SHIPRUSH_SPREADSHEETS_INFO,
    PROCESS_CARRIER_INVOICE,
    STORE_RESPONSE_MESSAGE,
    STORE_CARRIER_OPERATION_RESPONSE,
    CLEAR_CARRIER_OPERATION_RESPONSE,
    MANUAL_EXECUTE_AUTOMATION_SETTINGS,
    STORE_UPDATED_BANK,
    STORE_UPDATED_BANK_WITH_PREVIEW,
    PREVIEW_BANK_FILE,
    INVOICE_EMAIL_SEND,
} from "../../constants/settings";

export const storeAddresses = (addresses) => ({
    type: STORE_ADDRESSES,
    addresses,
});

export const storeCarriers = (carriers) => ({
    type: STORE_CARRIERS,
    carriers,
});

export const storeShippingTemplates = (shippingTemplates) => ({
    type: STORE_SHIPPING_TEMPLATES,
    shippingTemplates,
});

export const storeInventoryAutoAllocation = (autoApply) => ({
    type: STORE_INVENTORY_AUTO_ALLOCATION,
    autoApply,
});

export const storeCarrierNames = (carrierNames) => ({
    type: STORE_CARRIER_NAMES,
    carrierNames,
});

export const listAddresses = (id) => ({
    type: LIST_ADDRESSES,
    id,
});

export const listCarriers = (id) => ({
    type: LIST_CARRIERS,
    id,
});

export const listShippingTemplates = (id) => ({
    type: LIST_SHIPPING_TEMPLATES,
    id,
});


export const listCarriersNames = () => ({
    type: LIST_CARRIER_NAMES
})

export const listUniqueCarriers = (id) => ({
    type: LIST_UNIQUE_CARRIERS,
    id
})

export const listLinkedAddresses = (id) => ({
    type: LIST_LINKED_ADDRESSES,
    id,
});

export const importHistoricalData = (selectedFiles, sourceType) => ({
    type: IMPORT_HISTORICAL_DATA,
    selectedFiles,
    sourceType
});

export const listSheets = (folderInfo) => ({
    type: LIST_SHEETS,
    folderInfo,
});

export const clearShiprushSpreadsheetsInfo = () => ({
    type: CLEAR_SHIPRUSH_SPREADSHEETS_INFO
});

export const clearHistoricalData = () => ({
    type: CLEAR_HISTORICAL_DATA
});

export const storeHistoricalData = (message, source) => ({
    type: STORE_HISTORICAL_DATA,
    message,
    source
});

export const storeShiprushFileNames = (fileNames) => ({
    type: STORE_SHIPRUSH_FILE_NAMES,
    fileNames
});

export const createCarrier = (carrier, reset) => ({
    type: CREATE_CARRIER,
    carrier,
    reset,
});

export const updateCarriers = (carrier) => ({
    type: UPDATE_CARRIERS,
    carrier,
});

export const clearCarrierOperationResponse = () => ({
    type: CLEAR_CARRIER_OPERATION_RESPONSE
})

export const storeCarrierOperationResponse = (response) => ({
    type: STORE_CARRIER_OPERATION_RESPONSE,
    response
});


export const updateAddresses = (address) => ({
    type: UPDATE_ADDRESSES,
    address,
});

export const storeLinkedAddresses = (addresses) => ({
    type: STORE_LINKED_ADDRESSES,
    addresses,
});

export const editCarrier = (carrier, reset) => ({
    type: EDIT_CARRIER,
    carrier,
    reset,
});

export const setDefaultCarrier = (id, carrierId) => ({
    type: SET_DEFAULT_CARRIER,
    id,
    carrierId
});

export const addressValidationChange = (id, carrierId) => ({
    type: ADDRESS_VALIDATION_CHANGE,
        id,
        carrierId
});

export const updateCarriersEdit = (carrier) => ({
    type: UPDATE_CARRIERS_EDIT,
    carrier
});

export const deleteCarrier = (toDelete, reset) => ({
    type: DELETE_CARRIER,
    toDelete,
    reset,
});

export const updateCarriersDelete = (carrierId, isDeleted) => ({
    type: UPDATE_CARRIERS_DELETE,
    carrierId,
    isDeleted,
});

export const listAllBankAccounts = () => ({
    type: LIST_ALL_BANK_ACCOUNTS,
})

export const storeAllBankAccounts = (data) => ({
    type: STORE_ALL_BANK_ACCOUNTS,
    data
})

export const saveBankAccount = (data) => ({
    type: SAVE_BANK_ACCOUNT,
    data
})

export const storeUpdatedBank = (updatedBankItem) => ({
    type: STORE_UPDATED_BANK,
    updatedBankItem
});

export const storeUpdatedBankWithPreview = (updatedBankItem) => ({
    type: STORE_UPDATED_BANK_WITH_PREVIEW,
    updatedBankItem
})

export const previewBankFile = (previewRequest) => ({
    type: PREVIEW_BANK_FILE,
    previewRequest
});

export const deleteBankAccount = (data) => ({
    type: DELETE_BANK_ACCOUNT,
    data
})

export const createProduct = (product, reset) => ({
    type: CREATE_PRODUCT,
    product,
    reset
});

export const updateProduct = (updatedProduct) => ({
    type: UPDATE_PRODUCT,
    updatedProduct
})

export const storeProducts = (products) => ({
    type: STORE_PRODUCTS,
    products
})

export const listProducts = () => ({
    type: LIST_PRODUCTS
})

export const storeUpdatedProduct = (updatedProduct, isDelete, isCreate) => ({
    type: STORE_UPDATED_PRODUCT,
    updatedProduct,
    isDelete,
    isCreate
})

export const deleteProduct = (product) => ({
    type: DELETE_PRODUCT,
    product
})




export const createSupplier = (supplier, refresh) => ({
    type: CREATE_SUPPLIER,
    supplier,
    refresh
})

export const listSuppliers = () => ({
    type: LIST_SUPPLIERS
})

export const updateSupplier = (updatedSupplier) => ({
    type: UPDATE_SUPPLIER,
    updatedSupplier
})

export const storeSuppliers = (suppliers) => ({
    type: STORE_SUPPLIERS,
    suppliers
})

export const processSupplierInvoices = (supplier) => ({
    type: PROCESS_SUPPLIER_INVOICES,
    supplier
})




export const listLocations = () => ({
    type: LIST_LOCATIONS
})

export const createLocation = (location, refresh) => ({
    type: CREATE_LOCATION,
    location,
    refresh
})

export const updateLocation = (updatedLocation) => ({
    type: UPDATE_LOCATION,
    updatedLocation
})

export const storeLocations = (locations) => ({
    type: STORE_LOCATIONS,
    locations
})

export const listAvailableLocations = () => ({
    type: LIST_AVAILABLE_LOCATIONS
});

export const storeAvailableLocations = (availableLocations) => ({
    type: STORE_AVAILABLE_LOCATIONS,
    availableLocations
});

export const storeAutomationSettings = (settings) => ({
    type: AUTOMATION_SETTINGS,
    settings
})


export const listCompanies = () => ({
    type: LIST_COMPANIES
})

export const storeCompanies = (companies) => ({
    type: STORE_COMPANIES,
    companies
})

export const listPrinters = () => ({
    type: LIST_PRINTERS
})

export const refreshPrinters = (refresh) => ({
    type: REFRESH_PRINTERS,
    refresh
})

export const updatePrinters = (printers) => ({
    type: UPDATE_PRINTERS,
    printers
})

export const storePrinters = (printers) => ({
    type: STORE_PRINTERS,
    printers
})

export const uploadLogo = (file) => ({
    type: UPLOAD_LOGO,
    file
})

export const fetchLogo = (logo) => ({
    type: FETCH_LOGO,
    logo
})

export const storeLogo = (logo) => ({
    type: STORE_LOGO,
    logo
})

export const deleteLogo = () => ({
    type: DELETE_LOGO,
})

export const editTags = (companyId, newTags, editedTags, deletedTags, effect) => ({
    type: EDIT_TAGS,
    companyId,
    newTags,
    editedTags,
    deletedTags,
    effect
})

export const listTags = (companyId) => ({
    type: LIST_TAGS,
    companyId
})

export const storeTags = (tags) => ({
    type: STORE_TAGS,
    tags
})

export const getUUID = (field, setUUID) => ({
    type: GET_UUID,
    field,
    setUUID
})

export const getUUIDs = (field, number, setUUIDs) => ({
    type: GET_UUIDS,
    field,
    number,
    setUUIDs
})

export const createPackage = (pkg, reset) => ({
    type: CREATE_PACKAGE,
    pkg,
    reset
});

export const updatePackage = (updatedPackage) => ({
    type: UPDATE_PACKAGE,
    updatedPackage
})

export const storePackages = (pkgs) => ({
    type: STORE_PACKAGES,
    pkgs
})

export const listPackages = () => ({
    type: LIST_PACKAGES
})

export const storeUpdatedPackage = (updatedPackage) => ({
    type: STORE_UPDATED_PACKAGE,
    updatedPackage
})

export const getEmailTemplates = () => ({
    type: LIST_EMAIL_TEMPLATES
})

export const sendPurchaseOrderEmail = (supplierId, data, reset) => ({
    type: PO_EMAIL_SEND,
    supplierId,
    data,
    reset
})

export const emailTemplateChange = (data) => ({
    type: EMAIL_TEMPLATE_UPDATE,
    data
})

export const createNewEmailTemplate = (data) => ({
    type: CREATE_NEW_EMAIL_TEMPLATE,
    data
})

export const emailTemplateDelete = (data) => ({
    type: DELETE_NEW_EMAIL_TEMPLATE,
    data
})

export const getEmailTemplateTypes = () => ({
    type: GET_EMAIL_TEMPLATE_TYPES,
})

export const emailTemplateTypeChange = (data) => ({
    type: EMAIL_TEMPLATE_TYPE_UPDATE,
    data
})

export const createNewEmailTemplateType = (data) => ({
    type: CREATE_NEW_EMAIL_TEMPLATE_TYPE,
    data
})

export const emailTemplateTypeDelete = (data) => ({
    type: DELETE_NEW_EMAIL_TEMPLATE_TYPE,
    data
})

export const fetchSendgridTemplates = () => ({
    type: FETCH_SENDGRID_TEMPLATES,
})

export const fetchListings = () => ({
    type: FETCH_LISTINGS,
})

export const setSkuLocation = (data) => ({
    type: SET_SKU_LOCATION,
    data,
})
export const fetchPricingListings = () => ({
    type: FETCH_PRICING_LISTINGS,
})
export const addRelatedProduct = (relatedProduct, webstoreProductId) => ({
    type: Add_RELATED_PRODUCT,
    relatedProduct,
    webstoreProductId
})
export const editRelatedProduct = (relatedProduct, webstoreProductId) => ({
    type: EDIT_RELATED_PRODUCT,
    relatedProduct,
    webstoreProductId
})
export const updateRelatedProducts = (relatedProducts, notes, webstoreProductId) => ({
    type: UPDATE_RELATED_PRODUCTS,
    relatedProducts,
    notes,
    webstoreProductId
})
export const getSalesRank = (webstoreProductId, fromDate, toDate ) => ({
    type: GET_SALES_RANK,
    webstoreProductId,
    fromDate,
    toDate
})
export const getBuyBoxPrices = (webstoreProductId, fromDate, toDate ) => ({
    type: GET_BUY_BOX_PRICES,
    webstoreProductId,
    fromDate,
    toDate
})
export const getRelatedProducts = (webstoreProductId) => ({
    type: GET_RELATED_PRODUCTS,
    webstoreProductId
})
export const fetchAmazonListingsPrice = () => ({
    type: FETCH_AMAZON_LISTINGS_PRICE,
})
export const fetchBarcodes = () => ({
    type: FETCH_BARCODES,
})

export const changePrimary = (update, bool) => ({
    type: CHANGE_PRIMARY,
    update,
    bool,
})

export const addNewChild = (update) => ({
    type: ADD_NEW_CHILD,
    update,
})

export const deleteChild = (update) => ({
    type: DELETE_CHILD,
    update
})

export const storeListings = (data) => ({
    type: STORE_LISTINGS,
    data
})
export const storePricingListings = (data) => ({
    type: STORE_PRICING_LISTINGS,
    data
})

export const storeSalesrank = (salesRank) => ({
    type: STORE_SALES_RANK,
    salesRank
})
export const storeBuyBoxPrices = (buyBoxPrices, relatedProductBuyBoxPrices) => ({
    type: STORE_BUY_BOX_PRICES,
    buyBoxPrices,
    relatedProductBuyBoxPrices
})
export const storeRelatedProducts = (relatedProducts) => ({
    type: STORE_RELATED_PRODUCTS,
    relatedProducts
})
export const storeBarcodes = (data) => ({
    type: STORE_BARCODES,
    data
})

export const updateBarcodes = (data) => ({
    type: UPDATE_BARCODES,
    data
})

export const storeUpdatedListings = (listing) => ({
    type: STORE_UPDATED_LISTING,
    listing
})

export const storeUpdatedPricingListings = (pricingListing) => ({
    type: STORE_UPDATED_PRICING_LISTING,
    pricingListing
})

export const storeUpdatedBarcode = (newBarcodeRow) => ({
    type: STORE_UPDATED_BARCODE,
    newBarcodeRow
})

export const storeRemovedBarcode = (shortcode, quantity) => ({
    type: STORE_REMOVED_BARCODE,
    shortcode,
    quantity
})

export const updateListing = (data) => ({
    type: UPDATE_LISTING,
    data
})
export const updatePricingListing = (data) => ({
    type: UPDATE_PRICING_LISTING,
    data
})
export const updateRepricing = (data) => ({
    type: UPDATE_REPRICING,
    data
})


export const deleteListing = (data) => ({
    type: DELETE_LISTING,
    data
})

export const storeDeleteListing = (listingId) => ({
    type: STORE_DELETE_LISTING,
    listingId
})

export const getUnmappedProducts = () => ({
    type: GET_UNMAPPED_PRODUCTS
})

export const storeUnmappedProducts = (data) => ({
    type: STORE_UNMAPPED_PRODUCTS,
    data
})

export const getActiveProducts = () => ({
    type: GET_ACTIVE_PRODUCTS
})

export const storeActiveProducts = (data) => ({
    type: STORE_ACTIVE_PRODUCTS,
    data
})

export const getInactiveProducts = () => ({
    type: GET_INACTIVE_PRODUCTS
})

export const storeInactiveProducts = (data) => ({
    type: STORE_INACTIVE_PRODUCTS,
    data
})

export const getDeletedProducts = () => ({
    type: GET_DELETED_PRODUCTS
})

export const storeDeletedProducts = (data) => ({
    type: STORE_DELETED_PRODUCTS,
    data
})

export const fetchAmazonSkus = () => ({
    type: FETCH_AMAZON_SKUS,
})

export const scrubData = (fromDate, toDate) => ({
    type: SCRUB_DATA,
    fromDate,
    toDate
})

export const createMarketplace = (marketplace, reset) => ({
    type: CREATE_MARKETPLACE,
    marketplace,
    reset,
})

export const listMarketplaces = (id) => ({
    type: LIST_MARKETPLACES,
    id
})

export const manualExecuteCarrierAutoSetting = (data) => ({
    type: MANUAL_EXECUTE_AUTOMATION_SETTINGS,
    data
})

export const updateInventoryAllocation = (percentages, companyId) => ({
    type: UPDATE_INVENTORY_ALLOCATION,
    percentages,
    companyId
})


export const storeInventoryAllocation  = (percentages, companyId) => ({
    type: STORE_INVENTORY_ALLOCATION,
    percentages,
    companyId
})

export const editMarketplace = (marketplace, reset) => ({
    type: EDIT_MARKETPLACE,
    marketplace,
    reset,
})

export const deleteMarketplace = (toDelete, reset) => ({
    type: DELETE_MARKETPLACE,
    toDelete,
    reset
})

export const updateMarketplaces = (marketplace) => ({
    type: UPDATE_MARKETPLACES,
    marketplace,
});

export const storeMarketplaces = (marketplaces) => ({
    type: STORE_MARKETPLACES,
    marketplaces,
});

export const updateMarketplacesEdit = (marketplace) => ({
    type: UPDATE_MARKETPLACES_EDIT,
    marketplace
});

export const updateMarketplacesDelete = (marketplaceId, isDeleted) => ({
    type: UPDATE_MARKETPLACES_DELETE,
    marketplaceId,
    isDeleted,
});

export const storeResponseMessage = (responseMessage) => ({
    type: STORE_RESPONSE_MESSAGE,
    responseMessage
})

export const validateMarketplace = (marketplace, setIsValid) => ({
    type: VALIDATE_MARKETPLACE,
    marketplace,
    setIsValid
})

export const saveProductCategoryChanges = (data) => ({
    type: SAVE_PRODUCT_CATEGORY_CHANGES,
    data
})

export const getAmazonFeeds = () => ({
    type: GET_AMAZON_FEEDS,
})

export const restartFeedFetch = (data) => ({
    type: RESTART_FEED_FETCH,
    data
})

export const removeProductCategory = (id) => ({
    type: REMOVE_PRODUCT_CATEGORY,
    id
})

export const getAllCategory = () => ({
    type: GET_ALL_CATEGORY
})

export const storeAllCategory = (data) => ({
    type: STORE_ALL_CATEGORY,
    data
})

export const storeEmailTemplates = (data) => ({
    type: STORE_EMAIL_TEMPLATES,
    data
})

export const storeEmailTemplateTypes = (data) => ({
    type: STORE_EMAIL_TEMPLATE_TYPES,
    data
})

export const getCountryCodes = () => ({
    type: GET_COUNTRY_CODES
})

export const storeCountryCodes = (countries) => ({
    type: STORE_COUNTRY_CODES,
    countries
})

export const storeAmzFeeds = (data) => ({
    type: STORE_AMZ_FEEDS,
    data
})

export const getPrintConfig = () => ({
    type: GET_PRINT_CONFIG
})

export const storePrintConfig = (data) => ({
    type: STORE_PRINT_CONFIG,
    data
})

export const archiveLabels = (fromDate, toDate, orderIds) => ({
    type: ARCHIVE_LABELS,
    fromDate: fromDate, // String, ISO date format
    toDate: toDate, // String, ISO date format
    orderIds: orderIds // array of Strings
})

export const storeArchivedLabels = (archivedLabelTrackingNumbers) => ({
    type: STORE_ARCHIVED_LABELS,
    archivedLabelTrackingNumbers // array of Strings
})

export const storeArchivedLabelsReturned = (hasReturned) => ({
    type: STORE_ARCHIVED_LABELS_RETURNED,
    hasReturned // boolean
})

export const fetchPurchaseOrderEmailTemplate = (data) => ({
    type: FETCH_PURCHASE_ORDER_EMAIL_TEMPLATE,
    data
})

export const sendEmail_purchaseOrder = (data, reset) => ({
    type: SEND_EMAIL_PURCHASE_ORDER,
    data,
    reset
})

export const storePurchaseOrderEmailTemplate = (data) => ({
    type: STORE_PURCHASE_ORDER_EMAIL_TEMPLATE,
    data
})

export const sendInvoiceOrderEmail = (customerId, data, reset) => ({
    type: INVOICE_EMAIL_SEND,
    customerId,
    data,
    reset
})