import React from 'react'

// Constants
import { INVOICE_TEMPLATE_DATA_FIELDS, INVOICE_TEMPLATE_DATA_SUB_FIELDS, DEFAULT_DATE_FORMAT } from "./constants";
import {DEFAULT_PDF_ZOOM} from "../../../global/constants";

// Custom Components
import withShipment from "../../../../withShipment";

// Redux
import {
    fetchSupplierInvoiceTemplates,
    createSaveSupplierInvoiceTemplate,
    getSupplierInvoiceTemplatePDF,
    deleteSupplierInvoiceTemplate,
    getDateFormats,
    storeSupplierInvoiceTemplatePDF
} from "../../../../redux/actions/invoices"
import {
    supplierInvoiceTemplatesSelector,
    supplierInvoiceTemplatePDFSelector, allDateFormats
} from "../../../../redux/selectors/invoices";
import PropTypes from "prop-types";
import CustomToolbar from "../../../global/CustomToolbar";
import CreateInvoiceTemplate from "./CreateInvoiceTemplate";
import TemplateTable from "./TemplateTable";

// Icons
import SaveIcon from '@material-ui/icons/Save';
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MenuIcon from '@material-ui/icons/Menu';
import ErrorFeedbackBar from '../../../global/ErrorFeedbackBar';
import AddCircleIcon from "@material-ui/icons/AddCircle";

class SupplierInvoiceTemplates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            creating: false,
            editing: false,
            fields: JSON.parse(JSON.stringify(INVOICE_TEMPLATE_DATA_FIELDS)),
            subFields: JSON.parse(JSON.stringify(INVOICE_TEMPLATE_DATA_SUB_FIELDS)),
            fieldError: "",
            entryTaxText: "", // TODO: Update this state from backend entryTaxText
            selectedSupplier: "none",
            scale: DEFAULT_PDF_ZOOM,
            template: {
                name: "",
                contents: []
            },
            searchText: '',
            selectedDateFormat: DEFAULT_DATE_FORMAT,
        }
        this.updateParentFields = this.updateParentFields.bind(this)
        this.saveTemplate = this.saveTemplate.bind(this)
        this.deleteInvoiceTemplate = this.deleteInvoiceTemplate.bind(this)
    }
    componentDidMount() {
        this.props.fetchSupplierInvoiceTemplates();
        this.props.getDateFormats();
    }

    createTemplate() {
        this.setState({ creating: true })
    }
    setEditing(value) {
        this.setState({ editing: value })
    }
    setCreating(value) {
        this.setState({ creating: value })
    }
    saveTemplate() {
        let data = {
            data: {},
            marker: {
                coordinates: {},
                text: ""
            },
            dateFormat: this.state.selectedDateFormat
        }
        let tableBox = this.state.fields.table.position
        let fieldKeys = Object.keys(this.state.fields)
        let subFieldKeys = Object.keys(this.state.subFields)
        for (const key of fieldKeys) {
            if (this.state.fields[key].required && !this.fieldDefined(this.state.fields[key].position)) {
                this.setState({ fieldError: key })
                return
            }
            if (key !== "marker") {
                let tempPosition = {...this.state.fields[key].position}
                tempPosition.x = Math.round(tempPosition.x / this.state.scale)
                tempPosition.y = Math.round(tempPosition.y / this.state.scale)
                tempPosition.w = Math.round(tempPosition.w / this.state.scale)
                tempPosition.h = Math.round(tempPosition.h / this.state.scale)
                data.data[key] = tempPosition
            }
        }
        for (const key of subFieldKeys) {
            if (this.state.subFields[key].required && !this.fieldDefined(this.state.subFields[key].position)) {
                this.setState({ fieldError: key })
                return
            }
            let positionCopy = {...this.state.subFields[key].position}
            positionCopy.x = Math.round((this.state.subFields[key].position.x + tableBox.x) / this.state.scale)
            positionCopy.y = Math.round(tableBox.y / this.state.scale)
            positionCopy.w = Math.round(this.state.subFields[key].position.w / this.state.scale)
            positionCopy.h = Math.round(tableBox.h / this.state.scale)
            data.data[key] = positionCopy
        }
        data.supplierId = this.state.selectedSupplier
        let tempPosition = {...this.state.fields.marker.position}
        tempPosition.x = Math.round(tempPosition.x / this.state.scale)
        tempPosition.y = Math.round(tempPosition.y / this.state.scale)
        tempPosition.w = Math.round(tempPosition.w / this.state.scale)
        tempPosition.h = Math.round(tempPosition.h / this.state.scale)
        data.marker.coordinates = tempPosition
        data.pdf = Array.from(this.state.template.contents)
        this.props.createSaveSupplierInvoiceTemplate(data, () => window.location.reload(), () => alert("Something went wrong"));
    }
    fieldDefined(position) {
        if (position.h || position.h === 0) return position.w && position.h
        return position.w > 0
    }
    updateField(field, value) {
        this.setState({ [field]: value })
    }
    updateParentFields(fields, subFields) {
        this.setState({ fields: fields, subFields: subFields })
    }
    deleteInvoiceTemplate(supplierId, supplierName) {
        let data = { supplierId: supplierId, supplierName: supplierName }
        this.props.deleteSupplierInvoiceTemplate(data, () => window.location.reload(), () => alert("Something went wrong"))
    }
    cancelEdit() {
        this.setState({
            editing: false,
            fields: JSON.parse(JSON.stringify(INVOICE_TEMPLATE_DATA_FIELDS)),
            subFields: JSON.parse(JSON.stringify(INVOICE_TEMPLATE_DATA_SUB_FIELDS)),
            fieldError: "",
            showFields: false,
            widenBox: false,
            selectedDateFormat: DEFAULT_DATE_FORMAT
        })
    }
    zoomIn() {
        this.setState({scale: Math.min(2, this.state.scale + 0.25)})
    }
    zoomOut() {
        this.setState({scale: Math.max(1, this.state.scale - 0.25)})
    }
    setDateFormat = (format) => {
        this.setState({
            selectedDateFormat: format
        })
    }
    handleSearchTextChange = (e) => {
        this.setState({
            searchText: e.target.value
        })
    }

    render() {
        let headerButtons = this.state.editing ? [{icon: <KeyboardBackspaceIcon />, handler: () => this.cancelEdit(), disabled: false, title: "Back"},
            {icon: <SaveIcon />, handler: this.saveTemplate, disabled: false, title: "Save"}] :
            this.state.creating ? [{icon: <KeyboardBackspaceIcon />, handler: () => this.setCreating(false), disabled: false, title: "Cancel"}] :
            [{icon: <KeyboardBackspaceIcon />, handler: () => this.props.closeInvoiceTemplates(), disabled: false, title: "Back"},
            {icon: <AddCircleIcon/>, handler: () => this.setCreating(true), disabled: false, title: "Create Supplier Invoice Template"}];
        return (<>
            <ErrorFeedbackBar />
            <CustomToolbar
                createTitle="Create Supplier Invoice Template"
                title="Supplier Invoice Templates"
                onClick={() => this.setCreating(true)}
                headerButtons={headerButtons}
                zoomIn={() => this.zoomIn()}
                zoomOut={() => this.zoomOut()}
                scale={this.state.scale}
                addZoom={this.state.editing}
            />
            {this.state.creating &&
                <CreateInvoiceTemplate
                    editing={this.state.editing}
                    selectedSupplier={this.state.selectedSupplier}
                    template={this.state.template}
                    updateField={(field, value) => this.updateField(field, value)}
                    updateParentFields={this.updateParentFields}
                    fieldDefined={this.fieldDefined}
                    fieldError={this.state.fieldError}
                    exit={() => this.setCreating(false)}
                    next={() => this.setEditing(true)}
                    scale={this.state.scale}
                    resetError={() => this.setState({ fieldError: "" })}
                    dateFormats={this.props.dateFormats}
                    setDateFormat={this.setDateFormat}
                    selectedFormat={this.state.selectedDateFormat}
                /> ||
                <TemplateTable
                    data={this.props.supplierInvoiceTemplates}
                    editing={this.state.editing}
                    updateField={(field, value) => this.updateField(field, value)}
                    template={this.props.supplierInvoiceTemplatePDF}
                    fieldDefined={this.fieldDefined}
                    getPDFTemplate={this.props.getSupplierInvoiceTemplatePDF}
                    clearPDFTemplate={this.props.storeSupplierInvoiceTemplatePDF}
                    updateParentFields={this.updateParentFields}
                    fieldError={this.state.fieldError}
                    fields={this.state.fields}
                    subFields={this.state.subFields}
                    entryTaxText={this.state.entryTaxText}
                    scale={this.state.scale}
                    resetError={() => this.setState({ fieldError: "" })}
                    deleteInvoiceTemplate={this.deleteInvoiceTemplate}
                    searchText={this.state.searchText}
                    handleSearchChange={this.handleSearchTextChange}
                    dateFormats={this.props.dateFormats}
                    setDateFormat={this.setDateFormat}
                    selectedFormat={this.state.selectedDateFormat}
                    showSupplier={this.props.showSupplier}
                />
            }
        </>)
    }
}


const mapStateToProps = (state) => ({
    supplierInvoiceTemplates: supplierInvoiceTemplatesSelector(state),
    supplierInvoiceTemplatePDF: supplierInvoiceTemplatePDFSelector(state),
    dateFormats: allDateFormats(state)
});

const actionCreators = {
    fetchSupplierInvoiceTemplates,
    createSaveSupplierInvoiceTemplate,
    getSupplierInvoiceTemplatePDF,
    storeSupplierInvoiceTemplatePDF,
    deleteSupplierInvoiceTemplate,
    getDateFormats
}
SupplierInvoiceTemplates.propTypes = {
    supplierInvoiceTemplates: PropTypes.array,
    supplierInvoiceTemplatePDF: PropTypes.object,
    dateFormats: PropTypes.array,
}

SupplierInvoiceTemplates.defaultProps = {
    supplierInvoiceTemplates: [],
    supplierInvoiceTemplatePDF: new Uint8Array(0),
    dateFormats: [],
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, SupplierInvoiceTemplates);