import React from "react";
import withShipment from "../../../withShipment";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import {ADD_CHEQUE, CHEQUE, CHEQUE_FIELDS, CHEQUE_PAGES} from "./constants";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Utils from "../../Utils/Utils";

import {
    deleteCheques,
} from "../../../redux/actions/accounting";
import {Layout} from "../../global/Table/TablePageHelpers/LayoutObject";
import {FormControlLabel, TableCell} from "@mui/material";
import {Checkbox, IconButton} from "@material-ui/core";
import TwoStepDelete from "../../global/subcomponents/twoStepDelete/TwoStepDelete";
import TablePage from "../../global/Table/TablePage";
import {Filter} from "../../global/Table/TablePageHelpers/FilterObject";
import EditIcon from "@material-ui/icons/Edit";


class ChequeTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleting: false,
            deleteId: "",
            selectedEntries: [],
            isSelecting: false,
            selectedBankAccount: null
        }
    }

    componentDidMount() {
    }


    getTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null,null,null))
        headers.push(Layout.columnHead(null, 'selectAllCheckbox', null, null))
        CHEQUE_FIELDS.forEach((headCell) => {
            headers.push(Layout.columnHead(headCell.label, headCell.id))
        })
        headers.push(Layout.columnHead(null,null,null))
        return headers
    }


    tableRow = (cheque) => {
        return (
            <React.Fragment>
                <TableCell>
                    <IconButton size="small" onClick={() => this.props.setCurrentPage(CHEQUE_PAGES.ADD_CHECK, cheque)}>
                        <EditIcon/>
                    </IconButton>
                </TableCell>
                <TableCell align="center" style={{padding: "0.2rem"}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.selectedEntries.includes(cheque.chequeId)}
                                onChange={() => this.handleSelectCheque(cheque.chequeId)}
                                style={{marginTop: "8px"}}
                            />
                        }
                    />
                </TableCell>

                <TableCell className="table-cell-left">{cheque?.payee}</TableCell>
                <TableCell className="table-cell-left">{cheque?.chequeNumber}</TableCell>
                <TableCell className="table-cell-left">{cheque?.accountNumber}</TableCell>
                <TableCell className="table-cell-left">{cheque?.paymentDate}</TableCell>
                {/*<TableCell className="table-cell-left">{Utils.formatDollar(cheque?.amount)}</TableCell>*/}
                <TableCell className="table-cell-left">
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '3px',
                        marginRight: '15px'
                    }}>
                        <span style={{flexShrink: 0}}>$</span>
                        <span
                            style={{marginLeft: 'auto'}}>{cheque?.amount.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</span>
                    </div>
                </TableCell>

                <TableCell className="table-cell-left">{cheque?.status}</TableCell>
                <TableCell className="table-cell-left">{cheque?.currency}</TableCell>
                <TableCell className="table-cell-left">{cheque?.category}</TableCell>

                <TwoStepDelete
                    rowId={cheque.chequeId}
                    handleDelete={(rowIdToDelete) => this.handleDeleteCheques()}
                    deleteId={this.state.deleteId}
                    setDeleteId={(newDeleteId) => this.setState({deleteId: newDeleteId})}
                />
            </React.Fragment>
        )
    }

    handleSelectCheque = (chequeId) => {
        const {selectedEntries} = this.state
        let index = selectedEntries.indexOf(chequeId)
        if (index === -1) {
            selectedEntries.push(chequeId)
        } else {
            selectedEntries.splice(index, 1)
        }

        const isSelecting = selectedEntries.length > 0

        this.setState({selectedEntries, isSelecting})
    }

    handleSelectAllCheques = (displayedTableRows) => {
        const { selectedEntries } = this.state

        const allChequeIds = displayedTableRows.map((cheque) => cheque.chequeId)
        const missingChequeIds = allChequeIds.filter((chequeId) => !selectedEntries.includes(chequeId))

        let updatedSelectedEntries = []
        if (missingChequeIds.length > 0) {
            updatedSelectedEntries = [...selectedEntries, ...missingChequeIds]
        }

        const isSelecting = updatedSelectedEntries.length > 0

        this.setState({ selectedEntries: updatedSelectedEntries, isSelecting })
    }

    searchStrings = (cheque) => {
        return [cheque.paymentDate, cheque.currency, cheque.accountNumber,
            cheque.amount,cheque.category,cheque.payee,cheque.chequeNumber]
    }
    handleDeleteCheques = () => {
        let chequeToDelete = []
        if (this.state.deleteId) {
            chequeToDelete.push(this.state.deleteId)
        } else if (this.state.selectedEntries.length > 0) {
            chequeToDelete.push(...this.state.selectedEntries)
        }

        this.props.deleteCheques({cheques: chequeToDelete},
            () => {
                this.setState({
                    deleteId: "",
                    deleting: false,
                    isSelecting: false,
                    selectedEntries: [],
                })
            })
    }
    getContextualMenuItems = () => {
        let menuItems = []

            menuItems.push(
                {
                    title: ADD_CHEQUE,
                    icon: <AddCircleIcon/>,
                    onClick: () => this.props.setCurrentPage(CHEQUE_PAGES.ADD_CHECK, {})
                }
            )


        return menuItems
    }


    render() {
        return (
            <React.Fragment>
                <TopToolbar
                    pageName={CHEQUE}
                    menuItems={this.getContextualMenuItems()}
                />

                <TablePage
                    tableName="ChequesTable"
                    tableRows={this.props.cheques}
                    tableColumnHeads={this.getTableHeaders()}
                    tableRow={this.tableRow}
                    handleSelectAll={this.handleSelectAllCheques}
                    numSelectedEntries={this.state.selectedEntries.length}
                    paginationText="Cheques per page"
                    hasSearchField
                    searchStrings={this.searchStrings}
                    defaultSortBy="paymentDate"
                    filters={[
                        Filter.createFilter("accountName", (row) => row.accountNumber, null, Filter.relations.containedBy, true),
                        Filter.createFilter("category", (row) => row.category, null, Filter.relations.containedBy, true),
                        Filter.createFilter('fromDate', (row) => row.paymentDate, null, Filter.relations.greaterThanEquals, true, Filter.displayValueOverrides.valueIsDate, new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7).toISOString()),
                        Filter.createFilter('toDate', (row) => row.paymentDate, null, Filter.relations.lessThanEquals, true, Filter.displayValueOverrides.valueIsDate, new Date().toISOString()),
                        Filter.createFilter("showUncategorizedOnly", (row) => (row.category === null || row.category === ''|| row.category === 'Uncategorized'), false, Filter.relations.equals, false),
                    ]}


                    filterDropdown={ Layout.newLayout(2, [
                        Layout.row([
                            Layout.rowElement(1, "accountName", "Account", Layout.elementStyle.checkboxDropdown, this.props.bankAccounts.map((account) => `${account.name} (${account.accountNumber})`)),
                            Layout.rowElement(1, "category", "Category", Layout.elementStyle.checkboxDropdown, this.props.categories.map((category) => `${category.name}`)),
                        ]),
                        Layout.row([
                            Layout.rowElement(1, 'fromDate', 'From Date', Layout.elementStyle.dateSelect),
                            Layout.rowElement(1, 'toDate', 'To Date', Layout.elementStyle.dateSelect)
                        ])
                    ])}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({

})

const actionCreators = {
    deleteCheques,
}

export default withShipment({
    actionCreators,
    mapStateToProps
}, ChequeTable);