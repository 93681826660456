import React from "react";

// material components
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

// custom components
import InventoryToolbar from "./InventoryToolbar";
import SearchableTable from "../global/Search/SearchableTable";
import InventorySearchFilterField from "./InventorySearchFilterField";
import CreateInventoryAdjustmentDialog from "./CreateInventoryAdjustmentDialog";
import InventoryOverviewTable from "./InventoryOverviewTable";
import ErrorFeedbackBar from "../global/ErrorFeedbackBar";

// constants
import {INSUFFICIENT_STOCK, INVENTORY, LOW_INVENTORY, OUT_OF_STOCK, TORONTO_DC_SHIPADDRESS_ID} from "./constants";

// redux
import withShipment from "../../withShipment";
import {
    createInventoryAdjustment,
    listAllInventory,
    listInventoryAdjustmentTypes,
    listInventoryEntries,
    updateFTLReceive,
    listManufacturers, updateInventoryThreshold,
    listAllBoxInventory, listAllProductInventory, listInventoryAlerts
} from "../../redux/actions/inventory";
import { fetchAlerts } from "../../redux/actions/alerts";
import {
    alertsInventorySelector,
    allInventorySelector,
    boxesInventorySelector,
    inventoryAdjustmentTypesSelector, inventoryEntriesSelector,
    manufacturersSelector,
    productsInventorySelector,
} from "../../redux/selectors/inventory";
import { loadingSelector } from "../../redux/selectors/global"
import { userInfoSelector } from "../../redux/selectors/auth"
import { updateUserInfo } from "../../redux/actions/auth";
import {
    saveTableSetting,
    getTableSetting
} from "../../redux/actions/account";
import {
    tableSettingSelector
} from "../../redux/selectors/account";

// styling
import "./Inventory.css";
import InventoryThresholdDialog from "./InventoryThresholdDialog";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { FETCH_UNSHIPPED_ALERTS_TIME } from "../alerts/constants";
import { Slide } from "@material-ui/core";
import {availableLocationsSelector} from "../../redux/selectors/settings";
import {listAvailableLocations} from "../../redux/actions/settings";

class Inventory extends SearchableTable {

    constructor(props) {
        super(props);
        this.state = {

            isFilterApplied: false,
            selectedLocationId: "",

            creatingManualInventory: false,

            filtersMap: {},

            availableShortcodes: [],

            availableToLocations: [],

            shortcodeIndices: {},
            key: 0,

            thresholdDialogOpen: false,
            currentShortcodeThreshold: '-',

            currentLocationName: '',
            searchText: '',
            searchValue: '',

            fromDate: this.getLastWeekDate(),
            toDate: new Date(),
            dateChanged: false,

            showOutOfStock: true,
            onlyInsufficientStock: false,
            onlyLowInventory: false,

            showThresholds: false,
            maxAllowedSearchRange:0,
            maxAllowedSearchRangeError:false,
            toDateBeforeFromDateError:false
        };

    }

    async componentDidMount() {
        // this.props.listAllInventory();
        this.props.listAvailableLocations();
        this.props.listInventoryAdjustmentTypes();
        this.props.listManufacturers();
        //this.props.listInventoryAlerts();
        let changeBack = false
        const data = {
            selectedShipAddressId: this.props.user.locationIds ?? [this.props.user.defaultLocationId],
            fetchUnshipped: false
        }
        let currHour = new Date().getHours();
        data.fetchUnshipped = currHour >= FETCH_UNSHIPPED_ALERTS_TIME;
        this.props.fetchAlerts(data)

        //this.props.listInventoryEntries({ fromDate: this.getLastWeekDate() });
        this.props.getTableSetting()
        await this.setState({
            filtersMap: this.props.tableSetting?.inventoryFilterSetting && this.props.tableSetting?.inventoryFilterSetting !== undefined ? this.props.tableSetting?.inventoryFilterSetting : {"inventoryType" : "Products"}
        })
        let storage = JSON.parse(localStorage.getItem(INVENTORY))
        if (storage) {
            this.getNewDates()
            this.getShowOutOfStock()
            this.getOnlyInsufficientStock()
            this.getOnlyLowInventory()
            this.getShowThresholds()
            const expiry = parseInt(JSON.parse(localStorage.getItem("expiry")))
            if (expiry <= new Date().getTime()) {
                localStorage.removeItem(INVENTORY)
                this.setState({
                    fromDate: this.getLastWeekDate(),
                    toDate: new Date(),
                    showOutOfStock: true,
                    onlyInsufficientStock: false,
                    showThresholds: false,
                    onlyLowInventory: false,
                    filtersMap: {}
                })
            }
        }
        this.props.listInventoryEntries({fromDate: this.state.fromDate, toDate: this.state.toDate})
    }

    setMaxAllowedSearchRangeError = (res) => {
        this.setState({
            maxAllowedSearchRangeError: res
        })
    }

    setToDateBeforeFromDateError = (res) => {
        this.setState({
            toDateBeforeFromDateError: res
        })
    }

    getLastWeekDate = () => {
        const today = new Date();
        const lastWeekDate = new Date(today);
        lastWeekDate.setHours(0);
        lastWeekDate.setMinutes(0);
        lastWeekDate.setSeconds(0);
        lastWeekDate.setDate(lastWeekDate.getDate() - 7)
        return lastWeekDate
    }

    getNewDates = () => {
        let filter = {...this.state.filtersMap}
        let storage = JSON.parse(localStorage?.getItem(INVENTORY))
        if (storage) {
            if (storage.fromDate !== null && storage.fromDate !== undefined ) {
                const temp = new Date(storage.fromDate)
                filter.fromDate = temp;
                this.setState({
                    fromDate: temp
                })
            }
            if (storage.toDate !== null && storage.toDate !== undefined) {
                const temp = new Date(storage.toDate)
                filter.toDate = temp;
                this.setState({
                    toDate: temp
                })
            }
        }
        if (filter) {
            this.setState({
                filtersMap: filter,
            })
        }
    }

    getShowOutOfStock = () => {
        let storage = JSON.parse(localStorage?.getItem(INVENTORY))
        if (storage) {
            if (storage.showOutOfStock !== null && storage.showOutOfStock !== undefined ) {
                this.setState({
                    showOutOfStock: storage.showOutOfStock
                })
            }
        }
    }

    getOnlyInsufficientStock = () => {
        let storage = JSON.parse(localStorage?.getItem(INVENTORY))
        if (storage) {
            if (storage.onlyInsufficientStock !== null && storage.onlyInsufficientStock !== undefined ) {
                this.setState({
                    onlyInsufficientStock: storage.onlyInsufficientStock
                })
            }
        }
    }

    getShowThresholds = () => {
        let storage = JSON.parse(localStorage?.getItem(INVENTORY))
        if (storage) {
            if (storage.showThresholds !== null && storage.showThresholds !== undefined ) {
                this.setState({
                    showThresholds: storage.showThresholds
                })
            }
        }
    }

    getOnlyLowInventory = () => {
        let storage = JSON.parse(localStorage?.getItem(INVENTORY))
        if (storage) {
            if (storage.onlyLowInventory !== null && storage.onlyLowInventory !== undefined ) {
                this.setState({
                    onlyLowInventory: storage.onlyLowInventory
                })
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        // when the available locations are fetched
        if (this.props.availableLocations !== prevProps.availableLocations) {
            this.handleAvailableToLocationsUpdate();
        }

        // the selected location is changed or we get an inventory update
        if (this.props.allInventory !== prevProps.allInventory || this.state.selectedLocationId !== prevState.selectedLocationId) {
            let shortcodes = [];
            let shortcodeIndices = {};
            this.props.allInventory.forEach((item, i) => {
                if (item.shipAddressId === this.state.selectedLocationId) {
                    shortcodes.push(item.shortcode);
                    shortcodeIndices[item.shortcode] = i;
                }
            }
            );

            this.handleAvailableToLocationsUpdate();
            this.setState({
                availableShortcodes: shortcodes,
                shortcodeIndices: shortcodeIndices
            });

            this.handleShortcodeIndicesUpdate()
        }

        // if the location is changed
        if (this.state.selectedLocationId !== prevState.selectedLocationId) {
            // update the shortcode indicies
            this.handleShortcodeIndicesUpdate()
        }


        // manufacturer change need to update the list of available shortcodes
        // if (this.state.filtersMap?.manufacturer !== prevState.filtersMap.manufacturer) {
        //     this.handleShortcodeIndicesUpdate()
        // }

        if (prevProps.tableSetting !== this.props.tableSetting) {
            this.setState({
                filtersMap: this.props.tableSetting?.inventoryFilterSetting,
                maxAllowedSearchRange: this.props.tableSetting?.inventoryFilterSetting?.maxAllowedDateRange
            })
            if (this.props.tableSetting?.inventoryFilterSetting) {
                if (!this.props.tableSetting.inventoryFilterSetting.inventoryType) {
                    this.props.saveTableSetting({
                        userId: this.props.user.userId,
                        changeInventoryFilters: true,
                        inventoryFilterSetting: {...this.props.tableSetting.inventoryFilterSetting, inventoryType: 'Products'}
                    })
                }
            }
        }
    }


    handleShortcodeIndicesUpdate = () => {
        let shortcodes = new Set();
        let newShortcodeIndices = {}
        this.props.allInventory.forEach((item, index) => {
            // if the manufacturer is cleared or the brand matches with the product AND its available in the location
            if ((!this.state.filtersMap?.manufacturer || this.state.filtersMap?.manufacturer.includes(item.details?.brand)) && item.shipAddressId === this.state.selectedLocationId) {
                shortcodes.add(item.shortcode);
                newShortcodeIndices[item.shortcode] = index;
            }
        });

        this.setState({
            availableShortcodes: Array.from(shortcodes),
            shortcodeIndices: newShortcodeIndices
        });
        this.searchShortcodeFilter()
    }


    onLocationChange = (value) => {
        let currentLocation = this.props.availableLocations.find(loc => {
            return loc.shipAddressId === value?.shipAddressId
        });
        const data = {
            locationId: value?.shipAddressId
        }
        if (this.state.filtersMap?.inventoryType !== undefined) {
            this.state.filtersMap?.inventoryType === 'Packaging' ?
                this.props.listAllBoxInventory(data) :
                this.props.listAllProductInventory(data)
        }

        // clear the filters map
        this.setState({
            currentLocationName: currentLocation?.name,
            selectedLocationId: value?.shipAddressId
        })
    }

    handleAvailableToLocationsUpdate = () => {
        let availableLocs = this.props.availableLocations.filter(location => {
            return location.shipAddressId !== this.state.selectedLocationId
        })
        this.setState({
            availableToLocations: availableLocs
        })
    }


    openCreateManualInventory = () => {
        this.setState({
            creatingManualInventory: true
        })
    }

    closeCreateManualInventory = () => {
        this.setState({
            creatingManualInventory: false
        });
    }

    submitCreateManualInventory = (data) => {
        this.setState({
            creatingManualInventory: false
        })
        const locationData = {
            locationId: this.state.selectedLocationId
        }
        const fetchAlertsData = {
            selectedShipAddressId: this.props.user.locationIds,
            fetchUnshipped: false,
        }
        let currHour = new Date().getHours();
        fetchAlertsData.fetchUnshipped = currHour >= FETCH_UNSHIPPED_ALERTS_TIME;
        this.props.createInventoryAdjustment(data, fetchAlertsData, this.state.filtersMap?.inventoryType !== 'Packaging', locationData);
    }

    passFiltersUp = (filters) => {
        // shortcode change
        if (filters?.shortcodes !== this.state.filtersMap?.shortcodes) {
            let cleared = !("shortcodes" in filters)
            // need to update the shortcode indices
            let shortcodes = filters.shortcodes
            let updatedShortcodeMap = {}

            if (!cleared) {
                shortcodes.forEach((entry, index) => {
                    updatedShortcodeMap[entry] = index;
                })
            } else {
                this.state.availableShortcodes.forEach((entry, index) => {
                    updatedShortcodeMap[entry] = index
                })
            }
            this.setState({
                shortcodeIndices: updatedShortcodeMap
            })
        }

        // date change
        if (this.state.dateChanged) {
            this.props.listInventoryEntries({ fromDate: this.state.fromDate, toDate: this.state.toDate });
            this.setState({
                dateChanged: false
            })
        }

        if (filters.inventoryType !== this.state.filtersMap?.inventoryType) {
            const data = {
                locationId: this.state.selectedLocationId
            }
            filters?.inventoryType === 'Packaging' ?
                this.props.listAllBoxInventory(data) :
                this.props.listAllProductInventory(data)
        }
        this.setState({
            filtersMap: filters
        });

        this.searchShortcodeFilter()
    }

    handleDateChange = (date, key) => {
        this.setState({
            [key]: date,
            dateChanged: true
        })
    }

    applyOverviewFilters = (rows) => {
        const manufacturer = this.state.filtersMap?.brands;
        const shortcodes = this.state.filtersMap?.shortcodes;
        const showOutOfStock = this.state.showOutOfStock;
        const onlyInsufficientStock = this.state.onlyInsufficientStock;
        const onlyLowInventory = this.state.onlyLowInventory;

        let result = rows.filter(row => {
            return row.shortcode in this.state.shortcodeIndices && row.shipAddressId === this.state.selectedLocationId
        });

        if (shortcodes) {
            result = result.filter(row => {
                return this.state.filtersMap?.shortcodes.includes(row.shortcode)
            });
        }

        if (manufacturer) {
            result = result.filter(row => {
                return this.state.filtersMap?.brands.includes(row.details?.brand)
            })
        }

        if (!showOutOfStock) {
            result = result.filter(row => {
                //return this.hasAlert(row.shortcode, OUT_OF_STOCK)
                return row.quantity > "0"
            })
        }

        if (onlyInsufficientStock) {
            result = result.filter(row => {
                return this.hasAlert(row.shortcode, INSUFFICIENT_STOCK)
            })
        }

        if (onlyLowInventory) {
            result = result.filter(row => {
                return this.hasAlert(row.shortcode, LOW_INVENTORY)
            })
        }

        result = result?.sort((a, b) => {
            return a.shortcode.toLowerCase().localeCompare(b.shortcode.toLowerCase())
        })

        return result;
    }

    listInventoryAlerts = () => {
        if (this.state.selectedLocationId) {
            this.props.listInventoryAlerts(true, {selectedShipAddressId: this.state.selectedLocationId, fetchUnshipped: false})
        }
    }

    hasAlert = (shortcode, type) => {
        let alerts = this.props.alerts
        for (let i = 0; i < this.props.alerts.length; i++) {
            if (alerts[i].alertType === type) {
                if (alerts[i].address) {
                    let isSelectedLocation = false
                    for (let j = 0; j < alerts[i].address.length; j++) {
                        if (alerts[i].address[j].shipAddressId === this.state.selectedLocationId) {
                            isSelectedLocation = true
                        }
                    }
                    if (!isSelectedLocation) {
                        continue
                    }
                }
                if (alerts[i].identifier?.shortcode === shortcode) {
                    return type === INSUFFICIENT_STOCK ? true
                        : type === LOW_INVENTORY
                }
                if (alerts[i].byBrandName) {
                    for (let j = 0; j < alerts[i].byBrandName.length; j++) {
                        if (alerts[i].byBrandName[j].shortcode === shortcode) {
                            return true
                        }
                    }
                }
            }
        }
        return false
    }

    applyDetailFilters = (rows) => {

        if (!rows)
            return []

        const types = this.state.filtersMap?.actionTypes;
        let fromDate = this.state.filtersMap?.fromDate;
        let toDate = this.state.filtersMap?.toDate;

        let result = [...rows].filter(item => item._id.type === "FTL" ? (item._id.fromShipAddressId === this.state.selectedLocationId || item._id.toShipAddressId === this.state.selectedLocationId) : item._id.shipAddressId === this.state.selectedLocationId);

        if (types) {
            result = result.filter(row => {
                return types.includes(row._id.type)
            })
        }

        if (fromDate) {
            fromDate = new Date(fromDate)
            result = result.filter(row => {
                return row._id.date >= fromDate || row._id.receiveDate >= fromDate
            })
        }

        if (toDate) {
            toDate = new Date(toDate)
            result = result.filter(row => {
                return row._id.date <= toDate
            })
        }


        return result
    }


    showFiltersDialog = () => {

        // Make a copy of the filters applied as they exist upon entering
        const filtersMapClone = { ...this.state.filtersMap };

        this.setState({
            filtersMapBeforeOpenDialog: filtersMapClone,
            isDialogOpen: true,
        });
    }

    hideFiltersDialog = (applyChanges) => {

        // NOTE: changes are already applied as the user makes these changes,
        // but if the changes are cancelled, then the map is reverted to its
        // previous state
        this.setState({
            isDialogOpen: false,
            filtersMap: applyChanges ? this.state.filtersMap : this.state.filtersMapBeforeOpenDialog,
            isFilterApplied: applyChanges ? Object.keys(this.state.filtersMap).length !== 0 : Object.keys(this.state.filtersMapBeforeOpenDialog).length !== 0,
        });
    }

    getDisplayed = (rows) => {
        if (!rows)
            return []
        const displayed = [...rows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)];
        return displayed;
    }


    searchShortcodeFilter = () => {
        const searchValue = this.state.searchValue
        let shortcodeIndices = {};
        this.props.allInventory.forEach((item, i) => {
            if (item.shipAddressId === this.state.selectedLocationId) {
                shortcodeIndices[item.shortcode] = i;
            }
        });

        let shortcodes = shortcodeIndices
        let keys = Array.from(Object.keys(shortcodes));
        let foundKeys = [];
        let i = 0;
        while (i < keys.length) {
            if (keys[i].toLowerCase().includes(searchValue.toLowerCase())) {
                foundKeys.push(keys[i])
            }
            i += 1;
        }


        // delete the other keys from the map
        keys.forEach((key) => {
            if (!foundKeys.includes(key))
                delete shortcodes[key]
        })

        this.setState({
            shortcodeIndices: shortcodes,
            // searchText: e.target.value
        });

    }

    setInventoryThreshold = () => {
        this.setState({
            thresholdDialogOpen: true
        })
    }

    handleThresholdDialogClose = (save, data = []) => {
        if (save) {
            // actually save
            data["shipAddressId"] = this.state.selectedLocationId;
            //alert(JSON.stringify(data))
            const fetchAlertsData = {
                selectedShipAddressId: this.state.selectedLocationId,
                fetchUnshipped: false,
            }
            let currHour = new Date().getHours();
            if (currHour >= FETCH_UNSHIPPED_ALERTS_TIME) {
                fetchAlertsData.fetchUnshipped = true;
            } else {
                fetchAlertsData.fetchUnshipped = false;
            }
            this.props.updateInventoryThreshold(data, fetchAlertsData);
        }
        this.setState({
            thresholdDialogOpen: false,
            currentShortcodeThreshold: '-'
        })
    }

    getShortcodeThreshold = (value) => {
        // need to go through inventory list to find the threshold
        let selected = this.props.allInventory.find(entry => {
            return entry.shortcode === value && this.state.selectedLocationId === entry.shipAddressId
        });
        this.setState({
            currentShortcodeThreshold: selected.threshold
        });
    }

    handleClose = () => {
        this.setState({
            thresholdDialogOpen: false,
            creatingManualInventory: false
        })
    }

    handleSearchTextChange = (e) => {
        this.setState({
            searchText: e.target.value
        })
    }

    onSearchValueChange = (e) => {
        this.setState({
            searchValue: e.target.value,
            searchText: e.target.value
        }, () => { this.searchShortcodeFilter() })
    }

    handleShowOutOfStockChange = () => {
        const expiry = new Date().setHours(23, 59, 59, 0)
        let existing = localStorage.getItem(INVENTORY)
        existing = existing ? JSON.parse(existing) : {}
        existing.showOutOfStock = !this.state.showOutOfStock
        localStorage.setItem(INVENTORY, JSON.stringify(existing))
        localStorage.setItem("expiry", JSON.stringify(expiry))

        this.setState({
            showOutOfStock: !this.state.showOutOfStock
        })
        if (!this.state.showOutOfStock) {
            this.setState({
                showOutOfStock: true
            })
        }
    }

    handleOnlyInsufficientStockChange = () => {
        const expiry = new Date().setHours(23, 59, 59, 0)
        let existing = localStorage.getItem(INVENTORY)
        existing = existing ? JSON.parse(existing) : {}
        existing.onlyInsufficientStock = !this.state.onlyInsufficientStock
        if (!this.state.onlyInsufficientStock) {
            existing.onlyLowInventory = false
            existing.showOutOfStock = false
        }
        localStorage.setItem(INVENTORY, JSON.stringify(existing))
        localStorage.setItem("expiry", JSON.stringify(expiry))

        this.setState({
            onlyInsufficientStock: !this.state.onlyInsufficientStock
        })
        if (!this.state.onlyInsufficientStock) {
            this.setState({
                onlyLowInventory: false,
                showOutOfStock: false
            })
        }
    }

    handleOnlyLowInventoryChange = () => {
        const expiry = new Date().setHours(23, 59, 59, 0)
        let existing = localStorage.getItem(INVENTORY)
        existing = existing ? JSON.parse(existing) : {}
        existing.onlyLowInventory = !this.state.onlyLowInventory
        if (!this.state.onlyLowInventory) {
            existing.onlyInsufficientStock = false
            existing.showOutOfStock = false
        }
        localStorage.setItem(INVENTORY, JSON.stringify(existing))
        localStorage.setItem("expiry", JSON.stringify(expiry))

        this.setState({
            onlyLowInventory: !this.state.onlyLowInventory
        })
        if (!this.state.onlyLowInventory) {
            this.setState({
                onlyInsufficientStock: false,
                showOutOfStock: false
            })
        }
    }

    handleShowThresholdsChange = () => {
        const expiry = new Date().setHours(23, 59, 59, 0)
        let existing = localStorage.getItem(INVENTORY)
        existing = existing ? JSON.parse(existing) : {}
        existing.showThresholds = !this.state.showThresholds
        localStorage.setItem(INVENTORY, JSON.stringify(existing))
        localStorage.setItem("expiry", JSON.stringify(expiry))

        this.setState({
            showThresholds: !this.state.showThresholds
        })
    }

    render() {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ErrorFeedbackBar />
                <Paper className="paper">
                    <InventoryToolbar
                        title={INVENTORY}
                        createManualInventory={this.openCreateManualInventory}
                        setInventoryThreshold={this.setInventoryThreshold}
                        creatingManualInventory={this.state.creatingManualInventory}
                        settingInventoryThreshold={this.state.thresholdDialogOpen}
                        handleClose={this.handleClose}
                    />

                    {/*Custom inventory adjustment*/}
                    {this.state.creatingManualInventory ?
                        <CreateInventoryAdjustmentDialog
                            handleClose={this.closeCreateManualInventory}
                            manufacturers={this.props.manufacturers}
                            inventoryAdjustmentTypes={this.props.inventoryAdjustmentTypes}
                            fromShipAddressId={this.state.selectedLocationId}
                            availableLocations={this.props.availableLocations}
                            availableShortcodes={this.state.availableShortcodes}
                            availableToLocations={this.state.availableToLocations}
                            handleSubmit={this.submitCreateManualInventory}
                        />
                        : this.state.thresholdDialogOpen ?
                            <InventoryThresholdDialog
                                open={this.state.thresholdDialogOpen}
                                onClose={this.handleThresholdDialogClose}
                                availableShortcodes={this.state.availableShortcodes}
                                currentShortcodeThreshold={this.state.currentShortcodeThreshold}
                                getShortcodeThreshold={this.getShortcodeThreshold}
                                currentLocationName={this.state.currentLocationName}
                                shortcodeIndices={this.state.shortcodeIndices}
                                allInventory={this.props.allInventory}
                                alerts={this.props.alerts}
                                listInventoryAlerts={this.listInventoryAlerts}
                                locationId={this.state.selectedLocationId}
                                locationIds={this.props.user.locationIds}
                            />
                        :
                        <>
                            <InventorySearchFilterField
                                availableLocations={this.props.availableLocations}
                                isFilterApplied={this.state.isFilterApplied}
                                onLocationChange={this.onLocationChange}
                                onShortcodeSelectionChange={this.onShortcodeSelectionChange}
                                manufacturers={this.props.manufacturers}
                                inventoryAdjustmentTypes={this.props.inventoryAdjustmentTypes}
                                availableShortcodes={this.state.availableShortcodes}
                                passFiltersUp={this.passFiltersUp}
                                onSearchValueChange={this.onSearchValueChange}
                                selectedLocationId={this.state.selectedLocationId}
                                user={this.props.user}
                                updateUserInfo={this.props.updateUserInfo}
                                tableSetting={this.props.tableSetting}
                                saveTableSetting={this.props.saveTableSetting}
                                searchText={this.state.searchText}
                                handleSearchChange={this.handleSearchTextChange}
                                fromDate={this.state.fromDate}
                                toDate={this.state.toDate}
                                handleDateChange={this.handleDateChange}
                                filtersMap={this.state.filtersMap}
                                showOutOfStock={this.state.showOutOfStock}
                                handleShowOutOfStockChange={this.handleShowOutOfStockChange}
                                onlyInsufficientStock={this.state.onlyInsufficientStock}
                                handleOnlyInsufficientStockChange={this.handleOnlyInsufficientStockChange}
                                onlyLowInventory={this.state.onlyLowInventory}
                                handleOnlyLowInventoryChange={this.handleOnlyLowInventoryChange}
                                showThresholds={this.state.showThresholds}
                                handleShowThresholdsChange={this.handleShowThresholdsChange}
                                maxAllowedSearchRange={this.state.maxAllowedSearchRange}
                                setMaxAllowedSearchRangeError={this.setMaxAllowedSearchRangeError}
                                setToDateBeforeFromDateError={this.setToDateBeforeFromDateError}
                            />

                                {this.props.isloading ?
                                    <div className="getPrinterLoadingOuterBox">
                                        <div className="getPrinterLoadingBox">
                                            <CircularProgress size={100} />
                                        </div>
                                    </div>
                                    :
                                    this.props.allInventory && this.state.selectedLocationId !== "" && this.props.allInventory.filter((item) => item.shipAddressId === this.state.selectedLocationId).length === 0 ?
                                        <div className='no-inventory-card'>
                                            <Card variant='outlined'>
                                                <CardContent>
                                                    <Typography variant='h6' gutterBottom>
                                                        No Inventory Found For This Location!
                                                    </Typography>
                                                    <Typography variant="h5" component="h2">
                                                    </Typography>
                                                    <Typography>
                                                        Please add some products to this location.
                                                    </Typography>

                                                </CardContent>
                                            </Card>
                                        </div>
                                        :
                                        <InventoryOverviewTable
                                            filteredInventoryOverview={this.applyOverviewFilters(this.props.allInventory)}
                                            filteredInventoryEntries={this.applyDetailFilters(this.props.inventoryEntries)}
                                            shortcodeIndices={this.state.shortcodeIndices}
                                            key={this.state.key}
                                            selectedLocationId={this.state.selectedLocationId}
                                            handleReceive={this.props.updateFTLReceive}
                                            locationIds={this.props.user.locationIds}
                                            showProducts={this.state.filtersMap?.inventoryType !== 'Packaging'}
                                            alerts={this.props.alerts}
                                            listInventoryAlerts={this.listInventoryAlerts}
                                            showThresholds={this.state.showThresholds}
                                            maxAllowedSearchRange={this.state.maxAllowedSearchRange}
                                            maxAllowedSearchRangeError={this.state.maxAllowedSearchRangeError}
                                            setMaxAllowedSearchRangeError={this.setMaxAllowedSearchRangeError}
                                            toDateBeforeFromDateError={this.state.toDateBeforeFromDateError}
                                            setToDateBeforeFromDateError={this.setToDateBeforeFromDateError}
                                            onlyLowInventory={this.state.onlyLowInventory}
                                        />
                                }
                        </>
                    }
                </Paper>
            </MuiPickersUtilsProvider>
        )
    }


}

const mapStateToProps = (state) => ({
    user: userInfoSelector(state),
    allInventory: allInventorySelector(state),
    availableLocations: availableLocationsSelector(state),
    manufacturers: manufacturersSelector(state),
    alerts: alertsInventorySelector(state),
    inventoryAdjustmentTypes: inventoryAdjustmentTypesSelector(state),
    isloading: loadingSelector(state),

    inventoryEntries: inventoryEntriesSelector(state),
    boxesInventory: boxesInventorySelector(state),
    productsInventory: productsInventorySelector(state),
    tableSetting: tableSettingSelector(state)

})

const actionCreators = {
    listAllInventory,
    listAvailableLocations,
    listInventoryAdjustmentTypes,
    listManufacturers,
    listInventoryAlerts,
    listAllBoxInventory,
    listAllProductInventory,

    createInventoryAdjustment,
    updateFTLReceive,
    updateInventoryThreshold,

    listInventoryEntries,
    fetchAlerts,
    updateUserInfo,

    saveTableSetting,
    getTableSetting
}

Inventory.propTypes = {
    allInventory: PropTypes.array,
    availableLocations: PropTypes.array,
    fromShipAddressId: PropTypes.string,
    manufacturers: PropTypes.array,
    inventoryAdjustmentTypes: PropTypes.array,
    inventoryEntries: PropTypes.array,
}

Inventory.defaultProps = {
    allInventory: [],
    availableLocations: [],
    fromShipAddressId: "",
    manufacturers: [],
    inventoryAdjustmentTypes: [],
    inventoryEntries: [],
    alerts: [],
}


export default withShipment({
    mapStateToProps,
    actionCreators
}, Inventory);