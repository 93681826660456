import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ClearIcon from '@material-ui/icons/Clear';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import {
    ORDER_ID, TAG, NAME, ADDRESS, CITY, STATE, COUNTRY, MARKETPLACE, SHIP_METHOD, CARRIER, CANADA, UNITED_STATES,
    SHORT_CODE, SHIP_FROM, FILTER_DIALOG_TITLE, CLEAR_FILTERS, SKU, COUNTRY_OPTIONS
} from './constants';
import {APPLY, CANCEL} from '../global/constants';

// import styling
import './FilterDialog.css';
import {Select, MenuItem, InputLabel, FormControlLabel, RadioGroup, Radio} from '@material-ui/core';
import PropTypes from "prop-types";
import {Alert, Autocomplete} from "@material-ui/lab";
import Grid from '@material-ui/core/Grid';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Chip from "@material-ui/core/Chip";
import {CANCELLED, CLONED, RETURN, SHIPPED, UNSHIPPED} from "../stages/constants";

class FiltersDialog extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            orderId: "",
            //for unshipped date input errors
            shippedFromDateError: false,
            shippedToDateError: false,
            shippedShipByDateError: false,
            shippedDateOrderError:false,
            isChecked: this.props.filtersMap["prime"] !== undefined && this.props.filtersMap["prime"] !== null,
            toDateBeforeFromDateError:false,
            toDateBeforeFromDateErrorMessage:"",
            toDateNotFilledError:false,
            fromDateNotFilledError:false,
            dateRangeError:false
        }
    }

    componentDidMount() {
        if(this.props.shipmentStatus === SHIPPED)
            this.check(this.props.fromDate,this.props.toDate,this.props.maxAllowedDateRange)
    }

    componentDidUpdate(prevProps,prevState){
        if(this.props.shipmentStatus === SHIPPED && this.props.filtersMap !== prevProps.filtersMap){
            this.check(this.props.fromDate,this.props.toDate,this.props.maxAllowedDateRange)
        }
    }

    //check the gap between two date
    async check(dateA, dateB,maxGapAllowed) {

        this.setState({
            toDateBeforeFromDateError:false,
            dateRangeError:false,
        })


        let date1 = new Date(dateA);
        let date2 = new Date(dateB);
        date1.setHours(0);
        date1.setMinutes(0);
        date1.setSeconds(0);
        date2.setHours(0);
        date2.setMinutes(0);
        date2.setSeconds(0);
        //Check if the 'To Date is properly filled'
        if(isNaN(date2.getTime())){
            this.setState({
                toDateNotFilledError:true
            })
            return
        }
        else{
            this.setState({
                toDateNotFilledError:false
            })
        }

        //Check if the 'From Date is properly filled'
        if(isNaN(date1.getTime())){
            this.setState({
                fromDateNotFilledError:true
            })
            return
        }
        else{
            this.setState({
                fromDateNotFilledError:false
            })
        }


        let differenceInDays = (date2 - date1)/86400000; // Difference in milliseconds

        //Check if the 'Shipped Date To' is after or equal to 'Shipped Date From'
        if(differenceInDays<0){
            this.setState({
                toDateBeforeFromDateErrorMessage:"To date should be equal or later than from date",
                toDateBeforeFromDateError:true
            })
            return;
        }
        else{
            this.setState({
                toDateBeforeFromDateErrorMessage:"",
                toDateBeforeFromDateError:null,
            })
        }

        //if the gap is not within limit
        if(this.props.shipmentStatus===SHIPPED&&(differenceInDays<0 || differenceInDays>(parseInt(maxGapAllowed, 10)+0.99))) {
            this.props.triggerMaxDateGapError()
            this.setState({
                dateRangeError:true
            })
        }
        else {
            this.setState({
                dateRangeError:false
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.filtersMap !== this.props.filtersMap) {
            this.setState({
                isChecked: this.props.filtersMap["prime"] !== undefined && this.props.filtersMap["prime"] !== null
            })
        }
    }

    /**
     * Helper method to get values for input fields. Using this.props.filtersMap[id]
     * directly in the value field can make the textfields not render correctly when
     * the map clears and the map becomes "undefined"
     *
     * @param id the ID of the filtersMap for which key is being retrieved
     * @param defaultResponse a return value for if no value is found, defaults to empty string
     */
    getValue(id, defaultResponse = "") {
        if (this.props.filtersMap && this.props.filtersMap[id] !== undefined) {
            return this.props.filtersMap[id];
        } else {
            return defaultResponse;
        }
    }

    //this method takes two date as parameter checks if 'to date' is equal or greater than 'from date'.
    checkDateOrder= (dateA,dateB) => {
        let date1 = new Date(dateA);
        let date2 = new Date(dateB);
        date1.setHours(0);
        date1.setMinutes(0);
        date1.setSeconds(0);
        date2.setHours(0);
        date2.setMinutes(0);
        date2.setSeconds(0);
        let differenceInDays = (date2 - date1)/86400000; // Difference in milliseconds
        //Check if the 'to date' is after or equal to 'from date'
        if(differenceInDays<0){
            return true
        }
        return false
    }

    getDateSearchRow = () => {
        switch (this.props.shipmentStatus) {
            case UNSHIPPED:
                return <div className='row-flexbox'>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className='dateRowFlexBox'>
                            <div className='dateRowContainer'>
                                <KeyboardDatePicker
                                    autoOk
                                    name="fromDate"
                                    variant="inline"
                                    className="dateRowElement"
                                    inputVariant="outlined"
                                    label="Order Date (From)"
                                    format="yyyy-MM-dd"
                                    value={this.props.fromDate}
                                    InputAdornmentProps={{ position: "start" }}
                                    error={this.state.shippedFromDateError}
                                    helperText={this.state.shippedFromDateError?"Invalid Date":""}
                                    onChange={dateInput => {
                                        let date = new Date(dateInput?.toString())
                                        if (date.toString() === "Invalid Date") {
                                            this.setState({
                                                shippedFromDateError : true
                                            })
                                        }
                                        else {
                                            this.setState({
                                                shippedFromDateError: false
                                            })
                                        }
                                        if(this.checkDateOrder(date,this.props.toDate)){
                                            this.setState({
                                                shippedDateOrderError : true
                                            })
                                        }
                                        else{
                                            this.setState({
                                                shippedDateOrderError : false
                                            })
                                        }
                                        this.props.handleDateChange("fromDate", date)
                                    }}
                                />
                                <div className='dateRowSpacer'/>
                                <KeyboardDatePicker
                                    autoOk
                                    name="toDate"
                                    variant="inline"
                                    className="dateRowElement"
                                    inputVariant="outlined"
                                    label="Order Date (To)"
                                    format="yyyy-MM-dd"
                                    value={this.props.toDate}
                                    InputAdornmentProps={{ position: "start" }}
                                    error={this.state.shippedToDateError||this.state.shippedDateOrderError}
                                    helperText={this.state.shippedToDateError?"Invalid Date":this.state.shippedDateOrderError?"To date should be equal or after from date":""}
                                    onChange={dateInput => {
                                        let date = new Date(dateInput?.toString())
                                        if (date.toString() === "Invalid Date") {
                                            this.setState({
                                                shippedToDateError:true
                                            })
                                        }
                                        else {
                                            this.setState({
                                                shippedToDateError: false
                                            })
                                        }
                                        if(this.checkDateOrder(this.props.fromDate,date)){
                                            this.setState({
                                                shippedDateOrderError : true
                                            })
                                        }
                                        else{
                                            this.setState({
                                                shippedDateOrderError : false
                                            })
                                        }
                                        this.props.handleDateChange("toDate", date)
                                    }}
                                />
                            </div>
                            <div className='dateRowSpacer'/>
                            <div className='dateRowContainer'>
                                <div style={{visibility: "hidden"}}>
                                    <KeyboardDatePicker
                                        autoOk
                                        name="fromDate"
                                        variant="inline"
                                        className="dateRowElement"
                                        inputVariant="outlined"
                                        label="Ship By From"
                                        format="yyyy-MM-dd"
                                        value={this.props.shipFromDate}
                                        InputAdornmentProps={{ position: "start" }}
                                        onChange={dateInput => {
                                            let date = new Date(dateInput?.toString())
                                            if (date.toString() === "Invalid Date") {
                                                return
                                            }
                                            this.props.handleDateChange("shipFromDate", date)
                                        }}
                                    />
                                </div>
                                <div className='dateRowSpacer'/>
                                <KeyboardDatePicker
                                    autoOk
                                    name="toDate"
                                    variant="inline"
                                    className="dateRowElement"
                                    inputVariant="outlined"
                                    label="Ship By"
                                    format="yyyy-MM-dd"
                                    value={this.props.shipToDate}
                                    InputAdornmentProps={{ position: "start" }}
                                    error={this.state.shippedShipByDateError}
                                    helperText={this.state.shippedShipByDateError?"Invalid Date":""}
                                    onChange={dateInput => {
                                        let date = new Date(dateInput?.toString())
                                        if (date.toString() === "Invalid Date") {
                                            this.setState({
                                                shippedShipByDateError:true
                                            })
                                            return
                                        }
                                        this.setState({
                                            shippedShipByDateError:false
                                        })
                                        this.props.handleDateChange("shipToDate", date)
                                    }}
                                />
                            </div>
                        </div>
                    </MuiPickersUtilsProvider>
                </div>
            case RETURN:
            case CLONED:
            case CANCELLED:
            case SHIPPED:
                let dateField
                switch (this.props.shipmentStatus) {
                    case RETURN:
                    case CLONED:
                        dateField = "Order"
                        break
                    case CANCELLED:
                        dateField = "Cancelled"
                        break
                    case SHIPPED:
                        dateField = "Shipped"
                        break
                }
                return <div className='row-flexbox'>
                        <div className='dateRowFlexBox'>
                            <div className='dateRowContainer'>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        autoOk
                                        name="fromDate"
                                        variant="inline"
                                        className="dateRowElement"
                                        inputVariant="outlined"
                                        label={dateField + " Date (From)"}
                                        format="yyyy-MM-dd"
                                        value={this.props.fromDate}
                                        InputAdornmentProps={{ position: "start" }}
                                        onChange={dateInput => {
                                            let date = new Date(dateInput?.toString())
                                            this.check(date,this.props.toDate,this.props.maxAllowedDateRange)
                                            this.props.handleDateChange("fromDate", date)
                                        }}
                                        error={this.state.fromDateNotFilledError||this.state.dateRangeError}
                                        helperText={this.state.fromDateNotFilledError?"Invalid Date":""}
                                    />
                                </MuiPickersUtilsProvider>
                                <div className='dateRowSpacer'/>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        autoOk
                                        name="toDate"
                                        variant="inline"
                                        className="dateRowElement"
                                        inputVariant="outlined"
                                        label={dateField + " Date (To)"}
                                        format="yyyy-MM-dd"
                                        value={this.props.toDate}
                                        InputAdornmentProps={{ position: "start" }}
                                        onChange={dateInput => {
                                            let date = new Date(dateInput?.toString())
                                            this.check(this.props.fromDate,date,this.props.maxAllowedDateRange)
                                            this.props.handleDateChange("toDate", date)
                                        }}
                                        error={this.state.toDateBeforeFromDateError||this.state.toDateNotFilledError||this.state.dateRangeError}
                                        helperText={this.state.toDateBeforeFromDateError?this.state.toDateBeforeFromDateErrorMessage:this.state.toDateNotFilledError?"Invalid Date":""}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                </div>
        }
    }

    render() {
        return (
            // <Dialog onEntered={this.props.onOpen}
            //         open={this.props.isOpen}
            //         onClose={() => this.props.onClose(false)}
            //         aria-labelledby="filters-dialog-title">
            <div>
                <DialogTitle id="form-dialog-title">{FILTER_DIALOG_TITLE}
                    <Button className="clearFiltersButton" startIcon={<ClearIcon />}
                        onClick={this.props.clearFilters}>{CLEAR_FILTERS}</Button>
                </DialogTitle>
                <DialogContent>
                    {!this.props.inLogs ?
                    <>
                    <div className='row-flexbox'>
                        <Autocomplete
                            multiple
                            options={this.props.tags.map(tag => tag.tagName)}
                            getOptionLabel={(entry) => entry}
                            autoHighlight={true}
                            className={'leftColumn'}
                            value={this.getValue("tag", [])}
                            /* alternate tag layout
                            value={this.props.filtersMap?.tag?.toString()}
                            renderTags={(value) => value + ""}*/
                            renderTags={(value, getTagProps) =>
                                value.map((option) => (
                                    <Chip
                                        variant="outlined"
                                        size="small"
                                        style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px"}}
                                        label={option}
                                    />
                                ))
                            }
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option}
                                </React.Fragment>
                            )}
                            name="tag"
                            onChange={(event, value) => {
                                this.props.onChange(event, value, "tag", true)
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    // className="country_selectField_label"
                                    label={TAG}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    name="tag"
                                />
                            }
                        />
                        <TextField variant="outlined" ref={(input) => this.state.orderId = input} label={ORDER_ID}
                            InputLabelProps={{ shrink: true }} autoComplete="no" name="orderid" className="rightColumn"
                            value={this.getValue("orderid")} onChange={(event) => {
                                this.props.onChange(event)
                            }}
                        />
                    </div>
                    <div className='row-flexbox'>
                        <TextField variant="outlined" className="leftColumn" label={NAME} InputLabelProps={{ shrink: true }}
                            autoComplete="no" name="name" value={this.getValue("name")} onChange={(event) => {
                                this.props.onChange(event)
                            }} />
                        <TextField variant="outlined" className="rightColumn" label={ADDRESS}
                                   InputLabelProps={{ shrink: true }} autoComplete="no" name="address"
                                   value={this.getValue("address")} onChange={(event) => {
                            this.props.onChange(event)
                        }} />
                    </div>
                    <div className='row-flexbox'>
                        <TextField variant="outlined" className="firstOneThirdColumn" label={CITY}
                            InputLabelProps={{ shrink: true }} autoComplete="no" name="city"
                            value={this.getValue("city")} onChange={(event) => {
                                this.props.onChange(event)
                            }}
                        />
                        <Autocomplete
                            multiple
                            options={this.props.stateAndProvinceMapping.map(state => state.state)}
                            getOptionLabel={(entry) => entry}
                            autoHighlight={true}
                            className={'oneThirdColumn'}
                            value={this.getValue("state", [])}
                            renderTags={(value, getTagProps) =>
                                value.map((option) => (
                                    <Chip
                                        variant="outlined"
                                        size="small"
                                        style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px"}}
                                        label={option}
                                    />
                                ))
                            }
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option}
                                </React.Fragment>
                            )}
                            name="state"
                            onChange={(event, value) => {
                                this.props.onChange(event, value, "state", true)
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    // className="country_selectField_label"
                                    label={STATE}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    name="state"
                                />
                            }
                        />
                        <Autocomplete
                            options={COUNTRY_OPTIONS}
                            getOptionLabel={(option) => option}
                            className="country_selectField"
                            name="country"
                            onChange={(event, value) => {
                                this.props.onChange(event, value, "country", true)
                            }}
                            value={this.getValue(("country"))}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    // className="country_selectField_label"
                                    label={COUNTRY}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    name="country"
                                />
                            }
                        />
                    </div>
                    <div className='row-flexbox'>
                        <TextField variant="outlined" className="leftColumn" label={SKU} InputLabelProps={{ shrink: true }}
                            autoComplete="no" name="sku" value={this.getValue("sku")} onChange={(event) => {
                                this.props.onChange(event)
                            }}
                        />
                        <TextField variant="outlined" className="rightColumn" label={MARKETPLACE}
                            InputLabelProps={{ shrink: true }} autoComplete="no" name="marketplace"
                            value={this.getValue("marketplace")} onChange={(event) => {
                                this.props.onChange(event)
                            }}
                        />
                    </div>
                    <div className='row-flexbox'>
                        <Autocomplete
                            options={this.props.carriers.map(carriers => carriers.name)}
                            getOptionLabel={(option) => option}
                            className="firstOneThirdColumn"
                            name="carrier"
                            onChange={(event, value) => {
                                this.props.onChange(event, value, "carrier", true)
                            }}
                            value={this.getValue("carrier")}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label={CARRIER}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    name="carrier"
                                />
                            }
                        />

                        <TextField variant="outlined" className="oneThirdColumn" label={SHIP_METHOD}
                            InputLabelProps={{ shrink: true }} autoComplete="no" name="shipmethod"
                            value={this.getValue("shipmethod")} onChange={(event) => {
                                this.props.onChange(event)
                            }}
                        />
                        <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label={"Prime"}
                            className="oneThirdColumn"
                            checked={this.state.isChecked}
                            onChange={(event) => {
                                this.props.onChange(event, "", "prime", "")
                                this.setState({isChecked: event.target.checked})
                            }}
                            style={{width: "5vw", marginLeft: "0px"}}
                        />
                    </div>
                    </> : " " }
                    {this.getDateSearchRow()}
                </DialogContent>
                <div className='row-flexbox'>
                    <div className={'filter-action-buttons'}>
                        <Button variant='contained' style={{ marginRight: 10 }} onClick={() => this.props.onClose(false)}>{CANCEL}</Button>
                        <Button
                            variant='contained'
                            onClick={() => {
                                if(!this.state.filterError)
                                    this.props.onClose(true)
                            }}
                            color="primary"
                            disabled={((this.props.shipmentStatus===UNSHIPPED)&&(this.state.shippedFromDateError||this.state.shippedToDateError||this.state.shippedShipByDateError||this.state.shippedDateOrderError))||((this.props.shipmentStatus===SHIPPED&&this.state.dateRangeError)||this.state.fromDateNotFilledError||this.state.toDateBeforeFromDateError|| this.state.toDateNotFilledError)}
                        >{APPLY}
                    </Button>
                    </div>
                </div>
            </div>
        )
    }
}

FiltersDialog.propTypes = {
    isOpen: PropTypes.bool,
    onOpen: PropTypes.func,
    onChange: PropTypes.func,
    clearFilters: PropTypes.func,
    onClose: PropTypes.func,
    filtersMap: PropTypes.object

}

FiltersDialog.defaultProps = {
    isOpen: false,
    onOpen: () => { },
    onChange: () => { },
    clearFilters: () => { },
    onClose: () => { },
    filtersMap: {}
}


export default FiltersDialog