export const FULL_TRUCKLOAD = "LTL/FTL Shipment"
export const FULL_TRUCKLOAD_BROKERS = "LTL/FTL Shipment Brokers"
export const FULL_TRUCKLOAD_CARRIERS = "LTL/FTL Shipment Carriers"
export const FULL_TRUCKLOAD_SETTINGS = "LTL/FTL Shipment Settings"

export const FTL_PAGES = {
    TABLE: "table",
    SETTINGS: "settings",
    SETUP: "setup",
    CREATE: "create",
    UPDATE: "update",
    UPLOAD: "upload",
    PREVIEW: "preview",
    BROKERS: "BROKERS"
}

export const CREATE_FTL = "Create Shipment"
export const CREATE_NEW_BROKER = "New Broker"
export const CREATE_NEW_CARRIER = "New Carrier"
export const FTL_SETUP = "Shipment Setup"
export const ADD_BROKER = "Add Broker"
export const ADD_CARRIER = "Add Carrier"

export const MARK_DRAFT_FTL = "Mark as Draft"
export const APPROVE_FTL = "Approve Truckload"
export const UNAPPROVE_FTL = "Unapprove Truckload"
export const MARK_FTL_UNSHIPPED = "Mark as Unshipped"
export const MARK_FTL_SHIPPED = "Mark as Shipped"
export const MARK_FTL_INCOMPLETE = "Mark as Incomplete"
export const MARK_FTL_COMPLETE = "Mark as Completed"
export const VIEW_DOCUMENTS = "View Documents"
export const VIEW_BOL = "View Bill of Lading"

export const FULL_TRUCKLOAD_TABLE_HEADERS = [
    { id: 'shipDate', text: 'Ship Date' },
    { id: 'receivedDate', text: 'Receive Date' },
    { id: 'deliveryDate', text: 'Delivery Date'},
    { id: 'carrierName', text: 'Carrier Name' },
    { id: 'shipFrom', text: 'Ship From' },
    { id: 'shipTo', text: 'Ship To' },
    { id: 'status', text: 'Status' },
]

export const FULL_TRUCKLOAD_BROKERS_TABLE =[
    { id: 'name', text: 'companyName' },
    { id: 'contactName', text: 'contactName' },
    { id: 'email', text: 'email' },
    { id: 'phoneNumber', text: 'phoneNumber' },
    { id: 'accountNumber', text: 'accountNumber' },
    { id: 'addressLine1', text: 'addressLine1' },
]

export const FULL_TRUCKLOAD_CARRIERS_TABLE =[
    { id: 'companyName', text: 'companyName' },
    { id: 'contactName', text: 'contactName' },
    { id: 'email', text: 'email' },
    { id: 'phoneNumber', text: 'phoneNumber' },
    { id: 'location', text: 'location' },
    { id: 'accountNumber', text: 'accountNumber' },
    { id: 'addressLine1', text: 'addressLine1' },
]

export const EDIT = "Edit"
export const DELETE = "Delete"

export const FTL_STEPPER_HEADINGS = ["Shipment Items", "Shipper & Consignee Info", "Documents Info", "Additional Documents"]
export const FTL_STEPPER_HEADING_DESCRIPTIONS = ["Add items to this shipment", "Add shipper and consignee information", "Add information for Bill of Lading and Commercial Invoice", "Add additional documents to this shipment"]

export const STEP_FTL_ITEMS_INDEX = 0
export const STEP_FTL_ADDRESS_INDEX = 1
export const STEP_FTL_BOL_INDEX = 2
export const STEP_FTL_DOCUMENTS_INDEX = 3

export const CANCEL = "Cancel"
export const BACK = "Back"
export const NEXT = "Next"
export const SAVE_DRAFT = "Save As Draft"
export const REQUEST_FOR_APPROVAL = "Request Approval"
export const SAVE = "Save"

export const UPLOAD_BOL_AND_CI = "Upload BOL and CI"
export const DOWNLOAD = "Download"
export const PRINT = "Print"
export const ZOOM_IN = "Zoom In"
export const ZOOM_OUT = "Zoom Out"

export const SHIP = "Ship"
export const DElIVERY = "Delivery"
export const TIME_IN = "Time In"
export const TIME_OUT = "Time Out"

export const SHIPPER = "Shipper"
export const CONSIGNEE = "Consignee"

export const NEW_BROKER = "New Broker"
export const NEW_CARRIER = "New Carrier"

export const FTL_FORM_TABLE_HEADERS = [
    { id: 'shortcode', numeric: false, disablePadding: false, label: 'Shortcode' },
    { id: 'manufacturerPartNum', numeric: false, disablePadding: false, label: 'Manufacturer Part Number' },
    { id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity' },
    { id: 'numOfSkids', numeric: false, disablePadding: false, label: 'Number of Skids' },
    { id: 'sample', numeric: false, disablePadding: false, label: 'Sample' },
];

export const ADD_FTL_ENTRY = "Add an entry to the FTL"
export const REMOVE_FTL_ENTRY = "Remove entry"

export const PARTIES_TO_TRANSACTION_OPTIONS = ["Related", "Non-Related"]
export const DUTIES_TAXES_PAYABLE_BY_OPTIONS = ["Exporter", "Importer", "Other"]
export const SHIPMENT_TERMS_OPTIONS = ["F.O.B", "C&F", "CIF"]
export const CURRENCY_OF_SALE_OPTIONS = ["CAD", "USD"]

export const FTL_STEPPER_DOCUMENTS_HELPER_TEXT = "Select or upload documents to include in this shipment:"
export const FTL_STEPPER_UPLOAD_DOCUMENTS = "Upload additional documents to include in this shipment"
export const DRAG = "Drag"

export const FTL_STATUSES = {
    DRAFT: "Draft",
    REQUEST_APPROVAL: "Approval Requested",
    APPROVED: "Approved",
    SHIPPED: "Shipped",
    RECEIVED: "Received",
    COMPLETED: "Completed"
}

export const FTL_DOC_TYPES = {
    BILL_OF_LADING: "billOfLading",
    COMMERCIAL_INVOICE: "commercialInvoice",
    FINAL_DOCUMENT: "finalDocument"

}

export const COUNTRY_OPTIONS = [
    "Canada",
    "United States",
]

export const FTL_SETUP_HELPER_TEXT = "Add documents and specify shortcodes related to the document, if any:"

export const FTL_SETUP_TABLE_HEADERS = [
    { id: 'shortcodes', numeric: false, disablePadding: false, label: 'Shortcodes', width: "20%" },
    { id: 'attachment', numeric: false, disablePadding: false, label: 'Document', width: "20%" },
    { id: 'type', numeric: false, disablePadding: false, label: 'Type', width: "25%" },
    { id: 'sample', numeric: false, disablePadding: false, label: 'Required for Domestic Shipments', width: "15%" },
    { id: 'sample', numeric: false, disablePadding: false, label: 'Required for International Shipments', width: "20%" },
];

export const FTL_ADDITIONAL_DOCUMENTS_TABLE_HEADERS = [
    { id: 'attachment', numeric: false, disablePadding: false, label: 'Document', width: "20%" },
]

export const FTL_DOCUMENT_TYPES = [
    "EPA Pesticides Disclaimer",
    "EPA Notice Of Arrival (NOA)",
    "USMCA / CUSMA",
    "Product Details",
    "Other"
]

export const REMOVE_DOCUMENT = "Remove Document"
export const ADD_DOCUMENT = "Add Document"
export const BILL_OF_LADING = "Upload signed Bill of Lading (BOL)."
export const COMMERCIAL_INVOICE = "Upload signed Commercial Invoice."

export const FTL_CARRIER_MISSING_MESSAGE = "Missing carrier name, save failed"
export const FTL_BROKER_MISSING_MESSAGE = "Missing broker name, save failed, please select - if no broker is involved"

export const FTL_SAVED_SUCCESSFUL_MESSAGE = "FTL saved"
export const FTL_UPDATED_SUCCESSFUL_MESSAGE = "FTL updated"
export const FTL_MARKED_AS_DRAFT_MESSAGE = "FTL marked as draft"
export const FTL_UNAPPROVED_MESSAGE = "FTL unapproved"
export const FTL_MARKED_AS_UNSHIPPED_MESSAGE = "FTL marked as unshipped"
export const FTL_MARKED_AS_INCOMPLETE_MESSAGE = "FTL marked as incomplete"
export const FTL_MARKED_AS_COMPLETE_MESSAGE = "FTL marked as complete"
export const FTL_MARKED_AS_SHIPPED_MESSAGE = "FTL marked as shipped"
export const FTL_APPROVED_MESSAGE = "FTL approved"