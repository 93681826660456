import React from "react";
import {
    Button, Checkbox,
    FormControlLabel,
    IconButton,
    Step,
    StepButton,
    Stepper,
    TextField,
    Tooltip,
    Switch,
    Paper,
    Typography, Divider, Toolbar
} from "@material-ui/core";
import {
    STEPPER_HEADINGS,
    STEPPER_HEADING_DESCRIPTIONS,
    STEP_CUSTOMER_INFO_INDEX,
    STEP_CUSTOMER_PRODUCTS_INDEX, COUNTRY_OPTIONS,
} from "./constants";

import "./ManageCustomerStepper.css"
import {
    BACK,
    CANCEL,
    NEXT,
} from "../../automationrules/constants";
import { SAVE } from "../../global/constants";
import CustomToolbar from "../../global/CustomToolbar";
import InputAdornment from "@material-ui/core/InputAdornment";


//icons
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import PhoneIcon from '@material-ui/icons/Phone';
import PropTypes from "prop-types";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";

import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteStepper from "../../global/DeleteStepper";
import {ArrowBack, ArrowForward} from "@material-ui/icons";
import {CURRENCY_TYPE_OPTIONS} from "./constants";


class ManageCustomerStepper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            name: this.getValue('name'),
            addressLine1: this.getValue('addressLine1'),
            addressLine2: this.getValue('addressLine2'),
            city: this.getValue('city'),
            phone: this.getValue('phone'),
            province: this.getValue('province'),
            postalCode: this.getValue('postalCode'),
            country: this.getValue('country'),
            email: this.getValue('email'),
            customerShortcode: this.getValue('customerShortcode'),
            customerId: this.getValue('customerId'),
            currencyCode: this.getValue("currencyCode"),
            products: this.getValue('products'),
            invoiceOrderEmailTemplate: this.getValue('invoiceOrderEmailTemplate'),
            missingError: false,
            isDelete: {},
            shortcodeError: {},
            allItems: [],
            subject: this.getValue('subject'),
            from: this.getValue('from'),
            body: this.getValue('body'),
            label: this.getValue('label'),
            ccEmails: this.getValue("ccEmails"),
            bccEmails: this.getValue("bccEmails"),
        }
    }

    componentDidMount() {
        this.setState({ allItems: this.props.packages.concat(this.props.products) })
    }


    getValue = (key) => {
        // todo do something if key is products
        if (this.props.addingCustomer && (key === 'products' || key === 'bccEmails' || key === 'ccEmails'))
            return []
        if (this.props.addingCustomer)
            return ''
        if (!this.props.addingCustomer && key === 'products') {
            return this.props.editingCustomer?.customerProducts?.sort((a, b) => { return a.shortcode.toLowerCase().localeCompare(b.shortcode.toLowerCase()) })
        }
        return this.props.editingCustomer[key]
    }

    setStep = (index) => {
        this.setState({
            activeStep: index,

        });
    };

    handleTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    dynamicChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    getInvoiceOrderTemplate = () => {
        let arr = this.props.emailTemplates.filter((it) => it.templateName === this.state.invoiceOrderEmailTemplate)
        if (arr && arr[0]){
            return arr[0]
        }
        return null
    }


    handleBack = () => {
        // if they are on the first step
        if (this.state.activeStep === 0) {
            this.props.handleClose()
        }
        else {
            this.setState({
                activeStep: this.state.activeStep - 1,
            });
        }

    };

    getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case STEP_CUSTOMER_INFO_INDEX:
                return this.firstStepContent()
            case STEP_CUSTOMER_PRODUCTS_INDEX:
                return this.secondStepContent()
        }
    }


    addProductEntry = () => {
        let products = this.state.products ? this.state.products : []
        products.push({ shortcode: "", price: "", customerPartNumber: ""})
        this.setState({
            products: products
        }
        )

    }

    removeProductEntry = (index) => {
        const { products } = this.state
        products.splice(index, 1)
        this.setState({
            products: products
        })
    }



    firstStepContent = () => {
        return (
            <>
                <div className='stepper-page-one-grid'>
                    <div>
                        <div className='rowSpecCustomers'>
                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="Name"
                                InputLabelProps={{ shrink: true }}
                                name='name'
                                value={this.state.name}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.name === ''}
                            />
                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="Phone"
                                InputLabelProps={{ shrink: true }}
                                name='phone'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PhoneIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                value={this.state.phone}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.phone === ''}
                            />
                        </div>


                        <div className='rowSpecCustomers'>
                            <TextField
                                variant='outlined'
                                label="Address Line 1"
                                className='single-line-field'
                                InputLabelProps={{ shrink: true }}
                                name='addressLine1'
                                value={this.state.addressLine1}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.addressLine1 === ''}
                            />
                        </div>

                        <div className='rowSpecCustomers'>
                            <TextField
                                variant='outlined'
                                label="Address Line 2"
                                className='single-line-field'
                                InputLabelProps={{ shrink: true }}
                                name='addressLine2'
                                value={this.state.addressLine2}
                                onChange={this.handleTextChange}
                            />
                        </div>
                        <div className='rowSpecCustomers'>
                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="City"
                                InputLabelProps={{ shrink: true }}
                                name='city'
                                value={this.state.city}
                                onChange={this.handleTextChange}
                                required
                                error={ this.state.city === ''}
                            />
                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="State or Province"
                                InputLabelProps={{ shrink: true }}
                                name='province'
                                value={this.state.province}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.province === ''}
                            />
                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="Zip or Postal Code"
                                InputLabelProps={{ shrink: true }}
                                name='postalCode'
                                value={this.state.postalCode}
                                onChange={this.handleTextChange}
                                required
                                error={ this.state.postalCode === ''}
                            />
                        </div>
                        <div className='rowSpecCustomers'>
                            <Autocomplete
                                required
                                options={COUNTRY_OPTIONS}
                                getOptionLabel={(option) => option}
                                value={this.state.country}
                                onChange={(e, value) => this.handleTextChange({
                                    target: {
                                        name: "country",
                                        value: value !== null ? value : ''
                                    }
                                })}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Country"
                                        variant="outlined"
                                        error={this.state.country === ''}
                                        InputLabelProps={{shrink: true}}
                                    />
                                )}
                                className='input-text-field'
                            />

                        </div>
                        <div className='rowSpecCustomers'>
                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="Customer Shortcode"
                                InputLabelProps={{ shrink: true }}
                                name='customerShortcode'
                                value={this.state.customerShortcode}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.customerShortcode === ''}
                            />

                            <Autocomplete
                                required
                                options={CURRENCY_TYPE_OPTIONS}
                                getOptionLabel={(option) => option}
                                value={this.state.currencyCode}
                                onChange={(e, value) => this.handleTextChange({
                                    target: {
                                        name: "currencyCode",
                                        value: value !== null ? value : ''
                                    }
                                })}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Customer Currency"
                                        variant="outlined"
                                        error={this.state.currencyCode === ''}
                                        InputLabelProps={{shrink: true}}
                                    />
                                )}
                                className='input-text-field'
                            />
                        </div>

                        <Divider className='row-divider' />

                        <div className='rowSpecCustomers'>

                            <TextField
                                className='input-text-field-large'
                                variant='outlined'
                                label='Email'
                                InputLabelProps={{ shrink: true }}
                                name='email'
                                value={this.state.email}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MailOutlineIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.email === ''}
                            />

                            <Autocomplete
                                required
                                options={this.props.emailTemplates}
                                getOptionLabel={option => option.templateName}
                                 defaultValue={this.getInvoiceOrderTemplate()}
                                style={{ width: 225}}
                                onChange={(event, value) => {
                                    if(value !== null){
                                        this.dynamicChange('invoiceOrderEmailTemplate', value.templateName !== null ? value.templateName : '')
                                    } else{
                                        this.dynamicChange('invoiceOrderEmailTemplate', '')
                                    }
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        className='input-text-field'
                                        label="Email Template"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        margin="normal"
                                        error={this.state.invoiceOrderEmailTemplate === ''}
                                    />
                                )}
                            />

                        </div>
                        {
                            this.state.ccEmails?.map((ccEmail,index)=>(
                                <div className='rowSpecCustomers'>

                                    <TextField
                                        className='input-text-field-large'
                                        variant='outlined'
                                        label='CC Email'
                                        InputLabelProps={{ shrink: true }}
                                        name='cc email'
                                        value={ccEmail}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <MailOutlineIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(e)=>this.editCCEmails(e.target.value,index,"cc")}
                                        required
                                        error={ccEmail === ''}
                                    />
                                    <IconButton onClick={() => this.deleteEmail(index,"cc")}>
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            ))
                        }
                        {
                            this.state.bccEmails?.map((bccEmail,index)=>(
                                <div className='rowSpecCustomers'>

                                    <TextField
                                        className='input-text-field-large'
                                        variant='outlined'
                                        label='BCC Email'
                                        InputLabelProps={{ shrink: true }}
                                        name='bcc email'
                                        value={bccEmail}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <MailOutlineIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(e)=>this.editCCEmails(e.target.value,index,"bcc")}
                                        required
                                        error={bccEmail === ''}
                                    />

                                    <IconButton onClick={() => this.deleteEmail(index,"bcc")}>
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            ))
                        }
                        <div className='rowSpecCustomers'>
                            <Tooltip title="Add a CC email">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton onClick={() => this.addEmails("cc")}>
                                        <AddCircleIcon fontSize="small" color='secondary' />
                                    </IconButton>
                                    <span>Add CC</span>
                                </div>
                            </Tooltip>
                            <Tooltip title="Add a BCC email">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton onClick={()=>this.addEmails("bcc")}>
                                        <AddCircleIcon fontSize="small" color='secondary' />
                                    </IconButton>
                                    <span>Add BCC</span>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    editCCEmails= (newEmail,targetIndex,emailType) =>{

        if (emailType === 'cc') {
            // Create a new array with the updated email
            const ccEmailsCopy = this.state.ccEmails.map((email,index)=>{
                if (index === targetIndex) {
                    return newEmail
                }
                return email
            })
            this.setState({
                ccEmails: ccEmailsCopy
            })
        }
        else if (emailType === 'bcc') {
            // Create a new array with the updated email
            const bccEmailsCopy = this.state.bccEmails.map((email,index)=>{
                if (index === targetIndex) {
                    return newEmail
                }
                return email
            })
            this.setState({
                bccEmails: bccEmailsCopy
            })
        }
    }



    addEmails = (emailType) => {
        if (emailType == "cc") {
            let ccEmailsCopy = this.state.ccEmails || []
            ccEmailsCopy.push("")
            this.setState({
                ccEmails: ccEmailsCopy
            })
        }
        else if (emailType == "bcc") {
            let bccEmailsCopy = this.state.bccEmails || []
            bccEmailsCopy.push("")
            this.setState({
                bccEmails: bccEmailsCopy
            })
        }
    }

    deleteEmail = (targetIndex, emailType) => {
        if (emailType === "cc") {
            const ccEmailsCopy = this.state.ccEmails.filter((_,index)=>
                index !== targetIndex
            )
            this.setState({
                ccEmails: ccEmailsCopy
            })
        }
        else if (emailType === "bcc") {
            const bccEmailsCopy = this.state.bccEmails.filter((_,index)=>
                index !== targetIndex
            )
            this.setState({
                bccEmails: bccEmailsCopy
            })
        }
    }


    checkDuplicateShortcode = () => {
        return this.state.products.some(product =>
            !product.shortcode ||
            !product.price ||
            !product.customerPartNumber
        )
    }

    secondStepContent = () => {
        return (
            <div className='stepper-page-two-grid' key={'this'}>
                {this.state.products?.map((product, index) => {
                    return (
                        <React.Fragment>
                            <div></div>
                            <div key={'shortcode autocomplete' + index}>
                                <Autocomplete
                                    options={this.state.allItems?.sort((a, b) => { return a.shortcode.toLowerCase().localeCompare(b.shortcode.toLowerCase()) })}
                                    getOptionLabel={(product) => product.shortcode}
                                    autoHighlight={true}
                                    name="shortcode"
                                    className='input-text-field'
                                    value={product}
                                    onChange={(event, value) => {
                                        this.handleProductShortcodeChange(value, index)
                                    }
                                    }
                                    blurOnSelect={true}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            error={product.shortcode === ''}
                                            helperText={this.state.shortcodeError[index] ? "Duplicated shortcode" : null}
                                            label="Shortcode"
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            name="shortcode"
                                            required={product}
                                        />
                                    }
                                />

                            </div>
                            <div key={'price field ' + index}>
                                <TextField
                                    className='input-text-field'
                                    variant='outlined'
                                    label="Price"
                                    InputLabelProps={{ shrink: true }}
                                    name='price'
                                    value={product.price}
                                    // disabled={!("price" in product)}
                                    inputProps={{ style: { textAlign: "right" } }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AttachMoneyIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => this.handleProductPriceChange(e, index)}
                                    onBlur={(e) => this.formatPrice(e.target.value, index)}
                                    error={product.price === ''}
                                    required
                                />
                            </div>
                            <div key={'customerpartnumber field' + index}>
                                <TextField
                                    className='input-text-field'
                                    variant='outlined'
                                    label="Customer Part Number"
                                    InputLabelProps={{ shrink: true }}
                                    name='customerPartNumber'
                                    value={product.customerPartNumber}
                                    onChange={(e) => this.handleCustomerPartNumberChange(e, index)}
                                    error={product.customerPartNumber === ''}
                                    required
                                />
                            </div>
                            <div className={!this.state.isDelete[index] ? "delete-row-step-one" : ''}>
                                <DeleteStepper
                                    handleDeleteFalse={this.handleDeleteFalse}
                                    handleDeleteTrue={this.handleDeleteTrue}
                                    handleDelete={() => this.removeProductEntry(index)}
                                    index={index}
                                    isDelete={this.state.isDelete[index]}
                                />
                            </div>
                        </React.Fragment>
                    )
                })}

                {/*default adding row*/}
                <div></div>
                <div ></div>
                <div style={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                    <IconButton onClick={this.addProductEntry}>
                        <AddCircleIcon
                            fontSize="small"
                            color='secondary'
                        />
                    </IconButton>
                    Add customer products
                </div>
                <div></div>
                <div />
                <div />

            </div>
        )
    }



    handleDeleteTrue = (index) => {
        let newIsDelete = this.state.isDelete
        newIsDelete[index] = true
        this.setState({ isDelete: newIsDelete })
    }

    handleDeleteFalse = (index) => {
        let newIsDelete = this.state.isDelete
        newIsDelete[index] = false
        this.setState({ isDelete: newIsDelete })
    }

    handleProductShortcodeChange = (value, index) => {
        const { products } = this.state

        let shortcodes = products.map(product => product.shortcode)
        if (shortcodes.includes(value?.shortcode)) {
            let newShortcodeError = this.state.shortcodeError
            newShortcodeError[index] = true
            this.setState({
                shortcodeError: newShortcodeError
            })
        } else if (this.state.shortcodeError[index]) {
            let newShortcodeError = this.state.shortcodeError
            newShortcodeError[index] = false
            this.setState({
                shortcodeError: newShortcodeError
            })
        }

        products[index].shortcode = value && value.shortcode ? value.shortcode : ""
        products[index].customerPartNumber = ""
        products[index].price = ""

        this.setState({
            products: products
        })
    }

    handleProductPriceChange = (e, index) => {
        const { products } = this.state

        products[index].price = e.target.value

        this.setState({
            products: products
        })
    }

    formatPrice = (price, index) => {
        const { products } = this.state
        if (Number(price) || Number(price) === 0) {
            products[index].price = Number(price).toFixed(2).toString();
            this.setState({
                products: products
            })
        }
    }

    handleCustomerPartNumberChange = (e, index) => {
        const { products } = this.state

        products[index].customerPartNumber = e.target.value

        this.setState({
            products: products
        })
    }


    checkNext = () => {
        return (this.state.name === '' || this.state.phone === '' || this.state.addressLine1 === '' || this.state.city === '' ||
            this.state.province === '' || this.state.postalCode === '' || this.state.country === '' || this.state.email === '' || this.state.invoiceOrderEmailTemplate === '' ||
            this.state.customerShortcode === '' || this.state.currencyCode === '' ||
            this.state.activeStep === 2  ||
            (this.state.activeStep === 1 && this.checkDuplicateShortcode()))
    }


    handleNext = () => {
        if (this.state.activeStep === STEPPER_HEADINGS.length - 1) {
            // todo error checking
            this.props.handleSave(this.props.addingCustomer, this.state)
        }
        else {
            this.setState({
                activeStep: this.state.activeStep + 1,
            }
            );
        }
    }

    render() {

        let headerButtons
        if (this.props.addingCustomer) {
            headerButtons = [{
                icon: <ArrowBack />,
                handler: this.props.handleClose,
                title: this.props.backTitle
            },
                {
                    icon: <ArrowForward />,
                    handler: () => this.handleNext(STEPPER_HEADINGS.length),
                    title: NEXT
                }
            ]
            if (this.state.activeStep === STEPPER_HEADINGS.length - 1 || this.checkNext()) {
                headerButtons[1].disabled = true
            }
        }
        else {
            headerButtons = [{
                icon: <ArrowBack />,
                handler: this.handleBack,
                title: this.state.activeStep !== 0 ? BACK : this.props.backTitle
            },
            {
                icon: <SaveIcon />,
                handler: () => this.props.handleSave(this.props.addingCustomer, this.state),
                title: 'Save'
            },
                {
                    icon: <ArrowForward />,
                    handler: () => this.handleNext(STEPPER_HEADINGS.length),
                    title: NEXT
                }
            ]
            if (this.checkNext()) {
                headerButtons[1].disabled = true
            }
            if (this.state.activeStep === STEPPER_HEADINGS.length - 1 || this.checkNext()) {
                headerButtons[2].disabled = true
            }
        }
        return (
            <>
                <CustomToolbar
                    creatingOrder={this.props.creatingOrder}
                    title={this.props.title}
                    createTitle={this.props.createTitle}
                    backTitle={this.props.backTitle}
                    handleClose={this.handleBack}
                    headerButtons={headerButtons}
                />
                <Stepper activeStep={this.state.activeStep} alternativeLabel>
                    {STEPPER_HEADINGS.map((label, index) => (
                        <Step className='step' key={label}>
                            <StepButton
                                onClick={() => {
                                    this.setStep(index);
                                }}
                                optional={
                                    <Typography variant='caption'>
                                        {STEPPER_HEADING_DESCRIPTIONS[index]}
                                    </Typography>
                                }
                            >
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>

                <div className='customer-stepper-align'>

                    {this.getStepContent(this.state.activeStep)}
                    <div>
                        <Button
                            className={'customer-save-invoice-query-button'}
                            variant='contained'
                            onClick={this.handleBack}
                        >
                            {this.state.activeStep === 0 ? CANCEL : BACK}
                        </Button>

                        <Button
                            className={'customer-save-invoice-query-button'}
                            variant='contained'
                            color='primary'
                            onClick={() => this.handleNext(STEPPER_HEADINGS.length)}
                            disabled={this.checkNext()}
                        >
                            {this.state.activeStep === STEPPER_HEADINGS.length - 1 ? SAVE : NEXT}
                        </Button>
                    </div>

                </div>
            </>
        );
    }
}


// todo prop types

ManageCustomerStepper.propTypes = {
    addingCustomer: PropTypes.bool,
    products: PropTypes.array,
    packages: PropTypes.array
}

ManageCustomerStepper.defaultProps = {
    addingCustomer: true,
    products: [],
    packages: []

}
export default ManageCustomerStepper