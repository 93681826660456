import {
    STORE_CARRIERS,
    STORE_SHIPPING_TEMPLATES,
    STORE_INVENTORY_AUTO_ALLOCATION,
    STORE_ADDRESSES,
    UPDATE_CARRIERS,
    UPDATE_ADDRESSES,
    STORE_LINKED_ADDRESSES,
    UPDATE_CARRIERS_EDIT,
    UPDATE_CARRIERS_DELETE,
    STORE_PRODUCTS,
    STORE_SUPPLIERS,
    STORE_LOCATIONS,
    STORE_COMPANIES,
    STORE_PRINTERS,
    STORE_TAGS,
    STORE_LOGO,
    STORE_UPDATED_PRODUCT,
    STORE_PACKAGES,
    STORE_UPDATED_PACKAGE,
    STORE_LISTINGS,
    STORE_PRICING_LISTINGS,
    STORE_SALES_RANK,
    STORE_BUY_BOX_PRICES,
    STORE_RELATED_PRODUCTS,
    STORE_UPDATED_LISTING,
    STORE_UPDATED_PRICING_LISTING,
    STORE_UNMAPPED_PRODUCTS,
    STORE_MARKETPLACES,
    UPDATE_MARKETPLACES,
    UPDATE_MARKETPLACES_EDIT,
    UPDATE_MARKETPLACES_DELETE,
    AUTOMATION_SETTINGS,
    STORE_ALL_CATEGORY,
    STORE_ALL_HS_CODE,
    STORE_COUNTRY_CODES,
    STORE_AMZ_FEEDS,
    STORE_EMAIL_TEMPLATES,
    STORE_EMAIL_TEMPLATE_TYPES,
    STORE_AVAILABLE_LOCATIONS,
    STORE_PURCHASE_ORDER_EMAIL_TEMPLATE,
    STORE_INACTIVE_PRODUCTS,
    STORE_DELETED_PRODUCTS,
    STORE_ARCHIVED_LABELS,
    STORE_ARCHIVED_LABELS_RETURNED,
    STORE_PRINT_CONFIG,
    STORE_ACTIVE_PRODUCTS,
    STORE_BARCODES,
    STORE_UPDATED_BARCODE,
    STORE_REMOVED_BARCODE,
    STORE_DELETE_LISTING,
    STORE_CARRIER_NAMES,
    STORE_ALL_BANK_ACCOUNTS,
    STORE_INVENTORY_ALLOCATION,
    STORE_HISTORICAL_DATA,
    CLEAR_HISTORICAL_DATA,
    STORE_SHIPRUSH_FILE_NAMES,
    CLEAR_SHIPRUSH_SPREADSHEETS_INFO,
    STORE_RESPONSE_MESSAGE,
    STORE_CARRIER_OPERATION_RESPONSE,
    CLEAR_CARRIER_OPERATION_RESPONSE,
    STORE_UPDATED_BANK,
    STORE_UPDATED_BANK_WITH_PREVIEW,
} from "../../constants/settings";
import {SHIPRUSH} from "../../../components/settings/ManageHistoricalData/constants";
const initialState = {
    carriers: [],
    carrierNames: [],
    addresses: [],
    linkedAddresses: [],
    products: [],
    suppliers: [],
    locations: [],
    availableLocations: [],
    companies: [],
    printers: [],
    tags: [],
    logos: null,
    packages: [],
    listings: [],
    pricingListings: [],
    mainSalesRank: [],
    subSalesRank: [],
    relatedProductsMainSalesRank:[],
    buyBoxPrices: [],
    relatedProductBuyBoxPrices:[],
    relatedProducts:[],
    unmappedListings: [],
    marketplaces: [],
    sessionID: "",
    category: [],
    countries: [],
    bankAccounts: [],
    historicalData: "",
    historicalDataCount: 0,
    shiprushFileNames:[],
    shippingTemplates: [],
    autoApply: false,
    responseMessage: "",
    carrierOperationResponse: "",
};

const addPreviewToBank = (updatedBankItem, oldBanks) => {
    let filePreviewInfo = updatedBankItem.files.pop()
    let previewedFileIndex = filePreviewInfo.indexOfPreviewedFile
    let previewData = new Uint8Array(filePreviewInfo.previewedFile)
    let blobData = new Blob([previewData])
    updatedBankItem.files[previewedFileIndex]["localURL"] = URL.createObjectURL(blobData)
    updatedBankItem.files[previewedFileIndex]['byteArray'] = previewData

    let returnToUpdate = oldBanks.find((bankItem) => { return bankItem.bankAccountId === updatedBankItem.bankAccountId })
    returnToUpdate.files[previewedFileIndex] = updatedBankItem.files[previewedFileIndex]

    return updateList1(returnToUpdate, oldBanks, "bankAccountId")
}

const updateAfterEdit = (carrier, carrierList) => {
    let carriers = [];
    for (let i = 0; i < carrierList.length; i++) {
        if (carrierList[i].carrierId === carrier.carrierId) {
            carriers = [...carriers, carrier]
        } else {
            carriers = [...carriers, carrierList[i]]
        }
    }
    return carriers
}

const updateCarrierAfterDelete = (carrierId, isDeleted, carrierList) => {
    let carriers = []
    if (isDeleted) {
        for (let i = 0; i < carrierList.length; i++) {
            if (carrierList[i].carrierId !== carrierId) {
                carriers = [...carriers, carrierList[i]]
            }
        }
        return carriers
    } else {
        return carrierList
    }
}

const updateProductsList = (product, oldList, isDelete, isCreate) => {
    let newProducts = []
    if (isCreate) {
        newProducts = oldList
        newProducts.push(product)
    } else if (isDelete) {
        for (let prod of oldList) {
            if (prod.productId !== product.productId) {
                newProducts.push(prod)
            }
        }
    } else {
        for (let prod of oldList) {
            if (prod.productId === product.productId) {
                newProducts.push(product)
            } else {
                newProducts.push(prod)
            }
        }
    }
    return newProducts;
}

const convertB64ToFileFormat = (logo) => {
    if (logo.length === 0)
        return
    let previewData = new Uint8Array(logo)
    let blobData = new Blob([previewData])
    let localStorageUrl = URL.createObjectURL(blobData)
    return { localURL: localStorageUrl, displayName: "Current Logo", byteArray: logo, type: "" }
}

const updateListings = (oldListings, updatedListing) => {
    let listings = [];
    if (updatedListing.length > 0) {
        for (let i = 0; i < oldListings.length; i++) {
            if (updatedListing[0].webstoreProductId === oldListings[i].webstoreProductId) {
                listings = [...listings, updatedListing[0]]
            } else {
                listings = [...listings, oldListings[i]]
            }
        }
        return listings;
    }
    return oldListings
}

const updateBarcodes = (oldBarcodes, newBarcodeRow) => {
    if (!oldBarcodes || oldBarcodes.length < 1) {oldBarcodes = []}
    let index = oldBarcodes.findIndex(barcode => {
        return barcode.shortcode === newBarcodeRow.shortcode
    })
    if (index === -1) {
        oldBarcodes.push(newBarcodeRow)
    } else {
        oldBarcodes.splice(index, 1, newBarcodeRow)
    }
    return [...oldBarcodes] // returning a different array is necessary to trigger React to update
}

const removeBarcode = (oldBarcodes, shortcode, quantity) => {
    let barcodes = []
    if (oldBarcodes) {
        for (let i = 0; i < oldBarcodes.length; i++) {
            if (oldBarcodes[i].shortcode !== shortcode || oldBarcodes[i].quantity !== quantity) {
                barcodes.push(oldBarcodes[i])
            }
        }
    }
    return barcodes
}

const updateList = (pkg, oldList) => {
    let newList = [];
    let exists = oldList.findIndex(item => item.boxId === pkg.boxId);
    if (exists === -1) {
        newList = oldList;
        newList.push(pkg);
    } else {
        oldList.map((item, index) => {
            if (index === exists) {
                newList[index] = pkg;
            } else {
                newList[index] = item;
            }
        })
    }
    return newList;
}
export const updateList1 = (newItem, oldList, key) => {
    let newList = [...oldList]; // Create a copy of the old list

    // Check if the newItem already exists in the oldList
    let exists = oldList.findIndex(item => item[key] === newItem[key]);

    if (exists === -1) {
        // If not found, add the newItem to the end of the list
        newList.push(newItem);
    } else {
        // If found, replace the existing item with newItem
        newList[exists] = newItem;
    }

    return newList;
}


const updateMarketplacesAfterEdit = (marketplace, marketplaceList) => {
    let marketplaces = [];
    for (let i = 0; i < marketplaceList.length; i++) {
        if (marketplaceList[i].marketplaceId === marketplace.marketplaceId) {
            marketplaces = [...marketplaces, marketplace]
        } else {
            marketplaces = [...marketplaces, marketplaceList[i]]
        }
    }
    return marketplaces
}

const updateMarketplaceAfterDelete = (marketplaceId, isDeleted, marketplaceList) => {
    let marketplaces = []
    if (isDeleted) {
        for (let i = 0; i < marketplaceList.length; i++) {
            if (marketplaceList[i].marketplaceId !== marketplaceId) {
                marketplaces = [...marketplaces, marketplaceList[i]]
            }
        }
        return marketplaces
    } else {
        return marketplaceList
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case STORE_CARRIERS:
            return {
                ...state,
                carriers: action.carriers
            }
        case STORE_SHIPPING_TEMPLATES:
            return{
                ...state,
                shippingTemplates: action.shippingTemplates
            }
        case STORE_INVENTORY_AUTO_ALLOCATION:
            return{
                ...state,
                autoApply: action.autoApply
            }
        case STORE_CARRIER_NAMES:
            return {
                ...state,
                carrierNames: action.carrierNames
            }
        case STORE_UPDATED_BANK:
            return {
                ...state,
                bankAccounts: updateList1(action.updatedBankItem, state.bankAccounts, 'bankAccountId')
            }
        case STORE_UPDATED_BANK_WITH_PREVIEW:
            return {
                ...state,
                bankAccounts: addPreviewToBank(action.updatedBankItem, state.bankAccounts)
            }
        case STORE_HISTORICAL_DATA:
            // if (action.source == SHIPRUSH && !action.message.includes("all files processed*")) {
            //     const lastIndex = state.historicalData.lastIndexOf("Run");
            //     if (lastIndex !== -1) {
            //         // Extract the part of the string before the last occurrence of "Run"
            //         const result = state.historicalData.substring(0, lastIndex);
            //         return {
            //             ...state,
            //             historicalDataCount: state.historicalDataCount,
            //             historicalData: state.historicalData.substring(0, lastIndex)+"* Run "+state.historicalDataCount+"*"+action.message+"*"
            //         }
            //     }
            // }
            return {
                ...state,
                historicalDataCount: state.historicalDataCount + 1,
                historicalData: state.historicalData+"* Run "+state.historicalDataCount+"*"+action.message+"*"
            }
        case STORE_SHIPRUSH_FILE_NAMES:
            return {
                ...state,
                shiprushFileNames: action.fileNames
            }
        case CLEAR_SHIPRUSH_SPREADSHEETS_INFO:
            return {
                ...state,
                shiprushFileNames: []
            }
        case CLEAR_HISTORICAL_DATA:
            return{
                ...state,
                historicalDataCount: 0,
                historicalData: ""
            }
        case STORE_ADDRESSES:
            return {
                ...state,
                addresses: action.addresses
            }
        case UPDATE_CARRIERS:
            return {
                ...state,
                carriers: [...state.carriers, action.carrier]
            }
        case STORE_CARRIER_OPERATION_RESPONSE:
            return {
                ...state,
                carrierOperationResponse: action.response
            }
        case CLEAR_CARRIER_OPERATION_RESPONSE:
            return {
                ...state,
                carrierOperationResponse: ""
            }
        case UPDATE_ADDRESSES:
            return {
                ...state,
                addresses: [...state.addresses, action.address]
            }
        case STORE_LINKED_ADDRESSES:
            return {
                ...state,
                linkedAddresses: action.linkedAddresses
            }
        case STORE_INVENTORY_ALLOCATION:
            const updatedMarketplaces = state.marketplaces.map((marketplace) => {
                const matchedMarketplace = action.percentages?.percentages?.find(curMarketplace => curMarketplace.marketplaceName === marketplace.marketplaceName);

                if (matchedMarketplace) {
                    return {
                        ...marketplace,
                        percentage: matchedMarketplace.percentage
                    };
                }

                return marketplace;
            });
            return {
                ...state,
                marketplaces: updatedMarketplaces
            }
        case UPDATE_CARRIERS_EDIT:
            return {
                ...state,
                carriers: updateAfterEdit(action.carrier, state.carriers)
            }
        case UPDATE_CARRIERS_DELETE:
            return {
                ...state,
                carriers: updateCarrierAfterDelete(action.carrierId, action.isDeleted, state.carriers)
            }
        case STORE_ALL_BANK_ACCOUNTS:
            return {
                ...state,
                bankAccounts: action.data.bankAccounts
            }
        case STORE_PRODUCTS:
            return {
                ...state,
                products: action.products
            }
        case STORE_UPDATED_PRODUCT:
            return {
                ...state,
                products: updateProductsList(action.updatedProduct, state.products, action.isDelete)
            }

        case STORE_SUPPLIERS:
            return {
                ...state,
                suppliers: action.suppliers
            }
        case AUTOMATION_SETTINGS:
            return {
                ...state,
                automationSetting: action.settings
            }
        case STORE_LOCATIONS:
            return {
                ...state,
                locations: action.locations
            }
        case STORE_AVAILABLE_LOCATIONS:
            return {
                ...state,
                availableLocations: action.availableLocations
            };
        case STORE_COMPANIES:
            return {
                ...state,
                companies: action.companies
            }
        case STORE_PRINTERS:
            return {
                ...state,
                printers: action.printers
            }
        case STORE_TAGS:
            return {
                ...state,
                tags: action.tags
            }
        case STORE_LOGO:
            return {
                ...state,
                logo: convertB64ToFileFormat(action.logo)
            }
        case STORE_PACKAGES:
            return {
                ...state,
                packages: action.pkgs
            }
        case STORE_UPDATED_PACKAGE:
            return {
                ...state,
                packages: updateList(action.updatedPackage, state.packages)
            }
        case STORE_LISTINGS:
            return {
                ...state,
                listings: action.data
            }
        case STORE_PRICING_LISTINGS:
            return{
                ...state,
                pricingListings: action.data
            }
        case STORE_SALES_RANK:
            return{
                ...state,
                mainSalesRank: action.salesRank.mainSalesRank,
                subSalesRank: action.salesRank.subSalesRank,
                relatedProductsMainSalesRank: action.salesRank.relatedProductsMainSalesRank,
        }
        case STORE_BUY_BOX_PRICES:
            return{
                ...state,
                buyBoxPrices: action.buyBoxPrices,
                relatedProductBuyBoxPrices:action.relatedProductBuyBoxPrices
            }
        case STORE_RELATED_PRODUCTS:
            return{
                ...state,
                relatedProducts: action.relatedProducts
            }
        case STORE_BARCODES:
            return {
                ...state,
                barcodes: action.data
            }
        case STORE_UPDATED_LISTING:
            return {
                ...state,
                listings: updateListings(state.listings, action.listing),
            }
        case STORE_UPDATED_PRICING_LISTING:
            return {
                ...state,
                pricingListings: updateListings(state.pricingListings, action.pricingListing),
            }
        case STORE_DELETE_LISTING:
            return {
                ...state,
                listings: state.listings?.filter(listing => listing.webstoreProductId !== action.listingId)
            }
        case STORE_UPDATED_BARCODE:
            return {
                ...state,
                barcodes: updateBarcodes(state.barcodes, action.newBarcodeRow),
            }
        case STORE_REMOVED_BARCODE:
            return {
                ...state,
                barcodes: removeBarcode(state.barcodes, action.shortcode, action.quantity)
            }
        case STORE_UNMAPPED_PRODUCTS:
            return {
                ...state,
                unmappedListings: action.data
            }
        case STORE_ACTIVE_PRODUCTS:
            return {
                ...state,
                activeListings: action.data
            }
        case STORE_INACTIVE_PRODUCTS:
            return {
                ...state,
                inactiveListings: action.data
            }
        case STORE_DELETED_PRODUCTS:
            return {
                ...state,
                deletedListings: action.data
            }
        case STORE_ALL_CATEGORY:
            return {
                ...state,
                category: action.data
            }
        case STORE_COUNTRY_CODES:
            return {
                ...state,
                countries: action.countries
            }
        case STORE_MARKETPLACES:
            return {
                ...state,
                marketplaces: action.marketplaces
            }
        case STORE_RESPONSE_MESSAGE:
            return {
                ...state,
                responseMessage: action.responseMessage
            }
        case UPDATE_MARKETPLACES:
            return {
                ...state,
                marketplaces: [...state.marketplaces, action.marketplace]
            }
        case UPDATE_MARKETPLACES_EDIT:
            return {
                ...state,
                marketplaces: updateMarketplacesAfterEdit(action.marketplace, state.marketplaces)
            }
        case UPDATE_MARKETPLACES_DELETE:
            return {
                ...state,
                marketplaces: updateMarketplaceAfterDelete(action.marketplaceId, action.isDeleted, state.marketplaces)
            }
        case STORE_ALL_CATEGORY:
            return {
                ...state,
                category: action.data
            }
        case STORE_AMZ_FEEDS:
            return {
                ...state,
                feeds: action.data
            }
        case STORE_PRINT_CONFIG:
            return {
                ...state,
                printConfig: action.data
            }
        case STORE_ARCHIVED_LABELS:
            return {
                ...state,
                archivedLabelTrackingNumbers: action.archivedLabelTrackingNumbers
            }
        case STORE_ARCHIVED_LABELS_RETURNED:
            return {
                ...state,
                archivedLabelsReturned: action.hasReturned
            }
        case STORE_EMAIL_TEMPLATES:
            return {
                ...state,
                emailTemplates: action.data
            }
        case STORE_EMAIL_TEMPLATE_TYPES:
            return{
                ...state,
                emailTemplateTypes: action.data
            }
        case STORE_PURCHASE_ORDER_EMAIL_TEMPLATE:
            return{
                ...state,
                hasPurchaseOrderEmailTemplate: action.data
            }
        default:
            return state
    }
}