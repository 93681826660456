import React from "react";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import {CHEQUE_PAGES} from "./constants";
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import withShipment from "../../../withShipment";

import ChequeForm from "./ChequeForm";
import ChequeTable from "./ChequeTable";
import {automationInfoSelector} from "../../../redux/selectors/account";
import {getCompany} from "../../../redux/actions/automationRules";
import {listAllBankAccounts, previewBankFile} from "../../../redux/actions/settings";
import {bankAccountsSelector} from "../../../redux/selectors/settings";
import ChequePreview from "./ChequePreview";
import {
    listAllCheques,
    listTransactionCategories,
    saveCheque,
} from "../../../redux/actions/accounting";
import {chequeSelector, transactionCategoriesSelector,} from "../../../redux/selectors/accounting";
import {statusSelector} from "../../../redux/selectors/global";
import {clearStatus} from "../../../redux/actions/global";
class Cheques extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statusText: '',
            status: null,
            isEditingCheque:[],
            signatureFeedbackBarOpen: false,
            currentPage: CHEQUE_PAGES.TABLE,
            feedbackBarOpen: false,
            error: false,
            companyAddress:[],
            formState: {
                memoo: '',
                payToName: '',
                chequeNumber: '',
                paymentDate: null,
                amount: '',
                amountWords: '',
                category: '',
                bankAccount: null,
            },
            transactionCategories:this.props.transactionCategories
    }
    }

    handleFormChange = (formState) => {
        this.setState({ formState });
    }
    componentDidMount() {
        this.props.getCompany()
        this.props.listAllBankAccounts()
        this.props.listAllCheques()
        this.props.listTransactionCategories()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.status.statusText && prevProps.status.statusText !== this.props.status.statusText){
            this.openFeedbackBar()
        }
        //update state transactionCategories
        if (prevProps.transactionCategories !== this.props.transactionCategories) {
            this.setState({
                transactionCategories : this.props.transactionCategories
            })
        }
        //sort state transactionCategories by displayOrder
        if (prevState.transactionCategories !== this.state.transactionCategories) {
            this.setState({
                transactionCategories : this.state.transactionCategories.sort((a, b) => a.displayOrder - b.displayOrder)
            })
        }




        if(prevProps.automationSettings !== this.props.automationSettings) {
            this.setState({
              companyAddress: this.props?.automationSettings?.companyAddress === undefined ? [] : this.props?.automationSettings?.companyAddress,

            });
        }
    }


    setCurrentPage = (newPage,cheque) => {
        this.setState({
            currentPage: newPage,
            isEditingCheque: cheque
        })
    }

    displayTable = () => {
        return (
            <ChequeTable
                cheques={this.props.cheques}
                bankAccounts={this.props.bankAccounts}
                categories={this.state.transactionCategories}
                setCurrentPage={this.setCurrentPage}
                openFeedbackBar={this.openFeedbackBar}
            />
        )
    }

    displayForm = () => {
        return (
            <ChequeForm
                cheque={this.state.isEditingCheque}
                categories={this.state.transactionCategories}
                companyAddress={this.state.companyAddress}
                setCurrentPage={this.setCurrentPage}
                onFormChange={this.handleFormChange}
            />
        )
    }

    displayPreview = () => {
        return (
            <ChequePreview
                companyAddress={this.state.companyAddress}
                setCurrentPage={this.setCurrentPage}
                formState={this.state.formState}
                previewBankFile={this.props.previewBankFile}
                saveCheque={this.props.saveCheque}
                openSignatureFeedbackBar={this.openSignatureFeedbackBar}

            />
        )
    }

    displayCurrentPage = () => {
        switch (this.state.currentPage) {
            case CHEQUE_PAGES.TABLE:
                return this.displayTable()
            case CHEQUE_PAGES.ADD_CHECK:
                return this.displayForm()
            case CHEQUE_PAGES.PREVIEW:
                return this.displayPreview()
            default:
                console.log("Error: current transactions page doesn't exist")
                return <div>We encountered an error. Please refresh the page.</div>
        }
    }

    openFeedbackBar = () => {
        this.setState({feedbackBarOpen: true})
    }

    openSignatureFeedbackBar = () => {
        this.setState({signatureFeedbackBarOpen: true, statusText : "No Signature Found"})
    }

    closeSignatureFeedbackBar = () => {
        this.setState({ signatureFeedbackBarOpen: false }, () => {
            this.props.clearStatus()
        })
    }

    closeFeedbackBar = () => {
        this.setState({ feedbackBarOpen: false }, () => {
            this.props.clearStatus()
        })
    }

    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar/>
                <FeedbackBar
                    open={this.state.feedbackBarOpen}
                    handleClose={this.closeFeedbackBar}
                    severity={this.props.status?.success ? 'success' : 'error'}
                    message={this.props.status?.statusText}
                />
                <FeedbackBar
                    open={this.state.signatureFeedbackBarOpen}
                    handleClose={this.closeSignatureFeedbackBar}
                    severity={this.state.status ? 'success' : 'error'}
                     message={this.state.statusText}
                />
                {this.displayCurrentPage()}
            </React.Fragment>
        )
    }
}

Cheques.defaultProps = {

}

const mapStateToProps = (state) => ({
    status: statusSelector(state),
    automationSettings: automationInfoSelector(state),
    cheques: chequeSelector(state),
    bankAccounts: bankAccountsSelector(state),
    transactionCategories: transactionCategoriesSelector(state),
})

const actionCreators = {
    clearStatus,
    getCompany,
    listAllBankAccounts,
    saveCheque,
    previewBankFile,
    listAllCheques,
    listTransactionCategories,
}

export default withShipment({
    actionCreators,
    mapStateToProps
}, Cheques);