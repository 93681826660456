import React from 'react'

// Material UI
import {
    Divider,
    TableBody,
    TableFooter,
    TableHead,
    TableSortLabel,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";

// Constants
import {
    NEEDS_REVIEW_STATUS,
    APPROVE_STATUS,
    INVOICE_ITEM_CELLS_LEFT,
    INVOICE_ITEM_CELLS_RIGHT,
    ATTACHMENTS_INFO
} from "./constants";

// Styles
import "./EditInvoices.css"

// Custom Components
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import PDFViewer from "../../global/PDFViewer";
import {IconButton} from "@material-ui/core"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import PropTypes from "prop-types";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import styles from "../../../pages/Dashboard/DashboardFinal/DashboardFinal.module.css";
import DescriptionIcon from "@material-ui/icons/Description";
import FileUpload from "../../global/FileUpload";
import {getByteArray} from "../../global/constants";
import {PACKING_SLIP_INFO} from "../../purchaseOrders/constants";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {Tooltip} from "@mui/material";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";

const CurrencyCodeField = withStyles({
    root: {
        width: "10%",
        paddingLeft: "5px",
        paddingRight: "5px"
    },
})(TextField);

const StyledTextField = withStyles({
    root: {
        paddingLeft: "5px",
        paddingRight: "5px"
    }
})(TextField)

const LeftMostTextField = withStyles({
    root: {
        paddingRight: "5px"
    }
})(TextField)

const RightMostTextField = withStyles({
    root: {
        paddingLeft: "5px",
    },
})(TextField)

const StyledDateField = withStyles({
    root: {
        paddingLeft: "5px",
        paddingRight: "5px",
        width: "20%"
    }
})(KeyboardDatePicker)

class EditInvoices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteIndex: -1
        }
    }
    handleDateChange = (e) => {
        let month = e.getMonth() + 1
        let date = e.getDate()
        let dateString = e.getFullYear() + "-" + (month < 10 ? "0" + month : month) + "-" + (date < 10 ? "0" + date : date)
        this.props.handleChange("date", dateString)
    }

    render() {
        if (!this.props.editing) return (<></>)
        return (<>
            {this.props.viewing && <PDFViewer data={this.props.pdfData} scale={this.props.scale} /> ||
            <div className={"invoiceWrapper"}>
                <div className="infoRow">
                    <LeftMostTextField
                        variant="outlined"
                        label="Supplier Name"
                        InputLabelProps={{ shrink: true }}
                        name="supplierName"
                        value={this.props.editing.supplierName}
                        InputProps={{readOnly: true}}
                        error={!this.props.editing.supplierName}
                        onChange={(e) => this.props.handleChange(e.target.name, e.target.value)}
                    />
                    <StyledTextField
                        variant="outlined"
                        label="PO Number"
                        InputLabelProps={{ shrink: true }}
                        name="poNumber"
                        value={this.props.editing.poNumber}
                        error={!this.props.editing.poNumber}
                        onChange={(e) => this.props.handleChange(e.target.name, e.target.value)}
                        onBlur={(e) => {
                            this.props.handleChange(e.target.name, e.target.value.trim())
                            this.props.handlePONumberChange(e.target.value)
                        }}
                    />
                    <StyledTextField
                        variant="outlined"
                        label="Invoice Number"
                        InputLabelProps={{ shrink: true }}
                        name="invoiceNumber"
                        value={this.props.editing.invoiceNumber}
                        error={!this.props.editing.invoiceNumber}
                        onChange={(e) => this.props.handleChange(e.target.name, e.target.value)}
                    />
                    <StyledDateField
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Date"
                        format="yyyy-MM-dd"
                        value={new Date(this.props.editing.date).setDate(new Date(this.props.editing.date).getDate() + 1)}
                        onChange={this.handleDateChange}
                    />
                    <StyledTextField
                        style={{width:'15%'}}
                        variant="outlined"
                        label="Tax Percentage"
                        InputLabelProps={{ shrink: true }}
                        name="taxPercentage"
                        value={this.props.editing.taxPercentage ?? this.props.defaultTaxPercentage}
                        // error={!this.props.editing.poNumber}
                        onChange={(e) => this.props.handleChange(e.target.name, e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    %
                                </InputAdornment>
                            )
                        }}
                    />
                    <CurrencyCodeField
                        variant="outlined"
                        label="Currency"
                        InputLabelProps={{ shrink: true }}
                        name="currencyCode"
                        value={this.props.editing.supplierInvoiceItems[0]?.currencyCode ?? ""}
                        InputProps={{readOnly: true}}
                        error={!this.props.editing.supplierInvoiceItems[0]?.currencyCode}
                        onChange={(e) => this.props.handleChange(e.target.name, e.target.value)}
                    />
                    <RightMostTextField
                        variant="outlined"
                        label="Invoice Status"
                        InputProps={{readOnly: true}}
                        InputLabelProps={{shrink: true}}
                        name="invoicestatus"
                        value={this.props.editing.status ?? ""}
                    />
                </div>
                <br />
                <Divider className='divider' />
                <div className="lineItems">
                    <p>Line Items</p>
                    {this.props.editing.supplierInvoiceItems?.map((invoiceItem, index) => (
                        <React.Fragment key={index}>
                            <div className="lineItemRow">
                                {INVOICE_ITEM_CELLS_LEFT.map((item, i) => (
                                    <TextField
                                        style={{ width: "10%" }}
                                        key={"item" + i}
                                        variant="outlined"
                                        label={item.label + "*"}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ style: { textAlign: item.rightAlign ? "right" : "left" } }}
                                        InputProps={{ startAdornment: <InputAdornment position="start">{item.addDollar ? "$" : ""}</InputAdornment>, readOnly: item.readOnly }}
                                        name={item.id}
                                        value={item.addDollar ? this.props.formatDollar(invoiceItem[item.id]) : invoiceItem[item.id]}
                                        error={!(item.addDollar ? this.props.formatDollar(invoiceItem[item.id]) : invoiceItem[item.id])}
                                        helperText={this.props.itemError.index === index && this.props.itemError.id === item.id ? "Field is required" : ""}
                                        onChange={(e) => this.props.handleItemChange(e.target.name, e.target.value, index)}
                                        onBlur={(e) => this.props.handleBlurField(e.target.name, e.target.value, index)}
                                    />
                                ))}
                                <div style={{ width: "10%", display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                                    <TextField
                                        style={{ width: "100%" }}
                                        key={"item" + INVOICE_ITEM_CELLS_LEFT.length}
                                        variant="outlined"
                                        label={"Discount*"}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ style: { textAlign: "right" } }}
                                        InputProps={{ startAdornment: <InputAdornment position="start">{"$"}</InputAdornment>, readOnly: false }}
                                        name={'discount'}
                                        value={this.props.formatDollar(invoiceItem['discount'])}
                                        error={!(this.props.formatDollar(invoiceItem['discount']))}
                                        helperText={this.props.itemError.index === index && this.props.itemError.id === 'discount' ? "Field is required" : ""}
                                        onChange={(e) => this.props.handleItemChange(e.target.name, e.target.value, index)}
                                        onBlur={(e) => this.props.handleBlurField(e.target.name, e.target.value, index)}
                                    />
                                    <div className='discountPercentage'>
                                        ({(this.props.formatDollar(invoiceItem['discount']).replaceAll(',','') / (this.props.formatDollar(invoiceItem['unitPrice']).replaceAll(',','') * invoiceItem['quantity']) * 100).toFixed(2)}%)
                                    </div>
                                </div>
                                {INVOICE_ITEM_CELLS_RIGHT.map((item, i) => (
                                    <TextField
                                        style={{ width: "10%" }}
                                        key={"item" + (i + INVOICE_ITEM_CELLS_LEFT.length + 1)}
                                        variant="outlined"
                                        label={item.label + "*"}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ style: { textAlign: item.rightAlign ? "right" : "left" } }}
                                        InputProps={{ startAdornment: <InputAdornment position="start">{item.addDollar ? "$" : ""}</InputAdornment>, readOnly: item.readOnly }}
                                        name={item.id}
                                        value={item.addDollar ? this.props.formatDollar(invoiceItem[item.id]) : invoiceItem[item.id]}
                                        error={!(item.addDollar ? this.props.formatDollar(invoiceItem[item.id]) : invoiceItem[item.id])}
                                        helperText={this.props.itemError.index === index && this.props.itemError.id === item.id ? "Field is required" : ""}
                                        onChange={(e) => this.props.handleItemChange(e.target.name, e.target.value, index)}
                                        onBlur={(e) => this.props.handleBlurField(e.target.name, e.target.value, index)}
                                    />
                                ))}
                                <div style={{ width: "10%", display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                                    <TextField
                                        style={{ width: "100%" }}
                                        key={"item" + INVOICE_ITEM_CELLS_LEFT.length}
                                        variant="outlined"
                                        label={"Tax*"}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ style: { textAlign: "right" } }}
                                        InputProps={{ startAdornment: <InputAdornment position="start">{"$"}</InputAdornment>, readOnly: false }}
                                        name={'tax'}
                                        value={this.props.formatDollar(invoiceItem['tax'])}
                                        error={!(this.props.formatDollar(invoiceItem['tax']))}
                                        helperText={this.props.itemError.index === index && this.props.itemError.id === 'tax' ? "Field is required" : ""}
                                        onChange={(e) => this.props.handleItemChange(e.target.name, e.target.value, index)}
                                        onBlur={(e) => this.props.handleBlurField(e.target.name, e.target.value, index)}
                                    />
                                    <div className='discountPercentage'>
                                        ({(this.props.formatDollar(invoiceItem['tax']).replaceAll(',','') / (this.props.formatDollar(invoiceItem['amount']).replaceAll(',','')) * 100).toFixed(2)}%)
                                    </div>
                                </div>
                                <div style={{width: "205px", textAlign: "left"}}>
                                    {this.state.deleteIndex === index ? <>
                                        <Button onClick={() => this.setState({deleteIndex: -1})}>
                                            Cancel
                                        </Button>
                                        <Button onClick={() => {this.props.handleItemDelete(index); this.setState({deleteIndex: -1})}} variant="contained" startIcon={<DeleteIcon/>}>
                                            Delete
                                        </Button>
                                    </> : <IconButton onClick={() => this.setState({deleteIndex: index})}>
                                        <RemoveCircleIcon fontSize='small' color='error' />
                                    </IconButton>}
                                </div>
                            </div>
                            {index !== this.props.editing.supplierInvoiceItems.length - 1 && <br />}
                        </React.Fragment>
                    )) || <br />}
                    <IconButton onClick={this.props.addItem}>
                        <AddCircleIcon fontSize="small" color='secondary' />
                    </IconButton>
                    <div className="lineItemRow" style={{marginBottom: "23px" }}>
                        <div style={{width: "10%"}}></div>
                        <div style={{width: "10%"}}></div>
                        <div style={{width: "10%"}}></div>
                        <div style={{width: "10%"}}></div>
                        <div style={{width: "10%"}}></div>
                        <TextField
                            style={{width: "10%" }}
                            label="Amount SubTotal"
                            multiline
                            rowsMax={8}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ style: { textAlign: "right" } }}
                            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                            value={this.props.editing.amountSubTotal}
                            variant="outlined"
                        />
                        <TextField
                            style={{ width: "10%" }}
                            label="Tax SubTotal"
                            multiline
                            rowsMax={8}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ style: { textAlign: "right" } }}
                            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                            value={this.props.editing.taxSubTotal}
                            variant="outlined"
                        />
                        <div style={{width: "205px", textAlign: "left"}}></div>
                    </div>
                </div>
                <Divider className='divider' />
                <br />
                <div className="notesAndTotals">
                    <div className="invoiceNotes">
                        <div style={{paddingBottom: "10px"}}>
                            <TextField
                                label="Notes"
                                multiline
                                rowsMax={8}
                                InputLabelProps={{ shrink: true }}
                                style={{ width: "100%" }}
                                name="notes"
                                value={this.props.editing.notes ? this.props.editing.notes : ""}
                                onChange={(e) => this.props.handleChange(e.target.name, e.target.value)}
                                variant="outlined"
                            />
                        </div>
                        {this.props.editing.statusText && this.props.editing.statusText.length !== 0 ?
                            <div className="needs-review-container">
                                <Typography variant="h6" component="div" gutterBottom className="form-header">
                                </Typography>
                                <div className="statusText-content">
                                    <TableContainer>
                                        <Table size={"medium"} stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className='cellPadding'>
                                                        <p className={styles.cardTotalHeader}>
                                                            Issues:
                                                        </p>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.props.editing.statusText.map((report, index) => (
                                                    <TableRow hover key={index}>
                                                        <TableCell className='cellPadding'>
                                                            <p className={styles.cardCellItems}>
                                                                {report}
                                                            </p>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div> : null}
                    </div>
                    <div className="totals">
                        <TextField
                            variant="outlined"
                            label="Subtotal"
                            InputLabelProps={{ shrink: true}}
                            name="invoiceSubTotal"
                            value={this.props.formatDollar(this.props.editing.invoiceSubTotal)}
                            error={!this.props.formatDollar(this.props.editing.invoiceSubTotal)}
                            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                            inputProps={{ style: { textAlign: "right" } }}
                            onChange={(e) => this.props.handleChange(e.target.name, e.target.value)}
                            onBlur={(e) => this.props.handleBlurField(e.target.name, e.target.value, -1)}
                        />
                        <br />
                        <TextField
                            variant="outlined"
                            label="Tax"
                            InputLabelProps={{ shrink: true}}
                            name="invoiceTax"
                            value={this.props.formatDollar(this.props.editing.invoiceTax)}
                            error={!this.props.formatDollar(this.props.editing.invoiceTax)}
                            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                            inputProps={{ style: { textAlign: "right" } }}
                            onChange={(e) => this.props.handleChange(e.target.name, e.target.value)}
                            onBlur={(e) => this.props.handleBlurField(e.target.name, e.target.value, -1)}
                        />
                        <br />
                        <TextField
                            variant="outlined"
                            label="Total"
                            InputLabelProps={{ shrink: true}}
                            name="invoiceTotal"
                            value={this.props.formatDollar(this.props.editing.invoiceTotal)}
                            error={!this.props.formatDollar(this.props.editing.invoiceTotal)}
                            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                            inputProps={{ style: { textAlign: "right" } }}
                            onChange={(e) => this.props.handleChange(e.target.name, e.target.value)}
                            onBlur={(e) => this.props.handleBlurField(e.target.name, e.target.value, -1)}
                        />
                        <br />
                        {this.props.user.routes.includes('accounting') &&
                            <>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        name="paymentDate"
                                        label="Payment Date"
                                        value={this.props.editing?.paymentDate}
                                        onChange={(e, value) => this.props.handleChange("paymentDate", value)}
                                        format="MM/dd/yyyy"
                                        variant="inline"
                                        inputVariant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        InputAdornmentProps={{ position: "start" }}
                                        autoOk
                                    />
                                </MuiPickersUtilsProvider>
                                <br />
                                <Autocomplete
                                    options={this.props.bankAccounts}
                                    getOptionLabel={(option) => option.name && option.accountNumber ? `${option.name} (${option.accountNumber})`: ``}
                                    value={this.props.editing.bankAccount}
                                    onChange={(e, value) => this.props.handleChange("bankAccount", value)}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            name="bankAccount"
                                            label="Bank Account"
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    }
                                />
                                <br />
                                <TextField
                                    variant="outlined"
                                    name="referenceId"
                                    label="Reference Id"
                                    value={this.props.editing?.referenceId}
                                    onChange={(e) => this.props.handleChange(e.target.name, e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </>
                        }
                    </div>
                </div>
                <Divider className='divider' />
                <br />
                <Typography variant='h5' style={{alignSelf: "flex-start", marginBottom: "10px"}}>
                    Attachments
                </Typography>
                <div className="attachmentsRow">
                    <Tooltip
                        style={{marginLeft: "2px"}}
                        enterTouchDelay={0}
                        leaveTouchDelay={30000}
                        enterDelay={400}
                        // leaveDelay={1000000} // for testing purposes
                        title={
                            <React.Fragment>
                                {ATTACHMENTS_INFO}
                            </React.Fragment>
                        }
                    >
                        <IconButton aria-label='info'>
                            <InfoOutlinedIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                    {
                        this.props.editing.files?.length > 0 ?
                            this.props.editing.files?.map((file, index) => {
                                let label = index + 1;
                                if (Object.keys(file).length > 0 && Object.keys(file).includes('displayName') && (file.displayName.includes('.pdf') || file.displayName.includes('.PDF'))) {
                                    return (
                                        <div className="pdf-icon">
                                            <div style={{position: "relative"}}>
                                                <IconButton
                                                    style={{position: "relative"}}
                                                    aria-label='previous'
                                                    onClick={() => this.props.handleAttachmentPreview(file, index)}
                                                >
                                                    <DescriptionIcon variant='outlined' fontSize='large' />
                                                </IconButton>
                                                <div style={{position: "absolute", top: "40px", left: "30px", fontSize: "15px", color: "black"}}>
                                                    {file.displayName.length > 10 ? file.displayName.substring(0, 5) + '...' : file.displayName}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })
                            :
                            null}
                </div>
                <div style={{alignSelf: "flex-start"}}>
                    <FileUpload
                        handleFileAdd={(file) => this.props.handleGeneralFileAdd(file)}
                        handleFileDelete={(file, fileIndex) => this.props.handleGeneralFileDelete(file, fileIndex)}
                        files={this.props.newFiles ? this.props.newFiles : []}
                        fetchFilePreview={null}
                        singleFileUpload={true}
                        isPackingSlip={true}
                    />
                </div>
            </div>}
        </>)
    }
}

export default EditInvoices