import {
    CREATE_INVENTORY_ADJUSTMENT,
    LIST_ALL_INVENTORY,
    LIST_INVENTORY_ADJUSTMENT_TYPES,
    LIST_INVENTORY_ENTRIES,
    LIST_MANUFACTURERS,
    STORE_ALL_INVENTORY,
    UPDATE_FTL_RECEIVE,
    STORE_INVENTORY_ADJUSTMENT_TYPES,
    STORE_INVENTORY_ENTRIES,
    STORE_MANUFACTURERS,
    STORE_NEW_INVENTORY_ADJUSTMENT,
    STORE_NEW_INVENTORY_ENTRY,
    STORE_NEW_INVENTORY_TRANSFERRED,
    STORE_UPDATED_INVENTORY_ITEM,
    UPDATE_INVENTORY_THRESHOLD,
    LIST_ALL_BOX_INVENTORY,
    LIST_ALL_PRODUCT_INVENTORY,
    STORE_ALL_BOX_INVENTORY,
    STORE_ALL_PRODUCT_INVENTORY,
    LIST_INVENTORY_ALERTS, STORE_INVENTORY_ALERTS
} from "../../constants/inventory";


export const listAllInventory = () => ({
    type: LIST_ALL_INVENTORY
});

export const storeAllInventory = (allInventory) => ({
    type: STORE_ALL_INVENTORY,
    allInventory
});

export const listInventoryAdjustmentTypes = () => ({
    type: LIST_INVENTORY_ADJUSTMENT_TYPES
});

export const storeInventoryAdjustmentTypes = (inventoryAdjustmentTypes) => ({
    type: STORE_INVENTORY_ADJUSTMENT_TYPES,
    inventoryAdjustmentTypes
});

export const listManufacturers = () => ({
    type: LIST_MANUFACTURERS
});

export const storeManufacturers = (manufacturers) => ({
    type: STORE_MANUFACTURERS,
    manufacturers
});

export const listInventoryAlerts = (updateAlerts = false, fetchAlertsData = null) => ({
    type: LIST_INVENTORY_ALERTS,
    updateAlerts,
    fetchAlertsData
});

export const storeInventoryAlerts = (alerts) => ({
    type: STORE_INVENTORY_ALERTS,
    alerts
});

export const listAllBoxInventory = (data) => ({
    type: LIST_ALL_BOX_INVENTORY,
    data
});

export const storeAllBoxInventory = (boxes) => ({
    type: STORE_ALL_BOX_INVENTORY,
    boxes
});

export const listAllProductInventory = (data) => ({
    type: LIST_ALL_PRODUCT_INVENTORY,
    data
});

export const storeAllProductInventory = (products) => ({
    type: STORE_ALL_PRODUCT_INVENTORY,
    products
});

////////////////////////////////////////

export const createInventoryAdjustment = (inventoryAdjustment, data, isProducts, selectedLocationData) => ({
    type: CREATE_INVENTORY_ADJUSTMENT,
    inventoryAdjustment,
    data,
    isProducts,
    selectedLocationData
});

export const updateFTLReceive = (transferId, fromDate, data, isProducts, selectedLocationData) => ({
    type: UPDATE_FTL_RECEIVE,
    transferId,
    fromDate,
    data,
    isProducts,
    selectedLocationData
});

export const storeNewInventoryAdjustment = (inventoryAdjustment) => ({
    type: STORE_NEW_INVENTORY_ADJUSTMENT,
    inventoryAdjustment
});

export const storeNewInventoryTransferred = (inventoryTransferred) => ({
    type: STORE_NEW_INVENTORY_TRANSFERRED,
    inventoryTransferred
});


export const storeNewInventoryEntry = (inventoryEntries) => ({
    type: STORE_NEW_INVENTORY_ENTRY,
    inventoryEntries
});

//////////

export const listInventoryEntries = (dateRange) => ({
    type: LIST_INVENTORY_ENTRIES,
    dateRange
});

export const storeInventoryEntries = (inventoryEntries) => ({
    type: STORE_INVENTORY_ENTRIES,
    inventoryEntries
});

//
export const updateInventoryThreshold = (inventoryItem, data) => ({
    type: UPDATE_INVENTORY_THRESHOLD,
    inventoryItem,
    data
})

export const storeUpdatedInventoryItem = (updatedInventoryItem) => ({
    type: STORE_UPDATED_INVENTORY_ITEM,
    updatedInventoryItem
})




