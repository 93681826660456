import React from "react";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import {
    SETTINGS, STATEMENT_TAXES, STATEMENT_TAXES_PAGES, STATEMENT_TAXES_SUMMARY_FIELDS,
} from "./constants";
import { Layout } from "../../global/Table/TablePageHelpers/LayoutObject";
import { TableCell, Chip, Select, MenuItem, FormControl, InputLabel, Box } from "@mui/material";
import TablePage from "../../global/Table/TablePage";
import '../shippingSummary/ShippingSummary.css';
import SettingsIcon from "@material-ui/icons/Settings";

class StatementTaxesTable extends React.Component {
    constructor(props) {
        super(props)
    }

    getTableHeaders = () => {
        let headers = [];

        STATEMENT_TAXES_SUMMARY_FIELDS.forEach((headCell, index) => {
            if (index === 0) {
                headers.push(Layout.columnHead(headCell.label, headCell.id, "center")); // Adjust alignment to left for index 0
            } else {
                headers.push(Layout.columnHead(headCell.label, headCell.id, "center")); // Default alignment for other headers
            }
        });
        return headers;
    }



    tableRow = (summary,index) => {
        const lastIndex = this.props.taxSummary.length - 1; // Calculate the last index
        return (
                <React.Fragment>
                    <TableCell className="table-tax-cell-left" style={{fontWeight: index === lastIndex ? 'bold' : 'normal'}} >{summary?.description}</TableCell>

                    <TableCell className="custom-cell align-tax">
                        <div style={{fontWeight: index === lastIndex ? 'bold' : 'normal', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '3px' ,marginRight:'15px'}}>
                            <span style={{ flexShrink: 0, marginRight:"auto" }}>$</span>
                            <span style={{ marginLeft:0 }}>{parseFloat(summary?.amount).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</span>
                        </div>
                    </TableCell>


                </React.Fragment>
            )
    }

    searchStrings = (summary) => {
        return [summary.description]
    }


    getContextualMenuItems = () => {
        let menuItems = []
        menuItems.push(
            {
                title: SETTINGS,
                icon: <SettingsIcon/>,
                onClick: () => this.props.setCurrentPage(STATEMENT_TAXES_PAGES.SETTINGS, {})
            })

        return menuItems
    }

    getMonthsForQuarter = () => {
        const { selectedQuarters } = this.props;

        if (!selectedQuarters) {
            return [
                { name: 'January', value: '01' },
                { name: 'February', value: '02' },
                { name: 'March', value: '03' },
                { name: 'April', value: '04' },
                { name: 'May', value: '05' },
                { name: 'June', value: '06' },
                { name: 'July', value: '07' },
                { name: 'August', value: '08' },
                { name: 'September', value: '09' },
                { name: 'October', value: '10' },
                { name: 'November', value: '11' },
                { name: 'December', value: '12' },
            ]
        } else {
            switch (selectedQuarters) {
                case 1:
                    return [  { name: 'January', value: '01' },
                        { name: 'February', value: '02' },
                        { name: 'March', value: '03' }]
                case 2:
                    return [
                        { name: 'April', value: '04' },
                        { name: 'May', value: '05' },
                        { name: 'June', value: '06' }]
                case 3:
                    return [
                        { name: 'July', value: '07' },
                        { name: 'August', value: '08' },
                        { name: 'September', value: '09'}]
                case 4:
                    return [
                        { name: 'October', value: '10' },
                        { name: 'November', value: '11' },
                        { name: 'December', value: '12' }]
                default:
                    return [];
            }
        }
    }

    render() {
        const currency = ['CAD', 'USD']
        const currentYear = new Date().getFullYear()
        const years = Array.from(new Array(21), (val, index) => currentYear - index)
        const {  selectedYear, selectedQuarters, selectedCurrency, handleCurrencyChange, selectedMonth, handleMonthChange, handleYearChange, handleQuarterChange, taxSummary } = this.props

        return (
            <React.Fragment>
                <TopToolbar
                    pageName={STATEMENT_TAXES}
                    menuItems={this.getContextualMenuItems()}
                />


                <Box className="box">
                    <FormControl variant="outlined" className="form-control">
                        <InputLabel id="currency-label">Currency</InputLabel>
                        <Select
                            className="select"
                            labelId="currency-label"
                            value={selectedCurrency}
                            onChange={handleCurrencyChange}
                            label="Currency"
                        >
                            {currency.map((currency) => (
                                <MenuItem key={currency} value={currency}>
                                    {currency}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" className="form-control">
                        <InputLabel id="year-label">Year</InputLabel>
                        <Select
                            className="select-year"
                            labelId="year-label"
                            value={selectedYear}
                            onChange={handleYearChange}
                            label="Year"
                        >
                            {years.map((year) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" className="form-control">
                        <InputLabel id="month-label">Month</InputLabel>
                        <Select
                            className="select-month"
                            label="Month"
                            labelId="month-label"
                            value={selectedMonth}
                            onChange={handleMonthChange}>
                            <MenuItem value="">None</MenuItem>
                            {this.getMonthsForQuarter().map((month) => (
                                <MenuItem key={month.value} value={month.value}>
                                    {month.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <div className="chip-container">
                        {["QTR 1", "QTR 2", "QTR 3", "QTR 4"].map((quarter, index) => (
                            <Chip
                                key={index}
                                label={quarter}
                                onClick={() => handleQuarterChange(quarter)}
                                className={`chip ${selectedQuarters === index + 1 ? 'chip-selected' : ''}`}
                            />
                        ))}
                    </div>
                </Box>

                <div className="table-page-summary">
                    <TablePage
                        tableName="SummaryTable"
                        tableRows={taxSummary}
                        tableColumnHeads={this.getTableHeaders()}
                        tableRow={this.tableRow}
                        paginationText="Summary per page"
                        hasSearchField
                        searchStrings={this.searchStrings}
                    />
                </div>
                {/*{taxSummary.length > 0 && <div className="total-field taxSummary">*/}
                {/*    <div className="dollar-amount-total">*/}
                {/*        <span style={{marginRight:"100px"}}>$</span>*/}
                {/*        <span>{ parseFloat(this.props.totalSum).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</span>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*}*/}
            </React.Fragment>
        )
    }
}

export default StatementTaxesTable;
