export const LIST_ALL_INVENTORY = 'LIST_ALL_INVENTORY';
export const STORE_ALL_INVENTORY = 'STORE_ALL_INVENTORY';

export const LIST_INVENTORY_ADJUSTMENT_TYPES = 'LIST_INVENTORY_ADJUSTMENT_TYPES';
export const STORE_INVENTORY_ADJUSTMENT_TYPES = 'STORE_INVENTORY_ADJUSTMENT_TYPES';

export const LIST_MANUFACTURERS = 'LIST_MANUFACTURERS';
export const STORE_MANUFACTURERS = 'STORE_MANUFACTURERS';

export const LIST_INVENTORY_ALERTS = 'LIST_INVENTORY_ALERTS';
export const STORE_INVENTORY_ALERTS = 'STORE_INVENTORY_ALERTS';

export const CREATE_INVENTORY_ADJUSTMENT = 'CREATE_INVENTORY_ADJUSTMENT';
export const STORE_NEW_INVENTORY_ADJUSTMENT = 'STORE_NEW_INVENTORY_ADJUSTMENT';

export const STORE_NEW_INVENTORY_TRANSFERRED = 'STORE_NEW_INVENTORY_TRANSFERRED';


export const LIST_INVENTORY_ENTRIES = 'LIST_INVENTORY_ENTRIES';
export const STORE_INVENTORY_ENTRIES = 'STORE_INVENTORY_ENTRIES';

export const STORE_NEW_INVENTORY_ENTRY = 'STORE_NEW_INVENTORY_ENTRY';

export const UPDATE_FTL_RECEIVE = 'UPDATE_FTL_RECEIVE';

export const UPDATE_INVENTORY_THRESHOLD = 'UPDATE_INVENTORY_THRESHOLD';
export const STORE_UPDATED_INVENTORY_ITEM = 'STORE_UPDATED_INVENTORY_ITEM';

export const LIST_ALL_BOX_INVENTORY = 'LIST_ALL_BOX_INVENTORY';
export const STORE_ALL_BOX_INVENTORY = 'STORE_ALL_BOX_INVENTORY';

export const LIST_ALL_PRODUCT_INVENTORY = 'LIST_ALL_PRODUCT_INVENTORY';
export const STORE_ALL_PRODUCT_INVENTORY = 'STORE_ALL_PRODUCT_INVENTORY';


