import {
    GET_ALL_SUPPLIER_INVOICES,
    STORE_ALL_SUPPLIER_INVOICES,
    PROCESS_SUPPLIER_INVOICE,
    GET_SUPPLIER_INVOICE_PDF,
    STORE_SUPPLIER_INVOICE_PDF,
    UPDATE_SUPPLIER_INVOICE,
    DELETE_SUPPLIER_INVOICE,
    UPDATE_SUPPLIER_INVOICE_STATUS,
    OVERWRITE_SUPPLIER_INVOICE,
    SAVE_SUPPLIER_INVOICE_PAYMENT_DETAILS,

    FETCH_SUPPLIER_INVOICE_TEMPLATES,
    STORE_SUPPLIER_INVOICE_TEMPLATES,
    FETCH_SUPPLIERS_WITHOUT_TEMPLATE,
    STORE_SUPPLIERS_WITHOUT_TEMPLATE,
    GET_SUPPLIER_INVOICE_TEMPLATE_PDF,
    STORE_SUPPLIER_INVOICE_TEMPLATE_PDF,
    CREATE_SAVE_SUPPLIER_INVOICE_TEMPLATE,
    DELETE_SUPPLIER_INVOICE_TEMPLATE,

    LIST_CARRIER_INVOICE_TEMPLATES,
    STORE_CARRIER_INVOICE_TEMPLATES,
    LIST_CARRIERS_WITHOUT_TEMPLATE,
    STORE_CARRIERS_WITHOUT_TEMPLATE,
    DELETE_CARRIER_INVOICE_TEMPLATE,
    CREATE_SAVE_CARRIER_INVOICE_TEMPLATE,
    GET_CARRIER_INVOICE_TEMPLATE_PDF,
    STORE_CARRIER_INVOICE_TEMPLATE_PDF,

    GET_ALL_CARRIER_INVOICES,
    STORE_ALL_CARRIER_INVOICES,
    GET_CARRIER_INVOICE_PDF,
    STORE_CARRIER_INVOICE_PDF,
    UPDATE_CARRIER_INVOICE_STATUS,
    PROCESS_CARRIER_INVOICE,
    STORE_UPDATED_CARRIER_INVOICES,
    STORE_UPDATED_SUPPLIER_INVOICE,
    STORE_DATE_FORMATS,
    GET_DATE_FORMATS,
    DELETE_CARRIER_INVOICE,
    SET_PROCESSING,
    GET_SUPPLIER_INVOICE_ATTACHMENT,
    DELETE_SUPPLIER_INVOICE_ATTACHMENT,
    REPROCESS_SUPPLIER_INVOICE,
    GET_SUPPLIER_INVOICE_PO,
    STORE_SUPPLIER_INVOICE_PO,
    FETCH_INVOICE_BY_TRACKING_ID,
    SET_INVOICE_SMART_SEARCH_ROWS,
    SET_INVOICE_ITEM_SMART_SEARCH_DETAILS,
    REPROCESS_CARRIER_INVOICE,
    STORE_REPROCESSED_CARRIER_INVOICES,
    SAVE_CARRIER_INVOICE_ITEM_DETAILS,
    REMOVE_DELETED_CARRIER_INVOICES,
    STORE_UPDATED_INVOICE_ITEM_SMART_SEARCH_DETAILS,
    STORE_UPDATED_INVOICE_SMART_SEARCH_ROWS,
    UPDATE_SUPPLIER_INVOICE_CHANGED,
    STORE_SUPPLIER_INVOICE_OPERATION_RESPONSE,
    SAVE_CARRIER_INVOICE_PAYMENT_DETAILS,
    STORE_CARRIER_INVOICE_OPERATION_RESPONSE,
    CLEAR_CARRIER_INVOICE_OPERATION_RESPONSE,
    REMOVE_DELETED_SUPPLIER_INVOICES,
    PROCESS_FTL_CARRIER_INVOICE,
    GET_FTL_CARRIER_INVOICES,
    STORE_FTL_CARRIER_INVOICES,
    STORE_PROCESSED_FTL_CARRIER_INVOICE,
    REMOVE_DELETED_FTL_CARRIER_INVOICE,
    UPDATE_FTL_CARRIER_INVOICE_STATUS,
    STORE_REPROCESSED_FTL_CARRIER_INVOICE,
    SAVE_CARRIER_INVOICE,
    SAVE_FTL_CARRIER_INVOICE,
    STORE_UPDATED_CARRIER_INVOICE_STATUS,
    STORE_SUPPLIER_INVOICE,
    REIMPORT_CARRIER_INVOICE_TEMPLATE,
} from "../../constants/invoices";
import {LIST_FTL_CARRIER_INVOICE_TEMPLATES} from "../../constants/fullTruckloadCarriers";


export const getAllSupplierInvoices = (data) => ({
    type: GET_ALL_SUPPLIER_INVOICES,
    data
})
export const storeAllSupplierInvoices = (invoices) => ({
    type: STORE_ALL_SUPPLIER_INVOICES,
    invoices
})

export const storeSupplierInvoice = (fromDate, toDate, invoice) => ({
    type: STORE_SUPPLIER_INVOICE,
    fromDate,
    toDate,
    invoice
})

export const processSupplierInvoice = (invoice, storeInvoice, fromDate, toDate) => ({
    type: PROCESS_SUPPLIER_INVOICE,
    invoice,
    storeInvoice,
    fromDate,
    toDate,
})

export const reprocessSupplierInvoice = (data, storeInvoice, fromDate, toDate) => ({
    type: REPROCESS_SUPPLIER_INVOICE,
    data,
    storeInvoice,
    fromDate,
    toDate,
})

export const getSupplierInvoicePDF = (supplierInvoiceId) => ({
    type: GET_SUPPLIER_INVOICE_PDF,
    supplierInvoiceId
})

export const storeSupplierInvoicePDF = (data) => ({
    type: STORE_SUPPLIER_INVOICE_PDF,
    data
})

export const getInvoicePurchaseOrder = (purchaseOrder) => ({
    type: GET_SUPPLIER_INVOICE_PO,
    purchaseOrder
})

export const storeInvoicePurchaseOrder = (data) => ({
    type: STORE_SUPPLIER_INVOICE_PO,
    data
})

export const getSupplierInvoiceAttachment = (data) => ({
    type: GET_SUPPLIER_INVOICE_ATTACHMENT,
    data
})

export const updateSupplierInvoice = (data, validate, reset, error) => ({
    type: UPDATE_SUPPLIER_INVOICE,
    data,
    validate,
    reset,
    error
})

export const deleteSupplierInvoiceAttachment = (data, update) => ({
    type: DELETE_SUPPLIER_INVOICE_ATTACHMENT,
    data,
    update
})

export const storeUpdatedSupplierInvoice = (updatedInvoice) => ({
    type: STORE_UPDATED_SUPPLIER_INVOICE,
    updatedInvoice,
})

export const storeSupplierInvoiceOperationResponse = (response) => ({
    type: STORE_SUPPLIER_INVOICE_OPERATION_RESPONSE,
    response,
})

export const updateSupplierInvoiceChanged = () => ({
    type: UPDATE_SUPPLIER_INVOICE_CHANGED
})

export const fetchSupplierInvoiceTemplates = () => ({
    type: FETCH_SUPPLIER_INVOICE_TEMPLATES
})
export const storeSupplierInvoiceTemplates = (templates) => ({
    type: STORE_SUPPLIER_INVOICE_TEMPLATES,
    templates
})

export const getDateFormats = () => ({
    type: GET_DATE_FORMATS
})

export const storeDateFormats = (formats) => ({
    type: STORE_DATE_FORMATS,
    formats
})

export const fetchSuppliersWithoutTemplate = () => ({
    type: FETCH_SUPPLIERS_WITHOUT_TEMPLATE
})
export const storeSuppliersWithoutTemplate = (suppliers) => ({
    type: STORE_SUPPLIERS_WITHOUT_TEMPLATE,
    suppliers
})

export const createSaveSupplierInvoiceTemplate = (data, reset, error) => ({
    type: CREATE_SAVE_SUPPLIER_INVOICE_TEMPLATE,
    data,
    reset,
    error
})

export const getSupplierInvoiceTemplatePDF = (supplierId) => ({
    type: GET_SUPPLIER_INVOICE_TEMPLATE_PDF,
    supplierId
})
export const storeSupplierInvoiceTemplatePDF = (template) => ({
    type: STORE_SUPPLIER_INVOICE_TEMPLATE_PDF,
    template
})

export const deleteSupplierInvoiceTemplate = (data, reset, error) => ({
    type: DELETE_SUPPLIER_INVOICE_TEMPLATE,
    data,
    reset,
    error
})

export const removeDeletedSupplierInvoices = (supplierInvoiceId) => ({
    type: REMOVE_DELETED_SUPPLIER_INVOICES,
    supplierInvoiceId
})

export const listCarrierInvoiceTemplates = () => ({
    type: LIST_CARRIER_INVOICE_TEMPLATES
})
export const storeCarrierInvoiceTemplates = (templates) => ({
    type: STORE_CARRIER_INVOICE_TEMPLATES,
    templates
})

export const listCarriersWithoutTemplate = () => ({
    type: LIST_CARRIERS_WITHOUT_TEMPLATE
})
export const storeCarriersWithoutTemplate = (carriers) => ({
    type: STORE_CARRIERS_WITHOUT_TEMPLATE,
    carriers
})

export const deleteCarrierInvoiceTemplate = (data, reset, error) => ({
    type: DELETE_CARRIER_INVOICE_TEMPLATE,
    data,
    reset,
    error
})

export const createSaveCarrierInvoiceTemplate = (data, reset, error) => ({
    type: CREATE_SAVE_CARRIER_INVOICE_TEMPLATE,
    data,
    reset,
    error
})

export const getCarrierInvoiceTemplatePDF = (data) => ({
    type: GET_CARRIER_INVOICE_TEMPLATE_PDF,
    data
})
export const reimportCarrierInvoiceTemplatePDF = (data) => ({
    type: REIMPORT_CARRIER_INVOICE_TEMPLATE,
    data
})
export const storeCarrierInvoiceTemplatePDF = (pdf) => ({
    type: STORE_CARRIER_INVOICE_TEMPLATE_PDF,
    pdf
})


export const getAllCarrierInvoices = (data) => ({
    type: GET_ALL_CARRIER_INVOICES,
    data
})

export const saveCarrierInvoice = (data) => ({
    type: SAVE_CARRIER_INVOICE,
    data
})

export const saveFTLCarrierInvoice = (data) => ({
    type: SAVE_FTL_CARRIER_INVOICE,
    data
})

export const storeAllCarrierInvoices = (invoices) => ({
    type: STORE_ALL_CARRIER_INVOICES,
    invoices
})

export const getCarrierInvoicePDF = (data) => ({
    type: GET_CARRIER_INVOICE_PDF,
    data
})
export const storeCarrierInvoicePDF = (pdf) => ({
    type: STORE_CARRIER_INVOICE_PDF,
    pdf
})

export const updateCarrierInvoiceStatus = (data, reset, error, carrierType) => ({
    type: UPDATE_CARRIER_INVOICE_STATUS,
    data,
    reset,
    error,
    carrierType
})
export const storeUpdatedCarrierInvoiceStatus = (data) => ({
    type: STORE_UPDATED_CARRIER_INVOICE_STATUS,
    data
})

export const storeUpdatedCarrierInvoices = (data) => ({
    type: STORE_UPDATED_CARRIER_INVOICES,
    data
})

export const updateFTLCarrierInvoiceStatus = (invoices, status) => ({
    type: UPDATE_FTL_CARRIER_INVOICE_STATUS,
    invoices,
    status
})

export const storeCarrierInvoiceOperationResponse = (carrierInvoiceOperationResponse) => ({
    type: STORE_CARRIER_INVOICE_OPERATION_RESPONSE,
    carrierInvoiceOperationResponse
})

export const clearCarrierInvoiceOperationResponse = () => ({
    type: CLEAR_CARRIER_INVOICE_OPERATION_RESPONSE,
})

export const removeDeletedCarrierInvoices = (data) => ({
    type: REMOVE_DELETED_CARRIER_INVOICES,
    data
})

export const storeReprocessedCarrierInvoices = (data) => ({
    type: STORE_REPROCESSED_CARRIER_INVOICES,
    data
})

export const processCarrierInvoice = (data, reset, error) => ({
    type: PROCESS_CARRIER_INVOICE,
    data,
    reset,
    error
})

export const setProcessing = (processing) => ({
    type: SET_PROCESSING,
    processing
})

export const deleteCarrierInvoice = (data, carrierType) => ({
    type: DELETE_CARRIER_INVOICE,
    data,
    carrierType,
})

export const reprocessCarrierInvoice = (data, carrierType) => ({
    type: REPROCESS_CARRIER_INVOICE,
    data,
    carrierType
})

export const saveCarrierInvoiceItemDetails = (data) => ({
    type: SAVE_CARRIER_INVOICE_ITEM_DETAILS,
    data
})

export const saveCarrierInvoicePaymentDetails = (data, carrierType) => ({
    type: SAVE_CARRIER_INVOICE_PAYMENT_DETAILS,
    data,
    carrierType
})

export const deleteSupplierInvoice = (data, reload) => ({
    type: DELETE_SUPPLIER_INVOICE,
    data,
    reload,
})

export const updateSupplierInvoiceStatus = (data, callback, fromDate, toDate) => ({
    type: UPDATE_SUPPLIER_INVOICE_STATUS,
    data,
    callback,
    fromDate,
    toDate
})

export const overwriteSupplierInvoice = (data, callback) => ({
    type: OVERWRITE_SUPPLIER_INVOICE,
    data,
    callback
})

export const saveSupplierInvoicePaymentDetails = (data) => ({
    type: SAVE_SUPPLIER_INVOICE_PAYMENT_DETAILS,
    data
})

export const fetchInvoiceByTrackingId = (searchValue) => ({
    type: FETCH_INVOICE_BY_TRACKING_ID,
    searchValue
})

export const setInvoiceSmartSearchRows = (invoices) => ({
    type: SET_INVOICE_SMART_SEARCH_ROWS,
    invoices
})

export const storeUpdatedInvoiceSmartSearchRows = (invoice) => ({
    type: STORE_UPDATED_INVOICE_SMART_SEARCH_ROWS,
    invoice
})

export const storeUpdatedInvoiceItemSmartSearchDetails = (invoiceItem) => ({
    type: STORE_UPDATED_INVOICE_ITEM_SMART_SEARCH_DETAILS,
    invoiceItem
})

export const setInvoiceItemSmartSearchDetails = (invoiceItems) => ({
    type: SET_INVOICE_ITEM_SMART_SEARCH_DETAILS,
    invoiceItems
})

export const processFTLCarrierInvoice= (data, reset, error) => ({
    type: PROCESS_FTL_CARRIER_INVOICE,
    data,
    reset,
    error
})

export const getFTLCarrierInvoices = (data) => ({
    type: GET_FTL_CARRIER_INVOICES,
    data
})

export const storeFTLCarrierInvoices = (invoices) => ({
    type: STORE_FTL_CARRIER_INVOICES,
    invoices
})

export const storeProcessedFTLCarrierInvoice = (invoice) => ({
    type: STORE_PROCESSED_FTL_CARRIER_INVOICE,
    invoice
})

export const removeDeletedFTLCarrierInvoice = (invoiceId) => ({
    type: REMOVE_DELETED_FTL_CARRIER_INVOICE,
    invoiceId
})

export const storeReprocessedFTLCarrierInvoices = (invoices) => ({
    type: STORE_REPROCESSED_FTL_CARRIER_INVOICE,
    invoices
})