import {
    REMOVE_DELETED_CUSTOMER_INVOICES, REMOVE_DELETED_CUSTOMER_ORDER,
    STORE_ALL_CUSTOMER_INVOICES,
    STORE_CUSTOMER_INVOICE_OPERATION_RESPONSE, STORE_CUSTOMER_INVOICE_PDF, STORE_CUSTOMER_INVOICE_PO,
    STORE_CUSTOMERS, STORE_UPDATED_CUSTOMER_INVOICE, UPDATE_CUSTOMER_INVOICE_CHANGED
} from "../../constants/customer";
import {
    CUSTOMER_CACHE_PDF_PREVIEW, CUSTOMER_RESET_PDF_PREVIEW_DATA,
    CUSTOMER_STORE_ALL_PURCHASE_ORDERS, CUSTOMER_STORE_CREATED_PURCHASE_ORDER_PREVIEW,
    CUSTOMER_STORE_PDF_PREVIEW_READY, CUSTOMER_STORE_UPDATED_PURCHASE_ORDER,
    CUSTOMER_STORE_UPDATED_PURCHASE_ORDER_PREVIEW
} from "../../constants/customer";

const initialState = {
    customers: [],
    customerInvoices: [],
    allPurchaseOrders: [],
    newPurchaseOrders: [],
    partialPurchaseOrders: [],
    completedPurchaseOrders: [],
    storePDFPreviewReady: new Uint8Array(0),
    createdPurchaseOrderPreview: new Uint8Array(0),
    customerInvoiceChanged: false,
    operationResponse: "",
    purchaseOrder: {},
    customerInvoicePDF: new Uint8Array(0),
};

const updateList = (purchaseOrder, oldPurchaseOrders) => {
    let newList = [];
    let exists = oldPurchaseOrders.findIndex(item => item.customerPurchaseOrderId === purchaseOrder.customerPurchaseOrderId);
    if (exists === -1) {
        return oldPurchaseOrders;
    } else {
        oldPurchaseOrders.map((item, index) => {
            if (index === exists) {
                newList[index] = purchaseOrder;
            } else {
                newList[index] = item;
            }
        })
        return newList;
    }
}

const updatePurchaseOrderWithPreview = (updatedPO, oldPos, index) => {
    let filePreviewInfo = updatedPO.packingSlipFiles.pop()
    // get the data
    let previewData = new Uint8Array(filePreviewInfo.previewFile)
    let blobData = new Blob([previewData])
    let localStorageUrl = URL.createObjectURL(blobData)

    let poToUpdate = oldPos.find((po) => { return po.poNumber === updatedPO.poNumber })

    // we're only updating the specific file object as we dont want to lose state of currently uploaded files (locally) and other local changes user has made
    // if this is a general file
    if ("generalFileIndex" in filePreviewInfo) {
        let generalFileIndex = filePreviewInfo.generalFileIndex
        updatedPO.packingSlipFiles[generalFileIndex]["localURL"] = localStorageUrl
        poToUpdate.packingSlipFiles[generalFileIndex] = updatedPO.packingSlipFiles[generalFileIndex]
        poToUpdate.packingSlipFiles[generalFileIndex]['byteArray'] = previewData
    }


    return updateList(poToUpdate, oldPos)
}

const updateInvoices = (oldInvoices, updatedInvoice) => {

    // Check if the invoice exists in the oldInvoices list based on poNumber and date
    let invoiceExists = oldInvoices.find(item =>
        item.customerInvoiceId === updatedInvoice.customerInvoiceId
    );

    let newList;
    if (invoiceExists) {
        // Update the existing invoice
        newList = oldInvoices.map(item =>
            item.customerInvoiceId === updatedInvoice.customerInvoiceId ? updatedInvoice : item
        );
    } else {
        // Add the new invoice to the list
        newList = [...oldInvoices, updatedInvoice];
    }

    const customerInvoiceChanged = newList !== oldInvoices;

    return {
        invoices: newList,
        customerInvoiceChanged: customerInvoiceChanged
    };
};



export default (state = initialState, action) => {
    switch (action.type) {
        case STORE_ALL_CUSTOMER_INVOICES:
            return { ...state, customerInvoices: action.invoices }
        case STORE_CUSTOMER_INVOICE_PO:
            return { ...state, purchaseOrder: action.data }
        case STORE_UPDATED_CUSTOMER_INVOICE:
            const updateResult = updateInvoices(state.customerInvoices, action.updatedInvoice)
            return {
                ...state,
                customerInvoices: updateResult.invoices,
                customerInvoiceChanged: updateResult.customerInvoiceChanged
            }
        case STORE_CUSTOMER_INVOICE_PDF:
            return { ...state, customerInvoicePDF: action.data }
        case UPDATE_CUSTOMER_INVOICE_CHANGED:
            return {...state, customerInvoiceChanged: false}
        case REMOVE_DELETED_CUSTOMER_INVOICES:
            const updatedCustomerInvoices = state.customerInvoices.filter(invoice => invoice.customerInvoiceId !== action.customerInvoiceId);
            return {...state, customerInvoices: updatedCustomerInvoices}
        case REMOVE_DELETED_CUSTOMER_ORDER:
            const updatedCustomerOrder = state.allPurchaseOrders.filter(order => order.poNumber !== action.poNumber);
            return {...state, allPurchaseOrders: updatedCustomerOrder}
        case STORE_CUSTOMER_INVOICE_OPERATION_RESPONSE:
            return {
                ...state,
                operationResponse: action.response
            }
        case STORE_CUSTOMERS:
            return {
                ...state,
                customers: action.customers
            }
        case CUSTOMER_STORE_PDF_PREVIEW_READY || CUSTOMER_CACHE_PDF_PREVIEW:
            return {
                ...state,
                storePDFPreviewReady: action.storePDFPreviewReady
            }

        case CUSTOMER_STORE_ALL_PURCHASE_ORDERS:
            return {
                ...state,
                allPurchaseOrders: action.allPurchaseOrders
            }

        case CUSTOMER_STORE_UPDATED_PURCHASE_ORDER_PREVIEW:
            return {
                ...state,
                allPurchaseOrders: updatePurchaseOrderWithPreview(action.updatedPurchaseOrder, state.allPurchaseOrders, action.index)
            }

        case CUSTOMER_STORE_UPDATED_PURCHASE_ORDER:
            return {
                ...state,
                allPurchaseOrders: updateList(action.purchaseOrder, state.allPurchaseOrders)
            }

        case CUSTOMER_STORE_CREATED_PURCHASE_ORDER_PREVIEW:
            return {
                ...state,
                createdPurchaseOrderPreview: action.purchaseOrderPreview
            }
        case CUSTOMER_RESET_PDF_PREVIEW_DATA:
            return {
                ...state,
                storePDFPreviewReady: new Uint8Array(0),
                createdPurchaseOrderPreview: new Uint8Array(0)
            }
        default:
            return state
    }
}