import {createSelector} from "reselect";

export const customersSelector = createSelector(state => state && state.customer && state.customer.customers, customersSelector => customersSelector);
export const PDFpreviewReadySelector = createSelector(state => state.customer && state.customer.storePDFPreviewReady, PDFpreviewReadySelector => PDFpreviewReadySelector )
export const allPurchaseOrdersSelector = createSelector(state => state.customer && state.customer.allPurchaseOrders, allPurchaseOrdersSelector => allPurchaseOrdersSelector )
export const newPurchaseOrdersSelector = createSelector(state => state.customer && state.customer.newPurchaseOrders, newPurchaseOrdersSelector => newPurchaseOrdersSelector )
export const partialPurchaseOrdersSelector = createSelector(state => state.customer && state.customer.partialPurchaseOrders, partialPurchaseOrdersSelector => partialPurchaseOrdersSelector )
export const completedPurchaseOrdersSelector = createSelector(state => state.customer && state.customer.completedPurchaseOrders, completedPurchaseOrdersSelector => completedPurchaseOrdersSelector )
export const createdPurchaseOrderPreviewSelector = createSelector(state => state.customer && state.customer.createdPurchaseOrderPreview, createdPurchaseOrderPreviewSelector => createdPurchaseOrderPreviewSelector)

export const customerInvoiceChangedSelector = createSelector(state => state.customer && state.customer.customerInvoiceChanged, customerInvoiceChangedSelector => customerInvoiceChangedSelector)

export const allCustomerInvoicesSelector = createSelector(state => state.customer && state.customer.customerInvoices, customerInvoices => customerInvoices)

export const customerInvoiceOperationResponseSelector = createSelector(state => state.customer && state.customer.operationResponse, customerInvoiceOperationResponseSelector => customerInvoiceOperationResponseSelector)

export const customerInvoicePOSelector = createSelector(state => state.customer && state.customer.purchaseOrder, customerInvoicePOSelector => customerInvoicePOSelector)

export const customerInvoicePDFSelector = createSelector(state => state.customer && state.customer.customerInvoicePDF, customerInvoicePDFSelector => customerInvoicePDFSelector)
