import ShipmentsTable from "../components/shipments/ShipmentsTable";
import PendingShipments from "../components/stages/PendingShipments";
import ShippedShipments from "../components/stages/ShippedShipments";
import AutomationsRules from "../components/automationrules/AutomationRules";
import PricingDashboard from "../components/pricingDashboard/PricingDashboard";
import DashboardFinal from "../pages/Dashboard/DashboardFinal/DashboardFinal";
import Login from "../pages/Login/Login";
import AutomationRulesStepper from "../components/automationrules/AutomationRulesStepper";
import ManageUsersTable from "../components/settings/ManageUsers/ManageUsersTable";
import Playground from "../pages/Playground/Playground";
import ManageMarketplacesTable from '../components/settings/ManageMarketplaces/MarketplacesTable';
import CancelledShipments from "../components/stages/CancelledShipments";
import CarrierTable from "../components/settings/ManageCarriers/CarrierTable";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import PurchaseOrders from "../components/purchaseOrders/PurchaseOrders";
import Returns from "../components/returns/Returns";
import Tickets from "../components/tickets/Tickets"
import SupplierInvoices from "../components/invoices/supplierInvoices/SupplierInvoices"
import SupplierInvoiceTemplates from "../components/settings/InvoiceTemplates/supplierInvoiceTemplates/SupplierInvoiceTemplates"
import CarrierInvoices from "../components/invoices/carrierInvoices/CarrierInvoices";
import CarrierInvoiceTemplates from "../components/settings/InvoiceTemplates/carrierInvoiceTemplates/CarrierInvoiceTemplates";
import InvoiceTemplates from "../components/settings/InvoiceTemplates/InvoiceTemplates";
import ManageArchiveLabelTable from "../components/settings/ManageArchiveLabels/ManageArchiveLabel";
import {
    ROUTE_TYPE
} from './constants';

// Icons import
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import DashboardIcon from "@material-ui/icons/Dashboard";
import InsightsIcon from '@mui/icons-material/Insights';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import DoneIcon from '@material-ui/icons/Done';
import InTransitIcon from "../components/leftmenu/InTransitIcon";
import TuneIcon from '@material-ui/icons/Tune';
import SettingsIcon from "@material-ui/icons/Settings";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import CancelIcon from '@material-ui/icons/Cancel';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import Inventory from "../components/inventory/Inventory";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PrintIcon from '@material-ui/icons/Print';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ManageProductsTable from "../components/settings/ManageProducts/ManageProductsTable";
import ManageSuppliersTable from "../components/settings/ManageSuppliers/ManageSuppliersTable";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import StoreIcon from '@material-ui/icons/Store';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AssignmentIcon from '@material-ui/icons/Assignment';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import ManageLocationsTable from "../components/settings/ManageLocations/ManageLocationsTable";
import ManagePrintersTable from "../components/settings/ManagePrinters/ManagePrintersTable";
import ManageTagsTable from "../components/settings/ManageTags/ManageTagsTable"
import RefreshOrder from "../components/settings/ControlPanel/RefreshOrder/RefreshOrder";
import RecreateShipment from "../components/settings/ControlPanel/RecreateShipment/RecreateShipment";
import {AllInbox,History} from "@material-ui/icons";
import ManagePackagesTable from "../components/settings/ManagePackaging/ManagePackagesTable";
import ClonedShipments from "../components/stages/ClonedShipments";
import ReturnShipments from "../components/stages/ReturnShipments";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {AccountTree} from "@material-ui/icons";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ScrubData from "../components/settings/ControlPanel/ScrubData/ScrubData";
// import FetchOrderId from "../components/settings/ControlPanel/FetchOrderId/FetchOrderId";
import DataUsageIcon from '@material-ui/icons/DataUsage';
import ManageProductCategory from "../components/settings/ManageProductCatagory/ManageProductCategory";
import CategoryIcon from '@material-ui/icons/Category';
import SettingsInputComponentIcon from "@material-ui/icons/SettingsInputComponent";
import EmailIcon from '@material-ui/icons/Email';
import ManageEmailTemplates from "../components/settings/ManageEmail/ManageEmailTemplates";
import RateReviewIcon from '@material-ui/icons/RateReview';
import FeedbackRequestTable from "../components/feedbackRequest/FeedbackRequestTable";
import {CloudDownloadOutlined} from "@material-ui/icons";
import FetchOrder from "../components/settings/ControlPanel/FetchOrder/FetchOrder";

import BrokenImageIcon from '@material-ui/icons/BrokenImage';

import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ManageAmazonTable from "../components/settings/ManageAmazon/ManageAmazonTable";
import Damages from "../components/damages/Damages";
import Listings from "../components/settings/ManageListings/Listings";
import Barcodes from "../components/settings/ManageBarcodes/Barcodes";
import FullTruckload from "../components/fullTruckload/FullTruckload";
import Claims from "../components/claims/Claims";
import ManageBankAccounts from "../components/settings/ManageBankAccounts/ManageBankAccounts";
import {ReceiptLong} from "@mui/icons-material";
import Transactions from "../components/accounting/transactions/Transactions";
import ImportShiprushData from "../components/settings/ManageHistoricalData/ImportShiprushData";
import ImportClaimsData from "../components/settings/ManageHistoricalData/ImportClaimsData";
import ImportFTLData from "../components/settings/ManageHistoricalData/ImportFTLData";
import ManageCompany from "../components/settings/ManageCompany/ManageCompany";
import Cheques from "../components/accounting/cheques/Cheques";
import ManageCustomerTable from "../components/settings/ManageCustomer/ManageCustomerTable";
import CustomerInvoices from "../components/customer/customerInvoices/CustomerInvoices";
import PurchaseCustomerOrders from "../components/customer/customerOrders/PurchaseCustomerOrders";
import ShippingSummary from "../components/accounting/shippingSummary/ShippingSummary";
import PurchaseSummary from "../components/accounting/purchaseSummary/PurchaseSummary";
import StatementTaxes from "../components/accounting/statementTaxes/StatementTaxes";

/**
 * {
 *  id: Route id,
 *  name: Name of route,
 *  path: location,
 *  component: React component,
 *  icon: icon name for sidebar,
 *  sidebar: show on sidebar link or no, true or false
 * }
 */

export const routes = [
    {
        type: ROUTE_TYPE.MENU_ITEM,
        id: 'login',
        label: 'Login',
        icon: VpnKeyIcon,
        path: '/login',
        component: Login,
        sidebar: false
    },
]
export const protectedRoutes = [
    {
        type: ROUTE_TYPE.MENU_ITEM,
        id: 'dashboard',
        label: 'Dashboard',
        icon: DashboardIcon,
        path: '/dashboard',
        // component: Playground,
        component: DashboardFinal, // just change to Dashboard to revert to original Dashboard test component
        sidebar: true,
    },
    {
        type: ROUTE_TYPE.DIVIDER,
        sidebar: true,
    },
    {
        type: ROUTE_TYPE.SUB_MENU,
        id: 'shipments',
        label: 'Shipments',
        icon: LocalShippingIcon,
        sidebar: true,
        submenuItems: [{
            type: ROUTE_TYPE.MENU_ITEM,
            id: 'pending-shipments',
            label: 'Pending',
            icon: ScheduleIcon,
            path: '/shipments/pending',
            component: PendingShipments,
            sidebar: true,
        },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'cloned-shipments',
                label: 'Cloned',
                icon: FileCopyIcon,
                path: '/shipments/cloned',
                component: ClonedShipments,
                sidebar: true,
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'return-shipments',
                label: 'Return / Redirect',
                icon: AssignmentReturnIcon,
                path: '/shipments/returns',
                component: ReturnShipments,
                sidebar: true,
            },
            {
                type: ROUTE_TYPE.DIVIDER,
                sidebar: true,
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'shipped-shipments',
                label: 'Shipped',
                icon: MoveToInboxIcon,
                path: '/shipments/shipped',
                component: ShippedShipments,
                sidebar: true,
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'cancelled-shipments',
                label: 'Cancelled',
                icon: CancelIcon,
                path: '/shipments/cancelled',
                component: CancelledShipments,
                sidebar: true,
            },
            {
                type: ROUTE_TYPE.DIVIDER,
                sidebar: true,
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'ftl',
                label: 'LTL/FTL',
                icon: LocalShippingIcon,
                path: '/ftl',
                component: FullTruckload,
                sidebar: true,
            },
            // No need since we have status bar now
            // {
            //   type: ROUTE_TYPE.DIVIDER,
            //   sidebar: true,
            // },
            // {
            //   type: ROUTE_TYPE.MENU_ITEM,
            //   id: 'in-transit-shipments',
            //   label: 'In-Transit',
            //   icon: InTransitIcon,
            //   path: '/shipments/intransit',
            //   component: ShipmentsTable,
            //   sidebar: true,
            // },
            // {
            //   type: ROUTE_TYPE.MENU_ITEM,
            //   id: 'delivered-shipments',
            //   label: 'Delivered',
            //   icon: DoneIcon,
            //   path: '/shipment/delivered',
            //   component: ShipmentsTable,
            //   sidebar: true,
            // },
            // {
            //   type: ROUTE_TYPE.MENU_ITEM,
            //   id: 'delivery-expection-shipments',
            //   label: 'Delivery Exceptions',
            //   icon: ErrorOutlineIcon,
            //   path: '/shipment/exceptions',
            //   component: ShipmentsTable,
            //   sidebar: true,
            // }
        ],
    },
    {
        type: ROUTE_TYPE.DIVIDER,
        sidebar: true,
    },
    {
        type: ROUTE_TYPE.MENU_ITEM,
        id: 'purchase-orders',
        label: 'Purchase Orders',
        icon: InsertDriveFileIcon,
        sidebar: true,
        path: '/purchase-orders',
        component: PurchaseOrders,
    },
    {
        type: ROUTE_TYPE.MENU_ITEM,
        id: 'inventory',
        label: 'Inventory',
        icon: ShowChartIcon,
        sidebar: true,
        path: '/inventory',
        component: Inventory,
    },
    {
        type: ROUTE_TYPE.MENU_ITEM,
        id: 'damages',
        label: 'Damages',
        icon: BrokenImageIcon,
        sidebar: true,
        path: '/damages',
        component: Damages,
    },
    {
        type: ROUTE_TYPE.DIVIDER,
        sidebar: true,
    },
    {
        type: ROUTE_TYPE.SUB_MENU,
        id: 'invoices',
        label: 'Invoices',
        icon: ReceiptIcon,
        sidebar: true,
        submenuItems: [
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'supplier-invoices',
                label: 'Supplier Invoices',
                icon: AssignmentIcon,
                sidebar: true,
                path: '/invoices/supplierInvoices',
                component: SupplierInvoices
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'carrier-invoices',
                label: 'Carrier Invoices',
                icon: AssignmentIcon,
                sidebar: true,
                path: '/invoices/carrierInvoices',
                component: CarrierInvoices
            },
        ]
    },
    {
        type: ROUTE_TYPE.DIVIDER,
        sidebar: true,
    },
    {
        type: ROUTE_TYPE.SUB_MENU,
        id: 'customer',
        label: 'Customer',
        icon: SwitchAccountIcon,
        sidebar: true,
        submenuItems: [
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'customer-orders',
                label: 'Customer Orders',
                icon: InsertDriveFileIcon,
                sidebar: true,
                path: '/customer/customer-orders',
                component: PurchaseCustomerOrders
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'customer-invoices',
                label: 'Customer Invoices',
                icon: AssignmentIcon,
                sidebar: true,
                path: '/customer/customer-invoices',
                component: CustomerInvoices
            },
        ]
    },
    {
        type: ROUTE_TYPE.DIVIDER,
        sidebar: true,
    },
    {
        type: ROUTE_TYPE.SUB_MENU,
        id: 'accounting',
        label: 'Accounting',
        sidebar: true,
        submenuItems: [
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'transactions',
                label: 'Transactions',
                icon: ReceiptLong,
                sidebar: true,
                path: '/accounting/transactions',
                component: Transactions
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'cheques',
                label: 'Cheques',
                icon: ReceiptLong,
                sidebar: true,
                path: '/accounting/cheques',
                component: Cheques
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'shipping-summary',
                label: 'Shipping Summary',
                icon: ReceiptLong,
                sidebar: true,
                path: '/accounting/shippingSummary',
                component: ShippingSummary
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'purchase-summary',
                label: 'Purchase Summary',
                icon: ReceiptLong,
                sidebar: true,
                path: '/accounting/purchaseSummary',
                component: PurchaseSummary
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'statement-taxes',
                label: 'Statement of Taxes',
                icon: ReceiptLong,
                sidebar: true,
                path: '/accounting/statementTaxes',
                component: StatementTaxes
            }
        ]
    },
    {
        type: ROUTE_TYPE.DIVIDER,
        sidebar: true,
    },
    {
        type: ROUTE_TYPE.MENU_ITEM,
        id: 'claims',
        label: 'Claims',
        icon: TextSnippetIcon,
        path: '/claims',
        component: Claims,
        sidebar: true,
    },
    {
        type: ROUTE_TYPE.DIVIDER,
        sidebar: true,
    },
    {
        type: ROUTE_TYPE.SUB_MENU,
        id: 'customer-service',
        label: 'Customer Service',
        icon: PeopleAltIcon,
        sidebar: true,
        submenuItems: [{
            type: ROUTE_TYPE.MENU_ITEM,
            id: 'tickets',
            label: 'Tickets',
            icon: ReceiptIcon,
            path: '/customer-service/tickets',
            component: Tickets,
            sidebar: true,
        },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'returns',
                label: 'Returns',
                icon: AssignmentReturnIcon,
                path: '/customer-service/returns',
                component: Returns,
                sidebar: true,
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'feedback-request',
                label: 'Feedback Request',
                icon: RateReviewIcon,
                path: '/customer-service/feedback-request',
                component: FeedbackRequestTable,
                sidebar: true,
            }
        ],
    },

    {
        type: ROUTE_TYPE.DIVIDER,
        sidebar: true,
    },
    {
        type: ROUTE_TYPE.MENU_ITEM,
        id: 'pricing-dashboard',
        label: 'Pricing Dashboard',
        icon: InsightsIcon,
        path: '/pricing-dashboard',
        component: PricingDashboard,
        sidebar: true,
    },


    {
        type: ROUTE_TYPE.MENU_ITEM,
        id: 'automation-rules',
        label: 'Automation Rules',
        icon: TuneIcon,
        path: '/automation-rules',
        component: AutomationsRules,
        sidebar: true,
    },
    {
        type: ROUTE_TYPE.DIVIDER,
        sidebar: true,
    },
    {
        type: ROUTE_TYPE.MENU_ITEM,
        id: 'rules-stepper',
        label: 'Create Rule',
        icon: AutomationsRules,
        path: '/rules/create',
        component: AutomationRulesStepper,
        sidebar: false
    },
    {
        type: ROUTE_TYPE.SUB_MENU,
        id: 'settings',
        label: 'Setup',
        icon: SettingsIcon,
        sidebar: true,
        submenuItems: [
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'company',
                label: 'Company',
                icon: StoreIcon ,
                sidebar: true,
                path: '/settings/company',
                component: ManageCompany
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'locations',
                label: 'Locations',
                icon: LocationOnIcon,
                sidebar: true,
                path: '/settings/locations',
                component: ManageLocationsTable
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'product-category',
                label: 'Product Category',
                icon: CategoryIcon,
                sidebar: true,
                path: '/settings/product-category',
                component: ManageProductCategory
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'products',
                label: 'Products',
                icon: ListAltIcon,
                sidebar: true,
                path: '/settings/products',
                component: ManageProductsTable
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'packaging',
                label: 'Packaging',
                icon: AllInbox,
                sidebar: true,
                path: '/settings/packages',
                component: ManagePackagesTable
            },
            {
                type: ROUTE_TYPE.DIVIDER,
                sidebar: true,
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'suppliers',
                label: 'Suppliers',
                icon: EmojiTransportationIcon,
                sidebar: true,
                path: '/settings/suppliers',
                component: ManageSuppliersTable
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'carriers',
                label: 'Carriers',
                icon: CardTravelIcon,
                sidebar: true,
                path: '/settings/carriers',
                component: CarrierTable
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'customers',
                label: 'Customers',
                icon: ManageAccountsIcon,
                sidebar: true,
                path: '/customer/manage-customer',
                component: ManageCustomerTable
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'bank-accounts',
                label: 'Bank Accounts',
                icon: CreditCardIcon,
                sidebar: true,
                path: '/settings/bankAccounts',
                component: ManageBankAccounts
            },
            {
                type: ROUTE_TYPE.DIVIDER,
                sidebar: true,
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'marketplaces',
                label: 'Marketplaces',
                icon: LocalGroceryStoreIcon,
                sidebar: true,
                path: '/settings/marketplaces',
                component: ManageMarketplacesTable
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'listings',
                label: 'Listings',
                icon: AccountTree,
                sidebar: true,
                path: '/settings/listings',
                component: Listings
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'barcodes',
                label: 'Barcodes',
                icon: StickyNote2Icon,
                sidebar: true,
                path: '/settings/barcodes',
                component: Barcodes
            },
            {
                type: ROUTE_TYPE.DIVIDER,
                sidebar: true,
            },
            // {
            //     type: ROUTE_TYPE.MENU_ITEM,
            //     id: 'invoice-templates',
            //     label: 'Invoice Templates',
            //     icon: SettingsIcon,
            //     sidebar: true,
            //     path: '/settings/InvoiceTemplates',
            //     component: InvoiceTemplates
            // },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'email-templates',
                label: 'Email Templates',
                icon: EmailIcon,
                sidebar: true,
                path: '/settings/email-templates',
                component: ManageEmailTemplates
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'tags',
                label: 'Tags',
                icon: LoyaltyIcon,
                sidebar: true,
                path: '/settings/tags',
                component: ManageTagsTable
            },
            {
                type: ROUTE_TYPE.DIVIDER,
                sidebar: true,
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'printers',
                label: 'Printers',
                icon: PrintIcon,
                sidebar: true,
                path: '/settings/printers',
                component: ManagePrintersTable
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'manage-users',
                label: 'Users',
                icon: SupervisorAccountIcon,
                sidebar: true,
                path: '/settings/users',
                component: ManageUsersTable
            },
        ]
    },
    {
        type: ROUTE_TYPE.DIVIDER,
        sidebar: true,
    },
    {
        type: ROUTE_TYPE.SUB_MENU,
        id: 'historical',
        label: 'Historical',
        icon: History,
        sidebar: true,
        submenuItems: [
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'importShiprush',
                label: 'Shiprush',
                icon: CloudDownloadOutlined,
                sidebar: true,
                path: '/settings/shiprush-import',
                component: ImportShiprushData
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'importClaims',
                label: 'Claims',
                icon: CloudDownloadOutlined,
                sidebar: true,
                path: '/settings/claims-import',
                component: ImportClaimsData
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'importFTL',
                label: 'FTL',
                icon: CloudDownloadOutlined,
                sidebar: true,
                path: '/settings/ftl-import',
                component: ImportFTLData
            }
        ]
    },
    {
        type: ROUTE_TYPE.SUB_MENU,
        id: 'control-panel',
        label: 'Control Panel',
        icon: LocalShippingIcon,
        sidebar: true,
        submenuItems: [
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'archive-label',
                label: 'Archive Label',
                icon: ReceiptIcon,
                sidebar: true,
                path: '/settings/archive-labels',
                component: ManageArchiveLabelTable
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'fetch-order',
                label: 'Fetch Order',
                icon: CloudDownloadOutlined,
                sidebar: true,
                path: '/settings/fetch-order',
                component: FetchOrder
            },
            /*
            {
            type: ROUTE_TYPE.MENU_ITEM,
            id: 'refresh-order',
            label: 'Refresh Order',
            icon: FindReplaceIcon,
            sidebar: true,
            path: '/settings/refresh-order',
            component: RefreshOrder
            },
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'recreate-shipment',
                label: 'Recreate Shipment',
                icon: SettingsBackupRestoreIcon,
                sidebar: true,
                path: '/settings/recreate-shipment',
                component: RecreateShipment
            },
            */
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'scrub-data',
                label: 'Scrub Data',
                icon: DataUsageIcon,
                sidebar: true,
                path: '/settings/scrub-data',
                component: ScrubData
            },
        ],
    },
    {
        type: ROUTE_TYPE.SUB_MENU,
        id: 'feeds',
        label: 'Feeds',
        icon: PeopleAltIcon,
        sidebar: true,
        submenuItems: [
            {
                type: ROUTE_TYPE.MENU_ITEM,
                id: 'feeds-amazon',
                label: 'Amazon',
                icon: AccountTreeIcon,
                sidebar: true,
                path: '/feeds/feeds-amazon',
                component: ManageAmazonTable
            },
        ],
    },
];

export const getSubmenuRoutes = (submenuItems) => {
    let submenuRoutes = [];
    submenuItems.map(item => {
        if (item.type === ROUTE_TYPE.MENU_ITEM) {
            submenuRoutes = [...submenuRoutes, item];
        } else if (item.type === ROUTE_TYPE.SUB_MENU) {
            submenuRoutes = [...submenuRoutes, ...getSubmenuRoutes(item.submenuItems)]
        }

        return item;
    });

    return submenuRoutes;
}

export const getRoutes = () => {
    return [...getSubmenuRoutes(protectedRoutes)]
}