import React from "react";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import {
    PURCHASE_SUMMARY,
    PURCHASE_SUMMARY_FIELDS,
} from "./constants";
import { Layout } from "../../global/Table/TablePageHelpers/LayoutObject";
import { TableCell, Chip, Select, MenuItem, FormControl, InputLabel, Box } from "@mui/material";
import TablePage from "../../global/Table/TablePage";
import '../shippingSummary/ShippingSummary.css';

class PurchaseSummaryTable extends React.Component {
    constructor(props) {
        super(props)
    }


    getTableHeaders = () => {
        let headers = []
        PURCHASE_SUMMARY_FIELDS.forEach((headCell) => {
            headers.push(Layout.columnHead(headCell.label, headCell.id, "center"))
        })
        return headers
    }


    formatDollar = (amount) => {
        if (amount === null || amount === undefined) {
            amount = 0
        }
        const amountStr = parseFloat(amount).toFixed(2).toString()

        return (<div className="dollar-amount">
            <span className="dollar-sign">$</span>
            <span>{amountStr.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</span>
        </div>)
    }

    tableRow = (suppliers) => {
        return (
                <React.Fragment>
                    <TableCell className="custom-cell center ">{suppliers?.supplierName}</TableCell>
                    <TableCell className="custom-cell">{this.formatDollar(suppliers?.PY_SUBTOTAL_PAYABLE)}</TableCell>
                    <TableCell className="custom-cell">{this.formatDollar(suppliers?.PY_TAX_PAYABLE)}</TableCell>
                    <TableCell className="custom-cell">{this.formatDollar(suppliers?.CY_SUBTOTAL)}</TableCell>
                    <TableCell className="custom-cell ">{this.formatDollar(suppliers?.CY_TAX)}</TableCell>
                    <TableCell className="custom-cell ">{this.formatDollar(suppliers?.CY_SUBTOTAL_PAYABLE)}</TableCell>
                    <TableCell className="custom-cell ">{this.formatDollar(suppliers?.CY_TAX_PAYABLE)}</TableCell>
                    <TableCell className="custom-cell ">{this.formatDollar(suppliers?.CY_TOTAL_PAYABLE)}</TableCell>

                </React.Fragment>
            )
    }

    searchStrings = (suppliers) => {
        return [suppliers.supplierName]
    }

    getMonthsForQuarter = () => {
        const { selectedQuarters } = this.props;

        if (!selectedQuarters) {
            return [
                { name: 'January', value: '01' },
                { name: 'February', value: '02' },
                { name: 'March', value: '03' },
                { name: 'April', value: '04' },
                { name: 'May', value: '05' },
                { name: 'June', value: '06' },
                { name: 'July', value: '07' },
                { name: 'August', value: '08' },
                { name: 'September', value: '09' },
                { name: 'October', value: '10' },
                { name: 'November', value: '11' },
                { name: 'December', value: '12' },
            ]
        } else {
            switch (selectedQuarters) {
                case 1:
                    return [  { name: 'January', value: '01' },
                        { name: 'February', value: '02' },
                        { name: 'March', value: '03' }]
                case 2:
                    return [
                        { name: 'April', value: '04' },
                        { name: 'May', value: '05' },
                        { name: 'June', value: '06' }]
                case 3:
                    return [
                        { name: 'July', value: '07' },
                        { name: 'August', value: '08' },
                        { name: 'September', value: '09'}]
                case 4:
                    return [
                        { name: 'October', value: '10' },
                        { name: 'November', value: '11' },
                        { name: 'December', value: '12' }]
                default:
                    return [];
            }
        }
    }


    render() {
        const currency = ['CAD', 'USD']
        const currentYear = new Date().getFullYear()
        const years = Array.from(new Array(21), (val, index) => currentYear - index)
        const { selectedCurrency, handleCurrencyChange, selectedMonth, handleMonthChange,shippingSummary, selectedYear, selectedQuarters, handleYearChange, handleQuarterChange } = this.props

        return (
            <React.Fragment>
                <TopToolbar
                    pageName={PURCHASE_SUMMARY}
                />


                <Box className="box">
                    <FormControl variant="outlined" className="form-control">
                        <InputLabel id="currency-label">Currency</InputLabel>
                        <Select
                            className="select"
                            labelId="currency-label"
                            value={selectedCurrency}
                            onChange={handleCurrencyChange}
                            label="Currency"
                        >
                            {currency.map((currency) => (
                                <MenuItem key={currency} value={currency}>
                                    {currency}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" className="form-control">
                        <InputLabel id="year-label">Year</InputLabel>
                        <Select
                            className="select-year"
                            labelId="year-label"
                            value={selectedYear}
                            onChange={handleYearChange}
                            label="Year"
                        >
                            {years.map((year) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" className="form-control">
                        <InputLabel id="month-label">Month</InputLabel>
                        <Select
                            className="select-month"
                            label="Month"
                            labelId="month-label"
                            value={selectedMonth}
                            onChange={handleMonthChange}>
                            <MenuItem value="">None</MenuItem>
                            {this.getMonthsForQuarter().map((month) => (
                                <MenuItem key={month.value} value={month.value}>
                                    {month.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <div className="chip-container">
                        {["QTR 1", "QTR 2", "QTR 3", "QTR 4"].map((quarter, index) => (
                            <Chip
                                key={index}
                                label={quarter}
                                onClick={() => handleQuarterChange(quarter)}
                                className={`chip ${selectedQuarters === index + 1 ? 'chip-selected' : ''}`}
                            />
                        ))}
                    </div>
                </Box>

                <div className="table-page-summary">
                    <TablePage
                        tableName="SummaryTable"
                        tableRows={shippingSummary}
                        tableColumnHeads={this.getTableHeaders()}
                        tableRow={this.tableRow}
                        paginationText="Summary per page"
                        hasSearchField
                        searchStrings={this.searchStrings}
                    />
                </div>
                {shippingSummary.length > 0 && <div className="total-field purchase">
                    <div className="dollar-amount-total">
                        <span className="dollar-sign-total">$</span>
                        <span>{ parseFloat(this.props.supplierTotal).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</span>
                    </div>
                </div>
                }
            </React.Fragment>
        )
    }
}

export default PurchaseSummaryTable;
