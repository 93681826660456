import React from 'react'

// Material UI
import {
    Divider,
    TableBody,
    TableFooter,
    TableHead,
    TableSortLabel,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";

// Constants
import {
    NEEDS_REVIEW_STATUS,
    APPROVE_STATUS,
    INVOICE_ITEM_CELLS_LEFT,
    INVOICE_ITEM_CELLS_RIGHT,
    ATTACHMENTS_INFO, INVOICE_ITEM_CELLS
} from "./constants";

// Styles
import "./EditInvoices.css"

// Custom Components
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import PDFViewer from "../../global/PDFViewer";
import {IconButton} from "@material-ui/core"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import PropTypes from "prop-types";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import styles from "../../../pages/Dashboard/DashboardFinal/DashboardFinal.module.css";
import DescriptionIcon from "@material-ui/icons/Description";
import FileUpload from "../../global/FileUpload";
import {getByteArray} from "../../global/constants";
import {PACKING_SLIP_INFO} from "../../purchaseOrders/constants";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {Tooltip} from "@mui/material";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";

const CurrencyCodeField = withStyles({
    root: {
        width: "10%",
        paddingLeft: "5px",
        paddingRight: "5px"
    },
})(TextField);

const StyledTextField = withStyles({
    root: {
        paddingLeft: "5px",
        paddingRight: "5px"
    }
})(TextField)

const LeftMostTextField = withStyles({
    root: {
        paddingRight: "5px"
    }
})(TextField)

const RightMostTextField = withStyles({
    root: {
        paddingLeft: "5px",
    },
})(TextField)

const StyledDateField = withStyles({
    root: {
        paddingLeft: "5px",
        paddingRight: "5px",
        width: "20%"
    }
})(KeyboardDatePicker)

class CustomerCreateInvoices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteIndex: -1,
            customerInvoiceItems: [
            ],
            customerName: null,
            poNumber: null,
            invoiceNumber: null,
            date: new Date(),
            taxPercentage: '0',
            currencyCode: null,
            status: '',
            amountSubTotal: null,
            invoiceSubTotal: null,
            shippingAmount: '0.00',
            taxSubTotal: null,
            notes: '',
            statusText: [],
            invoiceTotal: null,
            invoiceTax: null,
            itemError: { index: -1, id: "" },
            customer: null,
        }
    }
    handleDateChange = (date) => {
        this.setState({ date });
    };


    handleItemDelete = (index) => {
        let tempItems = [...this.state.customerInvoiceItems]
        tempItems.splice(index, 1)
        this.setState({ customerInvoiceItems: tempItems });
    }
    addItem = () => {
        let newItem = { ...this.state.customerInvoiceItems[0], customerInvoiceItemId: "" }
        INVOICE_ITEM_CELLS.map(cell => newItem[cell.id] = "")
        let tempItems = [...this.state.customerInvoiceItems, newItem]
        this.setState({ customerInvoiceItems: tempItems });
    }


    parseNumber = (string) => {
        return parseFloat(string?.toString().replaceAll(',',''))
    }
    formatDollar = (amount, forceTwoDecimals) => { // Regex expression taken from https://stackoverflow.com/questions/2901102
        let value

        if (!amount) {
            return "";
        }

        if (forceTwoDecimals) {
            value = this.parseNumber(amount).toFixed(2)
        } else {
            // if the amount has more than two decimal places, limit it to two
            if (amount.match(/.*\.\d{2}.*/)) {
                try {
                    value = this.parseNumber(amount).toFixed(2)
                } catch {
                    value = amount.replaceAll(',','')
                }
            } else {
                value = amount.replaceAll(',','')
            }
        }

        return value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    blurEditing = (name, value, index) => {
        const { customerInvoiceItems } = this.state;

        switch (name) {
            case 'quantity':
                customerInvoiceItems[index][name] = isNaN(parseFloat(value)) ? '0' : this.parseNumber(value);
                break;
            case 'unitPrice':
            case 'discount':
            case 'amount':
            case 'tax':
                customerInvoiceItems[index][name] = isNaN(parseFloat(value)) ? '0.00' : this.formatDollar(value, true);
                break;
            case 'shippingAmount':
            case 'invoiceSubTotal':
            case 'invoiceTax':
                this.setState({
                    [name]: isNaN(parseFloat(value)) ? '0.00' : this.formatDollar(value, true)
                });
                break;
            default:
                break;
        }

        this.setState({
            customerInvoiceItems: [...customerInvoiceItems]
        });
    };

    handleChange = (name, value, index) => {
        const calculateShipping = () => {
            let shippingAmount = parseFloat((this.state.shippingAmount || '0').replace(/,/g, '')) || 0;
            let amountSubTotal = parseFloat(this.state.amountSubTotal) || 0;
            let taxPercentage = parseFloat(this.state.taxPercentage) || 0;
            let newTax = ((amountSubTotal + shippingAmount) * (taxPercentage / 100)).toFixed(2);
            let totalInvoice = (amountSubTotal + shippingAmount + parseFloat(newTax)).toFixed(2);

            this.setState({
               // shippingAmount: shippingAmount.toFixed(2),
                invoiceTax: newTax,
                invoiceTotal: totalInvoice,
            });
        };

        this.props.passStateToParent(this.state);

        if (index !== -1) {
            let customerInvoiceItems = [...this.state.customerInvoiceItems];
            let item = customerInvoiceItems[index];
            item[name] = value;

            if (name === 'quantity' || name === 'unitPrice' || name === 'discount' || name === 'tax') {
                // Calculate amount
                let quantity = parseFloat(item['quantity']) || 0;
                let unitPrice = parseFloat(item['unitPrice']) || 0;
                let discount = parseFloat(item['discount']) || 0;
                let amount = (quantity * unitPrice) - discount;

                // Calculate tax
                let taxPercentage = parseFloat(this.state.taxPercentage) || 0;
                let tax = (amount * (taxPercentage / 100)).toFixed(2);

                // Update item with calculated values
                item['amount'] = amount.toFixed(2);
                item['tax'] = tax;
                customerInvoiceItems[index] = item;

                // Calculate subtotals
                let amountSubTotal = customerInvoiceItems.reduce((acc, item) => acc + parseFloat(item['amount']) || 0, 0);
                let taxSubTotal = customerInvoiceItems.reduce((acc, item) => acc + parseFloat(item['tax']) || 0, 0);

                // Update state with new values
                this.setState({
                    customerInvoiceItems,
                    amountSubTotal: amountSubTotal.toFixed(2),
                    taxSubTotal: taxSubTotal.toFixed(2),
                    invoiceSubTotal: amountSubTotal.toFixed(2),
                    invoiceTax: taxSubTotal.toFixed(2),
                    invoiceTotal: (amountSubTotal + taxSubTotal).toFixed(2),
                } , calculateShipping);
            } else {
                this.setState({ customerInvoiceItems }); // , calculateShipping
            }
        } else {
            if (name === 'taxPercentage') {
                let customerInvoiceItems = [...this.state.customerInvoiceItems];
                customerInvoiceItems.forEach((item, index) => {
                    let quantity = parseFloat(item['quantity']) || 0;
                    let unitPrice = parseFloat(item['unitPrice']) || 0;
                    let discount = parseFloat(item['discount']) || 0;
                    let amount = (quantity * unitPrice) - discount;
                    let tax = (amount * (parseFloat(value) / 100)).toFixed(2);

                    // Update item with new tax value
                    item['tax'] = tax;
                    customerInvoiceItems[index] = item;
                });

                // Calculate amount subtotal and tax subtotal
                let amountSubTotal = customerInvoiceItems.reduce((acc, item) => acc + parseFloat(item['amount']) || 0, 0);
                let taxSubTotal = customerInvoiceItems.reduce((acc, item) => acc + parseFloat(item['tax']) || 0, 0);

                // Update state with new values
                this.setState({
                    customerInvoiceItems,
                    amountSubTotal: amountSubTotal.toFixed(2),
                    taxSubTotal: taxSubTotal.toFixed(2),
                    invoiceSubTotal: amountSubTotal.toFixed(2),
                    invoiceTax: taxSubTotal.toFixed(2),
                    invoiceTotal: (amountSubTotal + taxSubTotal + parseFloat(this.state.shippingAmount || 0)).toFixed(2),
                    [name]: value,
                }, calculateShipping);
            } else if (name === 'shippingAmount') {
                this.setState({
                    [name]: value,
                }, calculateShipping);
            } else {
                this.setState({ [name]: value }, calculateShipping);
            }
        }
    };

    render() {
        return (<>
            {this.props.viewing && <PDFViewer data={this.props.pdfData} scale={this.props.scale} /> ||
            <div className={"invoiceWrapper"}>
                <div className="infoRow">
                    <div style={{width:"200px"}}>
                    <Autocomplete
                        options={this.props.customers}
                        getOptionLabel={(entry) => entry.name}
                        autoHighlight={!this.props.customers}
                        value={this.props.customers.find(customer => customer.name === this.state.customerName) || null}
                        onChange={(event, value) => {

                            this.setState({
                                customerName : value.name,
                                customer : value
                            })
                            }
                        }
                        blurOnSelect={true}
                        renderInput={(params) =>
                            <LeftMostTextField
                                {...params}
                                error={!this.state.customerName}
                                label="Customer Name"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                name="customer"
                                onChange={() => { }}

                            />
                        }
                    />
                    </div>
                    <StyledTextField
                        variant="outlined"
                        label="PO Number"
                        InputLabelProps={{ shrink: true }}
                        name="poNumber"
                        value={this.state.poNumber}
                        error={!this.state.poNumber}
                        onChange={(e) => this.handleChange(e.target.name, e.target.value,-1)}
                        // onBlur={(e) => {
                        //     this.handleChange(e.target.name, e.target.value.trim())
                        //     this.props.handlePONumberChange(e.target.value)
                        // }}
                    />
                    <StyledTextField
                        variant="outlined"
                        label="Invoice Number"
                        InputLabelProps={{ shrink: true }}
                        name="invoiceNumber"
                        value={this.state.invoiceNumber}
                        error={!this.state.invoiceNumber}
                        onChange={(e) => this.handleChange(e.target.name, e.target.value,-1)}
                    />
                    <StyledDateField
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Date"
                        format="yyyy-MM-dd"
                        value={new Date(this.state.date).setDate(new Date(this.state.date).getDate())}
                        onChange={this.handleDateChange}
                    />
                    <StyledTextField
                        style={{width:'15%'}}
                        variant="outlined"
                        label="Tax Percentage"
                        InputLabelProps={{ shrink: true }}
                        name="taxPercentage"
                        value={this.state.taxPercentage}
                        onChange={(e) => this.handleChange(e.target.name, e.target.value,-1)}
                        onBlur={(e) => this.blurEditing(e.target.name, e.target.value, -1)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    %
                                </InputAdornment>
                            )
                        }}
                    />
                    <CurrencyCodeField
                        variant="outlined"
                        label="Currency"
                        InputLabelProps={{ shrink: true }}
                        name="currencyCode"
                        value={this.state.currencyCode}
                        error={!this.state.currencyCode}
                        onChange={(e) => this.handleChange(e.target.name, e.target.value,-1)}
                    />
                    <RightMostTextField
                        variant="outlined"
                        label="Invoice Status"
                        InputLabelProps={{shrink: true}}
                        name="invoicestatus"
                        disabled
                        value={this.state.status ?? ""}
                    />
                </div>
                <br />
                <Divider className='divider' />
                <div className="lineItems">
                    <p>Line Items</p>
                    {this.state.customerInvoiceItems.map((invoiceItem, index) => (
                        <React.Fragment key={index}>
                            <div className="lineItemRow">
                                {INVOICE_ITEM_CELLS_LEFT.map((item, i) => (
                                    <TextField
                                        style={{ width: "10%" }}
                                        key={"item" + i}
                                        variant="outlined"
                                        label={item.label + "*"}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ style: { textAlign: item.rightAlign ? "right" : "left" } }}
                                        InputProps={{ startAdornment: <InputAdornment position="start">{item.addDollar ? "$" : ""}</InputAdornment>, readOnly: item.readOnly }}
                                        name={item.id}
                                        value={item.addDollar ? this.formatDollar(invoiceItem[item.id]) : invoiceItem[item.id]}
                                        error={!(item.addDollar ? this.formatDollar(invoiceItem[item.id]) : invoiceItem[item.id])}
                                        helperText={this.state.itemError.index === index && this.state.itemError.id === item.id ? "Field is required" : ""}
                                        onChange={(e) => this.handleChange(e.target.name, e.target.value, index)}
                                        onBlur={(e) => this.blurEditing(e.target.name, e.target.value, index)}
                                    />
                                ))}
                                <div style={{ width: "10%", display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                                    <TextField
                                        style={{ width: "100%" }}
                                        key={"item" + INVOICE_ITEM_CELLS_LEFT.length}
                                        variant="outlined"
                                        label={"Discount*"}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ style: { textAlign: "right" } }}
                                        InputProps={{ startAdornment: <InputAdornment position="start">{"$"}</InputAdornment>, readOnly: false }}
                                        name={'discount'}
                                        value={this.formatDollar(invoiceItem['discount'])}
                                        error={!(this.formatDollar(invoiceItem['discount']))}
                                        helperText={this.state.itemError.index === index && this.state.itemError.id === 'discount' ? "Field is required" : ""}
                                        onChange={(e) => this.handleChange(e.target.name, e.target.value, index)}
                                       onBlur={(e) => this.blurEditing(e.target.name, e.target.value, index)}
                                    />
                                    <div className='discountPercentage'>
                                        ({(this.formatDollar(invoiceItem['discount']).replaceAll(',','') / (this.formatDollar(invoiceItem['unitPrice']).replaceAll(',','') * invoiceItem['quantity']) * 100).toFixed(2)}%)
                                    </div>
                                </div>
                                {INVOICE_ITEM_CELLS_RIGHT.map((item, i) => (
                                    <TextField
                                        style={{ width: "10%" }}
                                        key={"item" + (i + INVOICE_ITEM_CELLS_LEFT.length + 1)}
                                        variant="outlined"
                                        label={item.label + "*"}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ style: { textAlign: item.rightAlign ? "right" : "left" } }}
                                        InputProps={{ startAdornment: <InputAdornment position="start">{item.addDollar ? "$" : ""}</InputAdornment>, readOnly: item.readOnly }}
                                        name={item.id}
                                        value={item.addDollar ? this.formatDollar(invoiceItem[item.id]) : invoiceItem[item.id]}
                                        error={!(item.addDollar ? this.formatDollar(invoiceItem[item.id]) : invoiceItem[item.id])}
                                        helperText={this.state.itemError.index === index && this.state.itemError.id === item.id ? "Field is required" : ""}
                                        onChange={(e) => this.handleChange(e.target.name, e.target.value, index)}
                                        onBlur={(e) => this.blurEditing(e.target.name, e.target.value, index)}
                                    />
                                ))}
                                <div style={{ width: "10%", display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                                    <TextField
                                        style={{ width: "100%" }}
                                        key={"item" + INVOICE_ITEM_CELLS_LEFT.length}
                                        variant="outlined"
                                        label={"Tax*"}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ style: { textAlign: "right" } }}
                                        InputProps={{ startAdornment: <InputAdornment position="start">{"$"}</InputAdornment>, readOnly: false }}
                                        name={'tax'}
                                        value={this.formatDollar(invoiceItem['tax'])}
                                        error={!(this.formatDollar(invoiceItem['tax']))}
                                        helperText={this.state.itemError.index === index && this.state.itemError.id === 'tax' ? "Field is required" : ""}
                                        onChange={(e) => this.handleChange(e.target.name, e.target.value, index)}
                                        onBlur={(e) => this.blurEditing(e.target.name, e.target.value, index)}
                                    />
                                    <div className='discountPercentage'>
                                        ({(this.formatDollar(invoiceItem['tax']).replaceAll(',','') / (this.formatDollar(invoiceItem['amount']).replaceAll(',','')) * 100).toFixed(2)}%)
                                    </div>
                                </div>
                                <div style={{width: "205px", textAlign: "left"}}>
                                    {this.state.deleteIndex === index ? <>
                                        <Button onClick={() => this.setState({deleteIndex: -1})}>
                                            Cancel
                                        </Button>
                                        <Button onClick={() => {this.handleItemDelete(index); this.setState({deleteIndex: -1})}} variant="contained" startIcon={<DeleteIcon/>}>
                                            Delete
                                        </Button>
                                    </> : <IconButton onClick={() => this.setState({deleteIndex: index})}>
                                        <RemoveCircleIcon fontSize='small' color='error' />
                                    </IconButton>}
                                </div>
                            </div>
                            {index !== this.state.customerInvoiceItems.length - 1 && <br />}
                        </React.Fragment>
                    )) || <br />}
                    <IconButton onClick={this.addItem}>
                        <AddCircleIcon fontSize="small" color='secondary' />
                    </IconButton>

                    {this.state.customerInvoiceItems.length > 0 &&
                    <div className="lineItemRow" style={{marginBottom: "23px" }}>
                        <div style={{width: "10%"}}></div>
                        <div style={{width: "10%"}}></div>
                        <div style={{width: "10%"}}></div>
                        <div style={{width: "10%"}}></div>
                        <div style={{width: "10%"}}></div>
                        <TextField
                            style={{width: "10%" }}
                            label="Amount SubTotal"
                            multiline
                            rowsMax={8}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ style: { textAlign: "right" } }}
                            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                            value={this.state.amountSubTotal}
                            variant="outlined"
                        />
                        <TextField
                            style={{ width: "10%" }}
                            label="Tax SubTotal"
                            multiline
                            rowsMax={8}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ style: { textAlign: "right" } }}
                            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                            value={this.state.taxSubTotal}
                            variant="outlined"
                        />
                        <div style={{width: "205px", textAlign: "left"}}></div>
                    </div>
                    }
                </div>
                <Divider className='divider' />
                <br />
                <div className="notesAndTotals">
                    <div className="invoiceNotes">
                        <div style={{paddingBottom: "10px"}}>
                            <TextField
                                label="Notes"
                                multiline
                                rowsMax={8}
                                InputLabelProps={{ shrink: true }}
                                style={{ width: "100%" }}
                                name="notes"
                                value={this.state.notes}
                                onChange={(e) => this.handleChange(e.target.name, e.target.value,-1)}
                                variant="outlined"
                            />
                        </div>
                        {this.state.statusText && this.state.statusText.length !== 0 ?
                            <div className="needs-review-container">
                                <Typography variant="h6" component="div" gutterBottom className="form-header">
                                </Typography>
                                <div className="statusText-content">
                                    <TableContainer>
                                        <Table size={"medium"} stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className='cellPadding'>
                                                        <p className={styles.cardTotalHeader}>
                                                            Issues:
                                                        </p>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.statusText.map((report, index) => (
                                                    <TableRow hover key={index}>
                                                        <TableCell className='cellPadding'>
                                                            <p className={styles.cardCellItems}>
                                                                {report}
                                                            </p>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div> : null}
                    </div>
                    <div className="totals">
                        <TextField
                            variant="outlined"
                            label="SubTotal"
                            InputLabelProps={{ shrink: true}}
                            name="invoiceSubTotal"
                            value={this.formatDollar(this.state.invoiceSubTotal)}
                            error={!this.formatDollar(this.state.invoiceSubTotal)}
                            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                            inputProps={{ style: { textAlign: "right" } }}
                            onChange={(e) => this.handleChange(e.target.name, e.target.value,-1)}
                            onBlur={(e) => this.blurEditing(e.target.name, e.target.value, -1)}
                        />
                        <br />
                        <TextField
                            variant="outlined"
                            label="Shipping Amount"
                            InputLabelProps={{ shrink: true}}
                            name="shippingAmount"
                            value={this.formatDollar(this.state.shippingAmount)}
                            error={!this.formatDollar(this.state.shippingAmount)}
                            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                            inputProps={{ style: { textAlign: "right" } }}
                            onChange={(e) => this.handleChange(e.target.name, e.target.value,-1)}
                            onBlur={(e) => this.blurEditing(e.target.name, e.target.value, -1)}
                        />
                        <br />
                        <TextField
                            variant="outlined"
                            label="Tax Total"
                            InputLabelProps={{ shrink: true}}
                            name="invoiceTax"
                            value={this.formatDollar(this.state.invoiceTax)}
                            error={!this.formatDollar(this.state.invoiceTax)}
                            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                            inputProps={{ style: { textAlign: "right" } }}
                            onChange={(e) => this.handleChange(e.target.name, e.target.value,-1)}
                            onBlur={(e) => this.blurEditing(e.target.name, e.target.value, -1)}
                        />
                        <br />
                        <TextField
                            variant="outlined"
                            label="Amount Total"
                            InputLabelProps={{ shrink: true}}
                            name="invoiceTotal"
                            value={this.formatDollar(this.state.invoiceTotal)}
                            error={!this.formatDollar(this.state.invoiceTotal)}
                            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                            inputProps={{ style: { textAlign: "right" } }}
                            onChange={(e) => this.handleChange(e.target.name, e.target.value,-1)}
                            onBlur={(e) => this.blurEditing(e.target.name, e.target.value, -1)}
                        />

                    </div>
                </div>
                <Divider className='divider' />
                <br />
                <Typography variant='h5' style={{alignSelf: "flex-start", marginBottom: "10px"}}>
                    Attachments
                </Typography>
                <div className="attachmentsRow">
                    <Tooltip
                        style={{marginLeft: "2px"}}
                        enterTouchDelay={0}
                        leaveTouchDelay={30000}
                        enterDelay={400}
                        // leaveDelay={1000000} // for testing purposes
                        title={
                            <React.Fragment>
                                {ATTACHMENTS_INFO}
                            </React.Fragment>
                        }
                    >
                        <IconButton aria-label='info'>
                            <InfoOutlinedIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>

                </div>
                <div style={{alignSelf: "flex-start"}}>
                    <FileUpload
                        handleFileAdd={(file) => this.props.handleGeneralFileAdd(file)}
                        handleFileDelete={(file, fileIndex) => this.props.handleGeneralFileDelete(file, fileIndex)}
                        files={this.props.newFiles ? this.props.newFiles : []}
                        fetchFilePreview={null}
                        singleFileUpload={true}
                    />
                </div>
            </div>}
        </>)
    }
}

export default CustomerCreateInvoices