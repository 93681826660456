export const ADD_CHEQUE = "Add Cheque"

export const EDIT_CHEQUE = "Edit Cheque"
export const PRINT_CHEQUE = "Print Cheque"

export const CHEQUE = "Cheques"

export const CHEQUE_FIELDS = [
    {id: 'payee', label: 'Payee', align: "center"},
    {id: 'chequeNumber', label: 'Cheque Number', align: "center"},
    {id: 'accountNumber', label: 'Account Number', align: "center"},
    {id: 'paymentDate', label: 'Payment Date', align: "center"},
    {id: 'amount', label: 'Amount', align: "center"},
    {id: 'status', label: 'Status', align: "center"},
    {id: 'currency', label: 'Currency', align: "center"},
    {id: 'category', label: 'Category', align: "center"},
]


export const CHEQUE_PAGES = {
     TABLE: "table",
    PREVIEW: "preview",
    ADD_CHECK: "add_check",
}

// tooltip names
export const PREVIEW = "Preview Cheque"
export const BACK = "Back to Menu"

export const SAVE_DRAFT = "Save Draft"
