import React from 'react';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';

// import constants
import { ASC } from '../constants';
import { EXPAND_HEAD_CELL } from "../../shipments/constants";
import PropTypes from "prop-types";
import SearchField from "../Search/SearchField";
import { LIST_PURCHASE_ORDERS_CELLS } from "../../purchaseOrders/constants";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import IconButton from "@material-ui/core/IconButton";
import { Hidden } from '@material-ui/core';

class GlobalTableHead extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            showDropdown: false
        }
    }

    createSortHandler = (property) => (event) => {
        this.props.onRequestSort(event, property);
    }

    handleDropdown = () => {
        this.props.onSelectAllClick()
        this.setState({
            showDropdown: !this.state.showDropdown
        })
    }

    render() {
        return (

            <TableHead>
                <TableRow className="table">
                    {this.props.isShipmentTable ? <TableCell></TableCell> : null}
                    {this.props.isShipmentTable || this.props.isOrderTable || this.props.isInvoiceTable  ?
                        <TableCell
                            className="expand-cell"
                        /> : null
                    }
                    {this.props.isShipmentTable || this.props.isOrderTable || this.props.isInvoiceTable ?
                        <TableCell padding="checkbox">
                            <Checkbox
                                indeterminate={(this.props.numSelected > 0 && this.props.numSelected < this.props.rowCount && !this.props.isShipmentTable) || this.props.isIndeterminate}
                                checked={(this.props.rowCount > 0 && this.props.numSelected === this.props.rowCount && !this.props.isShipmentTable) || this.props.isChecked}
                                onChange={this.props.onSelectAllClick}
                                inputProps={{ 'aria-label': 'select all' }}
                                disabled={this.props.disableCheckbox}
                            />
                        </TableCell>
                        :
                        this.props.isPurchaseOrderTable ? null : this.props.isProductCategoryTable ? null : <TableCell padding="checkbox" />
                    }
                    {this.props.isPurchaseOrderTable ?
                        <TableCell padding={"checkbox"} align={"center"}>
                        </TableCell> :
                        null
                    }

                    {this.props.headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={this.props.isShipmentTable || headCell.center ? 'center' : headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'default'}
                            sortDirection={this.props.orderBy === headCell.id ? this.props.order : false}
                        >
                            {!this.props.isCreatePurchaseOrderTable && headCell.label !== "" && headCell.id !== "" ?
                                <TableSortLabel
                                    active={this.props.orderBy === headCell.id}
                                    direction={this.props.orderBy === headCell.id ? this.props.order : ASC}
                                    onClick={this.createSortHandler(headCell.id)}
                                >
                                    {headCell.label}

                                </TableSortLabel>
                                :
                                headCell.label
                            }
                            {headCell.id === 'isPrintableEdit' && (
                                <Tooltip title="If the tag is set on the shipment, checking this option will allow the shipment to be printed">
                                    <InfoIcon  style={{ color: 'red' }} />
                                </Tooltip>
                            )}

                        </TableCell>
                    ))}
                    {this.props.isInvoiceTable ?
                        <TableCell style={{padding: "35px"}}></TableCell> :
                        null
                    }
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
        );
    }
}

GlobalTableHead.propTypes = {
    isCreatePurchaseOrderTable: PropTypes.bool,
    isShipmentTable: PropTypes.bool,
    isPurchaseOrderTable: PropTypes.bool,
    headCells: PropTypes.array,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    onRequestSort: PropTypes.func,
    rowCount: PropTypes.number,
    onSelectAllClick: PropTypes.func,
    isChecked: PropTypes.bool,
    isIndeterminate: PropTypes.bool,
    disableCheckbox: PropTypes.bool
}

GlobalTableHead.defaultProps = {
    isCreatePurchaseOrderTable: false,
    isShipmentTable: false,
    isPurchaseOrderTable: false,
    headCells: [],
    order: 'desc',
    orderBy: '',
    onRequestSort: () => { },
    rowCount: 0,
    onSelectAllClick: () => { },
    isChecked: false,
    isIndeterminate: false,
    disableCheckbox: false
}

export default GlobalTableHead