import React from 'react'

// material components
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    TablePagination,
    Paper,
    Switch,
    Button,
    CircularProgress,
    Slide, withStyles, TextField, alpha
} from '@material-ui/core';
import DateFnsUtils from "@date-io/date-fns";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";

// custom components
import ShipmentsTableToolbar from './ShipmentsTableToolbar';
import ShipmentsSearchField from './ShipmentsSearchField'
import FiltersDialog from './FiltersDialog';
import TableDisplayDialog from './TableDisplayDialog'
import FilterTagsBar from './FilterTagsBar';
import CompactTableSwitch from './CompactTableSwitch'
import GlobalTableHead from '../global/Table/GlobalTableHead';
import SearchableTable from '../global/Search/SearchableTable';
import ExpandedView from "./ExpandedView";
import FileUpload from "../global/FileUpload";
import CreateOrderDialog from "./CreateOrderDialog";
import RatingDialog from "./rating/RatingDialog";
import FeedbackBar from "../feedbackBar/FeedbackBar";
import ShipmentsSummary from "./ShipmentsSummary";

// import styling
import './ShipmentsTable.css';

// import constants
import {
    DEFAULT_SORT_BY_HEAD_CELL,
    DEFAULT_ROWS_PER_PAGE,
    SHIPMENTS_PER_PAGE,
    HEAD_CELLS,
    COMPTACT_LABEL,
    TABLE_DISPLAY_LABEL,
    RATE_AND_COMPARE,
    ERROR,
    CARRIER_MISSING_ERROR,
    FEDEX,
    PENDING_SHIPMENTS,
    FILTER,
    SHOW_IMCOMPLETE,
    UNASSIGNED_LOCATION_NAME,
    FROM_DATE_DAYS_BEFORE_CURRENT_DATE,
    SHIPPED_SHIPMENTS
} from './constants';
import {ASC, DESC, ORDER_REGEX, TRACKING_ID_REGEX} from '../global/constants';
import PropTypes from "prop-types";
import { UNSHIPPED, SHIPPED, CANCELLED, RETURN, CLONED } from "../stages/constants";
import CustomTableHeader from "../global/Table/CustomTableHeader";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TocIcon from '@material-ui/icons/Toc';
import FilterAppliedIcon from "./FilterAppliedIcon";
import FilterIcon from "./FilterIcon";
import Tooltip from "@material-ui/core/Tooltip";

//redux
import withShipment from "../../withShipment";
import {
    selectedLocationIdSelector,
    userInfoSelector
} from "../../redux/selectors/auth";
import {
    saveTableSetting,
    getTableSetting
} from "../../redux/actions/account";
import {
    importOrders,
    rateToDefault,
    validateAddress,
    rateToCheapest,
    createOrder,
    rateShipment,
    clearRates,
    storeRates,
    editShipmentCarrier,
    updateBoxType,
    fetchByOrderId,
    createReturnShipments,
    clearLinkedShipment,
    updateAndValidateShipments,
    exportShipmentSummaryToExcel,
    setShipmentSummaryOrderDirection,
    fetchBuyShippingServices,
    saveBuyShippingService,
    fetchByTrackingId,
    setShipmentsSmartSearchRows,
setShipmentsOpenEditRow
} from "../../redux/actions/orders"
import {
    allLogsSelector,
    linkedShipmentSelector, orderIdSelector,
    ratesSelector,
    shipmentsSummaryOrderSelector,
    buyShippingServicesSelector,
    shipmentsSummaryPropertySelector,
    packageToListMapSelector,
    shipmentsSmartSearchRowsSelector,
    commercialInvoicePDFSelector,
    isSampleCommercialInvoiceSelector,
    shipmentsOpenEditRowSelector,
    shipmentsFromLogsSelector,
} from "../../redux/selectors/orders";
import {
    listPackages,
    listTags
} from "../../redux/actions/settings"
import {
    packagesSelector,
    tagsSelector
} from "../../redux/selectors/settings"
import {loadingSelector, stateAndProvinceMappingSelector} from "../../redux/selectors/global"
import ErrorFeedbackBar from '../global/ErrorFeedbackBar';
import Logs from './SystemEventLog';
import SystemEventLog from "./SystemEventLog";
import {TableHeader} from "material-ui";
import CustomToolbar from "../global/CustomToolbar";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import GetAppIcon from "@material-ui/icons/GetApp";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import BuyShippingServicesTable from "./rating/BuyShippingServicesTable";
import LocationSelectBox from "../global/subcomponents/locationSelectBox/LocationSelectBox";
import {getStateAndProvinceMapping} from "../../redux/actions/global";
import {DAY_FILTER_GAP_LIMIT_ERROR_PARTA,DAY_FILTER_GAP_LIMIT_ERROR_PARTB} from "../../pages/Dashboard/DashboardFinal/constants";


/**
 * Table for showing shipments
 *
 * How search works: The search field is passing on a call back function onSearchValueChange
 * Within ShipmentsSearchField, when onChange is triggered, the onSearchValueChange field is
 * called and that updates the searchValue field in the state.
 */
class ShipmentsTable extends SearchableTable {


    constructor(props) {
        super(props);
        this.state = {
            rows: this.props.rows,
            editingShipmentId: '',
            rowsPerPage: DEFAULT_ROWS_PER_PAGE, // number of rows per page
            page: 0, // page number
            order: ASC, // sort ascending or descending state
            orderBy: DEFAULT_SORT_BY_HEAD_CELL, // sorting order column
            selectedOrder: [],
            isSearch: false, // true if search value is not empty, false otherwise
            searchValue: '', // the value in the search field (updates on change)
            searchRows: 0, // number of rows displayed while search criteria is met
            isDialogOpen: false,
            isTableDisplayDialogOpen: false,
            isImporting: false,
            isRateAndCompare: false,
            rateCompareErrorFeedbackOpen: false,
            filterGapLimitError:false,
            fromDate: this.getFromDate(),
            toDate: this.getToDate(),
            shipFromDate: this.getDefaultShipByFromDate(),
            shipToDate: this.getDefaultShipByToDate(),
            dateType: "",
            filtersMapBeforeOpenDialog: {}, //map of filters before filters dialog is opened
            deletedTagsWithCookies: [],
            allTrackingData: [], // contains all tracking info for shipments before or after queried date
            shipmentRates: {}, // contains all shipment rates for specific shipment
            shippingLabels: '', // shipping labels to print
            validatedCanparAddress: {}, // validated address returned by Canpar API
            validatedFedExAddress: {}, // validated address returned by FedEx API
            correctedFields: [], // highlight corrected fields in red
            showAddressValidationPopup: false,
            showExpandedView: false,
            openCreateOrderDialog: false,
            showCommercialInvoice: false,
            carrier_id: '',
            sortingMap: {
                name: 'shippingAddressName',
                address: 'shippingAddressAddressLine1',
                state: 'shippingAddressStateOrRegion',
                sku: 'skus',
                marketplace: 'marketplace',
                shipfrom: 'fromAddressAlias',
                shipmethod: 'shipmentServiceLevel',
                rate: '',
                carrier: 'carrierName',
                orderid: '',
                isprime: 'isPrime',
                city: 'shippingAddressCity',
            },
            displayChange: 0,
            files: [],
            newFiles: [],
            deletedFiles: [],
            openEditOrderDialog: false,
            openEditOrderIndex: 0,
            ratingShipment: false,
            defaultShipmentReadyCheck: {
                defaultCarrierArray: [
                    "carrierName",
                    "carrierService"
                ],
                defaultFromAddressArray: [
                    "fromAddressAccountNumber",
                    "fromAddressContactName",
                    "fromAddressCompanyName",
                    "fromAddressPhone",
                    "fromAddressAddressLine1",
                    "fromAddressCity",
                    "fromAddressState",
                    "fromAddressPostalCode",
                    "fromAddressCountry",
                    "fromAddressEmail"
                ],
                defaultShipmentAddressArray: [
                    "shippingAddressName",
                    "shippingAddressPhone",
                    "shippingAddressAddressLine1",
                    "shippingAddressCity",
                    "shippingAddressStateOrRegion",
                    "shippingAddressPostalCode",
                    "shippingAddressCountry",
                    "signatureConfirmation"
                ],
                defaultPackageArray: [
                    "packaging",
                    "weight",
                    "length",
                    "width",
                    "height",
                    "declaredValue",
                    "currency",
                    "reference1",
                    "reference3"
                ]
            },
            selected: [],
            searchedByOrderId: false,
            listSearchedByOrderId: false,
            searchedByTrackingId: false,
            listSearchedByTrackingId: false,
            prevSelected: [],
            openShipmentSummary: false,
            openLogsTable: false,
            filtersMap: {},
            listOfIncomplete: [],
            sortIncomplete: true,
            sortCompleted: true,
            sortNotValidated: true,
            sortRated: false,
            dense: false,
            maxAllowedDateRange: "0",
            isValidated: this.props.isValidated,
            index: 0,
            fromLogs: false,
            selectedDate: new Date(),
            logsDetailPage: false,
            orderIdFromLogs: '',
            shipmentIdFromLogs: '',
            isBuyShippingServices: false,
            buyShippingSelectedService: '',
            fromDateLogs: this.getFromDateLogs(),
            toDateLogs: this.getToDateLogs(),
            commercialInvoicePDFScale: 1.25,
            commercialInvoicePDFLoadSuccess: true,
            allLogs: [],
            displayUnassignedShipments: false,
            enabledCarriers: [],
        };
        this.state.exportInfo = this.props.rows
        this.props.getStateAndProvinceMapping(this.props.stateAndProvinceMapping)
        this.props.setShipmentsOpenEditRow(null)
    }


    async componentDidMount() {
        const companyId = this.props.userInfo.company
        this.props.listTags(companyId)
        this.props.listPackages()
        await this.setState({
            dense: (this.props.tableSetting?.shipmentFilterSetting?.dense && this.props.tableSetting?.shipmentFilterSetting?.dense !== "false") ?? false,
            maxAllowedDateRange: (this.props.tableSetting?.shipmentFilterSetting?.shippedShipments?.maxAllowedDateRange) ?? 0
        })
        let storage = JSON.parse(localStorage.getItem(this.props.shipmentStatus))
        if (storage) {
            this.getNewDates()
            const expiry = parseInt(JSON.parse(localStorage.getItem("expiry")))
            if (expiry <= new Date().getTime()) {
                localStorage.removeItem(this.props.shipmentStatus)
                localStorage.removeItem("expiry")
                this.setState({
                    fromDate: this.getFromDate(),
                    toDate: this.getToDate(),
                    shipFromDate: this.getDefaultShipByFromDate(),
                    shipToDate: this.getDefaultShipByToDate(),
                    dateType: "",
                    filtersMap: {}
                })
            }
        }
        this.initializeSearchText()
        const data = {
            fromDate: this.state.fromDateLogs,
            toDate: this.state.toDateLogs
        }

        this.setState({page: 0})

        if(this.props.title !== SHIPPED_SHIPMENTS){
            this.applyDateFilters() // fetches order information as a side effect
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.rows !== this.props.rows ||
            prevProps.shipmentsSmartSearchRows !== this.props.shipmentsSmartSearchRows ||
            prevState.searchValue !== this.state.searchValue) {
            this.setState({
                rows: this.isSmartSearch(this.state.searchValue) ? this.props.shipmentsSmartSearchRows : this.props.rows
            })
        }
        if (prevProps.allLogs !== this.props.allLogs) {
            await this.setState({
                allLogs: this.props.allLogs
                })
        }
        if (prevProps.searchText !== this.props.searchText || this.state.searchValue !== this.props.searchText) {
            this.initializeSearchText()
        }
        if (this.state.searchedByOrderId) {
            this.searchByOrderId()
            this.setState({
                searchedByOrderId: false
            })
        } else if (this.state.searchedByTrackingId) {
            this.searchByTrackingId()
            this.setState({
                searchedByTrackingId: false
            })
        }
        if (this.props.tableSetting?.shipmentFilterSetting !== prevProps.tableSetting?.shipmentFilterSetting) {
            this.setState({
                dense: (this.props.tableSetting?.shipmentFilterSetting?.dense && this.props.tableSetting?.shipmentFilterSetting?.dense !== "false") ?? false,
                maxAllowedDateRange: (this.props.tableSetting?.shipmentFilterSetting?.shippedShipments?.maxAllowedDateRange) ?? 0
            })
        }
        if (prevState.rows !== this.state.rows) {
            if (this.props.linkedShipmentId) {
                const rowsToCheck = this.displayList(this.state.rows)
                const index = rowsToCheck.findIndex(row => row.shipmentId === this.props.linkedShipmentId)
                if (index === -1) {
                    this.props.clearLinkedShipment()
                } else {
                    const openEditOrderRow = this.displayList(this.state.rows)[index]
                    this.setState({
                        openEditOrderDialog: true,
                        openEditOrderIndex: index,
                        editingShipmentId: this.props.linkedShipmentId
                    })
                    this.handleExpand(
                        openEditOrderRow.shipmentId,
                        !openEditOrderRow.carrierName ? false : openEditOrderRow.carrierName.split(" ")[0] === FEDEX
                        )
                }
            } else if(this.state.openEditOrderDialog === true){
                let runner = this.displayList(this.state.rows)
                const index = runner.findIndex(row => row.shipmentId === this.state.editingShipmentId)
                this.setState({
                    openEditOrderDialog: true,
                    openEditOrderIndex: index
                })
            }
        }
        if (prevState.searchValue !== this.state.searchValue) {
            if (this.props.shipmentsSmartSearchRows && this.props.shipmentsSmartSearchRows.length > 0) {
                if (!this.isASmartSearch(this.state.searchValue)) {
                    this.props.setShipmentsSmartSearchRows([])
                }
            }
        }
        if (prevProps.carriers !== this.props.carriers) {
            this.setState({
                enabledCarriers:this.props.carriers?.filter((carrier) => carrier.isEnabled !== false)
            })
        }
    }

    isASmartSearch = (searchText) => {
        let isOrderId = false
        let isTrackingId = false
        if (this.props.smartSearch){
            for (let i = 0; i < ORDER_REGEX.length; i++) {
                if (ORDER_REGEX[i].test(this.props.searchText.trim())) {
                    isOrderId = true
                    break;
                }
            }
        }
        if (this.props.smartSearch){
            for (let i = 0; i < TRACKING_ID_REGEX.length; i++) {
                if (TRACKING_ID_REGEX[i].test(this.props.searchText.trim())) {
                    isTrackingId = true
                    break;
                }
            }
        }
        return isOrderId || isTrackingId
    }

    initializeSearchText = () => {
        this.setState({ searchValue: this.props.searchText })
    }

    toggleShipmentSummary = () => {
        this.setState({
            openShipmentSummary: !this.state.openShipmentSummary
        })
    }

    toggleLogsTable = () => {
        this.setState({
            openLogsTable: !this.state.openLogsTable
        })
    }

    logsTrue = () => {
        this.setState({
            openLogsTable: true
        })
    }

    logsFalse = () => {
        this.setState({
            openLogsTable: false
        })
    }

    isFromLogsFalse = () => {
        this.setState({
            fromLogs: false
        })
    }

    isFromLogs = () => {
        this.setState({
            fromLogs: !this.state.fromLogs,
        })
    }

    toggleShowCommercialInvoice = () => {
        this.setState({
            showCommercialInvoice: !this.state.showCommercialInvoice
        })
    }

    addFilterDates = (filters) => {
        if (filters) {
            if (!this.isDefaultFromDate(this.state.fromDate)) {
                filters['fromDate'] = this.state.fromDate
            }
            if (!this.isToday(this.state.toDate)) {
                filters['toDate'] = this.state.toDate
            }
            if (!this.isDate(this.state.shipFromDate, this.getDefaultShipByFromDate())) {
                filters['shipFromDate'] = this.state.shipFromDate
            }
            if (!this.isDate(this.state.shipToDate, this.getDefaultShipByToDate())) {
                filters['shipToDate'] = this.state.shipToDate
            }
        }
        return filters
    }

    getFromDateLogs = () => {
        var date = this.getTodayLogs()
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        return date
    }

    getTodayLogs = () => {
        var today = new Date()
        today = today.toString().split('GMT')[0] + "Z"
        return new Date(today)
    }

    getDefaultShipByFromDate = () => {
        return this.addDaysToDate(this.getToDate(), -1)
    }

    getDefaultShipByToDate = () => {
        return this.getToDate()
    }

    addDaysToDate = (date, days) => {
        const oneDay = 1000 * 60 * 60 * 24
        date.setUTCMilliseconds(date.getUTCMilliseconds() + days * oneDay)
        return date
    }

    getToDateLogs = () => {
        var date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 999)
        return date
    }

    isDate = (someDate, otherDate) => {
        return someDate.getDate() == otherDate.getDate() &&
            someDate.getMonth() == otherDate.getMonth() &&
            someDate.getFullYear() == otherDate.getFullYear()
    }

    isDefaultFromDate = (someDate) => {
        const defaultFromDate = this.getFromDate()
        return someDate.getDate() == defaultFromDate.getDate() &&
            someDate.getMonth() == defaultFromDate.getMonth() &&
            someDate.getFullYear() == defaultFromDate.getFullYear()
    }

    isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
    }

    getFromDate = () => {
        var date = this.getToday()
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        if (this.props.shipmentStatus !== SHIPPED) {
            date.setDate(date.getDate() - FROM_DATE_DAYS_BEFORE_CURRENT_DATE)
        }
        return date
    }

    getToDate = () => {
        var date = this.getToday()
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        return date
    }

    getToday = () => {
        var today = new Date()
        today = today.toString().split('GMT')[0] + "Z"
        return new Date(today)
    }

    getNewDates = () => {
        let filter = {...this.state.filtersMap}
        let storage = JSON.parse(localStorage?.getItem(this.props.shipmentStatus))
        if (storage.fromDate !== null && storage.fromDate !== undefined ) {
            const temp = new Date(storage.fromDate)
            filter.fromDate = temp;
            this.setState({
                fromDate: temp
            })
        }
        if (storage.toDate !== null && storage.toDate !== undefined) {
            const temp = new Date(storage.toDate)
            filter.toDate = temp;
            this.setState({
                toDate: temp
            })
        }
        if (storage.shipFromDate !== null && storage.shipFromDate !== undefined) {
            const temp = new Date(storage.shipFromDate)
            filter.shipFromDate = temp;
            this.setState({
                shipFromDate: temp
            })
        }
        if (storage.shipToDate !== null && storage.shipToDate !== undefined) {
            const temp = new Date(storage.shipToDate)
            filter.shipToDate = temp;
            this.setState({
                shipToDate: temp
            })
        }
        if (storage.dateType !== null && storage.dateType !== undefined) {
            filter.dateType = storage.dateType
            this.setState({
                dateType: storage.dateType
            })
        }
        if (filter) {
            this.setState({
                filtersMap: filter,
            })
        }
    }

    handleChangeDense = (event) => {
        this.setState({
            dense: event.target.checked,
        });
        let filters = this.state.filtersMap;
        filters["dense"] = event.target.checked;
        this.saveUpdatedFilters(filters,false)
    }

    handleFilterShipmentType = (event, name) => {
        this.setState({
            [name]: event.target.checked,
            selected: [],
            page: 0
        });
        // this code could be useful if we ever decide to save toggle filters to user settings again
        // let filters = this.state.filtersMap;
        // if (name == 'sortRated') {
        //     event.target.checked ? filters[name] = true : filters[name] = false
        // } else {
        //     event.target.checked === false ? filters[name] = true : filters[name] = false
        // }
    }


    handleChangeDisplay = (event) => {
        this.setState({
            isTableDisplayDialogOpen: !this.state.isTableDisplayDialogOpen,
        });
    }

    getTableDisplay = () => {
        switch (this.props.shipmentStatus) {
            case UNSHIPPED:
                if (this.props.tableSetting?.unshippedTableSetting == null) {
                    return []
                }
                return this.props.tableSetting.unshippedTableSetting
            case SHIPPED:
                if (this.props.tableSetting?.shippedTableSetting == null) {
                    return []
                }
                return this.props.tableSetting.shippedTableSetting
            case CANCELLED:
                if (this.props.tableSetting?.canceledTableSetting == null) {
                    return []
                }
                return this.props.tableSetting.canceledTableSetting
            case RETURN:
                if (this.props.tableSetting?.returnsTableSetting == null) {
                    return []
                }
                return this.props.tableSetting.returnsTableSetting
            case CLONED:
                if (this.props.tableSetting?.clonedTableSetting == null) {
                    return []
                }
                return this.props.tableSetting.clonedTableSetting
            default:
                return []
        }
    }

    getSelectedTableDisplay = () => {
        switch (this.props.shipmentStatus) {
            case UNSHIPPED:
                if (this.props.tableSetting?.unshippedTableSetting == null) {
                    return []
                }
                return this.props.tableSetting.unshippedTableSetting.filter((row, index) => {
                    return row.selected == true;
                });
                break
            case SHIPPED:
                if (this.props.tableSetting?.shippedTableSetting == null) {
                    return []
                }
                return this.props.tableSetting.shippedTableSetting.filter((row, index) => {
                    return row.selected == true;
                });
                break
            case CANCELLED:
                if (this.props.tableSetting?.canceledTableSetting == null) {
                    return []
                }
                return this.props.tableSetting.canceledTableSetting.filter((row, index) => {
                    return row.selected == true;
                });
                break
            case RETURN:
                if (this.props.tableSetting?.unshippedTableSetting == null) {
                    return []
                }
                return this.props.tableSetting.unshippedTableSetting.filter((row, index) => {
                    return row.selected === true;
                });
            case CLONED:
                if (this.props.tableSetting?.unshippedTableSetting == null) {
                    return []
                }
                return this.props.tableSetting.unshippedTableSetting.filter((row, index) => {
                    return row.selected === true;
                });
            default:
                return []
        }
    }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: event.target.value,
            page: 0,
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
    };

    isSmartSearch = (search) => {
        let isSmartSearch = false
        if (this.props.smartSearch) {
            for (let i = 0; i < ORDER_REGEX.length; i++) {
                if (ORDER_REGEX[i].test(search)) {
                    isSmartSearch = true
                    break;
                }
            }
        }
        if (this.props.smartSearch){
            for (let i = 0; i < TRACKING_ID_REGEX.length; i++) {
                if (TRACKING_ID_REGEX[i].test(search)) {
                    isSmartSearch = true
                    break;
                }
            }
        }
        return isSmartSearch
    }

    onSearchValueChange = (event) => {
        let isOrderId = false, isTrackingId = false
        if (this.props.smartSearch){
            for (let i = 0; i < ORDER_REGEX.length; i++) {
                if (ORDER_REGEX[i].test(event.target.value.trim())) {
                    isOrderId = true
                    break;
                }
            }
            for (let i = 0; i < TRACKING_ID_REGEX.length; i++) {
                if (TRACKING_ID_REGEX[i].test(event.target.value.trim())) {
                    isTrackingId = true
                    break;
                }
            }
        }

        if (isOrderId && !this.state.listSearchedByOrderId) {
            this.setState({
                isSearch: event.target.value.length > 0,
                searchValue: event.target.value.trim(),
                searchedByOrderId: true,
                listSearchedByOrderId: true,
                selected: [],
                page: 0
            })
        } else if (!isOrderId && this.state.listSearchedByOrderId) {
            this.setState({
                isSearch: event.target.value.length > 0,
                searchValue: event.target.value.trim(),
                searchedByOrderId: false,
                listSearchedByOrderId: false,
                selected: [],
                page: 0
            })
        } else if (isTrackingId && !this.state.listSearchedByTrackingId) {
            this.setState({
                isSearch: event.target.value.length > 0,
                searchValue: event.target.value.trim(),
                searchedByTrackingId: true,
                listSearchedByTrackingId: true,
                selected: [],
                page: 0
            })
        } else if (!isTrackingId && this.state.listSearchedByTrackingId) {
            this.setState({
                isSearch: event.target.value.length > 0,
                searchValue: event.target.value.trim(),
                searchedByTrackingId: false,
                listSearchedByTrackingId: false,
                selected: [],
                page: 0
            },()=>{
                if (this.state.searchValue !== "") {
                    this.applyDateFilters(this.state.fromDate, this.state.toDate)
                }
            })
        } else {
            this.setState({
                isSearch: event.target.value.length > 0,
                searchValue: event.target.value.trim(),
                selected: [],
                page: 0
            })
        }
        this.props.handleSearchChange(event)
    }
    // setHandleFromLogs = () => {
    //     this.setState({
    //         fromLogs: true,
    //     });
    // }

    toggleLogsDetailsPage = () => {
        this.setState({
            logsDetailPage: !this.state.logsDetailPage
        })
    }

    /**
     * Filters out rows based on the filter settings in the FiltersDialog
     *
     * @param {rows} the rows on which filters are to be applied
     */
    applyFilters = (rows) => {

        if (!rows) {
            return []
        }
        // if (!this.props.tableSetting?.shipmentFilterSetting)
        //   return rows
        if (!this.state.filtersMap) {
            return rows
        }
        const filtersMap = { ...this.state.filtersMap }

    const orderID = filtersMap["orderid"];
    const tag = filtersMap['tag'];
    const name = filtersMap["name"];
    const address = filtersMap["address"];
    const city = filtersMap["city"];
    const stateOrProvince = filtersMap["state"];
    const country = filtersMap["country"];
    const sku = filtersMap["sku"];
    const marketplace = filtersMap["marketplace"];
    const shipFrom = this.props.selectedLocation;
    const shipMethod = filtersMap["shipmethod"];
    const carrier = filtersMap["carrier"];
    const isPrime = filtersMap["prime"];
    let incomplete = false;
    let notValidated = false;
    let completed = false;
    let rated = false;
    if (this.props.shipmentStatus === UNSHIPPED) {
        incomplete = !this.state.sortIncomplete;
        notValidated = !this.state.sortNotValidated;
        completed = !this.state.sortCompleted;
        rated = this.state.sortRated
    }


    let result = [...rows]

    if (orderID) {
      result = result.filter((row, index) => {
        if (row.orders) {
          for (let i = 0; i < row.orders.length; i++) {
            if (row.orders[i].orderId.includes(orderID.toLowerCase())) {
              return true
            }
          }
        }
        return false
      });
    }

    if (tag) {
      result = result.filter(row => {
        let allTagsIncluded = false
        let tags = [];
        for (let i = 0; i < row.tags.length; i++) {
          if (row.tags[i].tagName) {
            tags = [...tags, row.tags[i].tagName?.toLowerCase()]
          }
        }
        for (let i = 0; i < tag.length; i++) {
            if (tags.includes(tag[i].toLowerCase())) {
                allTagsIncluded = true
                break
            }
        }
        return allTagsIncluded
      });
    }

    if (name) {
      result = result.filter((row, index) => {
        return row.shippingAddressName?.toLowerCase().includes(name.toLowerCase());
      });
    }

    if (address) {
      result = result.filter((row, index) => {
        return row.shippingAddressAddressLine1?.toLowerCase().includes(address.toLowerCase())
          || row.shippingAddressAddressLine2?.toLowerCase().includes(address.toLowerCase());
      });
    }

    if (city) {
      result = result.filter((row, index) => {
        return row.shippingAddressCity?.toLowerCase().includes(city.toLowerCase());
      });
    }

    if (stateOrProvince) {
        result = result.filter(row => {
            let state = row.shippingAddressStateOrRegion
            if (state) {
                let stateCode = this.props.stateAndProvinceMapping.find(stateEntry => stateEntry.aliases.find(alias => alias.toLowerCase() === state.toLowerCase()))?.state
                if (stateCode) {
                    state = stateCode
                }
            }
            return state ? stateOrProvince.includes(state) : null
        });
    }

    if (country) {
      result = result.filter((row, index) => {
        return row.shippingAddressCountry?.toLowerCase() === country.toLowerCase();
      });
    }

    if (sku) {
      result = result.filter(row => {
        if (row.packages?.length > 0 && row.packages[0].skus) {
          for (let i = 0; i < row.packages.length; i++) {
            for (let j = 0; j < row.packages[i].skus.length; j++) {
              if (row.packages[i].skus[j]?.toLowerCase().includes(sku.toLowerCase())) {
                return true
              }
            }
          }
        }
        return false
      });
    }

    if (marketplace) {
      result = result.filter((row, index) => {
        return row.marketplace?.toLowerCase().includes(marketplace.toLowerCase());
      });
    }

    if (this.state.displayUnassignedShipments) {
        result = result.filter((row, index) => {
            return !row.shipAddressId
        });
    } else if (shipFrom) {
      result = result.filter(row => {
        return row.shipAddressId === shipFrom
      });
    }

    if (shipMethod) {
      result = result.filter(row => {
        return row.orders?.length > 0 ? row.orders[0].shipmentServiceLevelCategory?.toLowerCase().includes(shipMethod.toLowerCase()) : false
      });
    }

    if (carrier) {
      result = result.filter(row => {
        return row.carrierName?.toLowerCase().includes(carrier.toLowerCase());
      });
    }

    if (isPrime) {
        result = result.filter(row => {
            return row.isPrime
        });
    }

    if (incomplete) {
        result = result.filter(row => {
            return !(!this.isShipmentReady(row.shipmentId) && !this.isShipmentReadyForMarkRedirected(row.shipmentId))
        });
    }

    if (completed) {
        result = result.filter(row => {
            return !((this.isShipmentReady(row.shipmentId) && row?.isValidated === true && this.isShipmentReadyMarkAsShipped(row.shipmentId)) ||
                        this.isShipmentReadyForMarkRedirected(row.shipmentId))
        })
    }

    if (notValidated) {
        result = result.filter(row => {
            if (this.isShipmentReady(row.shipmentId) && (row.isValidated === undefined || row.isValidated === null)) {
                return false
            } else if (this.isShipmentReady(row.shipmentId) && (row.isValidated === false)) {
                return false
            } else {
                return true
            }
        })
    }

    if (rated) {
        result = result.filter(row => {
            return row.rate ?? false
        })
    }

    // this is for filtering by ship by date on frontend
    // it is not currently needed due to the same filter being applied on backend
    // if (this.props.shipmentStatus === UNSHIPPED) {
    //     result = result.filter(row => {
    //         let shipByDate = this.getRowShipByDate(row)
    //         if (!shipByDate) {
    //             return true
    //         }
    //         if ( /* shipByDate > this.state.shipFromDate?.toISOString() && */ shipByDate <= this.state.shipToDate?.toISOString()) {
    //             return true
    //         }
    //         return false
    //     })
    // }

    return result;
  }

    applyOnlyLocationFilter = (rows) => {
        if (!rows) {
            return []
        }
        if (!this.state.filtersMap) {
            return rows
        }
        const shipFrom = this.props.selectedLocation;
        let result = [...rows]

        if (this.state.displayUnassignedShipments) {
        result = result.filter((row, index) => {
            return !row.shipAddressId
        });
        } else if (shipFrom) {
            result = result.filter(row => {
                return row.shipAddressId === shipFrom
            });
        }
        return result;
    }

    getRowShipByDate = (row) => {
        return row.modifiedLatestShipDate
    }

    /////////////////////////////////////////////////////////////////////////////
    // Callbacks for FilterTagsBar
    /////////////////////////////////////////////////////////////////////////////
    onTagClickedWhenDialogueOpen = (event, tag) => {
        const filtersMap = { ...this.state.filtersMap };
        let tagValue = filtersMap[tag]
        let deletedTagsWithCookies = this.state.deletedTagsWithCookies
        delete filtersMap[tag];
        if (tag === 'fromDate') {
            let storage = JSON.parse(localStorage.getItem(this.props.shipmentStatus))
            if (storage) {
                if (storage.fromDate !== null && storage.fromDate !== undefined) {
                    if (new Date(storage.fromDate).getTime() === new Date(tagValue).getTime()) {
                        this.setState({deletedTagsWithCookies: [...deletedTagsWithCookies, "fromDate"]})
                    }
                }
            }
            this.setState({
                fromDate: this.getToday()
            })
        }
        if (tag === 'toDate') {
            let storage = JSON.parse(localStorage.getItem(this.props.shipmentStatus))
            if (storage) {
                if (storage.toDate !== null && storage.toDate !== undefined) {
                    if (new Date(storage.toDate).getTime() === new Date(tagValue).getTime()) {
                        this.setState({deletedTagsWithCookies: [...deletedTagsWithCookies, "toDate"]})
                    }
                }
            }
            this.setState({
                toDate: this.getToDate()
            })
        }
        if (tag === 'shipFromDate') {
            let storage = JSON.parse(localStorage.getItem(this.props.shipmentStatus))
            if (storage) {
                if (storage.shipFromDate !== null && storage.shipFromDate !== undefined) {
                    if (new Date(storage.shipFromDate).getTime() === new Date(tagValue).getTime()) {
                        this.setState({deletedTagsWithCookies: [...deletedTagsWithCookies, "shipFromDate"]})
                    }
                }
            }
            this.setState({
                shipFromDate: this.getDefaultShipByFromDate(),
            })
        }
        if (tag === 'shipToDate') {
            let storage = JSON.parse(localStorage.getItem(this.props.shipmentStatus))
            if (storage) {
                if (storage.shipToDate !== null && storage.shipToDate !== undefined) {
                    if (new Date(storage.shipToDate).getTime() === new Date(tagValue).getTime()) {
                        this.setState({deletedTagsWithCookies: [...deletedTagsWithCookies, "shipToDate"]})
                    }
                }
            }
            this.setState({
                shipToDate: this.getDefaultShipByToDate(),
            })
        }
        if (tag === 'dateType') {
            let storage = JSON.parse(localStorage.getItem(this.props.shipmentStatus))
            if (storage) {
                if (storage.dateType !== null && storage.dateType !== undefined) {
                    if (storage.dateType === tagValue) {
                        this.setState({deletedTagsWithCookies: [...deletedTagsWithCookies, "dateType"]})
                    }
                }
            }
            this.setState({
                dateType: ""
            })
        }

        this.saveUpdatedFilters(filtersMap)
        this.setState({
            filtersMap: filtersMap,
        });
    }

    /**
     * When a  tag is clicked, it removes this tag from the FiltersMap
     */
    onTagClicked = (event, tag) => {
        // the tag name comes as key:value pair as a string, so we split it
        // remove the key from the filters map
        // clone the map, delete the key, set the state
        const filtersMap = this.state.filtersMap;
        delete filtersMap[tag];
        let callback = () => {}

        if (tag === 'fromDate') {
            let storage = JSON.parse(localStorage.getItem(this.props.shipmentStatus))
            delete storage.fromDate
            // this.onDateFiltersInputChange('fromDate', this.getFromDate())
            this.setState({
                    fromDate: this.getFromDate()
                }, () => this.applyDateFilters())
            localStorage.setItem(this.props.shipmentStatus, JSON.stringify(storage))
            if(JSON.stringify(storage) == JSON.stringify({})) localStorage.removeItem(this.props.shipmentStatus)
        }
        if (tag === 'toDate') {
            let storage = JSON.parse(localStorage.getItem(this.props.shipmentStatus))
            delete storage.toDate
            // this.onDateFiltersInputChange('toDate', new Date())
            this.setState({
                    toDate: this.getToDate()
                }, () => this.applyDateFilters())
            localStorage.setItem(this.props.shipmentStatus, JSON.stringify(storage))
            if(JSON.stringify(storage) == JSON.stringify({})) localStorage.removeItem(this.props.shipmentStatus)
        }
        if (tag === 'shipFromDate') {
            let storage = JSON.parse(localStorage.getItem(this.props.shipmentStatus))
            delete storage.shipFromDate
            this.setState({
                shipFromDate: this.getDefaultShipByFromDate(),
            }, () => this.applyDateFilters())
            localStorage.setItem(this.props.shipmentStatus, JSON.stringify(storage))
            if(JSON.stringify(storage) == JSON.stringify({})) localStorage.removeItem(this.props.shipmentStatus)
        }
        if (tag === 'shipToDate') {
            let storage = JSON.parse(localStorage.getItem(this.props.shipmentStatus))
            delete storage.shipToDate
            this.setState({
                shipToDate: this.getDefaultShipByToDate(),
            }, () => this.applyDateFilters())
            localStorage.setItem(this.props.shipmentStatus, JSON.stringify(storage))
            if(JSON.stringify(storage) == JSON.stringify({})) localStorage.removeItem(this.props.shipmentStatus)
        }
        if (tag === 'dateType') {
            let storage = JSON.parse(localStorage.getItem(this.props.shipmentStatus))
            delete storage.dateType
            this.setState({
                dateType: "",
            }, () => this.applyDateFilters())
            localStorage.setItem(this.props.shipmentStatus, JSON.stringify(storage))
            if(JSON.stringify(storage) == JSON.stringify({})) localStorage.removeItem(this.props.shipmentStatus)
        }

        if (tag !== 'fromDate' && tag !== 'toDate') {
            const filtersMaps = { ...this.state.filtersMap };
            this.saveUpdatedFilters(filtersMap,false)
        }

        this.setState({
            filtersMap: filtersMap,
        });
        this.clearSelected()
    }

    /////////////////////////////////////////////////////////////////////////////
    // Callbacks for FiltersDialog
    /////////////////////////////////////////////////////////////////////////////

    /**
     * When a filter field is modified, this function is invoked
     */
    onFiltersInputChange = (event, autoCompleteValue, name, isAutoCompleteField) => {
        const filtersMap = { ...this.state.filtersMap };
        filtersMap.dense = this.props.tableSetting?.shipmentFilterSetting?.dense
        if (name === "prime") {
            event.target.checked ? filtersMap[name] = event.target.checked : delete filtersMap[name]
        } else {
            if (isAutoCompleteField && !autoCompleteValue) {
                delete(filtersMap[name]);
                this.setState({
                    filtersMap
                })
                this.props.saveTableSetting({
                    userId: this.props.userInfo.userId,
                    tableSetting: null,
                    pageTitle: this.props.shipmentStatus,
                    defaultTable: this.props.defaultTableDisplay,
                    filterSetting: filtersMap,
                    changeFilters: true
                })
                return
            }
            let id = autoCompleteValue ? name : event.target.name;
            let value = autoCompleteValue ? autoCompleteValue : event.target.value;

            // if filter already exists, then update
            if (value && value !== "" && value.length !== 0) {
                filtersMap[id] = value;
            } else {
                delete filtersMap[id];
            }
        }
        this.setState({
            filtersMap
        })
        this.props.saveTableSetting({
            userId: this.props.userInfo.userId,
            tableSetting: null,
            pageTitle: this.props.shipmentStatus,
            defaultTable: this.props.defaultTableDisplay,
            filterSetting: filtersMap,
            changeFilters: true
        })
    }

    resetFilters = (fromDate, toDate, shipFromDate, shipToDate, dateType) => {
        let filtersMapClone = { ...this.state.filtersMap }
        if (!this.isDefaultFromDate(fromDate)) {
            filtersMapClone['fromDate'] = fromDate
        }
        if (!this.isToday(toDate)) {
            filtersMapClone['toDate'] = toDate
        }
        if (!this.isDate(shipFromDate, this.getDefaultShipByFromDate())) {
            filtersMapClone['shipFromDate'] = shipFromDate
        }
        if (!this.isDate(shipToDate, this.getDefaultShipByToDate())) {
            filtersMapClone['shipToDate'] = shipToDate
        }
        this.setState({
            fromDate: fromDate,
            toDate: toDate,
            shipFromDate: shipFromDate,
            shipToDate: shipToDate,
            dateType: dateType,
            deletedTagsWithCookies: [],
            filtersMap: filtersMapClone
        })
    }

    onDateFiltersInputChange = (id, value) => {
       if (id === "dateType") {
           let filtersMapClone = { ...this.state.filtersMap }
           filtersMapClone[id] = value ?? ""
           this.setState({
               dateType: id === "dateType" ? value : this.state.dateType,
               filtersMap: filtersMapClone
           })

       } else if (!value || isNaN(value.getTime())) {
            //User is still editing the date value, do not fetch orders
            this.setState({
                fromDate: id === "fromDate" ? value : this.state.fromDate,
                toDate: id === "toDate" ? value : this.state.toDate,
                shipFromDate: id === "shipFromDate" ? value : this.state.shipFromDate,
                shipToDate: id === "shipToDate" ? value : this.state.shipToDate,
            })
        } else {
            const date = new Date(value.toString().split('GMT')[0] + "Z")
            let filtersMapClone = { ...this.state.filtersMap }
            if (id === 'fromDate') {
                if(!this.isToday(value))
                    filtersMapClone[id] = value
                else {
                    delete filtersMapClone.fromDate
                }
            }
           if(id === 'toDate') {
               if(!this.isToday(value))
                    filtersMapClone[id] = value
               else{
                   delete filtersMapClone.toDate
               }
           }
           if(id === 'shipFromDate' && !this.isDate((value), this.getDefaultShipByFromDate())) {
               filtersMapClone[id] = value
           }
           if(id === 'shipToDate' && !this.isDate((value), this.getDefaultShipByToDate())) {
               filtersMapClone[id] = value
           }
            this.setState({
                fromDate: id === "fromDate" ? value : this.state.fromDate,
                toDate: id === "toDate" ? value : this.state.toDate,
                shipFromDate: id === "shipFromDate" ? value : this.state.shipFromDate,
                shipToDate: id === "shipToDate" ? value : this.state.shipToDate,
                filtersMap: filtersMapClone
            })
        }
    }

    applyDateFilters = () => {
        let fromDate = this.state.fromDate
        let toDate = this.state.toDate
        let shipByDate = (this.state.filtersMap && this.state.filtersMap["shipToDate"]) ?? this.getDefaultShipByToDate()
        // let fromDate = (this.state.filtersMap && this.state.filtersMap["dateType"]) === "order date" ? this.state.fromDate : (this.state.filtersMap && this.state.filtersMap["shipFromDate"])
        // let toDate = (this.state.filtersMap && this.state.filtersMap["dateType"]) === "order date" ? this.state.toDate : (this.state.filtersMap && this.state.filtersMap["shipToDate"])
        // let dateType = (this.state.filtersMap && this.state.filtersMap["dateType"]) === "order date" ? "order date" : "ship date"
        if (!fromDate) {fromDate = this.getFromDate()}
        if (!toDate) {toDate = this.getToDate()}

        this.props.fetchAllOrders(this.props.shipmentStatus,
            fromDate,
            toDate,
            shipByDate
        );
    }

    /**
     * Resets the filter map
     */
    clearFiltersMap = () => {
        this.setState({
            isDialogOpen: false,
            filtersMap: {},
            fromDate: this.getFromDate(),
            toDate: this.getToDate(),
            shipFromDate: this.getDefaultShipByFromDate(),
            shipToDate: this.getDefaultShipByToDate(),
        })
        this.saveUpdatedFilters({dense: this.state.dense})
    }

    /**
     * Callback executed when the filter dialog is shown
     *
     * Notes this call back is also used in ShipmentsSearchField
     */
    showFiltersDialog = () => {
        // Make a copy of the filters applied as they exist upon entering
        const filtersMapClone = { ...this.state.filtersMap };

        this.setState({
            filtersMapBeforeOpenDialog: filtersMapClone,
            isDialogOpen: !this.state.isDialogOpen,
        });
    }

    saveUpdatedFilters = (filters , deleteFilters = true) => {
        if(deleteFilters){
            delete filters['fromDate']
            delete filters['toDate']
            delete filters['shipFromDate']
            delete filters['shipToDate']
            delete filters['dateType']
        }
        this.props.saveTableSetting({
            userId: this.props.userInfo.userId,
            tableSetting: null,
            pageTitle: this.props.shipmentStatus,
            defaultTable: this.props.defaultTableDisplay,
            filterSetting: filters,
            changeFilters: true
        })
    }

    hideTableDisplayDialog = (applyChanges) => {

    // NOTE: changes are already applied as the user makes these changes,
    // but if the changes are cancelled, then the map is reverted to its
    // previous state
    this.setState({
      isTableDisplayDialogOpen: false,
    });
  }

    applyFiltersDialog = (applyChanges) => {
        // also closes the filters dialogue if open

        // NOTE: changes are already applied as the user makes these changes,
        // but if the changes are cancelled, then the map is reverted to its
        // previous state
        this.setState({
            isDialogOpen: false,
        })
        let filters = {}
        if (!applyChanges) {
            filters = { ...this.state.filtersMapBeforeOpenDialog }
            this.resetFilters(filters?.fromDate ?? this.getFromDate(), filters?.toDate ?? this.getToDate(),
                filters?.shipFromDate ?? this.getDefaultShipByFromDate(), filters?.shipToDate ?? this.getDefaultShipByToDate(), filters?.dateType ?? "")
            this.setState({
                filtersMap: filters,
            })
            this.saveUpdatedFilters(filters,false)
        } else {
            if (this.state.deletedTagsWithCookies.length > 0) {
                let storage = JSON.parse(localStorage.getItem(this.props.shipmentStatus))
                this.state.deletedTagsWithCookies.forEach((tagName) => {
                    delete storage[tagName]
                })
                localStorage.setItem(this.props.shipmentStatus, JSON.stringify(storage))
                if(JSON.stringify(storage) == JSON.stringify({})) localStorage.removeItem(this.props.shipmentStatus)
                this.setState({deletedTagsWithCookies: []})
            }
            filters = { ...this.state.filtersMap }
            const expiry = new Date().setHours(23, 59, 59, 0);
            if (filters.fromDate !== null && filters.fromDate !== undefined) {
                let existing = localStorage.getItem(this.props.shipmentStatus)
                existing = existing ? JSON.parse(existing) : {};
                existing.fromDate = filters.fromDate;
                localStorage.setItem(this.props.shipmentStatus, JSON.stringify(existing));
                localStorage.setItem("expiry", JSON.stringify(expiry))
            }
            if (filters.toDate !== null && filters.toDate !== undefined) {
                let existing = localStorage.getItem(this.props.shipmentStatus)
                existing = existing ? JSON.parse(existing) : {};
                existing.toDate = filters.toDate;
                localStorage.setItem(this.props.shipmentStatus, JSON.stringify(existing));
                localStorage.setItem("expiry", JSON.stringify(expiry))
            }
            if (filters.shipFromDate !== null && filters.shipFromDate !== undefined) {
                let existing = localStorage.getItem(this.props.shipmentStatus)
                existing = existing ? JSON.parse(existing) : {};
                existing.shipFromDate = filters.shipFromDate;
                localStorage.setItem(this.props.shipmentStatus, JSON.stringify(existing));
                localStorage.setItem("expiry", JSON.stringify(expiry))
            }
            if (filters.shipToDate !== null && filters.shipToDate !== undefined) {
                let existing = localStorage.getItem(this.props.shipmentStatus)
                existing = existing ? JSON.parse(existing) : {};
                existing.shipToDate = filters.shipToDate;
                localStorage.setItem(this.props.shipmentStatus, JSON.stringify(existing));
                localStorage.setItem("expiry", JSON.stringify(expiry))
            }
            if (filters.dateType !== null && filters.dateType !== undefined) {
                let existing = localStorage.getItem(this.props.shipmentStatus)
                existing = existing ? JSON.parse(existing) : {};
                existing.dateType = filters.dateType;
                localStorage.setItem(this.props.shipmentStatus, JSON.stringify(existing));
                localStorage.setItem("expiry", JSON.stringify(expiry))
            }
            this.applyDateFilters()
            this.setState({page: 0})
            this.saveUpdatedFilters(filters)
        }
    }

  setImportingPage = (open) => {
    this.setState({
      isImporting: open,
    })
  }

  getDisplayed = (rows) => {
    if (!rows)
      return []
    const displayed = [...rows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)];
    return displayed;
  }

  shipmentcomparetor = (value1, value2) => {
    if (value1 < value2) {
      return -1
    }
    if (value1 > value2) {
      return 1
    }
    return 0
  }

  getStateCode = (state) => {
      if (state) {
          let stateCode = this.props.stateAndProvinceMapping.find(stateEntry => stateEntry.aliases.find(alias => alias.toLowerCase() === state.toLowerCase()))?.state
          if (stateCode) {
              return stateCode
          }
      }
      return state
  }
  getStateFromStateCode = (code) => {
    if (code) {
        let state = this.props.stateAndProvinceMapping?.find(state => state.state === code)
        if (state) {
            return state
        }
    }
    return code
  }

  displaySkus = (row) => {
    if (row.packages && row.packages.length > 0) {
      return row.packages[0].skus ? row.packages[0].skus.length > 1 ? 'Multiple' : row.packages[0].skus[0] : ''
    } else {
      return ''
    }
  }

  displayOrderId = (row) => {
    if (row.orders && row.orders?.length > 1) {
      return "Multiple"
    } else {
      return row.orders ?
        row.orders[0].orderId
        :
        row.packages[0].reference1?.length > 0 ?
          row.packages[0].reference1
          :
          ''
    }
  }

  formatDate = (date) => {
    if (date != null) {
      return date.includes("T") ? date.split("T")[0]: date
    }
    return ""
  }

  displayTags = (row) => {
    const tags = [...row.tags];
    if (tags?.length > 0 && tags[0].tagName) { //at least one tag
        return tags[0].tagName?.length > 10 ?
            tags[0].tagName.substr(0, 9) + '...'
            :
            tags.length > 1 ?
                tags[0].tagName + '...'
                :
                tags[0].tagName
    }
    return ''
  }

  getCellValue = (row, labelname) => {
    if (labelname === 'sku') {
      return this.displaySkus(row)

    } else if (labelname === 'rate') {
      return row.rate ? parseFloat(row.rate) : 0

    } else if (labelname === 'orderid') {
      return this.displayOrderId(row)

    } else if (labelname === 'state') {
      return this.getStateCode(row[this.state.sortingMap[labelname]])

    } else if (labelname === 'isprime') {
      return row.isPrime

    } else if (labelname === 'orderDate') {
      if (row.orderDate) {
        return this.formatDate(row.orderDate)
      }
      return row.orders && row.orders[0].purchaseDate ? this.formatDate(row.orders[0].purchaseDate) : ''

    } else if (labelname === 'shipBy') {
      return row.orders && row.orders[0].latestShipDate ? this.formatDate(row.modifiedLatestShipDate): ''

    } else if (labelname === 'deliverBy') {
      return row.orders && row.orders[0].latestDeliveryDate ? this.formatDate(row.orders[0].latestDeliveryDate): ''

    } else if (labelname === 'shipDate') {
        return this.formatDate(row.shipDate)
    } else if (labelname === 'tag') {
        return row.tags ? this.displayTags(row) : ''
    }
    return row[this.state.sortingMap[labelname]]

  }

  shipmentSort = (order, orderBy, rows) => {
    rows.sort((a, b) => {
      var valueA = this.getCellValue(a, orderBy) ? this.getCellValue(a, orderBy) : false
      var valueB = this.getCellValue(b, orderBy) ? this.getCellValue(b, orderBy) : false

      if (typeof valueA == "boolean" && typeof valueB == "boolean") {
        if (!valueB && valueA) {
          return order === ASC ? 1 : -1;
        } else if (valueB && !valueA) {
          return order === ASC ? -1 : 1;
        } else {
          return 0;
        }
      }
      if (valueA !== undefined && typeof valueA !== 'number') {
        valueA = valueA ? valueA.toLowerCase() : ''; // ignore upper and lowercase
      }

      if (valueB !== undefined && typeof valueB !== 'number') {
        valueB = valueB ? valueB.toLowerCase() : ''; // ignore upper and lowercase
      }

      if (valueA < valueB) {
        if (order === ASC) {
          return -1;
        } else {
          return 1;
        }
      }
      if (valueA > valueB) {
        if (order === ASC) {
          return 1;
        } else {
          return -1;
        }
      }
      return 0
    })
    return rows
  }

    handleTableDisplayChange = (newlist) => {
        this.props.saveTableSetting({
            userId: this.props.userInfo.userId,
            tableSetting: newlist,
            pageTitle: this.props.shipmentStatus,
            defaultTable: this.props.defaultTableDisplay,
            filterSetting: null
        })
    }


  setTableDisplayChecked = (selected, text) => {
    let label = text
    let newtableDisplay = this.getTableDisplay()
    newtableDisplay.forEach(row => row.selected = label == row.label ? selected : row.selected)

    this.setState({
      tableDisplay: newtableDisplay,
      displayChange: this.state.displayChange + 1
    });
    this.props.saveTableSetting({
      userId: this.props.userInfo.userId,
      tableSetting: newtableDisplay,
      pageTitle: this.props.shipmentStatus,
      defaultTable: this.props.defaultTableDisplay,
      filterSetting: null
    })
  }

  getByteArray = (file) => {
    let reader = new FileReader();
    let fileByteArray = [];
    reader.readAsArrayBuffer(file);
    reader.onloadend = function (evt) {
      if (evt.target.readyState === FileReader.DONE) {
        let arrayBuffer = evt.target.result,
          uint8array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < uint8array.length; i++) {
          fileByteArray.push(uint8array[i]);
        }
      }
    }
        return fileByteArray
    }

    getFormattedGeneralFileObject = (file) => {
        return {
            localURL: URL.createObjectURL(file),
            displayName: file.name,
            byteArray: this.getByteArray(file),
            type: file.type
        }

    }
    getFormattedFileObject = (action, file) => {
        return {
            localURL: URL.createObjectURL(file),
            displayName: file.name,
            byteArray: this.getByteArray(file),
            actionName: action.actionName,
            type: file.type
        }
    }

    freeFromMemory = (url) => {
        URL.revokeObjectURL(url)
    }

    handleGeneralFileAdd = (file) => {
        const { files, newFiles } = this.state
        //let prevNewFiles = this.state.newFiles
        //let prevFiles = this.state.files
        let newFile = this.getFormattedGeneralFileObject(file)


        files.push(newFile)
        newFiles.push(newFile)

    this.setState({
      newFiles: newFiles,
      files: files
    });
  }

    handleGeneralFileDelete = (file, index) => {
        const { files, newFiles, deletedFiles } = this.state
        /*let prevFiles = this.state.files
        let deletedFiles = this.state.deletedFiles
        let prevNewFiles = this.state.newFiles*/

    let deletedFile = (files.splice(index, 1))[0]
    if (!("byteArray" in file)) {
      deletedFiles.push(deletedFile)
    }
    else {
      newFiles.splice(index, 1)
    }
    if ("localURL" in file) {
      this.freeFromMemory(file.localURL)
    }

    this.setState({
      files: files,
      deletedFiles: deletedFiles,
      newFiles: newFiles
    })
  }

    handleFileUploadCancel = () => {
        this.setImportingPage(false)
        this.setState({
            files: [],
            deletedFiles: [],
            newFiles: []
        })
    }

    handleFileUpload = () => {
        this.props.importOrders(this.state.files, this.state.fromDate, this.state.toDate, this.state.shipToDate)
        this.setImportingPage(false)
        this.setState({
            files: [],
            deletedFiles: [],
            newFiles: []
        })
    }

  rateAndCompare = () => {
    let selectedShipment = this.state.rows.find(row => {return row.shipmentId === this.state.selected[0].order})
    //This function is only called when there is 1 shipment selected
    this.setState({
      selectedShipment
    })
    if (selectedShipment.fromAddressAccountNumber === "Amazon") {
      this.handleBuyShippingServicesOpen()
    } else if(selectedShipment?.shipmentId &&
      selectedShipment?.fromAddressAccountNumber &&
      selectedShipment?.carrierService){
      this.setState({
        isRateAndCompare: true
      })
    }else{
      this.setState({
        rateCompareErrorFeedbackOpen: true
      })
    }
  }

  triggerMaxDateGapError = () =>{
      this.setState({
          filterGapLimitError: true
      })
  }
  handleRateCancel = () => {
    this.setState({
      isRateAndCompare: false,
      isBuyShippingServices: false,
    })
    if (!this.state.openEditOrderDialog) {
      this.clearSelected()
    }
    this.props.clearRates()
  }

    handleEditShipmentOpen = (index) => {
        const openEditOrderRow = this.displayList(this.state.rows)[index]
        this.setState({
            openEditOrderDialog: true,
            openEditOrderIndex: index,
            editingShipmentId: openEditOrderRow?.shipmentId,
        })
        this.props.setShipmentsOpenEditRow(openEditOrderRow)
        this.handleExpand(
            openEditOrderRow?.shipmentId,
            !openEditOrderRow?.carrierName ? false : openEditOrderRow.carrierName.split(" ")[0] === FEDEX
        )

    }

    handleEditShipmentLogs = (index) => {
        const openEditOrderRow1 = this.getDisplayed(this.shipmentSort(this.state.order, this.state.orderBy, this.filterBySearch(this.applyFilters(this.props.shipments[0]))))[index]
        this.setState({
            openEditOrderDialog: true,
            openEditOrderIndex: index,
        })
        this.handleExpand(
            openEditOrderRow1?.shipmentId,
            !openEditOrderRow1?.carrierName ? false : openEditOrderRow1.carrierName.split(" ")[0] === FEDEX
        )
    }

    handleExpand = (shipmentId, isFedex) => {
        let expandedShipment = [{ order: shipmentId, isFedex: isFedex }]
        this.setState({
            prevSelected: this.state.selected,
            selected: expandedShipment,
            selectedShipment: this.state.selected.length === 1 ? this.state.rows.find(row => {
                return row.shipmentId === this.state.selected[0].order
            }) : {}
        });
    }

    handleEditShipmentClose = () => {
        let temp1 = this.state.prevSelected
        let temp2 = this.state.selected
        if (temp1) {
            temp1 = temp1.filter( x => !temp2.filter( y => y.order === x.order).length);
        }
        this.props.clearLinkedShipment()
        this.setState({
            openEditOrderDialog: false,
            openEditOrderIndex: 0,
            searchValue: "",
            selected: temp1,
            prevSelected: []
        })
        this.props.setShipmentsOpenEditRow(null)
    }

    checkFiltersApplied = () => {
        if (!this.props.tableSetting?.shipmentFilterSetting) {
            return false
        }
        return Object.keys(this.props.tableSetting?.shipmentFilterSetting).length !== 0
    }

  detailsTitle = () => {
    const row = this.getOpenEditRow()
    if (row) {
      const displayOrderIdDetail = (orders) => {
        if (!orders) {
          return row.packages[0].reference1?.length > 0 ?
            row.packages[0].reference1
            :
            ''
        }
        const orderIds = [orders.map(order => order.orderId)]
        if (orders.length > 1) {
          return orderIds.join('/').toString()
        } else {
          return orderIds[0]
        }
      }

            return this.props.title + " - " + displayOrderIdDetail(row.orders)
        }
        return ''
    }

    getOrderIdFromLogs = (orderId) => {
        this.setState({
            orderIdFromLogs: orderId
        })
    }

    getShipmentIdFromLogs = (shipmentId) => {
        this.setState({
            shipmentIdFromLogs: shipmentId
        })
    }
    getOpenEditRow = (ignoreShipmentsOpenEditRow = false) => {
        if (!ignoreShipmentsOpenEditRow && this.props.shipmentsOpenEditRow) {
            return this.props.shipmentsOpenEditRow
        }
       return this.displayList(this.state.rows).find(row => row.shipmentId === this.state.editingShipmentId)
    }

    isShipmentArchived = (shipmentId) => {
        const shipment = this.state.rows.find((shipment) => shipment.shipmentId === shipmentId)
        if (!shipment) {
            return false
        }
        return shipment.labelArchived
    }

  isShipmentReady = (shipmentId) => {
    const shipment = this.state.rows.find((shipment) => shipment.shipmentId === shipmentId)
    if (!shipment) {
      return false
    }
    let isReady = true
    //Check mandatory fields for carriers
    this.state.defaultShipmentReadyCheck.defaultCarrierArray.forEach((value) => {
      if (!shipment[value] || shipment[value] === "") {
        isReady = false
      }
    })
    //Check mandatory fields of sender
    this.state.defaultShipmentReadyCheck.defaultFromAddressArray.forEach((value) => {
      if (!shipment[value] || shipment[value] === "") {
        if (value !== 'Company Name' && !shipment.isReturnShipment) {
          isReady = false
        }
      }
      if (value === "fromAddressState") {
          if (this.props.stateAndProvinceMapping.findIndex(state => state.state?.toLowerCase() === shipment[value]?.toLowerCase()) === -1) {
              isReady = false
          }
      }
    })
    //Check mandatory fields of shipment
    this.state.defaultShipmentReadyCheck.defaultShipmentAddressArray.forEach((value) => {
      if (!shipment[value] || shipment[value] === "") {
        isReady = false
      }
        if (value === "shippingAddressStateOrRegion") {
            if (this.props.stateAndProvinceMapping.findIndex(state => state.state?.toLowerCase() === shipment[value]?.toLowerCase()) === -1) {
                isReady = false
            }
        }
    })
    //Check mandatory fields of packages
    shipment.packages.forEach((shipmentPackage) => {
      //Tracking numbers CANNOT be attached if we want to ship it out
      if (shipmentPackage.trackingNumber) {
        isReady = false
      }
      this.state.defaultShipmentReadyCheck.defaultPackageArray.forEach((value) => {
        if (value !== "declaredValue" && (!shipmentPackage[value] || shipmentPackage[value] === "" || (value === "packaging" && shipmentPackage[value] === "-"))) {
          isReady = false
        }
      })
    })
    if (shipment.orders) {
      //Check to see if quantity being shipped matches quantity ordered
      shipment.orders.forEach((shipmentOrder) => {
        shipmentOrder.orderItems.forEach((orderItem) => {
          const sku = orderItem.sellerSku
          const internalSku = shipment.packages[0]?.products?.find(product => product.sku === sku)?.product
            if (!internalSku) return false
          const skuArray = internalSku.split("-")
          const itemsPerSku = parseInt(skuArray[skuArray.length - 1])
          const itemsOrdered = parseInt(orderItem.quantityOrdered) * itemsPerSku
          let itemsShipped = 0
          shipment.packages.forEach((shipmentPackage) => {
            shipmentPackage.products.forEach((product) => {
              if (product.sku === sku) {
                if (product.quantity === "split") {
                  itemsShipped += parseInt(product.splitQuantity)
                } else {
                  itemsShipped += parseInt(product.quantity) * itemsPerSku
                }
              }
            })
          })
          if (itemsShipped !== itemsOrdered) {
            isReady = false
          }
        })
      })
      //Check to see if any packages are empty
      shipment.packages.forEach((shipmentPackage) => {
        let hasProduct = false
        shipmentPackage.products.forEach((product) => {
          hasProduct = parseInt(product.quantity) > 0 || parseInt(product.splitQuantity) > 0 ? true : hasProduct
        })
        if (!hasProduct) {
          isReady = false
        }
      })
    }
    return isReady
  }

    isShipmentReadyMarkAsShipped = (shipmentId) => {
        const shipment = this.state.rows.find((shipment) => shipment.shipmentId === shipmentId)
        if (!shipment) {
            return false
        }
        let isReady = true
        //Check mandatory fields for carriers
        this.state.defaultShipmentReadyCheck.defaultCarrierArray.forEach((value) => {
            if (!shipment[value] || shipment[value] === "") {
                isReady = false
            }
        })
        //Check mandatory fields of sender
        this.state.defaultShipmentReadyCheck.defaultFromAddressArray.forEach((value) => {
            if (!shipment[value] || shipment[value] === "") {
                if (value !== 'Company Name' && !shipment.isReturnShipment) {
                    isReady = false
                }
            }
        })
        //Check mandatory fields of shipment
        this.state.defaultShipmentReadyCheck.defaultShipmentAddressArray.forEach((value) => {
            if (!shipment[value] || shipment[value] === "") {
                isReady = false
            }
        })
        //Check mandatory fields of packages
        shipment.packages.forEach((shipmentPackage) => {
            //Tracking numbers CANNOT be attached if we want to ship it out
            this.state.defaultShipmentReadyCheck.defaultPackageArray.forEach((value) => {
                if (value !== "declaredValue" && (!shipmentPackage[value] || shipmentPackage[value] === "" || (value === "packaging" && shipmentPackage[value] === "-"))) {
                    isReady = false
                }
            })
        })
        if (shipment.orders) {
            //Check to see if quantity being shipped matches quantity ordered
            shipment.orders.forEach((shipmentOrder) => {
                shipmentOrder.orderItems.forEach((orderItem) => {
                    const sku = orderItem.sellerSku
                    const internalSku = shipment.packages[0]?.products?.find(product => product.sku === sku)?.product
                    if (!internalSku) return false
                    const skuArray = internalSku.split("-")
                    const itemsPerSku = parseInt(skuArray[skuArray.length - 1])
                    const itemsOrdered = parseInt(orderItem.quantityOrdered) * itemsPerSku
                    let itemsShipped = 0
                    shipment.packages.forEach((shipmentPackage) => {
                        shipmentPackage.products.forEach((product) => {
                            if (product.sku === sku) {
                                if (product.quantity === "split") {
                                    itemsShipped += parseInt(product.splitQuantity)
                                } else {
                                    itemsShipped += parseInt(product.quantity) * itemsPerSku
                                }
                            }
                        })
                    })
                    if (itemsShipped !== itemsOrdered) {
                        isReady = false
                    }
                })
            })
            //Check to see if any packages are empty
            shipment.packages.forEach((shipmentPackage) => {
                let hasProduct = false
                shipmentPackage.products.forEach((product) => {
                    hasProduct = parseInt(product.quantity) > 0 || parseInt(product.splitQuantity) > 0 ? true : hasProduct
                })
                if (!hasProduct) {
                    isReady = false
                }
            })
        }
        return isReady
    }

  isShipmentReadyForMarkRedirected = (shipmentId) => {
    const shipment = this.state.rows.find((shipment) => shipment.shipmentId === shipmentId)
    if (!shipment)
      return false
    let isReady = true
    //Check mandatory fields for carriers
    this.state.defaultShipmentReadyCheck.defaultCarrierArray.forEach((value) => {
      if (!shipment[value] || shipment[value] === "") {
        isReady = false
      }
    })
    //Check mandatory fields of sender
    this.state.defaultShipmentReadyCheck.defaultFromAddressArray.forEach((value) => {
      if (!shipment[value] || shipment[value] === "") {
        isReady = false
      }
    })
    //Check mandatory fields of shipment
    this.state.defaultShipmentReadyCheck.defaultShipmentAddressArray.forEach((value) => {
      if (!shipment[value] || shipment[value] === "") {
        isReady = false
      }
    })
    //Check mandatory fields of packages
    shipment.packages.forEach((shipmentPackage) => {
      //Tracking numbers MUST be attached if we want to set as redirected
      if (!shipmentPackage.trackingNumber) {
        isReady = false
      }
      this.state.defaultShipmentReadyCheck.defaultPackageArray.forEach((value) => {
        if (value !== "declaredValue" && (!shipmentPackage[value] || shipmentPackage[value] === "" || (value === "packaging" && shipmentPackage[value] === "-"))) {
          isReady = false
        }
      })
    })
    if (shipment.orders && !shipment.isCloneShipment) {
      //Check to see if quantity being shipped matches quantity ordered
      shipment.orders.forEach((shipmentOrder) => {
        shipmentOrder.orderItems.forEach((orderItem) => {
          const sku = orderItem.sellerSku
          const internalSku = shipment.packages[0]?.products?.find(product => product.sku === sku)?.product
            if (!internalSku) return false
          const skuArray = internalSku.split("-")
          const itemsPerSku = parseInt(skuArray[skuArray.length - 1])
          const itemsOrdered = parseInt(orderItem.quantityOrdered) * itemsPerSku
          let itemsShipped = 0
          shipment.packages.forEach((shipmentPackage) => {
            shipmentPackage.products.forEach((product) => {
              if (product.sku === sku) {
                if (product.quantity === "split") {
                  itemsShipped += parseInt(product.splitQuantity)
                } else {
                  itemsShipped += parseInt(product.quantity) * itemsPerSku
                }
              }
            })
          })
          if (itemsShipped !== itemsOrdered) {
            isReady = false
          }
        })
      })
      //Check to see if any packages are empty
      shipment.packages.forEach((shipmentPackage) => {
        let hasProduct = false
        shipmentPackage.products.forEach((product) => {
          hasProduct = parseInt(product.quantity) > 0 || parseInt(product.splitQuantity) > 0 ? true : hasProduct
        })
        if (!hasProduct) {
          isReady = false
        }
      })
    }
    return isReady
  }

  isSelectedShipmentsArchived = () => { // Returns true if ALL selected shipments are archived; false is AT LEAST ONE selected shipment is not archived
    let shipmentsArchived = true
    this.state.selected.forEach((shipment) => {
        if (!this.isShipmentArchived(shipment.order))
            shipmentsArchived = false
    })
    return shipmentsArchived
  }

  isSelectedShipmentsReady = () => {
    let shipmentsReady = false
    this.state.selected.forEach((shipment) => {
      shipmentsReady = this.isShipmentReady(shipment.order) ? true : shipmentsReady
    })
    return shipmentsReady
  }

  isSelectedShipmentsReadyForMarkRedirected = () => {
    let shipmentsReady = false
    this.state.selected.forEach((shipment) => {
      shipmentsReady = this.isShipmentReadyForMarkRedirected(shipment.order) ? true : shipmentsReady
    })
    return shipmentsReady
  }

    isSelectedShipmentsReadyForMarkShipped = () => {
        let shipmentsReady = false
        this.state.selected.forEach((shipment) => {
            shipmentsReady = this.isShipmentReadyMarkAsShipped(shipment.order) ? true : shipmentsReady
        })
        return shipmentsReady
    }

    openRatingShipment = () => {
        this.setState({
            ratingShipment: true
        })
    }

    closeRatingShipment = () => {
        this.setState({
            ratingShipment: false
        })
    }

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const prevSelected = [...this.state.selected]
      const finalSelected = [...this.state.selected]
      const newSelected = this.displayList(this.state.rows)
        .map(n => ({
          order: n.shipmentId,
          isFedex: !n.carrierName ? false : n.carrierName.split(" ")[0] === FEDEX
        }));
      newSelected.forEach(selection => {
        if (!prevSelected.find(prevSelect => prevSelect.order === selection.order)) {
          finalSelected.push(selection)
        }
      })
      this.setState({
        selected: finalSelected,
      });
      return;
    }

    let newSelected = [...this.state.selected]
    this.displayList(this.state.rows)
      .forEach(row =>
        newSelected = newSelected.filter(selected => selected.order !== row.shipmentId))
    this.setState({
      selected: newSelected
    });
  }

    handleClick = (event, shipmentid, isFedex) => {
        const selectedIndex = this.state.selected.findIndex(item => item.order === shipmentid);
        let selected = [...this.state.selected];

        if (selectedIndex === -1) {
            selected = [...selected, {order: shipmentid, isFedex: isFedex}];
        } else {
            selected.splice(selectedIndex, 1);
        }
        this.setState({
            selected,
        });
    };

    isSelected = (item) => {
        const index = this.state.selected.findIndex(order => order.order === item);
        return index !== -1
    };

  isChecked = () => {
    if (this.state.rows?.length === 0) {
      return false
    }
    let isChecked = true
      this.displayList(this.state.rows).forEach(
      row =>
        isChecked = this.isSelected(row.shipmentId) ? isChecked : false
    )
    return isChecked
  }

  isIndeterminate = () => {
    let allChecked = true
    let hasSelected = false
      this.displayList(this.state.rows).forEach(
      row => {
        allChecked = this.isSelected(row.shipmentId) ? allChecked : false
        hasSelected = this.isSelected(row.shipmentId) ? true : hasSelected
      }
    )
    return hasSelected && !allChecked
  }

    clearSelected = () => {
        //TODO exit out of everything when clear selected is called
        let temp1 = this.state.prevSelected
        let temp2 = this.state.selected
        if (temp1) {
            temp1 = temp1.filter( x => !temp2.filter( y => y.order === x.order).length);
        }
        this.setState({
            selected: temp1,
            prevSelected: []
        })
    }

    handleDateChange = async (fromDate, toDate) => {
      await this.setState({
          fromDateLogs: fromDate,
          toDateLogs: toDate,
      })
    }

    createReturnShipments = (previousShipmentArray) => {
        let previousShipments = []
        previousShipmentArray.forEach(previousShipment => {
            previousShipments.push(this.state.rows.find(shipment => shipment.shipmentId === previousShipment.id))
        })
        let newShipments = []
        previousShipments.forEach(previousShipment => {
            previousShipment.packages.forEach((p) => {
                p.reference1 = ''
            }) //Reference 1 is removed
            newShipments.push({
                carrier: previousShipment.carrierName,
                commodities: previousShipment.commodities,
                defaultService: previousShipment.defaultService,
                fromAddressName: previousShipment.fromAddressName,
                fromAddressAccountNumber: previousShipment.fromAddressAccountNumber,
                fromAddressAddressLine1: previousShipment.shippingAddressAddressLine1,
                fromAddressAddressLine2: previousShipment.shippingAddressAddressLine2,
                fromAddressCity: previousShipment.shippingAddressCity,
                fromAddressCompanyName: previousShipment.shippingAddressCompanyName,
                fromAddressContactName: previousShipment.shippingAddressName,
                fromAddressCountry: previousShipment.shippingAddressCountry,
                fromAddressEmail: previousShipment.email,
                fromAddressMeterNumber: previousShipment.fromAddressMeterNumber,
                fromAddressPhone: previousShipment.shippingAddressPhone,
                fromAddressPostalCode: previousShipment.shippingAddressPostalCode,
                fromAddressState: previousShipment.shippingAddressStateOrRegion,
                isDefault: previousShipment.isDefault,
                isResidential: previousShipment.isResidential,
                packages: previousShipment.packages,
                service: previousShipment.carrierService,
                shippingAddressAddressLine1: previousShipment.fromAddressAddressLine1,
                shippingAddressAddressLine2: previousShipment.fromAddressAddressLine2,
                shippingAddressCity: previousShipment.fromAddressCity,
                shippingAddressCompanyName: previousShipment.fromAddressCompanyName,
                shippingAddressCountry: previousShipment.fromAddressCountry,
                shippingAddressName: previousShipment.fromAddressContactName,
                shippingAddressPhone: previousShipment.fromAddressPhone,
                shippingAddressPostalCode: previousShipment.fromAddressPostalCode,
                shippingAddressStateOrRegion: previousShipment.fromAddressState,
                signatureConfirmation: previousShipment.signatureConfirmation,
                email: previousShipment.fromAddressEmail,
                tags: previousShipment.tags,
                isReturnShipment: true,
                isCloneShipment: false,
                redirectFromOrderId: previousShipment.orders ? previousShipment.orders[0].orderId : ''
            })
        })
        this.props.createReturnShipments(newShipments, RETURN)
        if (!this.state.openEditOrderDialog) {
            this.clearSelected()
        }
    }

  createCloneShipments = (previousShipmentArray) => {
    let previousShipments = []
    previousShipmentArray.forEach(previousShipment => {
      //Create copy of the object so the initial object is not affected
      previousShipments.push({...this.state.rows.find(shipment => shipment.shipmentId === previousShipment.id)})
    })
    previousShipments.forEach(shipment => {
      shipment.isCloneShipment = true
      shipment.carrier = shipment.carrierName
      shipment.service = shipment.carrierService
      if (shipment.orders) {
        shipment.clonedOrderId = shipment.orders[0].orderId
      } else {
        shipment.clonedOrderId = ''
      }
    })
    this.props.createReturnShipments(previousShipments, 'Cloned')
    if(!this.state.openEditOrderDialog) {
      this.clearSelected()
    }
  }

    searchByOrderId = () => {
      if (this.props.smartSearch){
          this.props.fetchByOrderId(this.props.shipmentStatus, this.state.searchValue);
      }
      return this.state.rows
    }
    searchByTrackingId = () => {
      if (this.props.smartSearch){
          this.props.fetchByTrackingId(this.props.shipmentStatus, this.state.searchValue);
      }
      return this.state.rows
    }

    displayList = (rows) => {
      if (this.state.listSearchedByOrderId || this.state.listSearchedByTrackingId) {
          return this.applyOnlyLocationFilter(this.state.rows)
      } else {
          return this.getDisplayed(this.shipmentSort(this.state.order, this.state.orderBy, this.filterBySearch(this.applyFilters(rows))))
      }
    }

    openLogs = () => {
        this.setState({
            openLogsTable: true,
            openEditOrderDialog: false,
            logsDetailPage: false
        })
    }

    updateExportInfo = (rows) => {
      this.setState({
          exportInfo: rows,
      })
    }

    exportShipmentSummaryToExcel = () => {
        let productCodes = this.state.exportInfo.map(row => {
            return row.packages.map(pkg => {
                return pkg.reference3?.split('/')
            });
        }).flat().flat();
        let shortcodes = []
        let reports = []
        for (let row of productCodes) {
            if (row) {
                row = row.trim()
                if (!shortcodes.includes(row)) {
                    let newReport = {
                        productCode: row,
                        printedQty: 1
                    }
                    reports.push(newReport);
                    shortcodes.push(row);
                } else {
                    let selectedReport = {};
                    let selectedIndex = 0;
                    for (let report of reports) {
                        if (report.productCode === row) {
                            selectedReport = report;
                            selectedIndex = reports.indexOf(report);
                            break;
                        }
                    }
                    if (selectedReport !== null) {
                        selectedReport.printedQty++;
                        reports[selectedIndex] = selectedReport;
                    }
                }
            }
        }
        reports = reports.sort((a, b) => {
            return a.productCode.toLowerCase().localeCompare(b.productCode.toLowerCase())
        })

        let valueToOrderByShipmentsSummary = this.props.shipmentSummaryProperty
        let orderDirectionShipmentsSummary = this.props.shipmentsSummaryOrder
        let sortedSummary = this.stableSort(reports, this.getComparator(orderDirectionShipmentsSummary, valueToOrderByShipmentsSummary))
        this.props.exportShipmentSummaryToExcel(sortedSummary)
    }

  handleBuyShippingServicesOpen = () => {
    this.props.fetchBuyShippingServices(this.state.selected[0].order)
    this.setState({
      isBuyShippingServices: true
    })
  }

  setBuyShippingService = (value) => {
      this.setState({
          buyShippingSelectedService: value
      })
  }

  handleSaveBuyShippingService = () => {
    this.props.saveBuyShippingService(this.state.selected[0].order, this.state.buyShippingSelectedService, this.props.packageToListMap)
    this.setState({
      isBuyShippingServices: false,
      buyShippingSelectedService: ''
    })

    if (!this.state.openEditOrderDialog) {
      this.clearSelected()
    }
    this.props.clearRates()
  }

  handleCancelBuyShippingService = () => {
    this.setState({
      isBuyShippingServices: false,
      buyShippingSelectedService: ''
    })
    if (!this.state.openEditOrderDialog) {
      this.clearSelected()
    }
    this.props.clearRates()
  }

  // isShipmentBuyShipping = () => {
  //   //TODO should be implemented better
  //   let isShipmentBuyShipping = false
  //   // console.log(this.state.selectedShipment)
  //   const selectedShipment = [...this.state.selected][0]
  //   if (selectedShipment.carrierName.includes('Amazon')) {
  //     isShipmentBuyShipping = true
  //   }
  //   return isShipmentBuyShipping
  // }

    downloadFile = () => {
        let blob = new Blob([Uint8Array.from(this.props.commercialInvoicePDF)], { type: "application/pdf" });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        // if (shipmentId) link.download = "Sample Commercial Invoice - " + shipmentId //this.state.showingPurchaseOrder;
        // else link.download = "Sample Commercial Invoice"
        link.download = this.props.isSampleCommercialInvoice ? "Sample Commercial Invoice" : "Commercial Invoice"
        link.click();
        // now clear it from memory
        window.URL.revokeObjectURL(link.href)
    };


    setZoom = (zoom) => {
        let newScale = Math.min(Math.max(this.state.commercialInvoicePDFScale + zoom, 1), 2)
        this.setState({
            commercialInvoicePDFScale: newScale
        })
    }

    getHeaderButtons = () => {
        let headerButtons = [{ icon: <KeyboardBackspaceIcon />, handler: this.toggleShowCommercialInvoice, title: "Back" },
            { icon: <GetAppIcon />, handler: this.downloadFile, title: "Download", disabled: !this.state.commercialInvoicePDFLoadSuccess },
            { icon: <ZoomOutIcon />, handler: () => this.setZoom(-0.25), title: "Zoom Out", disabled: !this.state.commercialInvoicePDFLoadSuccess },
            { divider: <span style={{ padding: "5px", marginTop: "5px" }}>{this.state.commercialInvoicePDFScale * 100}%</span> },
            { icon: <ZoomInIcon />, handler: () => this.setZoom(0.25), title: "Zoom In", disabled: !this.state.commercialInvoicePDFLoadSuccess }]
        return headerButtons
    }

    handlePdfLoadSuccess = () => {
        this.setState({ commercialInvoicePDFLoadSuccess: true })
    }

    handlePdfLoadError = () => {
        this.setState({ commercialInvoicePDFLoadSuccess: false })
    }

    enableBuyShipping = () => {
        if (this.state.openEditOrderDialog || this.state.selected.length === 1) {
            let order = this.state.rows.find((shipment) => shipment.shipmentId === this.state.selected[0].order)
            let carrierFound = this.props.carriers.find((carrier) => carrier.accountNumber === order?.fromAddressAccountNumber && carrier.name === order?.carrierName)
            return order?.fromAddressAccountNumber && carrierFound?.isBuyShipping && order !== undefined
        }
        return false
    }

    filterTagsBarWrapper = (filtersMap) => {
        if (!filtersMap) {
            return filtersMap
        }

        let keys = Object.keys(filtersMap);
        let fromIndex, deleteFromIndex
        let toIndex, deleteToIndex
        let deleteTypeIndex

        if (filtersMap["dateType"] === "order date") {
            fromIndex = keys.findIndex(key => key === "fromDate")
            deleteFromIndex = keys.findIndex(key => key === "shipFromDate")
            toIndex = keys.findIndex(key => key === "toDate")
            deleteToIndex = keys.findIndex(key => key === "shipToDate")
            deleteTypeIndex = keys.findIndex(key => key === "dateType")
        } else {
            deleteFromIndex = keys.findIndex(key => key === "fromDate")
            fromIndex = keys.findIndex(key => key === "shipFromDate")
            deleteToIndex = keys.findIndex(key => key === "toDate")
            toIndex = keys.findIndex(key => key === "shipToDate")
            deleteTypeIndex = keys.findIndex(key => key === "dateType")
        }

        let newFiltersMap = new Map()
        keys.forEach((key, index) => {
            if (index !== fromIndex && index !== toIndex && index !== deleteToIndex && index !== deleteFromIndex && index !== deleteTypeIndex) {
                newFiltersMap[keys[index]] = filtersMap[key]
            }
        })
        if (fromIndex !== -1) {
            newFiltersMap[keys[fromIndex]] = filtersMap[keys[fromIndex]]
        }
        if (toIndex !== -1) {
            newFiltersMap[keys[toIndex]] = filtersMap[keys[toIndex]]
        }
        if (deleteFromIndex !== -1) {
            newFiltersMap[keys[deleteFromIndex]] = filtersMap[keys[deleteFromIndex]]
        }
        if (deleteToIndex !== -1) {
            newFiltersMap[keys[deleteToIndex]] = filtersMap[keys[deleteToIndex]]
        }

        return newFiltersMap
    }

    /**
     * Renders this component
     */
    render() {
        return (
            <div className="root">
                <ErrorFeedbackBar/>
                <FeedbackBar
                    open={this.state.rateCompareErrorFeedbackOpen}
                    handleClose={() => this.setState({rateCompareErrorFeedbackOpen: false})}
                    severity={ERROR}
                    message={CARRIER_MISSING_ERROR}
                />
                <FeedbackBar
                    open={this.state.filterGapLimitError}
                    handleClose={() => this.setState({filterGapLimitError: false})}
                    severity={ERROR}
                    message={DAY_FILTER_GAP_LIMIT_ERROR_PARTA+this.state.maxAllowedDateRange+DAY_FILTER_GAP_LIMIT_ERROR_PARTB}
                />
                <TableDisplayDialog
                    isOpen={this.state.isTableDisplayDialogOpen}
                    onClose={this.hideTableDisplayDialog}
                    tableDisplay={this.getTableDisplay()}
                    handleTableDisplayChange={this.handleTableDisplayChange}
                    setChecked={this.setTableDisplayChecked}
                    displayChange={this.state.displayChange}
                    defaultTable={this.props.defaultTableDisplay}
                />

                <Paper className="paper">
                    {!this.state.showCommercialInvoice ?
                        <ShipmentsTableToolbar
                            updateShipments={this.props.updateAndValidateShipments}
                            fromDateLogs={this.state.fromDateLogs}
                            toDateLogs={this.state.toDateLogs}
                            fromDate={this.state.fromDate}
                            toDate={this.state.toDate}
                            selectedDate={this.state.selectedDate}
                            numSelected={!this.state.selected ? 0 : this.state.selected.length}
                            title={this.state.isRateAndCompare ? RATE_AND_COMPARE : this.props.title}
                            clearSelected={this.clearSelected}
                            selected={this.state.selected}
                            shipmentStatus={this.props.shipmentStatus}
                            rows={this.state.rows}
                            tags={this.props.tags}
                            selectedShipment={this.state.selectedShipment}
                            carriers={this.props.carriers}
                            setImportingPage={this.setImportingPage}
                            handleFileUploadCancel={this.handleFileUploadCancel}
                            isImporting={this.state.isImporting}
                            handleRateToDefault={this.props.rateToDefault}
                            handleRateToCheapest={this.props.rateToCheapest}
                            handleValidateAddress={this.props.validateAddress}
                            openCreateOrderDialog={this.state.openCreateOrderDialog}
                            handleCreateOrderDialogOpen={() => this.setState({openCreateOrderDialog: true})}
                            handleCreateOrderDialogClose={() => this.setState({openCreateOrderDialog: false})}
                            rateAndCompare={this.rateAndCompare}
                            isRateAndCompare={(this.state.isRateAndCompare || this.state.isBuyShippingServices)}
                            handleRateCancel={this.handleRateCancel}
                            editOrderOpen={this.state.openEditOrderDialog}
                            getRow={this.getOpenEditRow}
                            handleEditShipmentClose={this.handleEditShipmentClose}
                            detailsTitle={this.state.openEditOrderDialog ? this.detailsTitle() : ""}
                            isSelectedShipmentsArchived={this.isSelectedShipmentsArchived}
                            isSelectedShipmentsReady={this.isSelectedShipmentsReady}
                            createReturnShipment={this.createReturnShipments}
                            createCloneShipment={this.createCloneShipments}
                            isSelectedShipmentsReadyForMarkShipped={this.isSelectedShipmentsReadyForMarkShipped}
                            isSelectedShipmentsReadyForMarkRedirected={this.isSelectedShipmentsReadyForMarkRedirected}
                            isShipmentReadyForMarkRedirected={this.isShipmentReadyForMarkRedirected}
                            isShipmentReady={this.isShipmentReady}
                            isShipmentReadyMarkAsShipped={this.isShipmentReadyMarkAsShipped}
                            toggleShipmentSummary={this.toggleShipmentSummary}
                            toggleLogsTable={this.toggleLogsTable}
                            shipmentSummary={this.state.openShipmentSummary}
                            logsTable={this.state.openLogsTable}
                            fromLogsState={this.state.fromLogs}
                            fromLogs={this.isFromLogs}
                            openLogs={this.openLogs}
                            logsFalse={this.logsFalse}
                            logsTrue={this.logsTrue}
                            isFromLogsFalse={this.isFromLogsFalse}
                            toggleLogsDetailsPage={this.toggleLogsDetailsPage}
                            logsDetailsPage={this.state.logsDetailPage}
                            orderIdFromLogs={this.state.orderIdFromLogs}
                            shipmentIdFromLogs={this.state.shipmentIdFromLogs}
                            handleBuyShippingServicesOpen={this.handleBuyShippingServicesOpen}
                            enableBuyShipping={this.enableBuyShipping}
                            isBuyShippingServices={this.state.isBuyShippingServices}
                            handleSaveBuyShippingService={this.handleSaveBuyShippingService}
                            buyShippingSelectedService={this.state.buyShippingSelectedService}
                            handleCancelBuyShippingService={this.handleCancelBuyShippingService}
                            exportShipmentSummaryToExcel={this.exportShipmentSummaryToExcel}
                        />
                        : <CustomToolbar
                            headerButtons={this.getHeaderButtons()}
                            handleClose={this.toggleShowCommercialInvoice}
                        />}
                    {this.state.openShipmentSummary ?
                        <ShipmentsSummary
                            allRows={this.state.rows}
                            locations={this.props.locations}
                            setShipmentSummaryOrderDirection={this.props.setShipmentSummaryOrderDirection}
                            updateExportInfo={this.updateExportInfo}
                        /> :
                        <>
                            {this.state.openLogsTable && !this.state.openEditOrderDialog ?
                                <SystemEventLog
                                    updateShipments={this.props.updateAndValidateShipments}
                                    // handleEditShipmentLogs={this.handleEditShipmentLogs}
                                    defaultTableDisplay={this.props.defaultTableDisplay}
                                    handleEditShipment={this.handleEditShipmentOpen}
                                    applyFilters={this.applyFilters}
                                    handleDateChange={this.handleDateChange}
                                    // handleFromLogs={this.isFromLogs}
                                    // expand={this.handleExpandShipment}
                                    // openEditShipment={this.handleOpenEditShipment}
                                    title={this.props.title}
                                    shipmentStatus={this.props.shipmentStatus}
                                    carriers={this.props.carriers}
                                    isItemSelected={true}
                                    labelId={`enhanced-table-checkbox-${0}`}
                                    row={this.getOpenEditRow()}
                                    selected={this.props}
                                    contentCells={this.getSelectedTableDisplay()}
                                    handleClick={(e) => this.handleClick(e, this.getOpenEditRow().shipmentId, !this.getOpenEditRow().carrierName ? false : this.getOpenEditRow().carrierName.split(" ")[0] === FEDEX)}
                                    tags={this.props.carrier}
                                    locations={this.props.locations}
                                    rateToDefault={this.props.rateToDefault}
                                    validateAddress={this.props.validateAddress}
                                    clearSelected={this.clearSelected}
                                    showInfo={true}
                                    handleEditShipmentClose={this.handleEditShipmentClose}
                                    isShipmentReady={() => this.isShipmentReady(this.getOpenEditRow()?.shipmentId)}
                                    isShipmentReadyForMarkRedirected={() => this.isShipmentReadyForMarkRedirected(this.getOpenEditRow()?.shipmentId)}
                                    history={this.props.history}
                                    clearLinkedShipment={this.props.clearLinkedShipment}
                                    boxes={this.props.packages}
                                    logsDetailsPage={this.state.logsDetailPage}
                                    toggleLogsDetailsPage={this.toggleLogsDetailsPage}
                                    orderIdFromLogs={this.getOrderIdFromLogs}
                                    toggleCommercialInvoiceView={this.toggleShowCommercialInvoice}
                                    showCommercialInvoice={this.state.showCommercialInvoice}
                                    scale={this.state.commercialInvoicePDFScale}
                                    handleCommercialInvoicePdfLoadSuccess={this.handlePdfLoadSuccess}
                                    handleCommercialInvoicePdfLoadError={this.handlePdfLoadError}
                                    commercialInvoicePdfLoadSuccess={this.state.commercialInvoicePDFLoadSuccess}
                                    shipmentIdFromLogs={this.getShipmentIdFromLogs}
                                />

                                :
                                <>
                                    {this.state.isBuyShippingServices ?
                                        <BuyShippingServicesTable
                                            shippingServices={this.props.buyShippingServices}
                                            shipment={this.state.rows.find(row => row.shipmentId === this.state.selected[0].order)}
                                            handleSave={this.handleSaveBuyShippingService}
                                            handleCancel={this.handleCancelBuyShippingService}
                                            setBuyShippingService={this.setBuyShippingService}
                                        />
                                        :
                                        <>
                                            {this.state.isRateAndCompare ?
                                                <RatingDialog
                                                    handleRateCancel={this.handleRateCancel}
                                                    orderId={this.displayOrderId(this.state.selectedShipment)}
                                                    selectedShipment={this.state.selectedShipment}
                                                    carrierService={this.state.selectedShipment?.carrierService}
                                                    rates={this.props.rates}
                                                    rateShipment={this.props.rateShipment}
                                                    city={this.state.selectedShipment?.shippingAddressCity}
                                                    postalCode={this.state.selectedShipment?.shippingAddressPostalCode}
                                                    state={this.state.selectedShipment?.shippingAddressStateOrRegion}
                                                    storeRates={this.props.storeRates}
                                                    editShipmentCarrier={this.props.editShipmentCarrier}
                                                    carriers={this.state.enabledCarriers}
                                                />
                                                :
                                                this.state.isImporting ?
                                                    <div>
                                                        <div className="shipment-fileImportBox">
                                                            <FileUpload
                                                                handleFileAdd={this.handleGeneralFileAdd}
                                                                handleFileDelete={this.handleGeneralFileDelete}
                                                                files={this.state.files ? this.state.files : []}
                                                                fetchFilePreview={(file) => this.props.fetchFilePreview({
                                                                    fullPath: file.fullPath,
                                                                    ticketId: this.props.ticket.ticketId
                                                                })}
                                                                singleFileUpload={false}
                                                            />
                                                        </div>

                                                        <div className="shipment-fileImportBox">
                                                            <div className="shipment-fileImportBtn">
                                                                <Button
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    onClick={() => this.handleFileUploadCancel()}>Cancel</Button>
                                                            </div>
                                                            <div className="shipment-fileImportBtn">
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => this.handleFileUpload()}>Upload</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    this.state.openCreateOrderDialog || this.state.openEditOrderDialog ?
                                                        this.state.openCreateOrderDialog ?
                                                            <CreateOrderDialog
                                                                handleClose={() => this.setState({openCreateOrderDialog: false})}
                                                                createOrder={this.props.createOrder}
                                                                carriers={this.props.carriers ? this.state.enabledCarriers : []}
                                                                tags={this.props.tags}
                                                                locations={this.props.locations}
                                                                boxes={this.props.packages}
                                                            />
                                                            :
                                                            //when you click on edit icon
                                                            <ExpandedView
                                                                toggleCommercialInvoiceView={this.toggleShowCommercialInvoice}
                                                                showCommercialInvoice={this.state.showCommercialInvoice}
                                                                title={this.props.title}
                                                                shipmentStatus={this.props.shipmentStatus}
                                                                carriers={this.state.enabledCarriers}
                                                                isItemSelected={true}
                                                                labelId={`enhanced-table-checkbox-${0}`}
                                                                row={this.getOpenEditRow()}
                                                                selected={this.props}
                                                                contentCells={this.getSelectedTableDisplay()}
                                                                handleClick={(e) => this.handleClick(e, this.getOpenEditRow().shipmentId, !this.getOpenEditRow().carrierName ? false : this.getOpenEditRow().carrierName.split(" ")[0] === FEDEX)}
                                                                tags={this.props.carrier}
                                                                locations={this.props.locations}
                                                                rateToDefault={this.props.rateToDefault}
                                                                validateAddress={this.props.validateAddress}
                                                                clearSelected={this.clearSelected}
                                                                showInfo={true}
                                                                handleEditShipmentClose={this.handleEditShipmentClose}
                                                                isShipmentReady={() => this.isShipmentReady(this.getOpenEditRow()?.shipmentId)}
                                                                isShipmentReadyForMarkRedirected={() => this.isShipmentReadyForMarkRedirected(this.getOpenEditRow()?.shipmentId)}
                                                                clearLinkedShipment={this.props.clearLinkedShipment}
                                                                updateBoxType={this.props.updateBoxType}
                                                                scale={this.state.commercialInvoicePDFScale}
                                                                handleCommercialInvoicePdfLoadSuccess={this.handlePdfLoadSuccess}
                                                                handleCommercialInvoicePdfLoadError={this.handlePdfLoadError}
                                                                commercialInvoicePdfLoadSuccess={this.state.commercialInvoicePDFLoadSuccess}
                                                                updateShipments={this.props.updateAndValidateShipments}
                                                                eventLogs={this.state.allLogs}
                                                                modifyShipOrDeliverDate={this.formatDate}
                                                                boxes={this.props.packages}
                                                                />
                                                        :
                                                        <>
                                                            <FilterTagsBar
                                                                filtersMap={this.filterTagsBarWrapper(this.state.filtersMap)}
                                                                onClick={this.state.isDialogOpen ? this.onTagClickedWhenDialogueOpen : this.onTagClicked}
                                                                isUnshippedShipmentsTable={this.props.shipmentStatus === UNSHIPPED ? true : false}
                                                            />

                                                            <CustomTableHeader
                                                                searchText={this.props.searchText}
                                                                onSearchValueChange={this.onSearchValueChange}
                                                                filterIcon={
                                                                    <Tooltip title={FILTER} style={{ paddingTop: 10 }}>
                                                                        <IconButton className="filterButton"
                                                                            size='small'
                                                                            aria-label={FILTER}
                                                                            onClick={this.showFiltersDialog}>
                                                                            <FilterIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                }
                                                                showFilters={this.state.isDialogOpen}
                                                                filtersComponent={
                                                                    <>
                                                                        <Slide in={this.state.isDialogOpen} mountOnEnter
                                                                                unmountOnExit timeout={300}>
                                                                            <FiltersDialog
                                                                                shipmentStatus={this.props.shipmentStatus}
                                                                                isOpen={this.state.isDialogOpen}
                                                                                onOpen={this.showFiltersDialog}
                                                                                onChange={this.onFiltersInputChange}
                                                                                clearFilters={this.clearFiltersMap}
                                                                                onClose={this.applyFiltersDialog}
                                                                                carriers={this.props.carriers}
                                                                                filtersMap={this.state.filtersMap}
                                                                                locations={this.props.locations}
                                                                                tags={this.props.tags}
                                                                                fromDate={this.state.fromDate}
                                                                                toDate={this.state.toDate}
                                                                                shipFromDate={this.state.shipFromDate}
                                                                                shipToDate={this.state.shipToDate}
                                                                                handleDateChange={this.onDateFiltersInputChange}
                                                                                getDefaultFromDate={this.getFromDate}
                                                                                getStateAndProvinceMapping={this.props.getStateAndProvinceMapping}
                                                                                stateAndProvinceMapping={this.props.stateAndProvinceMapping}
                                                                                maxAllowedDateRange={this.state.maxAllowedDateRange}
                                                                                triggerMaxDateGapError={this.triggerMaxDateGapError}
                                                                            />
                                                                        </Slide>
                                                                    </>
                                                                }
                                                                customFilterField={
                                                                    <div className='shipment-switches'>
                                                                        {this.props.title === PENDING_SHIPMENTS ?
                                                                            <>
                                                                        <div className="compactTableSwitch">
                                                                            <Tooltip
                                                                                title="Show Incompleted Shipments">
                                                                                <FormControlLabel
                                                                                    className="incomplete"
                                                                                    control={<Switch
                                                                                        checked={this.state.sortIncomplete}
                                                                                        onChange={(e) => {
                                                                                            this.handleFilterShipmentType(e, "sortIncomplete");
                                                                                        }}
                                                                                    />}
                                                                                    name="incomplete"
                                                                                />
                                                                            </Tooltip>
                                                                        </div>
                                                                        <div className="compactTableSwitch">
                                                                            <Tooltip
                                                                                title="Show Not Validated Shipments">
                                                                                <FormControlLabel
                                                                                    className="not-validated"
                                                                                    control={<Switch
                                                                                        checked={this.state.sortNotValidated}
                                                                                        onChange={(e) => {
                                                                                            this.handleFilterShipmentType(e, "sortNotValidated");
                                                                                        }}
                                                                                    />}
                                                                                    name="not-validated"
                                                                                />
                                                                            </Tooltip>
                                                                        </div>
                                                                        <div className="compactTableSwitch">
                                                                            <Tooltip
                                                                                title="Show Completed Shipments">
                                                                                <FormControlLabel
                                                                                    className="completed"
                                                                                    control={<Switch
                                                                                        checked={this.state.sortCompleted}
                                                                                        onChange={(e) => {
                                                                                            this.handleFilterShipmentType(e, "sortCompleted");
                                                                                        }}
                                                                                    />}
                                                                                    name="completed"
                                                                                />
                                                                            </Tooltip>
                                                                        </div>
                                                                        <div className="compactTableSwitch">
                                                                            <Tooltip
                                                                                title="Show Rated Shipments Only">
                                                                                <FormControlLabel
                                                                                    className="rated"
                                                                                    control={<Switch
                                                                                        checked={this.state.sortRated}
                                                                                        onChange={(e) => {
                                                                                            this.handleFilterShipmentType(e, "sortRated");
                                                                                        }}
                                                                                    />}
                                                                                    name="rated"
                                                                                />
                                                                            </Tooltip>
                                                                        </div>
                                                                            </>: null }
                                                                    </div>
                                                                }
                                                                pagination={
                                                                    <>
                                                                    <CompactTableSwitch checked={this.state.dense}
                                                                                        onChange={(e) => {
                                                                                            this.handleChangeDense(e);
                                                                                        }}
                                                                                        label={COMPTACT_LABEL}
                                                                                         name="dense" />
                                                                    <div className='shipment-TocIcon'>
                                                                    <FormControlLabel
                                                                        control={<IconButton
                                                                            onClick={this.handleChangeDisplay}
                                                                            size="medium">
                                                                            <TocIcon />
                                                                        </IconButton>}
                                                                        label={TABLE_DISPLAY_LABEL}
                                                                    />
                                                                    </div>
                                                                    <TablePagination
                                                                        className="shipment-table-pagination"
                                                                        labelRowsPerPage={SHIPMENTS_PER_PAGE}
                                                                        rowsPerPageOptions={[5, 25, 50, 100, {value: this.state.rows.length, label: "All"} ]}

                                                                        // count={!this.state.rows ? 0 : (this.state.listBySearchedByOrderId ? this.state.rows.length : this.filterBySearch(this.applyFilters(this.state.rows)).length)}
                                                                        /*count={!this.state.rows ? 0 : ((this.state.listSearchedByOrderId || this.state.listSearchedByTrackingId) ? this.state.rows.length
                                                                            : this.filterBySearch(this.applyFilters(this.state.rows)).length)}*/
                                                                        count={!this.state.rows ? 0 : ((this.state.listSearchedByOrderId || this.state.listSearchedByTrackingId) ? this.applyOnlyLocationFilter(this.state.rows).length :
                                                                            this.filterBySearch(this.applyFilters(this.state.rows)).length)}

                                                                        rowsPerPage={this.state.rowsPerPage}
                                                                        page={this.state.page}
                                                                        onChangePage={this.handleChangePage}
                                                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                                    /> </>}
                                                                locationField={
                                                                    <LocationSelectBox
                                                                        alternativeValues={[UNASSIGNED_LOCATION_NAME]}
                                                                        selectedAlternativeValue={this.state.displayUnassignedShipments ? {name: UNASSIGNED_LOCATION_NAME, shipAddressId: UNASSIGNED_LOCATION_NAME} : null}
                                                                        updateCallback={(value) => {
                                                                            if (value.name === UNASSIGNED_LOCATION_NAME) {
                                                                                this.setState({
                                                                                    displayUnassignedShipments: true
                                                                                })
                                                                            } else {
                                                                                this.setState({
                                                                                    displayUnassignedShipments: false
                                                                                })
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                            {this.props.isloading ?
                                                                <div className="getPrinterLoadingOuterBox">
                                                                    <div className="getPrinterLoadingBox">
                                                                        <CircularProgress size={100} />
                                                                    </div>
                                                                </div>
                                                                :
                                                                <TableContainer>
                                                                    <Table
                                                                        aria-labelledby="tableTitle"
                                                                        size={this.state.dense ? 'small' : 'medium'}
                                                                        aria-label={this.props.title}
                                                                    >
                                                                        <GlobalTableHead
                                                                            isShipmentTable={true}
                                                                            headCells={this.getSelectedTableDisplay()}
                                                                            numSelected={!this.state.selected ? 0 : this.state.selected.length}
                                                                            order={this.state.order}
                                                                            orderBy={this.state.orderBy}
                                                                            onSelectAllClick={this.handleSelectAllClick}
                                                                            onRequestSort={this.handleRequestSort}
                                                                            rowCount={!this.state.rows ? 0 : this.state.rows.length}
                                                                            isChecked={this.isChecked()}
                                                                            isIndeterminate={this.isIndeterminate()}
                                                                        />
                                                                        <TableBody>
                                                                            {/*
                this.getDisplayed(this.shipmentSort(this.state.order, this.state.orderBy, this.filterBySearch(this.applyFilters(this.state.rows))))
                */}
                                                                            {this.displayList(this.state.rows)
                                                                                .map((row, index) => {
                                                                                    const isItemSelected = this.isSelected(row.shipmentId);
                                                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                                                    if (row.orderStatus === this.props.shipmentStatus) {
                                                                                        return (
                                                                                            <ExpandedView
                                                                                                toggleCommercialInvoiceView={this.toggleShowCommercialInvoice}
                                                                                                showCommercialInvoice={this.state.showCommercialInvoice}
                                                                                                title={this.props.title}
                                                                                                shipmentStatus={this.props.shipmentStatus}
                                                                                                carriers={this.props.carriers}
                                                                                                isItemSelected={isItemSelected}
                                                                                                labelId={labelId}
                                                                                                row={row}
                                                                                                selected={this.props}
                                                                                                contentCells={this.getSelectedTableDisplay()}
                                                                                                handleClick={(e) => this.handleClick(e, row.shipmentId, !row.carrierName ? false : row.carrierName.split(" ")[0] === FEDEX)}
                                                                                                tags={this.props.carrier}
                                                                                                locations={this.props.locations}
                                                                                                rateToDefault={this.props.rateToDefault}
                                                                                                validateAddress={this.props.validateAddress}
                                                                                                clearSelected={this.clearSelected}
                                                                                                handleEditShipment={() => this.handleEditShipmentOpen(index)}
                                                                                                isShipmentReady={() => this.isShipmentReady(row.shipmentId)}
                                                                                                isShipmentReadyForMarkRedirected={() => this.isShipmentReadyForMarkRedirected(row.shipmentId)}
                                                                                                ratingShipment={this.state.ratingShipment}
                                                                                                openRatingShipment={this.openRatingShipment}
                                                                                                closeRatingShipment={this.closeRatingShipment}
                                                                                                boxes={this.props.packages}
                                                                                                updateBoxType={this.props.updateBoxType}
                                                                                                openLogsTable={this.state.openLogsTable}
                                                                                                updateShipments={this.props.updateAndValidateShipments}
                                                                                                scale={this.state.commercialInvoicePDFScale}
                                                                                                handleCommercialInvoicePdfLoadSuccess={this.handlePdfLoadSuccess}
                                                                                                handleCommercialInvoicePdfLoadError={this.handlePdfLoadError}
                                                                                                commercialInvoicePdfLoadSuccess={this.state.commercialInvoicePDFLoadSuccess}
                                                                                                eventLogs={this.state.allLogs}
                                                                                                modifyShipOrDeliverDate={this.formatDate}
                                                                                            />
                                                                                        );
                                                                                    }
                                                                                })}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            }
                                                        </>
                                            }
                                        </>
                                    }
                                </>
                            }
                        </>
                    }
                </Paper>
            </div>
        );
    }
}

ShipmentsTable.propTypes = {
    title: PropTypes.string,
    shipmentStatus: PropTypes.string,
    handleSelectAllClick: PropTypes.func,
    handleClick: PropTypes.func,
    isSelected: PropTypes.func,
    selected: PropTypes.array,
    rows: PropTypes.array,
    fetchAllOrders: PropTypes.func,
    updateDisplayedWithParent: PropTypes.func,
    clearSelected: PropTypes.func,
    carriers: PropTypes.array,
    handleExpand: PropTypes.func,
    clearLinkedShipment: PropTypes.func,
    packages: PropTypes.array,
    smartSearch: PropTypes.bool
}

ShipmentsTable.defaultProps = {
  title: "",
  shipmentStatus: "",
  handleSelectAllClick: () => { },
  handleClick: () => { },
  isSelected: () => { },
  selected: [],
  rows: [],
  fetchAllOrders: () => { },
  updateDisplayedWithParent: () => { },
  clearSelected: () => { },
  carriers: [],
  handleExpand: () => { },
  packages: [],
  clearLinkedShipment: ()=>{},
    shipments: [],
    shipmentsSmartSearchRows: [],
    stateAndProvinceMapping: [],
    smartSearch: false,
}

const mapStateToProps = (state) => ({
    userInfo: userInfoSelector(state),
    tags: tagsSelector(state),
    isloading: loadingSelector(state),
    rates: ratesSelector(state),
    linkedShipmentId: linkedShipmentSelector(state),
    packages: packagesSelector(state),
    shipments: shipmentsFromLogsSelector(state),
    messages: orderIdSelector(state),
    buyShippingServices: buyShippingServicesSelector(state),
    packageToListMap: packageToListMapSelector(state),
    commercialInvoicePDF: commercialInvoicePDFSelector(state),
    isSampleCommercialInvoice: isSampleCommercialInvoiceSelector(state),
    allLogs: allLogsSelector(state),
    shipmentsOpenEditRow: shipmentsOpenEditRowSelector(state),
    shipmentsSummaryOrder: shipmentsSummaryOrderSelector(state),
    shipmentSummaryProperty: shipmentsSummaryPropertySelector(state),
    selectedLocation: selectedLocationIdSelector(state),
    shipmentsSmartSearchRows: shipmentsSmartSearchRowsSelector(state),
    stateAndProvinceMapping: stateAndProvinceMappingSelector(state),
})

const actionCreators = {
    saveTableSetting,
    getTableSetting,
    importOrders,
    rateShipment,
    clearRates,
    storeRates,
    rateToDefault,
    validateAddress,
    rateToCheapest,
    editShipmentCarrier,
    fetchByOrderId, fetchByTrackingId,
    createOrder,
    listTags,
    createReturnShipments,
    clearLinkedShipment,
    listPackages,
    updateBoxType,
    fetchBuyShippingServices,
    saveBuyShippingService,
    updateAndValidateShipments,
    setShipmentsOpenEditRow,
    exportShipmentSummaryToExcel,
    setShipmentsSmartSearchRows,
    setShipmentSummaryOrderDirection,
    getStateAndProvinceMapping,
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, ShipmentsTable);