import React from 'react';

// Table Components
import GlobalTableHead from "../../../global/Table/GlobalTableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import { IconButton, TableBody, TablePagination } from "@material-ui/core";
import PropTypes from "prop-types";
import SearchableTable from "../../../global/Search/SearchableTable";
import CustomTableHeader from "../../../global/Table/CustomTableHeader";

// Material UI Icons
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from "@material-ui/icons/Edit";

// Constants
import { ASC, DESC } from "../../../global/constants";
import {
    INVOICE_TEMPLATE_TABLE_HEAD_CELLS,
    DEFAULT_SORT_BY_HEAD_CELL,
    ROWS_PER_PAGE_LABEL,
    DEFAULT_ROWS_PER_PAGE,
    INVOICE_TEMPLATE_DATA_FIELDS,
    INVOICE_TEMPLATE_DATA_SUB_FIELDS,
    DEFAULT_DATE_FORMAT
} from "./constants"
import EditInvoiceTemplate from "./EditInvoiceTemplate";
import Button from "@material-ui/core/Button";

class TemplateTable extends SearchableTable {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            order: ASC,
            orderBy: DEFAULT_SORT_BY_HEAD_CELL,
            deleteId: -1,
            searchValue: "", // the value in the search field (updates on change)
            isSearch: false, // true if search value is not empty, false otherwise
            searchRows: 0, // number of rows displayed while search criteria is met
        }
    }

    componentDidMount() {
        this.initializeSearchText()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.searchText !== this.props.searchText || this.state.searchValue !== this.props.searchText) {
            this.initializeSearchText()
        }
    }

    initializeSearchText = () => {
        this.setState({ searchValue: this.props.searchText })
    }

    handleEditTemplate(template) {
        let fields = JSON.parse(JSON.stringify(INVOICE_TEMPLATE_DATA_FIELDS))
        let subFields = JSON.parse(JSON.stringify(INVOICE_TEMPLATE_DATA_SUB_FIELDS))
        Object.keys(template.data).map(key => {
            if (key in fields) {
                fields[key].position = {...template.data[key]}
            } else if (key in subFields) {
                subFields[key].position = {...template.data[key]}
            }
        })
        fields.marker.position = {...template.marker.coordinates}
        this.props.clearPDFTemplate([])
        this.props.getPDFTemplate(template.supplierId)
        this.props.updateField("fields", fields)
        this.props.updateField("subFields", subFields)
        this.props.updateField("selectedSupplier", template.supplierId)
        this.props.updateField("editing", true)
        this.props.updateField("entryTaxText", template.entryTaxText)
        this.props.setDateFormat(template.dateFormat ?? DEFAULT_DATE_FORMAT)
    }
    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === ASC;
        this.setState({
            order: isAsc ? DESC : ASC,
            orderBy: property,
        });
    };
    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
    };
    onSearchValueChange = (e) => {
        this.setState({
            isSearch: e.target.value.length > 0,
            searchValue: e.target.value,
            page: 0
        });
        this.props.handleSearchChange(e)
    }
    getDisplayed = (rows) => {
        if (!rows)
            return [];
        return [...rows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)];
    }
    handleDelete = (template) => {
        this.props.deleteInvoiceTemplate(template.supplierId, template.supplierName)
        this.setState({deleteIndex: ""})
    }

    render() {
        return (
            <>
                {this.props.editing && <EditInvoiceTemplate
                    pdf={{ contents: this.props.template }}
                    fieldDefined={this.props.fieldDefined}
                    updateParentFields={this.props.updateParentFields}
                    fieldError={this.props.fieldError}
                    fields={this.props.fields}
                    subFields={this.props.subFields}
                    entryTaxText={this.props.entryTaxText}
                    resetError={this.props.resetError}
                    scale={this.props.scale}
                    updateField={this.props.updateField}
                    dateFormats={this.props.dateFormats}
                    setDateFormat={this.props.setDateFormat}
                    selectedFormat={this.props.selectedFormat}
                /> || <>
                    <CustomTableHeader
                        searchText={this.props.searchText}
                        onSearchValueChange={this.onSearchValueChange}
                        pagination={
                            <TablePagination
                                className="table-pagination"
                                labelRowsPerPage={ROWS_PER_PAGE_LABEL}
                                rowsPerPageOptions={[5, 25, 50, 100]}
                                count={!this.props.data ? 0 : this.filterBySearch(this.props.data).length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        }
                        showSupplier={this.props.showSupplier}
                    />
                    <TableContainer>
                        <Table size='medium'>
                            <GlobalTableHead
                                isCreatePurchaseOrderTable={false}
                                isShipmentTable={false}
                                headCells={INVOICE_TEMPLATE_TABLE_HEAD_CELLS}
                                order={this.state.order}
                                orderBy={this.state.orderBy}
                                onRequestSort={this.handleRequestSort}
                                rowCount={!this.props.data ? 0 : this.props.data.length}
                            />
                            <TableBody>
                                {this.getDisplayed(this.stableSort(this.filterBySearch(this.props.data), this.getComparator(this.state.order, this.state.orderBy))).map((template, index) => (
                                    <TableRow hover onDoubleClick={()=>this.handleEditTemplate(template)} key={index}>
                                        <TableCell>
                                            <IconButton onClick={()=>this.handleEditTemplate(template)}>
                                                <EditIcon/>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell className='cellPadding'>
                                            {template.supplierName}
                                        </TableCell>
                                        <TableCell className='cellPadding'>
                                            {template.createDate}
                                        </TableCell>
                                        <TableCell className='cellPadding'>
                                            {template.lastUpdatedDate}
                                        </TableCell>
                                        <TableCell style={{width: "25%", padding: "5px"}}>
                                            {this.state.deleteId !== index ?
                                                <IconButton onClick={() => this.setState({ deleteId: index })}>
                                                    <DeleteIcon />
                                                </IconButton> : <div>
                                                    <Button onClick={() => this.setState({deleteId: ""})}>
                                                        Cancel
                                                    </Button>
                                                    <Button onClick={() => this.handleDelete(template)} variant="contained" startIcon={<DeleteIcon/>}>
                                                        Delete
                                                    </Button>
                                                </div>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>}
            </>
        )
    }
}

TemplateTable.propTypes = {
    data: PropTypes.array
}

TemplateTable.defaultProps = {
    data: []
}

export default TemplateTable;