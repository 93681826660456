import React from "react";
import './PricingDashboardTableRow.css';
import Tooltip from "@material-ui/core/Tooltip";
import {APPLY_EDIT_ENTRY, CANCEL_EDIT_ENTRY, DETAIL, EDIT_ENTRY, PRICING_DASHBOARD_PAGES} from "../constants";
import EditIcon from "@material-ui/icons/Edit";
import {IconButton, TableCell, TextField, Switch} from "@material-ui/core";
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import withShipment from "../../../withShipment";
import {updatePricingListing, updateRepricing} from "../../../redux/actions/settings";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import StyledBadge from "@material-ui/core/Badge";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

class PricingDashboardTableRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        let newState = {
            deleteListing: false,
            shortcode: this.props.row.shortcode,
            newPrice: 0,
            newMinimum: 0,
            newMaximum: 0,
            newRepricing: false,

            newQuantity: 0

        }
        this.props.setRowState(newState)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.listingIdBeingEdited !== this.props.listingIdBeingEdited) {
            if (this.props.listingIdBeingEdited === this.props.row.webstoreProductId) {
                this.props.setRowState({
                    shortcode: this.props.row.shortcode,
                    newPrice: this.props.row.price,
                    newMinimum: this.props.row.minimumPrice,
                    newMaximum: this.props.row.maximumPrice,
                    newRepricing: this.props.row.repricing,
                })
            }
        }
    }

    handleCancel = (row) => {
        this.props.setListingIdBeingEdited("")
        this.props.setRowState({
            newPrice: 0,
            newMinimum: 0,
            newMaximum: 0,
            newRepricing: false,
        })
    }

    handleConfirm = (row) => {
        let state = this.props.getRowState()
        if (!state.newPrice) {
            return
        }

        this.props.setListingIdBeingEdited("")
        const request = {
            id: row.webstoreProductId,
            price: state.newPrice,
            minimumPrice: state.newMinimum,
            maximumPrice: state.newMaximum,
            repricing: state.newRepricing,
        };
        this.props.setRowState({
            newPrice: 0,
            newMinimum: 0,
            newMaximum: 0,
            newRepricing: false,
        })
        this.props.updatePricingListing(request);
    }

    getEditingCancelConfirm = (row) => {
        return <>
            <div>
                <Tooltip title={CANCEL_EDIT_ENTRY}>
                    <IconButton onClick={() => this.handleCancel(row)}>
                        <ClearIcon
                            fontSize='small'
                            color='error'
                        />
                    </IconButton>
                </Tooltip>

                {/*<Tooltip title={APPLY_EDIT_ENTRY}>
                    <IconButton onClick={() => this.handleConfirm(row)}>
                        <CheckCircleIcon
                            fontSize="small"
                            color='secondary'
                        />
                    </IconButton>
                </Tooltip>*/}
            </div>
        </>
    }

    updateNewQuantity = (newValue) => {
        let value
        try {
            if (!newValue) {
                value = ""
            } else {
                value = parseInt(newValue)
            }
        } catch (ignored) {
            return
        }

        this.props.setRowState({
            newQuantity: value
        })
    }

    displayPriceEditingTextField = () => {
        let state = this.props.getRowState()
        return <TextField
            variant="outlined"
            name="price"
            label="Price"
            value={state.newPrice}
            onChange={(e) => this.props.setRowState({ newPrice: e.target.value })}
            error={state.newPrice === '' || state.newPrice === null || state.newPrice === undefined}
        />
    }

    displayMinimumPriceEditingTextField = () => {
        let state = this.props.getRowState()
        return <TextField
            variant="outlined"
            name="minimumPrice"
            label="Minimum"
            value={state.newMinimum}
            onChange={(e) => this.props.setRowState({ newMinimum: e.target.value })}
        />
    }
    displayMaximumPriceEditingTextField = () => {
        let state = this.props.getRowState()
        return <TextField
            variant="outlined"
            name="maximumPrice"
            label="Maximum"
            value={state.newMaximum}
            onChange={(e) => this.props.setRowState({ newMaximum: e.target.value })}
        />
    }

    displayRepircingField = (row) => {
        return <Switch
            color='primary'
            checked={row.repricing}
            disabled={this.disableRepricing(row.minimumPrice, row.maximumPrice)}
            onChange={(event) => this.handleRepricingChange(event, row)}
        />
    }

    handleRepricingChange = (event, row) => {
        this.props.setRowState({
            newRepricing: event.target.checked
        });
        let state = this.props.getRowState()
        const request = {
            id: row.webstoreProductId,
            repricing: state.newRepricing,
        };
        this.props.updateRepricing(request);
    };

    disableRepricing= (min, max) => {
        return min == null || min === undefined || min === "" || max == null || max === undefined || max === "";

    }



    displayEditingRow = (row) => {
        return <React.Fragment>
            <TableCell style={{width: "76px"}}>
                {this.getEditingCancelConfirm(row)}
            </TableCell>
            <TableCell style={{minWidth: "100px", width: "12%", paddingLeft: "3px"}}>
                {row.shortcode}
            </TableCell>
            <TableCell style={{minWidth: "100px", width: "16%", paddingLeft: "3px"}}>
                {row.sku}
            </TableCell>
            <TableCell style={{minWidth: "50px", width: "6%", paddingLeft: "7px"}}>
                {row.quantity}
            </TableCell>
            <TableCell style={{minWidth: "100px", width: "10%", paddingLeft: "3px"}}>
                <a href={`https://www.${row.marketplaces[0]}/dp/${row.asin}`} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
                    {row.asin}
                </a>
            </TableCell>
            <TableCell style={{minWidth: "80px", width: "9%", paddingLeft: "3px"}}>
            {row.marketplaces[0]}
            </TableCell>
            <TableCell style={{minWidth: "80px", width: "8%", paddingLeft: "3px"}}>
                {this.displayPriceEditingTextField()}
            </TableCell>
            <TableCell style={{minWidth: "80px", width: "8%", paddingLeft: "3px"}}>
                {this.displayMinimumPriceEditingTextField()}
            </TableCell>
            <TableCell style={{minWidth: "80px", width: "8%", paddingLeft: "3px"}}>
                {this.displayMaximumPriceEditingTextField()}
            </TableCell>
            <TableCell style={{minWidth: "80px", width: "8%", paddingLeft: "3px"}}>
                <Tooltip title={APPLY_EDIT_ENTRY}>
                    <IconButton onClick={() => this.handleConfirm(row)}>
                        <CheckCircleIcon
                            fontSize="small"
                            color='secondary'
                        />
                    </IconButton>
                </Tooltip>
            </TableCell>
            <TableCell style={{minWidth: "80px", width: "10%"}}>
                {row.isBuyBoxWinner ? <ThumbUpAltIcon/> : <ThumbUpOffAltIcon/>}
                <StyledBadge badgeContent={row.relatedProducts?.length} color="secondary" style={{marginLeft:'15px', marginRight:'6px'}}>
                </StyledBadge>
                <Tooltip title={DETAIL}>
                    <IconButton onClick={() => { this.props.setCurrentPage(PRICING_DASHBOARD_PAGES.DETAIL, row.webstoreProductId)}}>
                        <KeyboardDoubleArrowRightIcon
                            disable={true}
                        />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </React.Fragment>
    }

    displayNonEditingRow = (row) => {
        return <React.Fragment>
            <TableCell style={{width: "76px"}}>
                <Tooltip title={EDIT_ENTRY}>
                    <IconButton onClick={() => this.props.setListingIdBeingEdited(row.webstoreProductId)}>
                        <EditIcon
                            fontSize='small'
                        />
                    </IconButton>
                </Tooltip>
            </TableCell>
            <TableCell style={{minWidth: "100px", width: "12%", paddingLeft: "3px"}}>
                {row.shortcode}
            </TableCell>
            <TableCell style={{minWidth: "100px", width: "16%", paddingLeft: "3px"}}>
                {row.sku}
            </TableCell>
            <TableCell style={{minWidth: "50px", width: "6%", paddingLeft: "7px"}}>
                {row.quantity}
            </TableCell>
            <TableCell style={{minWidth: "100px", width: "10%", paddingLeft: "3px"}}>
                <a href={`https://www.${row.marketplaces[0]}/dp/${row.asin}`} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
                    {row.asin}
                </a>
            </TableCell>
            <TableCell style={{minWidth: "80px", width: "9%", paddingLeft: "3px"}}>
                {row.marketplaces[0]}
            </TableCell>
            <TableCell style={{minWidth: "80px", width: "8%", paddingLeft: "3px"}}>
                {row.price}
            </TableCell>
            <TableCell style={{minWidth: "80px", width: "8%", paddingLeft: "3px"}}>
                {row.minimumPrice}
            </TableCell>
            <TableCell style={{minWidth: "80px", width: "8%", paddingLeft: "3px"}}>
                {row.maximumPrice}
            </TableCell>
            <TableCell style={{minWidth: "80px", width: "8%", paddingLeft: "3px"}}>
                {this.displayRepircingField(row)}
            </TableCell>
            <TableCell style={{minWidth: "80px", width: "10%"}}>
                {row.isBuyBoxWinner ? <ThumbUpAltIcon/> : <ThumbUpOffAltIcon/>}
                <StyledBadge badgeContent={row.relatedProducts?.length} color="secondary" style={{marginLeft:'15px', marginRight:'6px'}}>
                </StyledBadge>
                <IconButton onClick={() => { this.props.setCurrentPage(PRICING_DASHBOARD_PAGES.DETAIL, row.webstoreProductId)}}>
                    <KeyboardDoubleArrowRightIcon
                    />
                </IconButton>
            </TableCell>
        </React.Fragment>
    }

    displayListingRow = (listingIdBeingEdited) => {
        if (listingIdBeingEdited === this.props.row.webstoreProductId) {
            return this.displayEditingRow(this.props.row)
        } else {
            return this.displayNonEditingRow(this.props.row)
        }
    }

    render() {
        // should return a <React.Fragment> of <TableCell> elements
        return (
            <React.Fragment>
                {this.displayListingRow(this.props.listingIdBeingEdited)}
            </React.Fragment>
        )
    }
}

PricingDashboardTableRow.defaultProps = {
    newPrice: 0,
    newMinimum: 0,
    newMaximum: 0,
    newRepricing: false
}

PricingDashboardTableRow.propTypes = {
}

const mapStateToProps = (state) => ({
})

const actionCreators = {
    updatePricingListing,
    updateRepricing

}

export default withShipment({
    mapStateToProps,
    actionCreators
}, PricingDashboardTableRow);