import React from "react";
import {
    Button, Checkbox,
    FormControlLabel,
    IconButton,
    Step,
    StepButton,
    Stepper,
    TextField,
    Tooltip,
    Switch,
    Paper,
    Typography, Divider, Toolbar
} from "@material-ui/core";
import {
    STEPPER_HEADINGS,
    STEPPER_HEADING_DESCRIPTIONS,
    STEP_SUPPLIER_INFO_INDEX,
    STEP_SUPPLIER_PRODUCTS_INDEX,
    STEP_SUPPLIER_INVOICE_INDEX,
    ACTION_TYPE_TEXT,
    ACTION_TYPE_BOOLEAN, INFO_ON_CONTENT_FILTER, INFO_ON_CUSTOM_QUERY, EXECUTE, INFO_ON_MANUAL_EXECUTE
} from "./constants";

import QueryActionBoolean from "./QueryActionBoolean";

import "./ManageSuppliersStepper.css"
import {
    ADD_ACTION,
    ADD_FILTER, ANY_ALL_PART_1, ANY_ALL_PART_2A, ANY_ALL_PART_2B, ANY_ALL_PART_3,
    BACK,
    CANCEL, CONTINUE_ON_MATCH,
    EXIT_ON_MATCH, FILTER_TYPE_BOOLEAN_1, FILTER_TYPE_BOOLEAN_2, FILTER_TYPE_NUMERIC, FILTER_TYPE_TEXT,
    FINISH, INFO_ON_CONTINUE_OPTION,
    INFO_ON_EXIT_OPTION, MAP_ALL,
    NEW_RULE,
    NEXT, TABLE_TYPES
} from "../../automationrules/constants";
import { SAVE } from "../../global/constants";
import CustomToolbar from "../../global/CustomToolbar";
import InputAdornment from "@material-ui/core/InputAdornment";
import { listTags, getUUID, getUUIDs } from "../../../redux/actions/settings";

//icons
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import PhoneIcon from '@material-ui/icons/Phone';
import PropTypes from "prop-types";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DialpadIcon from '@material-ui/icons/Dialpad';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PublicIcon from '@material-ui/icons/Public';
import DeleteIcon from "@material-ui/icons/Delete";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import SaveIcon from "@material-ui/icons/Save";

import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteStepper from "../../global/DeleteStepper";
import {ToggleButton} from "@material-ui/lab";
import CheckIcon from "@material-ui/icons/Check";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ConditionDialog from "../../automationrules/ConditionDialog";
import AddIcon from "@material-ui/icons/Add";
import {HTML5Backend} from "react-dnd-html5-backend";
import DragAndDrop from "../../global/Dnd/DragAndDrop";
import FilterBooleanSelect from "../../automationrules/ConditionFilters/FilterBooleanSelect";
import FilterBooleanPredefinedSelect from "../../automationrules/ConditionFilters/FilterBooleanPredefinedSelect";
import FilterNumericField from "../../automationrules/ConditionFilters/FilterNumericField";
import FilterTextField from "../../automationrules/ConditionFilters/FilterTextField";
import {DndProvider} from "react-dnd";
import QueryFilterTextField from "./QueryFilterTextField";
import QueryConditionDialog from "./QueryConditionDialog";
import {COMPONENT_TYPES} from "../../automationrules/ConditionActions/constants";
import ActionBoolean from "../../automationrules/ConditionActions/ActionBoolean";
import ActionNumericDropdown from "../../automationrules/ConditionActions/ActionNumericDropdown";
import SingleNumericalAction from "../../automationrules/ConditionActions/SingleNumericalAction";
import DoubleNumericalAction from "../../automationrules/ConditionActions/DoubleNumericalAction";
import TripleNumericalAction from "../../automationrules/ConditionActions/TripleNumericalAction";
import ActionDropdown from "../../automationrules/ConditionActions/ActionDropdown";
import ActionText from "../../automationrules/ConditionActions/ActionText";
import QueryActionText from "./QueryActionText";
import {ArrowBack, ArrowForward} from "@material-ui/icons";


class ManageSuppliersStepper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            name: this.getValue('name'),
            addressLine1: this.getValue('addressLine1'),
            addressLine2: this.getValue('addressLine2'),
            city: this.getValue('city'),
            phone: this.getValue('phone'),
            province: this.getValue('province'),
            postalCode: this.getValue('postalCode'),
            country: this.getValue('country'),
            email: this.getValue('email'),
            supplierShortcode: this.getValue('supplierShortcode'),
            supplierId: this.getValue('supplierId'),
            currencyCode: this.getValue("currencyCode"),
            netTerm: this.getValue("netTerm"),
            products: this.getValue('products'),
            purchaseOrderEmailTemplate: this.getValue('purchaseOrderEmailTemplate'),
            saved: 0,
            missingError: false,
            isDelete: {},
            shortcodeError: {},
            allItems: [],
            customQuery: this.getValue('customQuery'),
            autoDownload: this.getValue('autoDownload'),
            query: this.getValue('query'),
            pdfPassword: this.getValue('pdfPassword') ? this.getValue('pdfPassword'): "",
            zipPassword: this.getValue('zipPassword') ? this.getValue('zipPassword') : "",
            hasPdfPassword: this.getValue('hasPdfPassword') ? this.getValue('hasPdfPassword') : false,
            hasZipPassword: this.getValue('hasZipPassword') ? this.getValue('hasZipPassword'): false,
            queryTypeName: 'Custom Query (Click to Change)',
            subject: this.getValue('subject'),
            from: this.getValue('from'),
            body: this.getValue('body'),
            label: this.getValue('label'),
            isFilterDialogOpen: false,
            isActionDialogOpen: false,
            filters: this.getValue("filters"),
            customQueryActions: this.getValue("customQueryActions"),
            filterActions: this.getValue("filterActions"),
            applyAllFilters: this.getValue("applyAllFilters"),
            ccEmails: this.getValue("ccEmails"),
            bccEmails: this.getValue("bccEmails"),
            accounts: this.getValue("accounts"),
        }
    }

    componentDidMount() {
        this.setState({ allItems: this.props.packages.concat(this.props.products) })
    }


    getValue = (key) => {
        // todo do something if key is products
        if (this.props.addingSupplier && (key === 'products' || key === 'filters' || key === 'customQueryActions' || key === 'filterActions' || key === 'accounts' || key === 'bccEmails' || key === 'ccEmails'))
            return []
        if (this.props.addingSupplier && (key === 'applyAllFilters' || key === 'autoDownload' || key === 'customQuery'))
            return false
        if (this.props.addingSupplier)
            return ''
        if (!this.props.addingSupplier && key === 'products') {
            return this.props.editingSupplier?.supplierProducts?.sort((a, b) => { return a.shortcode.toLowerCase().localeCompare(b.shortcode.toLowerCase()) })
        }
        return this.props.editingSupplier[key]
    }

    setStep = (index) => {
        this.setState({
            activeStep: index,

        });
    };


    handleTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    dynamicChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    getPurchaseOrderTemplate = () => {
        let arr = this.props.emailTemplates.filter((it) => it.templateName === this.state.purchaseOrderEmailTemplate)
        if (arr && arr[0]){
            return arr[0]
        }
        return null
    }


    handleBack = () => {
        // if they are on the first step
        if (this.state.activeStep === 0) {
            this.props.handleClose()
        }
        else {
            this.setState({
                activeStep: this.state.activeStep - 1,
            });
        }

    };

    getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case STEP_SUPPLIER_INFO_INDEX:
                return this.firstStepContent()
            case STEP_SUPPLIER_PRODUCTS_INDEX:
                return this.secondStepContent()
            case STEP_SUPPLIER_INVOICE_INDEX:
                return this.thirdStepContent()
        }
    }

    isFilterSet = (filterName) => {
        let filters = this.state.filters
        let isFilterSet = false
        for (let i = 0; i < filters.length; i++) {
            if (filters[i].name === filterName) {
                isFilterSet = true
                break
            }
        }
        return isFilterSet
    }

    addProductEntry = () => {
        let products = this.state.products ? this.state.products : []
        products.push({})
        this.setState({
            products: products
        }
        )

    }

    removeProductEntry = (index) => {
        const { products } = this.state
        products.splice(index, 1)
        this.setState({
            products: products
        })
    }



    firstStepContent = () => {
        return (
            <>
                <div className='stepper-page-one-grid'>
                    <div>
                        <div className='rowSpecSuppliers'>
                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="Name"
                                InputLabelProps={{ shrink: true }}
                                name='name'
                                value={this.state.name}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.saved && this.state.name === ''}
                            />
                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="Phone"
                                InputLabelProps={{ shrink: true }}
                                name='phone'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PhoneIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                value={this.state.phone}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.saved && this.state.phone === ''}
                            />
                        </div>


                        <div className='rowSpecSuppliers'>
                            <TextField
                                variant='outlined'
                                label="Address Line 1"
                                className='single-line-field'
                                InputLabelProps={{ shrink: true }}
                                name='addressLine1'
                                value={this.state.addressLine1}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.saved && this.state.addressLine1 === ''}
                            />
                        </div>

                        <div className='rowSpecSuppliers'>
                            <TextField
                                variant='outlined'
                                label="Address Line 2"
                                className='single-line-field'
                                InputLabelProps={{ shrink: true }}
                                name='addressLine2'
                                value={this.state.addressLine2}
                                onChange={this.handleTextChange}
                            />
                        </div>
                        <div className='rowSpecSuppliers'>
                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="City"
                                InputLabelProps={{ shrink: true }}
                                name='city'
                                value={this.state.city}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.saved && this.state.city === ''}
                            />
                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="State or Province"
                                InputLabelProps={{ shrink: true }}
                                name='province'
                                value={this.state.province}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.saved && this.state.province === ''}
                            />
                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="Zip or Postal Code"
                                InputLabelProps={{ shrink: true }}
                                name='postalCode'
                                value={this.state.postalCode}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.saved && this.state.postalCode === ''}
                            />
                        </div>
                        <div className='rowSpecSuppliers'>
                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="Country"
                                InputLabelProps={{ shrink: true }}
                                name='country'
                                value={this.state.country}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.saved && this.state.country === ''}
                            />
                        </div>
                        <div className='rowSpecSuppliers'>
                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="Supplier Shortcode"
                                InputLabelProps={{ shrink: true }}
                                name='supplierShortcode'
                                value={this.state.supplierShortcode}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.saved && this.state.supplierShortcode === ''}
                            />
                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="Supplier Currency"
                                InputLabelProps={{ shrink: true }}
                                name='currencyCode'
                                value={this.state.currencyCode}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.saved && this.state.currencyCode === ''}
                            />
                            <TextField
                                className='input-text-field'
                                variant='outlined'
                                label="Net Terms"
                                InputLabelProps={{ shrink: true }}
                                name='netTerm'
                                value={this.state.netTerm}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.saved && this.state.netTerm === ''}
                            />
                        </div>

                        <Divider className='row-divider' />

                        <div className='rowSpecSuppliers'>
                            <p className='row-divider'>Accounts</p>
                        </div>
                        {
                            this.state.accounts?.map((account,index)=>(
                                <div className='rowSpecSuppliers'>
                                    <TextField
                                        className='input-text-field'
                                        variant='outlined'
                                        label='Account Number'
                                        InputLabelProps={{ shrink: true }}
                                        name='account number'
                                        value={account.accountNumber}
                                        onChange={(e)=>this.handleAccountChange(e.target.value,index,"accountNumber")}
                                        required
                                        // error={}
                                    />
                                    <Autocomplete
                                        options={this.props.locations}
                                        getOptionLabel={(option) => option?.name}
                                        autoHighlight={true}
                                        className='ticket-text-field'
                                        onChange={(event, value) => { this.handleAccountChange(value,index,"location") }}
                                        value={account.location}
                                        name="location"
                                        blurOnSelect={true}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Location"
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                name="location"
                                                required
                                            />
                                        }
                                    />
                                    <IconButton onClick={() => this.deleteAccount(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            ))
                        }
                        <div className='rowSpecSuppliers'>
                            <Tooltip title="Add an account">
                                <IconButton onClick={()=>this.addAccount()}>
                                    <AddCircleIcon fontSize="small" color='secondary' />
                                </IconButton>
                            </Tooltip>
                        </div>

                        <Divider className='row-divider' />

                        <div className='rowSpecSuppliers'>

                            <TextField
                                className='input-text-field-large'
                                variant='outlined'
                                label='Email'
                                InputLabelProps={{ shrink: true }}
                                name='email'
                                value={this.state.email}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MailOutlineIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.saved && this.state.email === ''}
                            />

                            <Autocomplete
                                options={this.props.emailTemplates}
                                getOptionLabel={option => option.templateName}
                                defaultValue={this.getPurchaseOrderTemplate()}
                                style={{ width: 225}}
                                onChange={(event, value) => {
                                    if(value !== null){
                                        this.dynamicChange('purchaseOrderEmailTemplate', value.templateName)
                                    } else{
                                        this.dynamicChange('purchaseOrderEmailTemplate', null)
                                    }
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        className='input-text-field'
                                        label="Email Template"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        margin="normal"
                                    />
                                )}
                            />

                        </div>
                        {
                            this.state.ccEmails?.map((ccEmail,index)=>(
                                <div className='rowSpecSuppliers'>

                                    <TextField
                                        className='input-text-field-large'
                                        variant='outlined'
                                        label='CC Email'
                                        InputLabelProps={{ shrink: true }}
                                        name='cc email'
                                        value={ccEmail}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <MailOutlineIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(e)=>this.editCCEmails(e.target.value,index,"cc")}
                                        required
                                        error={ccEmail === ''}
                                    />
                                    <IconButton onClick={() => this.deleteEmail(index,"cc")}>
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            ))
                        }
                        {
                            this.state.bccEmails?.map((bccEmail,index)=>(
                                <div className='rowSpecSuppliers'>

                                    <TextField
                                        className='input-text-field-large'
                                        variant='outlined'
                                        label='BCC Email'
                                        InputLabelProps={{ shrink: true }}
                                        name='bcc email'
                                        value={bccEmail}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <MailOutlineIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(e)=>this.editCCEmails(e.target.value,index,"bcc")}
                                        required
                                        error={bccEmail === ''}
                                    />

                                    <IconButton onClick={() => this.deleteEmail(index,"bcc")}>
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            ))
                        }
                        <div className='rowSpecSuppliers'>
                            <Tooltip title="Add a CC email">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton onClick={() => this.addEmails("cc")}>
                                        <AddCircleIcon fontSize="small" color='secondary' />
                                    </IconButton>
                                    <span>Add CC</span>
                                </div>
                            </Tooltip>
                            <Tooltip title="Add a BCC email">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton onClick={()=>this.addEmails("bcc")}>
                                        <AddCircleIcon fontSize="small" color='secondary' />
                                    </IconButton>
                                    <span>Add BCC</span>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    editCCEmails= (newEmail,targetIndex,emailType) =>{

        if (emailType === 'cc') {
            // Create a new array with the updated email
            const ccEmailsCopy = this.state.ccEmails.map((email,index)=>{
                if (index === targetIndex) {
                    return newEmail
                }
                return email
            })
            this.setState({
                ccEmails: ccEmailsCopy
            })
        }
        else if (emailType === 'bcc') {
            // Create a new array with the updated email
            const bccEmailsCopy = this.state.bccEmails.map((email,index)=>{
                if (index === targetIndex) {
                    return newEmail
                }
                return email
            })
            this.setState({
                bccEmails: bccEmailsCopy
            })
        }
    }

    addAccount = () =>{
        let tempAccounts = this.state.accounts || []
        let obj = {
            accountNumber: "",
            location:""
        }
        tempAccounts.push(obj)
        this.setState({
            accounts: tempAccounts
        })
    }

    handleAccountChange = (value, targetIndex, field) =>{
        const updatedAccount = this.state.accounts.map((account,index)=> {
            if (targetIndex == index) {
                let accountCopy = account
                accountCopy[field] = value
                return accountCopy
            }
            return account
        })
        this.setState({
            accounts: updatedAccount
        })
    }

    addEmails = (emailType) => {
        if (emailType == "cc") {
            let ccEmailsCopy = this.state.ccEmails || []
            ccEmailsCopy.push("")
            this.setState({
                ccEmails: ccEmailsCopy
            })
        }
        else if (emailType == "bcc") {
            let bccEmailsCopy = this.state.bccEmails || []
            bccEmailsCopy.push("")
            this.setState({
                bccEmails: bccEmailsCopy
            })
        }
    }

    deleteEmail = (targetIndex, emailType) => {
        if (emailType === "cc") {
            const ccEmailsCopy = this.state.ccEmails.filter((_,index)=>
                index !== targetIndex
            )
            this.setState({
                ccEmails: ccEmailsCopy
            })
        }
        else if (emailType === "bcc") {
            const bccEmailsCopy = this.state.bccEmails.filter((_,index)=>
                index !== targetIndex
            )
            this.setState({
                bccEmails: bccEmailsCopy
            })
        }
    }

    deleteAccount = (targetIndex) => {
        const updatedAccounts = this.state.accounts.filter((_,index)=> index!=targetIndex)
        this.setState({
            accounts: updatedAccounts
        })
    }

    checkDuplicateShortcode = () => {
        let shortcodes = this.state.products?.map(product => product.shortcode)
        return new Set(shortcodes)?.size !== shortcodes?.length
    }

    secondStepContent = () => {
        return (
            <div className='stepper-page-two-grid' key={'this'}>
                {this.state.products?.map((product, index) => {
                    return (
                        <React.Fragment>
                            <div></div>
                            <div key={'shortcode autocomplete' + index}>
                                <Autocomplete
                                    options={this.state.allItems?.sort((a, b) => { return a.shortcode.toLowerCase().localeCompare(b.shortcode.toLowerCase()) })}
                                    getOptionLabel={(product) => product.shortcode}
                                    autoHighlight={true}
                                    name="shortcode"
                                    className='input-text-field'
                                    value={product}
                                    onChange={(event, value) => {
                                        this.handleProductShortcodeChange(value, index)
                                    }
                                    }
                                    blurOnSelect={true}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            error={(this.state.shortcodeError[index] ?? false) || !product.shortcode}
                                            helperText={this.state.shortcodeError[index] ? "Duplicated shortcode" : null}
                                            label="Shortcode"
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            name="shortcode"
                                        />
                                    }
                                />

                            </div>
                            <div key={'price field ' + index}>
                                <TextField
                                    className='input-text-field'
                                    variant='outlined'
                                    label="Price"
                                    error={!product.price}
                                    InputLabelProps={{ shrink: true }}
                                    name='price'
                                    value={product.price}
                                    // disabled={!("price" in product)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AttachMoneyIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => this.handleProductPriceChange(e, index)}
                                />
                            </div>
                            <div key={'supplierpartnumber field' + index}>
                                <TextField
                                    className='input-text-field'
                                    variant='outlined'
                                    error={!product.supplierPartNumber}
                                    label="Supplier Part Number"
                                    InputLabelProps={{ shrink: true }}
                                    name='supplierPartNumber'
                                    // disabled={!("supplierPartNumber" in product)}
                                    value={product.supplierPartNumber}
                                    onChange={(e) => this.handleSupplierPartNumberChange(e, index)}
                                />
                            </div>
                            <div className={!this.state.isDelete[index] ? "delete-row-step-one" : ''}>
                                <DeleteStepper
                                    handleDeleteFalse={this.handleDeleteFalse}
                                    handleDeleteTrue={this.handleDeleteTrue}
                                    handleDelete={() => this.removeProductEntry(index)}
                                    index={index}
                                    isDelete={this.state.isDelete[index]}
                                />
                            </div>
                        </React.Fragment>
                    )
                })}

                {/*default adding row*/}
                <div></div>
                <div ></div>
                <div style={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                    <IconButton onClick={this.addProductEntry}>
                        <AddCircleIcon
                            fontSize="small"
                            color='secondary'
                        />
                    </IconButton>
                    Add supplier products
                </div>
                <div></div>
                <div />
                <div />

            </div>
        )
    }


     handlePasswordChanges = (listOfChanges) => {
        this.setState(currentState => {
            // Create a new state object based on the current state
            const newState = { ...currentState };

            // Apply each change to the new state object
            listOfChanges.forEach(({ name, value }) => {
                if(name === "hasZipPassword" && value === false){
                    newState["zipPassword"] = '';
                }else  if(name === "hasPdfPassword" && value === false){
                    newState["pdfPassword"] = '';
                }
                newState[name] = value;
            });
            return newState;
        });
    }

    handleAutoDownloadCheck = (e) => {
        const checked = e.target.checked
        this.setState({
            autoDownload: checked
        })
    }

    handleSwitchToggle = (event) => {
        this.setState(
            {
                customQuery: !event.target.checked
            }//,
            //() => {
              //  this.props.updateConditionWithParent({ ...this.state });
            //}
        );
    };

    showFilterDialog = (show) => {
        this.setState({
            isFilterDialogOpen: show,
        });
    };

    showActionDialog = (show) => {
        this.setState({
            isActionDialogOpen: show,
        })
    }

    onFilterApply = (filters, copyFilters = false) => {
        // iterate over the filters that are being added and assign an filterId property
            var count = 0
            let newFilters = []
            filters.forEach((item) => {
                // FILTER_TYPE_BOOLEAN_1 types only have value while others have value1 and value2
                    if (!copyFilters) {
                        let filter = item.type === FILTER_TYPE_BOOLEAN_1
                            ? { ...item, filterId:this.state.filters.length === 0 ? newFilters.length : Math.max(...this.state.filters.map(f => f.filterId)) + 1 + newFilters.length, value: '' }
                            : { ...item, filterId:this.state.filters.length === 0 ? newFilters.length : Math.max(...this.state.filters.map(f => f.filterId)) + 1 + newFilters.length, value1: '', value2: item.name === "From Date" || item.name === "To Date" ? new Date().toISOString().split('T')[0].replaceAll('-', '/') : "" }

                        newFilters.push(filter)
                    } else {
                        let filter = { ...item, filterId:this.state.filters.length === 0 ? newFilters.length : Math.max(...this.state.filters.map(f => f.filterId)) + 1 + newFilters.length}
                        newFilters.push(filter)
                    }
                count++
            })

            this.setState(
                {
                    isFilterDialogOpen: false,
                    filters: [...this.state.filters, ...newFilters],
                },
            );
        //this.props.getUUIDs("filter", filters.length, setUUIDs)
        this.showFilterDialog(false)
    };

    onActionsApply = (actions, copyActions = false) => {
        // iterate over the filters that are being added and assign an filterId property
        var count = 0
        let newActions = []
        let stateActions = this.state.customQuery ? this.state.customQueryActions : this.state.filterActions
        actions.forEach((item) => {
            // FILTER_TYPE_BOOLEAN_1 types only have value while others have value1 and value2
            if (!copyActions) {
                let filter = item.type === ACTION_TYPE_BOOLEAN
                    ? { ...item, actionId:this.state.customQueryActions.length + this.state.filterActions.length === 0 ? newActions.length : Math.max(Math.max(...this.state.customQueryActions.map(f => f.actionId)), Math.max(...this.state.filterActions.map(f => f.actionId))) + 1 + newActions.length, value: true }
                    : { ...item, actionId:this.state.customQueryActions.length + this.state.filterActions.length === 0 ? newActions.length : Math.max(Math.max(...this.state.customQueryActions.map(f => f.actionId)), Math.max(...this.state.filterActions.map(f => f.actionId))) + 1 + newActions.length, value1: '' }

                newActions.push(filter)
            } else {
                let filter = { ...item, actionId:this.state.customQueryActions.length + this.state.filterActions.length === 0 ? newActions.length : Math.max(Math.max(...this.state.customQueryActions.map(f => f.actionId)), Math.max(...this.state.filterActions.map(f => f.actionId))) + 1 + newActions.length}
                newActions.push(filter)
            }
            count++
        })

        this.state.customQuery ? this.setState({isActionDialogOpen: false, customQueryActions: [...stateActions, ...newActions],},) : this.setState({isActionDialogOpen: false, filterActions: [...stateActions, ...newActions],},)
        //this.props.getUUIDs("filter", filters.length, setUUIDs)
        this.showActionDialog(false)
    };

    deleteFilter = (filterId) => {
        const index = this.state.filters.findIndex((filter) => filter.filterId === filterId);
        if (index !== -1) {
            const filters = [...this.state.filters];
            filters.splice(index, 1);
            this.setState(
                {
                    filters,
                },
            );
        }
    };

    deleteAction = (actionId) => {
        let stateActions = this.state.customQuery ? this.state.customQueryActions : this.state.filterActions
        const index = stateActions.findIndex((action) => action.actionId === actionId);
        if (index !== -1) {
            let actions = [...stateActions];
            actions.splice(index, 1);
            this.state.customQuery ? this.setState({customQueryActions: actions},) : this.setState({filterActions: actions})
        }
    };

    copyFilter = (filterId) => {
        //Get filter from filterId and just copy it
        let filterToCopy = this.state.filters.find(filter => filter.filterId === filterId)
        let filterCopy = filterToCopy.type === FILTER_TYPE_BOOLEAN_1
            ? { ...filterToCopy, filterId:this.state.filters.length, value: filterToCopy.value }
            : { ...filterToCopy, filterId:this.state.filters.length, value1: filterToCopy.value1, value2: filterToCopy.value2 }
        this.onFilterApply([filterCopy], true)
    }

    updateFilters = (newFilters) => {
        this.setState(
            {
                filters: newFilters,
            },
        );
    };

    updateActions = (newActions) => {
        this.state.customQuery ? this.setState({customQueryActions: newActions},) : this.setState({filterActions: newActions});
    };

    updateFilter = (newFilter) => {
        const index = this.state.filters.findIndex(
            (filter) => filter.filterId === newFilter.filterId
        );

        if (index !== -1) {
            const filters = [...this.state.filters];
            filters.splice(index, 1, { ...newFilter });
            this.setState(
                {
                    filters,
                },
            );
        }
    };

    updateAction = (newAction) => {
        let stateActions = this.state.customQuery ? this.state.customQueryActions : this.state.filterActions
        const index = stateActions.findIndex(
            (action) => action.actionId === newAction.actionId
        );

        if (index !== -1) {
            let actions = [...stateActions];
            actions.splice(index, 1, { ...newAction });
            this.state.customQuery ? this.setState({customQueryActions: actions},) : this.setState({filterActions: actions});
        }
    };

    handleAnyOrAllToggle = (event) => {
        this.setState(
            {
                applyAllFilters: event.target.checked,
            },
        );
    };

    thirdStepContent = () => {
        let querySection;
        let filterSection;
        if (this.state.autoDownload && this.state.customQuery) {
            filterSection=(
                <div>
                    <br />
                    <TextField
                        className='query-text-field'
                        variant='outlined'
                        label="Query"
                        InputLabelProps={{ shrink: true }}
                        name='query'
                        value={this.state.query}
                        onChange={this.handleTextChange}
                        required
                        error={this.state.saved && (this.state.query === '' || this.state.query === undefined)} //TODO: Add error check for query format
                    />
                    <Tooltip
                        className='infoTooltipIcon'
                        style={{float: "right"}}
                        enterTouchDelay={0}
                        leaveTouchDelay={30000}
                        enterDelay={400}
                        // leaveDelay={1000000} // for testing purposes
                        title={
                            <React.Fragment>
                                {INFO_ON_CUSTOM_QUERY}
                            </React.Fragment>
                        }
                    >
                        <IconButton aria-label='info'>
                            <InfoOutlinedIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </div>
            );
        }
        else if (!this.state.autoDownload && this.state.customQuery){
            filterSection=(
                <div>
                    <br />
                    <TextField
                        className='query-text-field'
                        variant='outlined'
                        label="Query"
                        InputLabelProps={{ shrink: true }}
                        name='query'
                        value={this.state.query}
                        onChange={this.handleTextChange}
                        //error={this.state.saved && this.state.name === ''} TODO: Add error check for query format
                    />
                    <Tooltip
                        className='infoTooltipIcon'
                        style={{float: "right"}}
                        enterTouchDelay={0}
                        leaveTouchDelay={30000}
                        enterDelay={400}
                        // leaveDelay={1000000} // for testing purposes
                        title={
                            <React.Fragment>
                                {INFO_ON_CONTENT_FILTER}
                            </React.Fragment>
                        }
                    >
                        <IconButton aria-label='info'>
                            <InfoOutlinedIcon variant='outlined' fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </div>
            );
        }
        else {
            filterSection=(
                <div>
                    <DndProvider backend={HTML5Backend}>
                        <DragAndDrop
                            cards={this.state.filters}
                            handleTableDisplayChange = {this.updateFilters}
                            items = {this.state.filters.map((filter, i) => {
                                switch (filter.type) {
                                    case FILTER_TYPE_BOOLEAN_1:
                                        return ({
                                            id: filter.filterId,
                                            component: <div>
                                                <FilterBooleanSelect
                                                    filter={filter}
                                                    updateFilterWithParent={this.updateFilter}
                                                    deleteFilter={this.deleteFilter}
                                                    isEdit={this.props.isEdit}
                                                    copyFilter={this.copyFilter}
                                                    key={filter.filterId}
                                                />
                                            </div>
                                        });
                                    case FILTER_TYPE_TEXT:
                                        return ({
                                            id: filter.filterId,
                                            component:<div>
                                                <QueryFilterTextField
                                                    filter={filter}
                                                    updateFilterWithParent={this.updateFilter}
                                                    deleteFilter={this.deleteFilter}
                                                    isEdit={this.props.isEdit}
                                                    copyFilter={this.copyFilter}
                                                    key={filter.filterId}
                                                />
                                            </div>
                                        });
                                }

                            })}
                        />
                    </DndProvider>
                    <Button
                        className='automationRulesFilterButton'
                        variant='contained'
                        color='primary'
                        startIcon={<AddIcon />}
                        onClick={() => this.showFilterDialog(true)}
                    >
                        {"Filter"}
                    </Button>
                    <br />
                    <FormControlLabel
                        className='anyAllSwitch'
                        label={
                            ANY_ALL_PART_1 +
                            ' ' +
                            (this.state.applyAllFilters ? ANY_ALL_PART_2B : ANY_ALL_PART_2A) +
                            ' ' +
                            ANY_ALL_PART_3
                        }
                        control={
                            <Switch
                                color='primary'
                                checked={this.state.applyAllFilters}
                                onChange={this.handleAnyOrAllToggle}
                            />
                        }
                    />
                </div>
            )
        }
        let stateActions = this.state.customQuery ? this.state.customQueryActions : this.state.filterActions
            querySection=(
                <div>
                     <QueryConditionDialog
                        isOpen={this.state.isFilterDialogOpen}
                        onClose={this.showFilterDialog}
                        onApply={this.onFilterApply}
                        tableType={TABLE_TYPES.FILTER}
                        isFilterSet={this.isFilterSet}
                    />
                    <QueryConditionDialog
                        isOpen={this.state.isActionDialogOpen}
                        onClose={this.showActionDialog}
                        onApply={this.onActionsApply}
                        tableType={TABLE_TYPES.ACTION}
                    />
                    <Toolbar className="conditionsToolbar"></Toolbar>
                    <div>
                        {filterSection}
                    </div>
                    <Divider className='divider' />
                    <div>
                        <DndProvider backend={HTML5Backend}>
                            <DragAndDrop
                                cards={stateActions}
                                handleTableDisplayChange={this.updateActions}
                                items = {stateActions.map((action, i) => {
                                    switch (action.type) {
                                        case ACTION_TYPE_BOOLEAN:
                                            return ({
                                                id: action.actionId,
                                                component: <div>
                                                    <QueryActionBoolean
                                                        action={action}
                                                        updateActionWithParent={this.updateAction}
                                                        deleteAction={this.deleteAction}
                                                        isEdit={this.props.isEdit}
                                                        key={action.actionId}
                                                    />
                                                </div>
                                            });
                                        case ACTION_TYPE_TEXT:
                                            return ({
                                                id: action.actionId,
                                                component: <div>
                                                    <QueryActionText
                                                        action={action}
                                                        updateActionWithParent={this.updateAction}
                                                        deleteAction={this.deleteAction}
                                                        isEdit={this.props.isEdit}
                                                        key={action.actionId}
                                                    />
                                                </div>
                                            });
                                    }
                                })}
                            />
                        </DndProvider>
                    </div>
                    <Button
                        className='queryActionButton'
                        variant='contained'
                        color='primary'
                        startIcon={<AddIcon />}
                        onClick={() => this.showActionDialog(true)}
                    >
                        {ADD_ACTION}
                    </Button>
                    {/*<TextField
                        className='subject-text-field'
                        variant='outlined'
                        label="Subject Contains (Seperate by commas)"
                        InputLabelProps={{ shrink: true }}
                        name='subject'
                        value={this.state.subject}
                        onChange={this.handleTextChange}
                    />
                    <Button onClick={this.handleSubjectsAnyAll}>
                        { this.state.subjectSearchType }
                    </Button>
                    <br />
                    <br />
                    <TextField
                        className='from-text-field'
                        variant='outlined'
                        label="From"
                        InputLabelProps={{ shrink: true }}
                        name='from'
                        value={this.state.from}
                        onChange={this.handleTextChange}
                    />
                    <br />
                    <br />
                    <TextField
                        className='body-text-field'
                        variant='outlined'
                        label="Body Contains (Seperate by commas)"
                        InputLabelProps={{ shrink: true }}
                        name='body'
                        value={this.state.body}
                        onChange={this.handleTextChange}
                    />
                    <Button onClick={this.handleBodyAnyAll}>
                        { this.state.bodySearchType }
                    </Button>
                    <br />
                    <br />
                    <TextField
                        className='label-text-field'
                        variant='outlined'
                        label="Labels (Seperate by commas)"
                        InputLabelProps={{ shrink: true }}
                        name='label'
                        value={this.state.label}
                        onChange={this.handleTextChange}
                    />
                    <Button onClick={this.handleLabelAnyAll}>
                        { this.state.labelSearchType }
                    </Button>
                    <br />
                    <br />
                    <FormControlLabel
                        className="hasAttachment"
                        control={
                            <Checkbox
                                checked={this.state.hasAttachment}
                                onChange={this.handleAttachmentCheck}
                            />
                        }
                        label={"Has Attachment"}
                    />*/}
                    <br />
                    <br />
                </div>
            );


        return (
                <div>
                    <div className='stepper-page-three-grid'>
                        <FormControlLabel
                            className="autoDownload"
                            control={
                                <Checkbox
                                    checked={this.state.autoDownload}
                                    onChange={this.handleAutoDownloadCheck}
                                />
                            }
                            label={"Enable automatic download of invoices"}
                        />
                        <br/>
                        <div>
                            <FormControlLabel
                                className='invoiceQuerySwitch'
                                control={
                                    <Switch
                                        color='primary'
                                        checked={this.state.hasZipPassword}
                                        onChange={(e) =>
                                            this.handlePasswordChanges([{
                                                name: "hasZipPassword",
                                                value: e.target.checked
                                            }])
                                        }
                                    />
                                }
                                label="Use password for UNZIP"
                            />
                            {this.state.hasZipPassword && (
                                <TextField
                                    style={{width: "15%"}}
                                    variant='outlined'
                                    label="ZIP Password"
                                    InputLabelProps={{shrink: true}}
                                    name='zipPassword'
                                    required
                                    InputProps={{
                                        style: {height: '44px'}
                                    }}
                                    value={this.state.zipPassword}
                                    onChange={(e) =>
                                        this.handlePasswordChanges([{
                                            name: "zipPassword",
                                            value: e.target.value
                                        }])
                                    }
                                    error={this.state.saved && this.state.zipPassword === ''}
                                />
                            )}
                        </div>
                        <div style={{marginTop: "8px", marginBottom: "8px"}}>
                            <FormControlLabel
                                className='invoiceQuerySwitch'
                                control={
                                    <Switch
                                        color='primary'
                                        checked={this.state.hasPdfPassword}
                                        onChange={(e) =>
                                            this.handlePasswordChanges([{
                                                name: "hasPdfPassword",
                                                value: e.target.checked
                                            }])
                                        }
                                    />
                                }
                                label="Use password for PDF"
                            />
                            {this.state.hasPdfPassword && (
                                <TextField
                                    style={{width: "15%", marginLeft: "16px"}}
                                    //style={{width:"15%",marginTop:"-16px"}}
                                    variant='outlined'
                                    label="PDF Password"
                                    InputLabelProps={{shrink: true}}
                                    name='pdfPassword'
                                    InputProps={{
                                        style: {height: '44px'}
                                    }}
                                    required
                                    value={this.state.pdfPassword}
                                    onChange={(e) =>
                                        this.handlePasswordChanges([{
                                            name: "pdfPassword",
                                            value: e.target.value
                                        }])
                                    }
                                    error={this.state.saved && this.state.pdfPassword === ''}
                                />
                            )}
                        </div>
                        {
                            /*
                            <Button onClick={this.handleQueryChange}>
                            { this.state.queryTypeName }
                        </Button>
                             */
                        }
                        <FormControlLabel
                            className='invoiceQuerySwitch'
                            control={
                                <Switch
                                    color='primary'
                                    checked={!this.state.customQuery}
                                    onChange={this.handleSwitchToggle}
                                />
                            }
                            label={
                                this.state.customQuery ? "Use custom query (Advanced)" : "Use the wizard to specify filters and actions (Recommended)"
                            }
                        />

                        <br/>
                    </div>
                    <Paper className='queryType' variant='outlined' style={{minWidth: 950}}>{querySection}</Paper>

                </div>
        )
    }

    handleDeleteTrue = (index) => {
        let newIsDelete = this.state.isDelete
        newIsDelete[index] = true
        this.setState({isDelete: newIsDelete})
    }

    handleDeleteFalse = (index) => {
        let newIsDelete = this.state.isDelete
        newIsDelete[index] = false
        this.setState({isDelete: newIsDelete})
    }

    handleProductShortcodeChange = (value, index) => {
        const {products} = this.state

        let shortcodes = products.map(product => product.shortcode)
        if (shortcodes.includes(value?.shortcode)) {
            let newShortcodeError = this.state.shortcodeError
            newShortcodeError[index] = true
            this.setState({
                shortcodeError: newShortcodeError
            })
        } else if (this.state.shortcodeError[index]) {
            let newShortcodeError = this.state.shortcodeError
            newShortcodeError[index] = false
            this.setState({
                shortcodeError: newShortcodeError
            })
        }

        products[index].shortcode = value && value.shortcode ? value.shortcode : ""
        products[index].supplierPartNumber = ""
        products[index].price = ""

        this.setState({
            products: products
        })
    }

    handleProductPriceChange = (e, index) => {
        const {products} = this.state

        products[index].price = e.target.value

        this.setState({
            products: products
        })
    }

    handleSupplierPartNumberChange = (e, index) => {
        const {products} = this.state

        products[index].supplierPartNumber = e.target.value

        this.setState({
            products: products
        })
    }

    supplierProductsIncomplete = () => {
        return this.state.products.some(product => {
            const isIncomplete = !product || !product.shortcode || !product.price || !product.supplierPartNumber
            return isIncomplete;
        });
    }

    checkNext = () => {
        return (this.state.name === '' || this.state.phone === '' || this.state.addressLine1 === '' || this.state.city === '' ||
            this.state.province === '' || this.state.postalCode === '' || this.state.country === '' || this.state.email === '' ||
            this.state.supplierShortcode === '' || this.state.currencyCode === '' ||  this.state.netTerm === '' ||
            (this.state.activeStep === 2 && this.state.autoDownload && this.state.customQuery && (this.state.query === '' || this.state.query === undefined)) ||
            (this.state.activeStep === 1 && this.checkDuplicateShortcode()))
    }

    handleExecute = () => {
        this.props.handleExecute(this.state)
    }

    handleNext = () => {
        // submitting
        {/*
        if(this.state.name == '' || this.state.phone == '' || this.state.addressLine1 == '' || this.state.city == '' || 
        this.state.province == '' || this.state.postalCode == '' || this.state.country == '' || this.state.email == '' || 
        this.state.supplierShortcode == ''){
            if(this.state.saved <= this.state.activeStep){
                this.setState({
                    saved: this.state.saved + 1,
                })
            }
        }else{
            if(this.state.activeStep === STEPPER_HEADINGS.length-1){
                // todo error checking
                this.props.handleSave(this.props.addingSupplier, this.state)
            }
            else {
                this.setState({
                    activeStep: this.state.activeStep + 1,
                    
                }
                );
            }
        }
        */
        }
        if (this.state.activeStep === STEPPER_HEADINGS.length - 1) {
            // todo error checking
            this.props.handleSave(this.props.addingSupplier, this.state)
        } else {
            this.setState({
                    activeStep: this.state.activeStep + 1,

                }
            );
        }
    }

    render() {
        if (typeof this.state.filters === 'undefined') {
            this.setState({filters: []})
        }
        if (typeof this.state.customQueryActions === 'undefined') {
            this.setState({customQueryActions: []})
        }
        if (typeof this.state.filterActions === 'undefined') {
            this.setState({filterActions: []})
        }
        if (typeof this.state.applyAllFilters === 'undefined') {
            this.setState({applyAllFilters: false})
        }
        if (typeof this.state.autoDownload === 'undefined') {
            this.setState({autoDownload: false})
        }
        if (typeof this.state.customQuery === 'undefined') {
            this.setState({customQuery: false})
        }
        let headerButtons
        if (this.props.addingSupplier) {
            headerButtons = [{
                icon: <ArrowBack />,
                handler: this.props.handleClose,
                title: this.props.backTitle
            },
                {
                    icon: <ArrowForward />,
                    handler: () => this.handleNext(STEPPER_HEADINGS.length),
                    disabled: this.supplierProductsIncomplete(this.state.products),
                    title: NEXT
                }
            ]
            if (this.state.activeStep === STEPPER_HEADINGS.length - 1 || this.checkNext()) {
                headerButtons[1].disabled = true
            }
        }
        else {
            headerButtons = [{
                icon: <ArrowBack />,
                handler: this.handleBack,
                title: this.state.activeStep !== 0 ? BACK : this.props.backTitle
            },
            {
                icon: <SaveIcon />,
                disabled: this.supplierProductsIncomplete(this.state.products),
                handler: () => this.props.handleSave(this.props.addingSupplier, this.state),
                title: 'Save'
            },
                {
                    icon: <ArrowForward />,
                    disabled: this.supplierProductsIncomplete(this.state.products),
                    handler: () => this.handleNext(STEPPER_HEADINGS.length),
                    title: NEXT
                }
            ]
            if (this.checkNext()) {
                headerButtons[1].disabled = true
            }
            if (this.state.activeStep === STEPPER_HEADINGS.length - 1 || this.checkNext()) {
                headerButtons[2].disabled = true
            }
        }
        return (
            <>
                <CustomToolbar
                    creatingOrder={this.props.creatingOrder}
                    title={this.props.title}
                    createTitle={this.props.createTitle}
                    backTitle={this.props.backTitle}
                    handleClose={this.handleBack}
                    headerButtons={headerButtons}
                />
                <Stepper activeStep={this.state.activeStep} alternativeLabel>
                    {STEPPER_HEADINGS.map((label, index) => (
                        <Step className='step' key={label}>
                            <StepButton
                                onClick={() => {
                                    this.setStep(index);
                                }}
                                optional={
                                    <Typography variant='caption'>
                                        {STEPPER_HEADING_DESCRIPTIONS[index]}
                                    </Typography>
                                }
                            >
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>

                <div className='supplier-stepper-align'>

                    {this.getStepContent(this.state.activeStep)}
                    {this.state.activeStep === STEPPER_HEADINGS.length - 1 ?
                        <div className='supplier-execute-invoice-query-button'>
                            <Button
                                //className='supplier-execute-invoice-query-button'
                                variant='contained'
                                color='primary'
                                onClick={() => this.handleExecute()}
                                disabled={this.checkNext()}
                            >
                                {EXECUTE}
                            </Button>
                            <Tooltip
                                //className='infoTooltipIcon'
                                enterTouchDelay={0}
                                leaveTouchDelay={30000}
                                enterDelay={400}
                                // leaveDelay={1000000} // for testing purposes
                                title={
                                    <React.Fragment>
                                        {INFO_ON_MANUAL_EXECUTE}
                                    </React.Fragment>
                                }
                            >
                                <IconButton aria-label='info'>
                                    <InfoOutlinedIcon variant='outlined' fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </div>
                        :
                        null
                    }
                    <div>
                        <Button
                            className={'supplier-save-invoice-query-button'}
                            variant='contained'
                            onClick={this.handleBack}
                        >
                            {this.state.activeStep === 0 ? CANCEL : BACK}
                        </Button>

                        <Button
                            className={'supplier-save-invoice-query-button'}
                            variant='contained'
                            color='primary'
                            onClick={() => this.handleNext(STEPPER_HEADINGS.length)}
                            disabled={this.checkNext() || this.supplierProductsIncomplete(this.state.products)}
                        >
                            {this.state.activeStep === STEPPER_HEADINGS.length - 1 ? SAVE : NEXT}
                        </Button>
                    </div>

                </div>
            </>
        );
    }
}


// todo prop types

ManageSuppliersStepper.propTypes = {
    addingSupplier: PropTypes.bool,
    products: PropTypes.array,
    packages: PropTypes.array
}

ManageSuppliersStepper.defaultProps = {
    addingSupplier: true,
    products: [],
    packages: []

}
export default ManageSuppliersStepper