import React from 'react'
import PropTypes from "prop-types";
import {Box, IconButton, Tooltip, Typography} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from "@material-ui/icons/Error";
import EditIcon from "@material-ui/icons/Edit";
import './CarrierInvoiceItemDetails.css'
import {
    CHARGES,
    CHARGES_ERROR,
    ITEM_DETAILS,
    ITEM_DETAILS_ERROR,
    PROCESS_FAILED_TOOLTIP,
    PROCESS_SUCCESS_TOOLTIP, RECIPIENT_INFO, RECIPIENT_INFO_ERROR, SENDER_INFO, SENDER_INFO_ERROR
} from "./constants";

class CarrierInvoiceItemDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    // function that takes the input headers and converts them from camel case to title case
    formatHeaders = (inputText) => {
        const result = inputText.replace(/([A-Z0-9])/g, " $1"); // add space before uppercase letters and digits
        const resultWithSpaces = result.replace(/[0-9]+/g, " $&"); // add space before numbers
        return resultWithSpaces.charAt(0).toUpperCase() + resultWithSpaces.slice(1); // capitalize the first letter
    }

    calculateTotalCharges = (itemDetails) => {
        let netTransportationCharge = 0
        if (itemDetails.netTransportationCharges) {
            netTransportationCharge = Object.values(itemDetails.netTransportationCharges)
                .map(val => parseFloat(val))
                .reduce((acc, val) => acc + val, 0)
                .toFixed(2)
        }

        let subtotalSum = 0
        if (itemDetails.subtotalCharges) {
            subtotalSum = Object.values(itemDetails.subtotalCharges)
                .map(val => parseFloat(val))
                .reduce((acc, val) => acc + val, 0)
                .toFixed(2)
        }
        const subtotalCharge = (parseFloat(netTransportationCharge) + parseFloat(subtotalSum)).toFixed(2)

        let totalSum = 0
        if (itemDetails.totalCharges) {
            totalSum = Object.values(itemDetails.totalCharges)
                .map(val => parseFloat(val))
                .reduce((acc, val) => acc + val, 0)
                .toFixed(2)
        }

        const totalCharge = (parseFloat(subtotalCharge) + parseFloat(totalSum)).toFixed(2)
        return {netTransportationCharge, subtotalCharge, totalCharge}
    }

    render() {
        return (
            <>
                {this.props.invoiceItemSmartSearchDetails.map((itemDetails) => {
                    const charges = this.calculateTotalCharges(itemDetails)
                    return (
                        <div className='invoice-item'>
                            <Box className='invoice-item-container'>
                                <div className="header-row">
                                    <div className="header-title">
                                        {itemDetails.error ?
                                            <Tooltip title={PROCESS_FAILED_TOOLTIP}>
                                                <ErrorIcon className='icons' style={{color:'#f50057', size: 'small'}}/>
                                            </Tooltip>
                                            :
                                            <Tooltip title={PROCESS_SUCCESS_TOOLTIP}>
                                                <CheckCircleIcon className='icons' style={{color: '#acd685', size: 'small'}}/>
                                            </Tooltip>
                                        }
                                        <Typography variant="h6">
                                            Tracking Id: {itemDetails.trackingId}
                                        </Typography>
                                    </div>
                                    <div className="header-icons">
                                        <IconButton onClick={() => this.props.openEditInvoiceEntry(itemDetails)}>
                                            <EditIcon style={{color: 'gray', size: 'small'}}/>
                                        </IconButton>
                                    </div>
                                </div>
                                <div className='invoice-number'>
                                    <p>Invoice Date: {this.props.invoices.find(invoice => invoice.invoiceNumber === itemDetails?.invoiceNumber)?.invoiceDate}</p>
                                </div>
                                <div className='invoice-number'>
                                    <p>Invoice Number: {itemDetails?.invoiceNumber}</p>
                                </div>
                                <div className='error-message'>
                                    {itemDetails.error ?
                                        <p>Error Message: {itemDetails.errorMessage}</p>
                                        : null
                                    }
                                </div>
                                <hr/>
                                <div className="invoice-item-information">
                                    <Box className="invoice-item-details-container">
                                        <h6>{ITEM_DETAILS}</h6>
                                        <hr/>
                                        {itemDetails.details && Object.keys(itemDetails.details).length > 0 ?
                                            Object.entries(itemDetails.details).map(([key, value]) => (
                                                <div className='invoice-item-details'>
                                                    <div className='invoice-item-details-header'>
                                                        <p key={key}>{this.formatHeaders(key)} </p>
                                                    </div>
                                                    <div className='invoice-item-details-value'>
                                                        <p key={key}>{value} </p>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <div className='invoice-item-details-error'>
                                                <p>{ITEM_DETAILS_ERROR}</p>
                                            </div>
                                        }
                                    </Box>
                                    <Box className="invoice-item-details-container">
                                        <h6>{CHARGES}</h6>
                                        <hr/>
                                        {itemDetails.charges && Object.keys(itemDetails.charges).length > 0 ?
                                            <>
                                            {Object.entries(itemDetails.charges).map(([key, value]) => (
                                                                <div className='invoice-item-details'>
                                                                    <div className='invoice-item-details-header'>
                                                                        <p key={key}>{this.formatHeaders(key)}</p>
                                                                    </div>
                                                                    <div className='invoice-item-details-value invoice-item-charges-value'>
                                                                        <p key={key}>{value}</p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                            </>
                                            // <>
                                            //     {itemDetails.netTransportationCharges && Object.keys(itemDetails.netTransportationCharges).length > 0 ?
                                            //         <>
                                            //             {Object.entries(itemDetails.netTransportationCharges).map(([key, value]) => (
                                            //                 <div className='invoice-item-details'>
                                            //                     <div className='invoice-item-details-header'>
                                            //                         <p key={key}>{this.formatHeaders(key)}</p>
                                            //                     </div>
                                            //                     <div className='invoice-item-details-value invoice-item-charges-value'>
                                            //                         <p key={key}>{value}</p>
                                            //                     </div>
                                            //                 </div>
                                            //             ))}
                                            //             <div className='invoice-item-details'>
                                            //                 <div className='invoice-item-details-header invoice-item-total'>
                                            //                     <p>Net Transportation Charges</p>
                                            //                 </div>
                                            //                 <div className='invoice-item-details-value invoice-item-charges-value invoice-item-total'>
                                            //                     <p>{charges.netTransportationCharge}</p>
                                            //                 </div>
                                            //             </div>
                                            //         </> : null}
                                            //     {itemDetails.subtotalCharges && Object.keys(itemDetails.subtotalCharges).length > 0 ?
                                            //         <>
                                            //             {Object.entries(itemDetails.subtotalCharges).map(([key, value]) => (
                                            //                 <div className='invoice-item-details'>
                                            //                     <div className='invoice-item-details-header'>
                                            //                         <p key={key}>{this.formatHeaders(key)}</p>
                                            //                     </div>
                                            //                     <div className='invoice-item-details-value invoice-item-charges-value'>
                                            //                         <p key={key}>{value}</p>
                                            //                     </div>
                                            //                 </div>
                                            //             ))}
                                            //             <div className='invoice-item-details'>
                                            //                 <div className='invoice-item-details-header invoice-item-total'>
                                            //                     <p>Subtotal</p>
                                            //                 </div>
                                            //                 <div className='invoice-item-details-value invoice-item-charges-value invoice-item-total'>
                                            //                     <p>{charges.subtotalCharge}</p>
                                            //                 </div>
                                            //             </div>
                                            //         </> : null}
                                            //     {itemDetails.totalCharges && Object.keys(itemDetails.totalCharges).length > 0 ?
                                            //         <>
                                            //             {Object.entries(itemDetails.totalCharges).map(([key, value]) => (
                                            //                 <div className='invoice-item-details'>
                                            //                     <div className='invoice-item-details-header'>
                                            //                         <p key={key}>{this.formatHeaders(key)}</p>
                                            //                     </div>
                                            //                     <div className='invoice-item-details-value invoice-item-charges-value'>
                                            //                         <p key={key}>{value}</p>
                                            //                     </div>
                                            //                 </div>
                                            //             ))}
                                            //             <div className='invoice-item-details'>
                                            //                 <div className='invoice-item-details-header invoice-item-total'>
                                            //                     <p>TOTAL</p>
                                            //                 </div>
                                            //                 <div className='invoice-item-details-value invoice-item-charges-value invoice-item-total'>
                                            //                     <p>{charges.totalCharge}</p>
                                            //                 </div>
                                            //             </div>
                                            //         </> : null}
                                            // </>
                                            :
                                            <div className='invoice-item-details-error'>
                                                <p>{CHARGES_ERROR}</p>
                                            </div>
                                        }
                                    </Box>
                                    <Box className="invoice-item-details-half-split-container">
                                        <div className='sender-recipient-information'>
                                            <h6>{SENDER_INFO}</h6>
                                            <hr/>
                                            {itemDetails.sender && Object.keys(itemDetails.sender).length > 0 ?
                                                Object.entries(itemDetails.sender).map(([key, value]) => (
                                                    <div className='invoice-item-details'>
                                                        <div className='invoice-item-details-header'>
                                                            <p key={key}>{this.formatHeaders(key)} </p>
                                                        </div>
                                                        <div className='invoice-item-details-value'>
                                                            <p key={key}>{value} </p>
                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                <div className='invoice-item-details-error'>
                                                    <p>{SENDER_INFO_ERROR}</p>
                                                </div>
                                            }
                                        </div>
                                        <div className='sender-recipient-information'>
                                            <h6>{RECIPIENT_INFO}</h6>
                                            <hr/>
                                            {itemDetails.recipient && Object.keys(itemDetails.recipient).length > 0 ?
                                                Object.entries(itemDetails.recipient).map(([key, value]) => (
                                                    <div className='invoice-item-details'>
                                                        <div className='invoice-item-details-header'>
                                                            <p key={key}>{this.formatHeaders(key)} </p>
                                                        </div>
                                                        <div className='invoice-item-details-value'>
                                                            <p key={key}>{value} </p>
                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                <div className='invoice-item-details-error'>
                                                    <p>{RECIPIENT_INFO_ERROR}</p>
                                                </div>
                                            }
                                        </div>
                                    </Box>
                                </div>
                            </Box>
                        </div>
                    )
                })}
            </>
        )
    }
}

CarrierInvoiceItemDetails.propTypes = {
    invoiceItemSmartSearchDetails: PropTypes.object
}

CarrierInvoiceItemDetails.defaultProps = {
    invoiceItemSmartSearchDetails: {}
}

export default CarrierInvoiceItemDetails

