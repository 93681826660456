export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const LIST_CUSTOMERS = "LIST_CUSTOMERS";
export const STORE_CUSTOMERS = "STORE_CUSTOMERS";
export const PROCESS_CUSTOMER_INVOICES = "CUSTOMER_PROCESS_CUSTOMER_INVOICES";

export const CUSTOMER_FETCH_ALL_SUPPLIERS = 'CUSTOMER_FETCH_ALL_SUPPLIERS'
export const CUSTOMER_CREATE_NEW_PURCHASE_ORDER = 'CUSTOMER_CREATE_NEW_PURCHASE_ORDER'
export const CUSTOMER_CREATE_SEND_NEW_PURCHASE_ORDER = 'CUSTOMER_CREATE_SEND_NEW_PURCHASE_ORDER'

export const CUSTOMER_GET_PDF_PREVIEW = 'CUSTOMER_GET_PDF_PREVIEW'
export const CUSTOMER_SLIP_PDF_PREVIEW = 'CUSTOMER_SLIP_PDF_PREVIEW'
export const CUSTOMER_STORE_PDF_PREVIEW_READY = 'CUSTOMER_STORE_PDF_PREVIEW_READY'
export const CUSTOMER_CACHE_PDF_PREVIEW = 'CUSTOMER_CACHE_PDF_PREVIEW'
export const CUSTOMER_STORE_PENDING_ORDERS = 'CUSTOMER_STORE_PENDING_ORDERS'
export const CUSTOMER_STORE_ALL_PURCHASE_ORDERS = 'CUSTOMER_STORE_ALL_PURCHASE_ORDERS'
export const CUSTOMER_FETCH_ALL_PURCHASE_ORDERS = 'CUSTOMER_FETCH_ALL_PURCHASE_ORDERS'
export const CUSTOMER_STORE_NEW_PURCHASE_ORDERS = 'CUSTOMER_STORE_NEW_PURCHASE_ORDERS'
export const CUSTOMER_STORE_PARTIAL_PURCHASE_ORDERS = 'CUSTOMER_STORE_PARTIAL_PURCHASE_ORDERS'
export const CUSTOMER_STORE_COMPLETED_PURCHASE_ORDERS = 'CUSTOMER_STORE_COMPLETED_PURCHASE_ORDERS'

export const CUSTOMER_UPDATE_PURCHASE_ORDER = 'CUSTOMER_UPDATE_PURCHASE_ORDER'
export const CUSTOMER_DELETE_INVENTORY_RECEIVED = 'CUSTOMER_DELETE_INVENTORY_RECEIVED'
export const CUSTOMER_STORE_UPDATED_PURCHASE_ORDER = 'CUSTOMER_STORE_UPDATED_PURCHASE_ORDER';

export const CUSTOMER_PREVIEW_CREATED_PURCHASE_ORDER = 'CUSTOMER_PREVIEW_CREATED_PURCHASE_ORDER';
export const CUSTOMER_STORE_CREATED_PURCHASE_ORDER_PREVIEW = 'CUSTOMER_STORE_CREATED_PURCHASE_ORDER_PREVIEW';

export const CUSTOMER_PREVIEW_PACKING_SLIP_FILE = 'CUSTOMER_PREVIEW_PACKING_SLIP_FILE';
export const CUSTOMER_STORE_UPDATED_PURCHASE_ORDER_PREVIEW = 'CUSTOMER_STORE_UPDATED_PURCHASE_ORDER_PREVIEW'
export const CUSTOMER_DELETE_PACKING_SLIP = 'CUSTOMER_DELETE_PACKING_SLIP';
export const CUSTOMER_CANCEL_PURCHASE_ORDER = 'CUSTOMER_CANCEL_PURCHASE_ORDER';
export const CUSTOMER_EDIT_PURCHASE_ORDER = 'CUSTOMER_EDIT_PURCHASE_ORDER';
export const CUSTOMER_UNDO_CANCEL = 'CUSTOMER_UNDO_CANCEL';

export const CUSTOMER_RESET_PDF_PREVIEW_DATA = "CUSTOMER_RESET_PDF_PREVIEW_DATA";

export const CUSTOMER_UPDATE_EXPECTED_DELIVERY_DATE = "CUSTOMER_UPDATE_EXPECTED_DELIVERY_DATE"

export const GET_ALL_CUSTOMER_INVOICES = 'GET_ALL_CUSTOMER_INVOICES'

export const STORE_ALL_CUSTOMER_INVOICES = 'STORE_ALL_CUSTOMER_INVOICES'

export const UPDATE_CUSTOMER_INVOICE = 'UPDATE_CUSTOMER_INVOICE'

export const CREATE_CUSTOMER_INVOICE = 'CREATE_CUSTOMER_INVOICE'

export const DELETE_CUSTOMER_INVOICE = "DELETE_CUSTOMER_INVOICE"

export const DELETE_CUSTOMER_ORDER = "DELETE_CUSTOMER_ORDER"
export const STORE_CUSTOMER_INVOICE_OPERATION_RESPONSE = 'STORE_CUSTOMER_INVOICE_OPERATION_RESPONSE';

export const STORE_UPDATED_CUSTOMER_INVOICE = "STORE_UPDATED_CUSTOMER_INVOICE"

export const STORE_CUSTOMER_INVOICE_PDF = 'STORE_CUSTOMER_INVOICE_PDF'

export const REMOVE_DELETED_CUSTOMER_INVOICES = "REMOVE_DELETED_CUSTOMER_INVOICES";
export const REMOVE_DELETED_CUSTOMER_ORDER = "REMOVE_DELETED_CUSTOMER_ORDER";

export const UPDATE_CUSTOMER_INVOICE_CHANGED = 'UPDATE_CUSTOMER_INVOICE_CHANGED';

export const GET_CUSTOMER_INVOICE_PO = 'GET_CUSTOMER_INVOICE_PO';

export const STORE_CUSTOMER_INVOICE_PO = 'STORE_CUSTOMER_INVOICE_PO';

export const GET_CUSTOMER_INVOICE_PDF = 'GET_CUSTOMER_INVOICE_PDF'

export const UPDATE_CUSTOMER_INVOICE_STATUS = "UPDATE_CUSTOMER_INVOICE_STATUS"

export const DELETE_CUSTOMER_INVOICE_ATTACHMENT = 'DELETE_CUSTOMER_INVOICE_ATTACHMENT'

export const GET_CUSTOMER_INVOICE_ATTACHMENT = "GET_CUSTOMER_INVOICE_ATTACHMENT"



